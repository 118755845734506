define('cn-front/components/social-auth', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
    var inject = _ember['default'].inject;

    /*globals FB*/
    exports['default'] = _ember['default'].Component.extend({
        apiConnect: inject.service('api-resolver'),
        currentUser: inject.service('current-user'),
        redirect: false,
        login: true,
        type: 'Register',

        FB_AuthUser: function FB_AuthUser(socialToken) {
            var self = this;
            var flashMessages = _ember['default'].get(this, 'flashMessages');
            var url = self.get('apiConnect').getUrl('/signin/social');
            var callbacks = self.get('targetObject');
            var createMethod = this.get('type') + ' FB';

            $.ajax({
                type: "POST",
                url: url,
                data: { 'socialInfo': socialToken, 'create_method': createMethod },
                success: function success(data) {
                    self.get('currentUser').login(data);
                    _cnFrontUtilsGtm['default'].trackEvent({ event: 'fb_login' });
                    if (self.get('redirect')) {
                        callbacks.redirectToPrevious();
                    } else {
                        if (callbacks.hasOwnProperty('onSelect')) {
                            callbacks.onSelect();
                        } else {
                            callbacks.verifyUser();
                        }
                    }
                },
                error: function error(data) {
                    if (data.responseJSON.error === 'tos_not_accepted') {
                        self.get('currentUser').setTemporaryToken(data.responseJSON.token);
                        if (data.responseJSON.register) {
                            _cnFrontUtilsGtm['default'].trackEvent({ event: 'fb_register' });
                        }
                        if (!self.get('redirect')) {
                            callbacks.set('toslogin', false);
                        }
                        self.sendAction('showTos');
                    } else {
                        flashMessages.danger(data.responseJSON.message);
                    }
                }
            });
        },
        actions: {
            facebookLogin: function facebookLogin() {
                var self = this;
                console.log('action');
                FB.login(function (response) {
                    if (response.status === 'connected') {
                        var socialToken = { 'accessToken': response.authResponse.accessToken, social: 'facebook' };
                        self.FB_AuthUser(socialToken);
                    }
                }, {
                    // scope: 'publish_actions, email',
                    scope: 'public_profile, email',
                    return_scopes: true
                });
            }
        }
    });
});