define("cn-front/templates/components/campground/header-slider", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 16
                },
                "end": {
                  "line": 7,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "id", "0");
              dom.setAttribute(el1, "class", "photo photo-blur");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element9, 'style');
              return morphs;
            },
            statements: [["attribute", "style", ["subexpr", "css-styles", [], ["background-image", ["subexpr", "concat", ["url(", ["get", "campground.photos.0.small", ["loc", [null, [6, 108], [6, 135]]], 0, 0, 0, 0], ")"], [], ["loc", [null, [6, 93], [6, 140]]], 0, 0]], ["loc", [null, [null, null], [6, 143]]], 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 16
                },
                "end": {
                  "line": 9,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "id", "0");
              dom.setAttribute(el1, "class", "photo");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element8, 'style');
              morphs[1] = dom.createElementMorph(element8);
              return morphs;
            },
            statements: [["attribute", "style", ["subexpr", "css-styles", [], ["background-image", ["subexpr", "concat", ["url(", ["get", "campground.photos.0.small", ["loc", [null, [8, 116], [8, 143]]], 0, 0, 0, 0], ")"], [], ["loc", [null, [8, 101], [8, 148]]], 0, 0]], ["loc", [null, [null, null], [8, 151]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["show", 0], [], ["loc", [null, [8, 32], [8, 51]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 12
              },
              "end": {
                "line": 10,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "campground.photos.0.is_cg_photo", ["loc", [null, [5, 22], [5, 55]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [5, 16], [9, 23]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 12
              },
              "end": {
                "line": 12,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "0");
            dom.setAttribute(el1, "class", "photo photo-blur");
            dom.setAttribute(el1, "style", "background-image: url('/assets/images/cg/cover-placeholder_2.jpg');");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 16
                },
                "end": {
                  "line": 16,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "id", "1");
              dom.setAttribute(el1, "class", "photo photo-blur");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element7, 'style');
              return morphs;
            },
            statements: [["attribute", "style", ["subexpr", "css-styles", [], ["background-image", ["subexpr", "concat", ["url(", ["get", "campground.photos.1.small", ["loc", [null, [15, 108], [15, 135]]], 0, 0, 0, 0], ")"], [], ["loc", [null, [15, 93], [15, 140]]], 0, 0]], ["loc", [null, [null, null], [15, 143]]], 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 16
                },
                "end": {
                  "line": 18,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "id", "1");
              dom.setAttribute(el1, "class", "photo");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element6, 'style');
              morphs[1] = dom.createElementMorph(element6);
              return morphs;
            },
            statements: [["attribute", "style", ["subexpr", "css-styles", [], ["background-image", ["subexpr", "concat", ["url(", ["get", "campground.photos.1.small", ["loc", [null, [17, 119], [17, 146]]], 0, 0, 0, 0], ")"], [], ["loc", [null, [17, 104], [17, 151]]], 0, 0]], ["loc", [null, [null, null], [17, 154]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["show", 1], [], ["loc", [null, [17, 33], [17, 52]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 12
              },
              "end": {
                "line": 19,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "campground.photos.1.is_cg_photo", ["loc", [null, [14, 22], [14, 55]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [14, 16], [18, 23]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 12
              },
              "end": {
                "line": 21,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "1");
            dom.setAttribute(el1, "class", "photo photo-blur");
            dom.setAttribute(el1, "style", "background-image: url('/assets/images/cg/cover-placeholder_2.jpg');");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 16
                },
                "end": {
                  "line": 25,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "id", "2");
              dom.setAttribute(el1, "class", "photo photo-blur");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element5, 'style');
              return morphs;
            },
            statements: [["attribute", "style", ["subexpr", "css-styles", [], ["background-image", ["subexpr", "concat", ["url(", ["get", "campground.photos.2.small", ["loc", [null, [24, 108], [24, 135]]], 0, 0, 0, 0], ")"], [], ["loc", [null, [24, 93], [24, 140]]], 0, 0]], ["loc", [null, [null, null], [24, 143]]], 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 16
                },
                "end": {
                  "line": 27,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "id", "2");
              dom.setAttribute(el1, "class", "photo");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element4, 'style');
              morphs[1] = dom.createElementMorph(element4);
              return morphs;
            },
            statements: [["attribute", "style", ["subexpr", "css-styles", [], ["background-image", ["subexpr", "concat", ["url(", ["get", "campground.photos.2.small", ["loc", [null, [26, 117], [26, 144]]], 0, 0, 0, 0], ")"], [], ["loc", [null, [26, 102], [26, 149]]], 0, 0]], ["loc", [null, [null, null], [26, 152]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["show", 2], [], ["loc", [null, [26, 32], [26, 51]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 12
              },
              "end": {
                "line": 28,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "campground.photos.2.is_cg_photo", ["loc", [null, [23, 22], [23, 55]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [23, 16], [27, 23]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 12
              },
              "end": {
                "line": 30,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "2");
            dom.setAttribute(el1, "class", "photo photo-blur");
            dom.setAttribute(el1, "style", "background-image: url('/assets/images/cg/cover-placeholder_3.jpg');");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 20
                  },
                  "end": {
                    "line": 35,
                    "column": 20
                  }
                },
                "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "id", "3");
                dom.setAttribute(el1, "class", "photo photo-blur");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element3, 'style');
                return morphs;
              },
              statements: [["attribute", "style", ["subexpr", "css-styles", [], ["background-image", ["subexpr", "concat", ["url(", ["get", "campground.photos.3.small", ["loc", [null, [34, 112], [34, 139]]], 0, 0, 0, 0], ")"], [], ["loc", [null, [34, 97], [34, 144]]], 0, 0]], ["loc", [null, [null, null], [34, 147]]], 0, 0], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 35,
                    "column": 20
                  },
                  "end": {
                    "line": 37,
                    "column": 20
                  }
                },
                "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "id", "3");
                dom.setAttribute(el1, "class", "photo");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element2, 'style');
                morphs[1] = dom.createElementMorph(element2);
                return morphs;
              },
              statements: [["attribute", "style", ["subexpr", "css-styles", [], ["background-image", ["subexpr", "concat", ["url(", ["get", "campground.photos.3.small", ["loc", [null, [36, 121], [36, 148]]], 0, 0, 0, 0], ")"], [], ["loc", [null, [36, 106], [36, 153]]], 0, 0]], ["loc", [null, [null, null], [36, 156]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["show", 3], [], ["loc", [null, [36, 36], [36, 55]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 16
                },
                "end": {
                  "line": 38,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "campground.photos.3.is_cg_photo", ["loc", [null, [33, 26], [33, 59]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [33, 20], [37, 27]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 16
                },
                "end": {
                  "line": 40,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "id", "3");
              dom.setAttribute(el1, "class", "photo photo-blur");
              dom.setAttribute(el1, "style", "background-image: url('/assets/images/cg/cover-placeholder_4.jpg');");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 12
              },
              "end": {
                "line": 41,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "gte", [["get", "campground.photos.length", ["loc", [null, [32, 27], [32, 51]]], 0, 0, 0, 0], 4], [], ["loc", [null, [32, 22], [32, 54]]], 0, 0]], [], 0, 1, ["loc", [null, [32, 16], [40, 23]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 43,
              "column": 8
            }
          },
          "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "gte", [["get", "campground.photos.length", ["loc", [null, [4, 23], [4, 47]]], 0, 0, 0, 0], 1], [], ["loc", [null, [4, 18], [4, 50]]], 0, 0]], [], 0, 1, ["loc", [null, [4, 12], [12, 19]]]], ["block", "if", [["subexpr", "gte", [["get", "campground.photos.length", ["loc", [null, [13, 23], [13, 47]]], 0, 0, 0, 0], 2], [], ["loc", [null, [13, 18], [13, 50]]], 0, 0]], [], 2, 3, ["loc", [null, [13, 12], [21, 19]]]], ["block", "if", [["subexpr", "gte", [["get", "campground.photos.length", ["loc", [null, [22, 23], [22, 47]]], 0, 0, 0, 0], 3], [], ["loc", [null, [22, 18], [22, 50]]], 0, 0]], [], 4, 5, ["loc", [null, [22, 12], [30, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "type", ["loc", [null, [31, 22], [31, 26]]], 0, 0, 0, 0], "cs"], [], ["loc", [null, [31, 18], [31, 32]]], 0, 0]], [], 6, null, ["loc", [null, [31, 12], [41, 19]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 12
              },
              "end": {
                "line": 49,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "background-image: url('/assets/images/cg/cover-placeholder_4.jpg');");
            dom.setAttribute(el1, "class", "photo photo-blur");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 8
            },
            "end": {
              "line": 50,
              "column": 8
            }
          },
          "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "background-image: url('/assets/images/cg/cover-placeholder_1.jpg');");
          dom.setAttribute(el1, "class", "photo photo-blur");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "background-image: url('/assets/images/cg/cover-placeholder_2.jpg');");
          dom.setAttribute(el1, "class", "photo photo-blur");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "background-image: url('/assets/images/cg/cover-placeholder_3.jpg');");
          dom.setAttribute(el1, "class", "photo photo-blur");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "type", ["loc", [null, [47, 22], [47, 26]]], 0, 0, 0, 0], "cs"], [], ["loc", [null, [47, 18], [47, 32]]], 0, 0]], [], 0, null, ["loc", [null, [47, 12], [49, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 8
            },
            "end": {
              "line": 58,
              "column": 8
            }
          },
          "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "header-map");
          dom.setAttribute(el1, "class", "map");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("section");
          dom.setAttribute(el2, "class", "map-section");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "id", "header-map-canvas");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createAttrMorph(element1, 'data-lat');
          morphs[2] = dom.createAttrMorph(element1, 'data-lng');
          return morphs;
        },
        statements: [["element", "action", ["goToMap"], [], ["loc", [null, [52, 45], [52, 65]]], 0, 0], ["attribute", "data-lat", ["concat", [["get", "campground.latitude", ["loc", [null, [54, 60], [54, 79]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "data-lng", ["concat", [["get", "campground.longitude", ["loc", [null, [55, 37], [55, 57]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 61,
            "column": 6
          }
        },
        "moduleName": "cn-front/templates/components/campground/header-slider.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "pagebanner");
        dom.setAttribute(el1, "data-photos", "");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "images");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element10, 1, 1);
        morphs[1] = dom.createMorphAt(element10, 2, 2);
        return morphs;
      },
      statements: [["block", "if", [["get", "campground.photos", ["loc", [null, [3, 14], [3, 31]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [3, 8], [50, 15]]]], ["block", "if", [["subexpr", "not-eq", [["get", "type", ["loc", [null, [51, 22], [51, 26]]], 0, 0, 0, 0], "cs"], [], ["loc", [null, [51, 14], [51, 32]]], 0, 0]], [], 2, null, ["loc", [null, [51, 8], [58, 15]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});