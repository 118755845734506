define('cn-front/components/explore/camp-card', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    source: null,
    type: 'similar_card', /* one of [featured_card, explore_list, similar_card] */
    classNameBindings: ['type'],
    isEmpty: (function () {
      return parseInt(this.get('camp.cs_total')) === 0;
    }).property('camp'),
    isSingle: (function () {
      return parseInt(this.get('camp.cs_total')) === 1;
    }).property('camp'),

    distance: (function () {
      return !!this.get('sortBy') && this.get('sortBy') === 'distmap' ? this.get('camp.distance_from_map_center') : this.get('camp.distance');
    }).property('sortBy'),

    didReceiveAttrs: function didReceiveAttrs() {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        $('#' + this.get('elementId') + ' .desc').dotdotdot({
          watch: "window"
        });
      });
    },

    willClearRender: function willClearRender() {},

    actions: {
      goToCampground: function goToCampground() {
        var params = this.get('camp.url_params');
        this.get('router').transitionTo('campground', params.country, params.state, params.city, params.slug, { queryParams: { source: this.get('source') } });
      }
    }
  });
});