define('cn-front/components/state-pages-list', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Component.extend({
        ustates: inject.service(),
        stateList: (function () {
            var states = this.get('ustates').data;
            var res = [];
            for (var key in states) {
                if (states.hasOwnProperty(key)) {
                    if (key === 'DC') {
                        continue;
                    }
                    var state = states[key];
                    if (state.hasOwnProperty('code') && !state.hasOwnProperty('code_lowercase')) {
                        state.code_lowercase = state['code'].toLowerCase();
                    }
                    res.push(state);
                }
            }
            return res.slice(0, 52).sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
        }).property()
    });
});