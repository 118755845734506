define('cn-front/components/dashboard/avail-report', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        data: {},
        startDate: '',
        endDate: '',
        initLayout: function initLayout() {

            // Get reports object, start and end dates to work with
            var reports = this.get('data');
            var startDate = this.get('startDate');
            var endDate = this.get('endDate');

            // Cash selectors of result table and empty their elements' content
            var reportsTableHead = $('#availability-reports-table').find('thead');
            reportsTableHead.empty();
            var reportsTableBody = $('#availability-reports-table').find('tbody');
            reportsTableBody.empty();
            var reportsTableHeadSuppTable = $('#availability-reports-table-supp').find('thead');
            reportsTableHeadSuppTable.empty();
            var reportsTableBodySuppTable = $('#availability-reports-table-supp').find('tbody');
            reportsTableBodySuppTable.empty();

            // Get report dates array
            function getReportDates(start, end) {
                var datesArray = [];
                var current = moment(start);
                end = moment(end);
                while (current <= end) {
                    datesArray.push(moment(current).format('YYYY-MM-DD'));
                    current = moment(current).add(1, 'days');
                }
                return datesArray;
            }
            var reportDates = getReportDates(startDate, endDate);

            if (reportDates.length > 20) {
                var reportDatesSecondPart = reportDates.splice(Math.floor(reportDates.length / 2));

                var headRowSupp = this.renderCalendarHeader(reportDatesSecondPart);
                reportsTableHeadSuppTable.append(headRowSupp);

                var bodyRowSupp = this.renderCalendarBody(reports, reportDatesSecondPart);
                reportsTableBodySuppTable.append(bodyRowSupp);
            }

            var headRow = this.renderCalendarHeader(reportDates);
            reportsTableHead.append(headRow);

            var bodyRow = this.renderCalendarBody(reports, reportDates);
            reportsTableBody.append(bodyRow);
        },

        renderCalendarHeader: function renderCalendarHeader(reportDates) {
            // Create table head row
            var headRow = '<tr><th class="empty-cell"></th>';
            // Feel table head with the set of report dates
            _ember['default'].$.each(reportDates, function (index, value) {
                var placeholder = moment(value).format('D');

                // TODO: If we finally won't need this functionality, clean this comment out
                // var day = moment(value).format('dddd');
                // var dayAndMonth = moment(value).format('MMM D');
                // placeholder += '<strong>' + day.substr(0, 3) + '</strong><br>' + dayAndMonth;

                headRow += '<th>' + placeholder + '</th>';
            });
            headRow += '</tr>';
            return headRow;
        },

        renderCalendarBody: function renderCalendarBody(reports, reportDates) {
            // For each campsite append row to table body
            var bodyRow = '';
            _ember['default'].$.each(reports, function (key, value) {
                bodyRow += '<tr><td>' + key + '</td>';
                _ember['default'].$.each(reportDates, function (index, date) {
                    var itemClass = '';
                    switch (value[date].status) {
                        case 'available':
                            itemClass = 'icon-available green';
                            break;
                        case 'instant':
                        case 'confirm':
                        case 'on_site':
                            itemClass = 'icon-closed red';
                            break;
                        case 'out-of-season':
                            itemClass = 'icon-outofseason black';
                            break;
                        case 'fcfs':
                            itemClass = 'icon-site_first_come_icon yellow';
                            break;
                        case 'closed':
                            itemClass = 'icon-closed black';
                            break;
                        case 'in_use':
                        case 'maint':
                        case 'confirm_pending':
                            itemClass = 'icon-closed grey';
                            break;
                        default:
                            itemClass = 'wrong-type purple';
                            break;
                    }
                    bodyRow += '<td data-date="' + date + '"><span class="' + itemClass + '"></span></td>';
                });
                bodyRow += '</tr>';
            });
            return bodyRow;
        },
        didInsertElement: function didInsertElement() {
            this.initLayout();
        },
        onDataChange: (function () {
            this.initLayout();
        }).observes('data')
    });
});