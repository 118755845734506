define('cn-front/components/ava-calendar/cs-row', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        campsite: null,
        reservations: null,
        classNames: ['cs-row'],
        cgView: true,
        onlineReservations: true,

        didReceiveAttrs: function didReceiveAttrs() {
            if (this.get('reservations')) {
                var csReservationsc = this.get('reservations')[this.get('campsite.id')];
                this.set('campsite.reservations', csReservationsc);
            }
        },

        actions: {
            toReservation: function toReservation(data, campsite) {
                console.log('calendar = ', data, campsite);
                this.sendAction("goToReservation", data, campsite);
            },

            toCampsite: function toCampsite(id) {
                this.get('router').transitionTo('campground.campsite', id);
            }
        }
    });
});