define('cn-front/routes/dashboard/campground/edit/pendings', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),

        model: function model() /*params*/{
            _ember['default'].$.ajaxSetup({
                headers: {
                    'Authorization': 'bearer ' + $.cookie('token')
                },
                async: false
            });
            var cg = this.modelFor('dashboard.campground.edit');
            var reservations = _ember['default'].$.getJSON(this.get('apiConnect').getUrl('/dashboard/campgrounds/' + cg.general.id + '/pending'));

            return {
                reservations: reservations.responseJSON,
                cg: cg
            };
        }
    });
});