define('cn-front/mixins/responsive-campground', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        activate: function activate() {
            this._super.apply(this, arguments); // Call super
            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                var jPBanner = $('#pagebanner');
                var header_height = $('#header').outerHeight(true);
                var pagebanner_inner_height = jPBanner.find('.inner').outerHeight(true);
                // var campnav_height = $('#camp-nav').outerHeight(true);

                $('#camp-nav').addClass('sticky').css({
                    'top': header_height + pagebanner_inner_height + 'px'
                });

                // need to change filters visible when resize
                $(window).on('resize', function () {
                    var winWidth = $(this).width();
                    if (winWidth < 992) {
                        $(".filter-wrapper a[data-toggle='collapse']:not(.collapsed)").addClass('wasExpanded').click();
                    } else {
                        $(".wasExpanded.collapsed").click();
                    }
                }).trigger('resize');

                // force most of the tabs to open
                $('#camp-details a[data-toggle="tab"]:not(:first)').on('shown.bs.tab', function () {
                    $('#pagebanner .images').hide();
                    $('.tab-title').html(this.innerHTML);
                    $('body,html').animate({
                        scrollTop: 0
                    }, 50);
                });
                $('#camp-details a[data-toggle="tab"]:first').on('shown.bs.tab', function () {
                    $('#pagebanner .images').show();
                    $('.tab-title').html(this.innerHTML);
                    $('body,html').animate({
                        scrollTop: 0
                    }, 50);
                });
            });
        }
    });
});