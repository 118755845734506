define('cn-front/components/user/details-info', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        currentUser: _ember['default'].inject.service('current-user'),
        ustates: _ember['default'].inject.service('ustates'),

        statesList: (function () {
            var array = $.map(this.get('ustates').data, function (value) {
                return [value];
            });
            return array.slice(0, -2);
        }).property(),

        actions: {
            update: function update() {
                var flashMessages = _ember['default'].get(this, 'flashMessages');

                $.fn.serializeObject = function () {
                    var o = {};
                    var a = this.serializeArray();
                    $.each(a, function () {
                        if (o[this.name]) {
                            if (!o[this.name].push) {
                                o[this.name] = [o[this.name]];
                            }
                            o[this.name].push(this.value || '');
                        } else {
                            o[this.name] = this.value || '';
                        }
                    });
                    return o;
                };

                var self = this;
                this.get('api').sendReq({
                    type: "PUT",
                    url: '/user/update',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    data: $('#profile-info').serializeObject()
                }).then(function (data) {
                    $('body,html').animate({
                        scrollTop: 0
                    }, 400);
                    flashMessages.success(data.success);
                    self.get('currentUser').setUser(data.user);
                }, function (data) {
                    $('body,html').animate({
                        scrollTop: 0
                    }, 400);
                    $.each(data.responseJSON, function (key, value) {
                        flashMessages.danger(value[0]);
                    });
                });
            }
        }
    });
});