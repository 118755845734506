define("cn-front/templates/dashboard/modal/choose-restype", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 8
              },
              "end": {
                "line": 20,
                "column": 8
              }
            },
            "moduleName": "cn-front/templates/dashboard/modal/choose-restype.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("Camper`s credit card will be charged immediately:");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "radio");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createElement("input");
            dom.setAttribute(el3, "type", "radio");
            dom.setAttribute(el3, "name", "resType");
            dom.setAttribute(el3, "value", "on_site");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("New Onsite Reservation (phone-in, drive-up, etc.)");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 22,
              "column": 0
            }
          },
          "moduleName": "cn-front/templates/dashboard/modal/choose-restype.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createTextNode("Select Item Type");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          dom.setAttribute(el1, "id", "type");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("No charge, but campsite is marked unavailable for reservations:");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "radio");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createElement("input");
          dom.setAttribute(el4, "type", "radio");
          dom.setAttribute(el4, "name", "resType");
          dom.setAttribute(el4, "value", "in_use");
          dom.setAttribute(el4, "checked", "checked");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("In Use (campsite has a prior booking)");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "radio");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createElement("input");
          dom.setAttribute(el4, "type", "radio");
          dom.setAttribute(el4, "name", "resType");
          dom.setAttribute(el4, "value", "maint");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("Maintenance (campsite is temporarily closed)");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "radio");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createElement("input");
          dom.setAttribute(el4, "type", "radio");
          dom.setAttribute(el4, "name", "resType");
          dom.setAttribute(el4, "value", "fcfs");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("First Come First Served (campsite is open but may not be reserved)");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          if (this.cachedFragment) {
            dom.repairClonedNode(dom.childAt(fragment, [3, 3, 1, 0]), [], true);
          }
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 9, 9);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "model.camp.reservation_mode", ["loc", [null, [15, 22], [15, 49]]], 0, 0, 0, 0], "confirmation_booking"], [], ["loc", [null, [15, 18], [15, 73]]], 0, 0], ["subexpr", "eq", [["get", "model.camp.reservation_mode", ["loc", [null, [15, 78], [15, 105]]], 0, 0, 0, 0], "instant_booking"], [], ["loc", [null, [15, 74], [15, 124]]], 0, 0]], [], ["loc", [null, [15, 14], [15, 125]]], 0, 0]], [], 0, null, ["loc", [null, [15, 8], [20, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 13
          }
        },
        "moduleName": "cn-front/templates/dashboard/modal/choose-restype.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "bs-modal", [], ["title", "New Calendar Item For Campsite", "okLabel", "Next", "closeLabel", "Cancel", "ok", "chooseType", "autoclose", false, "finishClose", "removeModal"], 0, null, ["loc", [null, [1, 0], [22, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});