define('cn-front/controllers/dashboard/campground/edit/modal/delete-cs', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        actions: {
            deleteCs: function deleteCs() {

                var url = this.get('apiConnect').getUrl('/dashboard/campsites/' + this.get('model.id') + '/delete');
                var self = this;
                var flashMessages = _ember['default'].get(self, 'flashMessages');
                $.ajax({
                    type: "DELETE",
                    url: url,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        console.log(data);
                        if (data.success) {
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                        } else {
                            flashMessages.danger(_ember['default'].String.htmlSafe(data.error));
                        }
                        self.send('refreshModel');
                    }

                });
            }
        }
    });
});