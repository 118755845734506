define("cn-front/templates/components/partials/section-footer", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 10
            },
            "end": {
              "line": 8,
              "column": 10
            }
          },
          "moduleName": "cn-front/templates/components/partials/section-footer.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "src", "/assets/images/footer/logo.png");
          dom.setAttribute(el1, "alt", "Camp Native Logo");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 14
            },
            "end": {
              "line": 45,
              "column": 38
            }
          },
          "moduleName": "cn-front/templates/components/partials/section-footer.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Home");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 46,
              "column": 14
            },
            "end": {
              "line": 46,
              "column": 58
            }
          },
          "moduleName": "cn-front/templates/components/partials/section-footer.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("List Your Property");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 14
            },
            "end": {
              "line": 47,
              "column": 55
            }
          },
          "moduleName": "cn-front/templates/components/partials/section-footer.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Find A Camping Spot");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 53,
              "column": 14
            },
            "end": {
              "line": 53,
              "column": 53
            }
          },
          "moduleName": "cn-front/templates/components/partials/section-footer.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("How It Works");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 54,
              "column": 14
            },
            "end": {
              "line": 54,
              "column": 49
            }
          },
          "moduleName": "cn-front/templates/components/partials/section-footer.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Contact Us");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 97,
              "column": 8
            },
            "end": {
              "line": 97,
              "column": 54
            }
          },
          "moduleName": "cn-front/templates/components/partials/section-footer.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Terms & Privacy");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 102,
            "column": 0
          }
        },
        "moduleName": "cn-front/templates/components/partials/section-footer.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("footer");
        dom.setAttribute(el1, "class", "footer");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-md");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "footer-top");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "block-description");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "block-text");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createTextNode("Camp Native is the easiest and fastest way to discover and reserve the perfect campsite.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5, "class", "socials");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "http://www.facebook.com/campnative");
        dom.setAttribute(el7, "target", "_blank");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el8 = dom.createElement("svg");
        dom.setAttribute(el8, "width", "12");
        dom.setAttribute(el8, "height", "21");
        dom.setAttribute(el8, "viewBox", "0 0 12 21");
        dom.setAttribute(el8, "fill", "none");
        dom.setAttribute(el8, "xmlns", "http://www.w3.org/2000/svg");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("path");
        dom.setAttribute(el9, "d", "M7.20003 11.7726V20.25H3.4038V11.7726H0.25V8.33515H3.4038V7.0845C3.4038 2.44138 5.29461 0 9.29526 0C10.5217 0 10.8284 0.2022 11.5 0.366956V3.76692C10.7481 3.63212 10.5363 3.55723 9.75519 3.55723C8.82803 3.55723 8.3316 3.82683 7.87897 4.35854C7.42635 4.89025 7.20003 5.81139 7.20003 7.12944V8.34264H11.5L10.3465 11.78H7.20003V11.7726Z");
        dom.setAttribute(el9, "fill", "#212322");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.setNamespace(null);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "http://www.instagram.com/campnative");
        dom.setAttribute(el7, "target", "_blank");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el8 = dom.createElement("svg");
        dom.setAttribute(el8, "width", "21");
        dom.setAttribute(el8, "height", "20");
        dom.setAttribute(el8, "viewBox", "0 0 21 20");
        dom.setAttribute(el8, "fill", "none");
        dom.setAttribute(el8, "xmlns", "http://www.w3.org/2000/svg");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("path");
        dom.setAttribute(el9, "d", "M16.9907 19.75H4.00934C2.2155 19.75 0.75 18.2845 0.75 16.4907V3.50934C0.75 1.7155 2.2155 0.25 4.00934 0.25H16.9907C18.7845 0.25 20.25 1.7155 20.25 3.50934V16.4907C20.25 18.2925 18.7925 19.75 16.9907 19.75Z");
        dom.setAttribute(el9, "fill", "#212322");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("path");
        dom.setAttribute(el9, "d", "M10.125 14.5C8.8224 14.5 7.5978 13.993 6.6774 13.0726C5.757 12.1522 5.25 10.9276 5.25 9.625C5.25 8.3224 5.757 7.0978 6.6774 6.1774C7.5978 5.257 8.8224 4.75 10.125 4.75C11.4276 4.75 12.6522 5.257 13.5726 6.1774C14.493 7.0978 15 8.3224 15 9.625C15 10.9276 14.493 12.1522 13.5726 13.0726C12.6444 13.993 11.4276 14.5 10.125 14.5ZM10.125 5.7874C8.0112 5.7874 6.2874 7.5034 6.2874 9.625C6.2874 11.7388 8.0034 13.4626 10.125 13.4626C12.2388 13.4626 13.9626 11.7466 13.9626 9.625C13.9548 7.5112 12.2388 5.7874 10.125 5.7874Z");
        dom.setAttribute(el9, "fill", "#F8FAF7");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("path");
        dom.setAttribute(el9, "d", "M16.875 5.5C17.4963 5.5 18 4.99632 18 4.375C18 3.75368 17.4963 3.25 16.875 3.25C16.2537 3.25 15.75 3.75368 15.75 4.375C15.75 4.99632 16.2537 5.5 16.875 5.5Z");
        dom.setAttribute(el9, "fill", "#F8FAF7");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.setNamespace(null);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "http://www.pinterest.com/campnative");
        dom.setAttribute(el7, "target", "_blank");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el8 = dom.createElement("svg");
        dom.setAttribute(el8, "width", "18");
        dom.setAttribute(el8, "height", "24");
        dom.setAttribute(el8, "viewBox", "0 0 18 24");
        dom.setAttribute(el8, "fill", "none");
        dom.setAttribute(el8, "xmlns", "http://www.w3.org/2000/svg");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("path");
        dom.setAttribute(el9, "fill-rule", "evenodd");
        dom.setAttribute(el9, "clip-rule", "evenodd");
        dom.setAttribute(el9, "d", "M7.20323 16.1273C7.90233 16.1503 8.53376 17.0923 10.383 17.0923C13.8559 17.1153 16.2463 14.4041 16.9905 10.8427C19.5839 -1.49565 1.92622 -2.48364 0.122115 7.67196C-0.30636 10.0615 0.392731 12.8187 2.21939 13.7148C3.61757 14.4041 3.70778 12.4281 3.2793 11.578C1.38499 7.80982 3.66268 4.4093 6.63945 3.44429C9.43582 2.52523 11.4203 3.28346 12.9313 4.86883C14.8707 6.91374 13.9912 12.474 11.691 14.2432C10.8791 14.8636 9.39071 15.0014 8.62397 14.2662C7.04538 12.7727 9.48092 9.60198 9.1201 7.28136C8.75928 4.98371 5.19617 5.32836 5.01576 8.7059C4.92555 10.4291 5.42168 10.9346 5.03831 12.6579C4.42942 15.415 2.58021 21.0443 3.88819 23.25C5.80505 22.3309 6.75221 16.8166 7.20323 16.1273Z");
        dom.setAttribute(el9, "fill", "#212322");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.setNamespace(null);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "block-navigation");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        var el6 = dom.createTextNode("Menu");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        dom.setAttribute(el5, "class", "action-slide");
        var el6 = dom.createTextNode("Menu");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "https://blog.campnative.com/");
        dom.setAttribute(el7, "target", "_");
        var el8 = dom.createTextNode("\n              Camping Guides\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "block-contact");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        var el6 = dom.createTextNode("Contact Us");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        dom.setAttribute(el5, "class", "action-slide");
        var el6 = dom.createTextNode("Contact Us");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        dom.setAttribute(el5, "class", "contacts");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6, "class", "mailto");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el7 = dom.createElement("svg");
        dom.setAttribute(el7, "width", "15");
        dom.setAttribute(el7, "height", "12");
        dom.setAttribute(el7, "viewBox", "0 0 15 12");
        dom.setAttribute(el7, "fill", "none");
        dom.setAttribute(el7, "xmlns", "http://www.w3.org/2000/svg");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("path");
        dom.setAttribute(el8, "d", "M2.16634 11.1335C1.79967 11.1335 1.4859 11.003 1.22501 10.7421C0.963675 10.4808 0.833008 10.1668 0.833008 9.80013V1.80013C0.833008 1.43346 0.963675 1.11969 1.22501 0.858797C1.4859 0.597463 1.79967 0.466797 2.16634 0.466797H12.833C13.1997 0.466797 13.5137 0.597463 13.775 0.858797C14.0359 1.11969 14.1663 1.43346 14.1663 1.80013V9.80013C14.1663 10.1668 14.0359 10.4808 13.775 10.7421C13.5137 11.003 13.1997 11.1335 12.833 11.1335H2.16634ZM7.49967 6.35013C7.55523 6.35013 7.61345 6.34169 7.67434 6.3248C7.73567 6.30835 7.79412 6.28346 7.84967 6.25013L12.5663 3.30013C12.6552 3.24457 12.7219 3.17524 12.7663 3.09213C12.8108 3.00857 12.833 2.9168 12.833 2.8168C12.833 2.59457 12.7386 2.42791 12.5497 2.3168C12.3608 2.20569 12.1663 2.21124 11.9663 2.33346L7.49967 5.13346L3.03301 2.33346C2.83301 2.21124 2.63856 2.20835 2.44967 2.3248C2.26079 2.44169 2.16634 2.60569 2.16634 2.8168C2.16634 2.92791 2.18856 3.02502 2.23301 3.10813C2.27745 3.19169 2.34412 3.25569 2.43301 3.30013L7.14967 6.25013C7.20523 6.28346 7.26367 6.30835 7.32501 6.3248C7.3859 6.34169 7.44412 6.35013 7.49967 6.35013Z");
        dom.setAttribute(el8, "fill", "#212322");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.setNamespace(null);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "mailto:support@campnative.com");
        var el8 = dom.createTextNode("\n              support@campnative.com\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6, "class", "tel");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el7 = dom.createElement("svg");
        dom.setAttribute(el7, "width", "15");
        dom.setAttribute(el7, "height", "14");
        dom.setAttribute(el7, "viewBox", "0 0 15 14");
        dom.setAttribute(el7, "fill", "none");
        dom.setAttribute(el7, "xmlns", "http://www.w3.org/2000/svg");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("path");
        dom.setAttribute(el8, "d", "M11.0997 13.6667C8.37787 13.6631 5.76855 12.5803 3.84394 10.6557C1.91933 8.73112 0.836536 6.1218 0.833008 3.39999C0.833008 2.58666 1.1561 1.80665 1.73121 1.23153C2.30633 0.656423 3.08634 0.333328 3.89967 0.333328C4.0719 0.332016 4.24384 0.347647 4.41301 0.379995C4.57655 0.404195 4.73732 0.444388 4.89301 0.499995C5.00251 0.538414 5.10008 0.60475 5.17608 0.692443C5.25208 0.780136 5.30387 0.886147 5.32634 0.999995L6.23967 5C6.26429 5.10857 6.26133 5.22158 6.23105 5.32872C6.20077 5.43585 6.14415 5.5337 6.06634 5.61333C5.97967 5.70666 5.97301 5.71333 5.15301 6.14C5.80967 7.58057 6.96181 8.73743 8.39967 9.4C8.83301 8.57333 8.83967 8.56666 8.93301 8.48C9.01264 8.40219 9.11048 8.34556 9.21762 8.31529C9.32475 8.28501 9.43777 8.28204 9.54634 8.30666L13.5463 9.22C13.6565 9.24556 13.7584 9.29876 13.8424 9.37457C13.9264 9.45037 13.9897 9.54631 14.0263 9.65333C14.0826 9.81157 14.125 9.9744 14.153 10.14C14.1798 10.3075 14.1932 10.477 14.193 10.6467C14.1807 11.4565 13.8485 12.2286 13.269 12.7944C12.6894 13.3602 11.9096 13.6738 11.0997 13.6667Z");
        dom.setAttribute(el8, "fill", "#212322");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.setNamespace(null);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "tel:18008204991");
        var el8 = dom.createTextNode("+1 800-820-4991");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        dom.setAttribute(el6, "class", "address");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el7 = dom.createElement("svg");
        dom.setAttribute(el7, "width", "11");
        dom.setAttribute(el7, "height", "14");
        dom.setAttribute(el7, "viewBox", "0 0 11 14");
        dom.setAttribute(el7, "fill", "none");
        dom.setAttribute(el7, "xmlns", "http://www.w3.org/2000/svg");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("path");
        dom.setAttribute(el8, "d", "M5.50033 7.00004C5.86699 7.00004 6.18099 6.86937 6.44233 6.60804C6.70321 6.34715 6.83366 6.03337 6.83366 5.66671C6.83366 5.30004 6.70321 4.98604 6.44233 4.72471C6.18099 4.46382 5.86699 4.33337 5.50033 4.33337C5.13366 4.33337 4.81988 4.46382 4.55899 4.72471C4.29766 4.98604 4.16699 5.30004 4.16699 5.66671C4.16699 6.03337 4.29766 6.34715 4.55899 6.60804C4.81988 6.86937 5.13366 7.00004 5.50033 7.00004ZM5.50033 13.4167C5.41144 13.4167 5.32255 13.4 5.23366 13.3667C5.14477 13.3334 5.06699 13.2889 5.00033 13.2334C3.3781 11.8 2.16699 10.4696 1.36699 9.24204C0.566992 8.01404 0.166992 6.86671 0.166992 5.80004C0.166992 4.13337 0.703215 2.8056 1.77566 1.81671C2.84766 0.827818 4.08921 0.333374 5.50033 0.333374C6.91144 0.333374 8.15299 0.827818 9.22499 1.81671C10.2974 2.8056 10.8337 4.13337 10.8337 5.80004C10.8337 6.86671 10.4337 8.01404 9.63366 9.24204C8.83366 10.4696 7.62255 11.8 6.00033 13.2334C5.93366 13.2889 5.85588 13.3334 5.76699 13.3667C5.6781 13.4 5.58921 13.4167 5.50033 13.4167Z");
        dom.setAttribute(el8, "fill", "#212322");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.setNamespace(null);
        var el7 = dom.createElement("a");
        dom.setAttribute(el7, "href", "https://www.google.com/maps/place/109+E+Hudson+St,+Spearfish,+SD+57783,+USA/@44.4888014,-103.8582899,17z/data=!3m1!4b1!4m6!3m5!1s0x5332984afb79e5c5:0x3d00e31e931961ee!8m2!3d44.4888014!4d-103.8582899!16s%2Fg%2F11hz2btdm4?entry=ttu");
        dom.setAttribute(el7, "target", "_blank");
        var el8 = dom.createTextNode("\n              109 E Hudson St, Spearfish, SD 57783\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "footer-bottom");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "copyright");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "copyright");
        var el6 = dom.createTextNode("© Camp Native 2023");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "privacy");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element2, [5]);
        var element5 = dom.childAt(element1, [5, 3]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 1]), 1, 1);
        morphs[1] = dom.createAttrMorph(element3, 'onclick');
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [5]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [9]), 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element4, [11]), 0, 0);
        morphs[7] = dom.createAttrMorph(element5, 'onclick');
        morphs[8] = dom.createMorphAt(dom.childAt(element0, [3, 3]), 1, 1);
        return morphs;
      },
      statements: [["block", "link-to", ["index"], ["class", "footer-logo"], 0, null, ["loc", [null, [6, 10], [8, 22]]]], ["attribute", "onclick", ["subexpr", "action", ["slideToggle"], [], ["loc", [null, [null, null], [43, 65]]], 0, 0], 0, 0, 0, 0], ["block", "link-to", ["index"], [], 1, null, ["loc", [null, [45, 14], [45, 50]]]], ["block", "link-to", ["camp-owners"], [], 2, null, ["loc", [null, [46, 14], [46, 70]]]], ["block", "link-to", ["explore"], [], 3, null, ["loc", [null, [47, 14], [47, 67]]]], ["block", "link-to", ["how-it-works"], [], 4, null, ["loc", [null, [53, 14], [53, 65]]]], ["block", "link-to", ["contact-us"], [], 5, null, ["loc", [null, [54, 14], [54, 61]]]], ["attribute", "onclick", ["subexpr", "action", ["slideToggle"], [], ["loc", [null, [null, null], [59, 65]]], 0, 0], 0, 0, 0, 0], ["block", "link-to", ["terms-of-service"], [], 6, null, ["loc", [null, [97, 8], [97, 66]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  })());
});