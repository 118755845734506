define('cn-front/services/reservation/instance', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        campsite: {},
        campground: {},
        reservation_type: '',

        start_date: '',
        end_date: '',
        daterange: '',
        days: '',
        days_computed: '',
        min_duration: null,

        type: '',

        rate_daily: 0,
        rate_weekly: 0,
        rate_monthly: 0,
        dow_rates: null,
        override_rates: null,

        total_without_fee: '',
        camper_fee: '',
        camper_charged: '', // Needed for braintree transaction (amount)
        cg_fee: '',
        rate_cleaning: '',
        cg_received: '',
        svc_fee: '', // Needed for braintree transaction (service fee)

        remote_token: '',
        remote_user: '',
        braintree_token: '',
        response: '',
        type_desc: '',

        // View/Cancel/Change reservation
        model: {},
        initiator: '',

        init: function init() {},
        clearAll: function clearAll() {
            var fields = ['campsite', 'campground', 'start_date', 'end_date', 'daterange', 'days', 'days_computed', 'type', 'rate_daily', 'rate_weekly', 'rate_monthly', 'dow_rates', 'override_rates', 'restype', 'total_without_fee', 'camper_fee', 'camper_charged', 'cg_fee', 'cg_received', 'svc_fee', 'remote_user', 'remote_token', 'braintree_token', 'response', 'type_desc', 'model', 'initiator', 'min_duration', 'rate_cleaning'];

            var self = this;
            fields.forEach(function (field) {
                self.set(field, null);
            });
        },

        /**
         * Helper function: check reservation type
         * @returns {boolean}
         */
        isPaymentsEnabled: function isPaymentsEnabled() {
            if (this.get('model')) {
                this.set('reservation_type', this.get('model.type'));
            }
            var disabledPaymentOn = ["in_use", "maint", "fcfs", "info_req"];
            return disabledPaymentOn.indexOf(this.get('reservation_type')) == -1;
        },

        /**
         * Setting financails to service props
         * @param data
         */
        setFinancialInformation: function setFinancialInformation(data) {
            this.set('total_without_fee', data.total_without_fee);
            this.set('camper_fee', data.camper_fee);
            this.set('camper_charged', data.camper_charged);
            this.set('cg_fee', data.cg_fee);
            this.set('rate_cleaning', data.rate_cleaning);
            this.set('cg_received', data.cg_received);
            this.set('cn_fee_cap', data.cn_fee_cap);
            this.set('svc_fee', data.svc_fee);
            this.set('override_rates', data.override_rates);
            this.set('dow_rates', data.dow_rates);
            this.set('rate_daily', data.rate_daily);
            this.set('rate_weekly', data.rate_weekly);
            this.set('rate_monthly', data.rate_monthly);
        },

        /**
         * Set initiator
         * @param initiator
         */
        setInitiator: function setInitiator(initiator) {
            this.set('initiator', initiator);
        },

        /**
         * Set additional cancel information
         * @param data
         */
        setCancelInformation: function setCancelInformation(data) {
            var initiator = this.get('initiator');
            if (initiator == 'campground') {
                this.set('model.cg_penalty', data.cg_fee);
            } else {
                this.set('model.cg_fee', data.cg_fee);
                this.set('model.camper_fee', data.camper_fee);
            }
            this.set('model.camper_refunded', data.camper_refunded);
            this.set('model.cg_charged', data.cg_charged);
        },

        calculateDuration: function calculateDuration(type) {
            var computed = undefined,
                day = undefined,
                days = undefined,
                weeks = undefined,
                month = undefined,
                wr = undefined,
                mr = undefined,
                override_rates = undefined,
                dataResource = undefined;

            if (type == 'new') {
                days = this.get('days');
                dataResource = this;
            } else if (type == 'change') {
                dataResource = this.get('model.create_calculations');
                if (dataResource) {
                    days = dataResource.days_count;
                }
            } else {
                dataResource = this.get('model.financial_data');
                if (dataResource) {
                    days = dataResource.days_count;
                }
            }
            // make sure we have days, one way or the other
            if (!days) {
                days = this.get('model.days');
            }

            wr = dataResource && dataResource.rate_weekly ? dataResource.rate_weekly : 0;
            mr = dataResource && dataResource.rate_monthly ? dataResource.rate_monthly : 0;
            override_rates = dataResource && dataResource.override_rates ? dataResource.override_rates : 0;

            if (days >= 7 && wr !== 0 && (!override_rates || override_rates.length < 1)) {
                month = Math.floor(days / 28);
                if (month > 0 && mr !== 0) {
                    day = days - month * 28;
                    weeks = Math.floor(day / 7);
                    day = day - weeks * 7;
                    computed = month + ' month(s)';
                    computed = weeks > 0 ? computed + ', ' + weeks + ' week(s) ' : computed;
                    computed = day > 0 ? computed + ', ' + day + ' day(s)' : computed;
                } else {
                    weeks = Math.floor(days / 7);
                    day = days - weeks * 7;
                    computed = weeks + ' week(s), ' + day + ' day(s)';
                }
            } else {
                computed = days + ' day(s)';
            }

            this.set('days_computed', computed);
        }

    });
});