define('cn-front/controllers/auth/login', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Controller.extend({
        currentUser: inject.service('current-user'),
        refererConfirmation: false,
        refererInfoRequest: false,
        creationType: 'Register',
        tosNotAccepted: false,

        bindEvents: (function () {
            var self = this;
            _ember['default'].run.schedule('afterRender', this, function () {
                if (self.get('previousTransition')) {
                    var referer = self.get('previousTransition').split('?', 1);
                    if (referer[0] == '/confirmation') {
                        self.set('refererConfirmation', true);
                    } else if (referer[0] == '/info-request') {
                        self.set('refererInfoRequest', true);
                    }
                }
            });
        }).on('init'),
        redirectToPrevious: function redirectToPrevious() {
            var referer = this.get('previousTransition');
            if (referer) {
                this.transitionToRoute(referer);
            } else {
                this.transitionToRoute('index');
            }
        },
        actions: {
            login: function login() {
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                var self = this;

                this.get('api').sendReq({
                    type: "POST",
                    url: '/signin',
                    data: $('#login-form').serializeJSON()
                }).then(function (data) {
                    if ($('#login-form [name="remember"]').is(':checked')) {
                        $.cookie('remember', 'true', { expires: 30, path: '/' });
                    }

                    self.get('currentUser').login(data);
                    _cnFrontUtilsGtm['default'].trackEvent({ event: 'login' });
                    if (data.reservation_count > 0) {
                        self.send('showModal', 'welcome-popover', data);
                        _ember['default'].run.later(function () {
                            $('.modal').modal('hide');
                            self.redirectToPrevious();
                        }, 3000);
                    } else {
                        self.redirectToPrevious();
                    }
                }, function (data) {
                    if (data.responseJSON.error === 'tos_not_accepted') {
                        self.get('currentUser').setTemporaryToken(data.responseJSON.token);
                        self.send('showTos');
                    } else {
                        flashMessages.danger(data.responseJSON.message);
                        $('html,body').animate({
                            scrollTop: 0
                        }, 400);
                    }
                });
            },
            handleTos: function handleTos(accept) {
                if (accept) {
                    this.redirectToPrevious();
                } else {
                    var flashMessages = _ember['default'].get(this, 'flashMessages');
                    flashMessages.danger('Terms of Service are not accepted');
                }

                this.set('tosNotAccepted', false);
            },
            showTos: function showTos() {
                this.set('tosNotAccepted', true);
            }
        }
    });
});