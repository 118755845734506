define('cn-front/routes/seo/state', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            return _ember['default'].$.getJSON(this.get('api').getUrl('/states/' + params.state));
        },

        afterModel: function afterModel() {
            var name = this.modelFor(this.routeName).name;

            this.get('meta').update({
                title: 'Campgrounds in ' + name + ' - RV & Tent Camping - Camp Native',
                description: 'Find the best campgrounds in ' + name + '. The easiest way to book your trip online.'
            });

            _ember['default'].run.schedule('afterRender', this, function () {
                $('html,body').animate({ scrollTop: 0 }, 200);
            });
        }
    });
});