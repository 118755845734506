define('cn-front/controllers/dashboard/campground/edit/availability', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        camp: (function () {
            var obj = {};
            obj = this.get('model.general');
            obj.campsites = this.get('model.campsites');
            console.log(obj);
            return obj;
        }).property('model')
    });
});