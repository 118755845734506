define('cn-front/services/preselect', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({

        shelter: '',
        location: '',
        locationObj: {},
        sdate: '',
        edate: '',
        mapCenter: {},

        reservable: [],
        activities: [],
        shelters: [],
        services: [],
        accesses: [],
        environs: [],
        agency: [],
        specialty: [],

        getFilterLabels: function getFilterLabels() {
            return {
                'reservable': 'Properties to display',
                'activities': 'Activities',
                'shelters': 'Shelter type',
                'services': 'Services',
                'accesses': 'Accesses',
                'environs': 'Environments',
                'environments': 'Environments',
                'agency': 'Agencies',
                'specialty': 'Specialities'
            };
        },

        onInit: (function () {
            if ($.cookie('homefilter') != null && $.cookie('homefilter') !== "null") {
                var d = JSON.parse($.cookie('homefilter'));
                var properties = this.getFilterLabels();

                this.set('shelter', d.shelter);
                this.set('location', d.location);
                this.set('locationObj', d.locationObj);
                this.set('sdate', d.sdate);
                this.set('edate', d.edate);

                for (var key in properties) {
                    if (properties.hasOwnProperty(key)) {
                        this.set(key, d[key]);
                    }
                }
            }
        }).on('init'),

        setField: function setField(f, v) {
            this.set(f, v);

            var date = new Date();
            var minutes = 10;
            date.setTime(date.getTime() + minutes * 60 * 1000);

            var jsonProperties = {
                shelter: this.shelter,
                location: this.location,
                locationObj: this.locationObj,
                sdate: this.sdate,
                edate: this.edate
            };

            var properties = this.getFilterLabels();
            for (var key in properties) {
                if (properties.hasOwnProperty(key)) {
                    jsonProperties[key] = this.get(key);
                }
            }

            $.cookie('homefilter', JSON.stringify(jsonProperties), { path: '/', expires: date });
        },

        resetPreselect: function resetPreselect() {
            this.set('shelter', '');
            this.set('location', '');
            this.set('locationObj', {});
            this.set('sdate', '');
            this.set('edate', '');

            var properties = this.getFilterLabels();
            for (var key in properties) {
                if (properties.hasOwnProperty(key)) {
                    this.set(key, []);
                }
            }

            $.removeCookie('homefilter', { path: '/' });
        }

    });
});