define('cn-front/components/campground/photos-section', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        ids: ['#campground-photo', '#campsite-photo', '#campground-maps'],
        type: 'campground',

        didRender: function didRender() {
            var self = this;
            this.get('ids').map(function (id) {
                if ($(id).length) {
                    self.initCustomNavs(id, self.initOwl(id));
                }
            });
        },

        initCustomNavs: function initCustomNavs(id, selector) {
            var n = selector.find('.owl-item').length;
            if (n <= 3) {
                $('#' + this.get('elementId') + ' ' + id + ' .next').hide();
                $('#' + this.get('elementId') + ' ' + id + ' .prev').hide();
            } else {
                $('#' + this.get('elementId') + ' ' + id + ' .next').click(function () {
                    return selector.trigger('next.owl.carousel');
                });
                $('#' + this.get('elementId') + ' ' + id + ' .prev').click(function () {
                    return selector.trigger('prev.owl.carousel');
                });
            }
        },

        initOwl: function initOwl(id) {
            var selector = $('#' + this.get('elementId') + ' ' + id + ' .owl-carousel');
            selector.trigger('destroy.owl.carousel').removeClass('owl-loaded');
            selector.find('.owl-stage-outer').children().unwrap();

            selector.owlCarousel({
                loop: false,
                margin: 0,
                nav: false,
                items: 4,
                dots: false,
                responsive: {
                    0: { items: 3 },
                    600: { items: 4 },
                    1000: { items: 4 }
                }
            });
            return selector;
        },
        actions: {
            show: function show(id) {
                var data = {
                    type: 'photo',
                    cg: this.get('model.campground'),
                    photos: this.get('model.campground.photos').concat(this.get('model.campground.cs_photos')),
                    clicked: id
                };
                this.sendAction('showModal', 'photo-popup', data);
            },
            showMap: function showMap(id) {
                var data = {
                    type: 'photo',
                    cg: this.get('model.campground'),
                    photos: this.get('model.campground.maps'),
                    clicked: id
                };
                this.sendAction('showModal', 'photo-popup', data);
            }

        }

    });
});