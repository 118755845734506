define('cn-front/components/campground/section-calendar', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {

  var inject = _ember['default'].inject;

  exports['default'] = _ember['default'].Component.extend({
    preselect: inject.service(),
    currentUser: inject.service('current-user'),
    currentWeekSize: 0,
    weekSize: 9,
    weekSizeExpanded: 14,
    pickedDate: '',
    cookieDate: '',
    headerDates: [],
    sd: '',
    page: 0,
    type: 'front',
    cg: null,
    singleCampsite: false,
    dateRange: '',
    campsites: null,
    reservations: null,
    csFullView: true,
    cgView: true,
    filters: null,
    onlineReservations: true,

    setWeekSize: function setWeekSize() {
      var width = window.outerWidth > 0 ? window.outerWidth : $(window).outerWidth();
      if (width < 1000 && width > 500) {
        this.set('weekSize', 2);
        this.set('weekSizeExpanded', 7);
      } else if (width <= 500) {
        this.set('weekSize', 0);
        this.set('weekSizeExpanded', 2);
      } else {
        this.set('weekSize', 9);
        this.set('weekSizeExpanded', 14);
      }

      this.set('currentWeekSize', this.get('csFullView') ? this.get('weekSize') : this.get('weekSizeExpanded'));
    },

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('cg.reservation_ccprocessing_id') !== 3) {
        this.set('onlineReservations', false);
      }

      this.setWeekSize();
      this.set('currentWeekSize', this.get('cgView') ? this.get('weekSize') : this.get('weekSizeExpanded'));

      this.calendarDates();

      if (!this.get('singleCampsite')) {
        this.campsitesAjax();
      }

      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      });
    },

    didInsertElement: function didInsertElement() {
      var self = this;
      var datePicker = $('.ava_date_picker');

      datePicker.datepicker({
        orientation: "bottom left",
        autoclose: true,
        startDate: self.get('type') == "dash" ? false : new Date()
      }).on('changeDate', function () {
        if (datePicker.val() !== '') {
          self.set('pickedDate', datePicker.val());
          self.set('page', 0);
          self.calendarDates();
        }
      });
    },

    didRender: function didRender() {

      this._super.apply(this, arguments);
      if (this.get('cgView')) {
        this.stickyRelocate();
      }

      var $calendarDates = $('.calendar-dates.icon-status');
      if (this.get('currentWeekSize') === 2) {
        $calendarDates.addClass(' icon-status-' + this.get('currentWeekSize'));
        $calendarDates.removeClass(' icon-status-7');
      } else if (this.get('currentWeekSize') === 7) {
        $calendarDates.addClass(' icon-status-' + this.get('currentWeekSize'));
        $calendarDates.removeClass(' icon-status-2');
      } else {
        $calendarDates.removeClass(' icon-status-7');
        $calendarDates.removeClass(' icon-status-2');
      }

      var self = this;
      $(window).resize(function () {
        var oldWeekSize = self.get('weekSize');
        self.setWeekSize();

        if (oldWeekSize != self.get('weekSize')) {
          self.calendarDates();
        }

        var $calendarDates = $('.calendar-dates.icon-status');
        if (this.get('currentWeekSize') === 2) {
          $calendarDates.addClass(' icon-status-' + this.get('currentWeekSize'));
          $calendarDates.removeClass(' icon-status-7');
        } else if (this.get('currentWeekSize') === 7) {
          $calendarDates.addClass(' icon-status-' + this.get('currentWeekSize'));
          $calendarDates.removeClass(' icon-status-2');
        } else {
          $calendarDates.removeClass(' icon-status-7');
          $calendarDates.removeClass(' icon-status-2');
        }
      });
    },

    actions: {
      prev: function prev() {
        this.page--;
        this.calendarDates();
      },
      next: function next() {
        this.page++;
        this.calendarDates();
      },
      goToReservation: function goToReservation(date, cs, end_date) {
        var campground = this.get('cg');
        var reservation = this.get('reservations')[cs.id][date];

        if (!this.get('onlineReservations')) {
          this.get('router').transitionTo('campground.info-request');
        } else if (reservation.status === 'available') {
          this.gtmEvent(campground, cs, date);
          this.get('router').transitionTo('campground.campsite.new-reservation', cs.id, { queryParams: { start_date: date, end_date: end_date } }); //
        } else if (reservation.status !== 'closed' && reservation.view_allowed) {
            this.get('router').transitionTo('reservation', reservation.reservation_id);
          }
      },

      campsiteView: function campsiteView() {
        var calendar = $('#availability-calendar');

        if (calendar.hasClass('full-cs-view')) {
          calendar.removeClass('full-cs-view');
          this.set('csFullView', false);
          this.set('currentWeekSize', this.get('weekSizeExpanded'));
        } else {
          calendar.addClass('full-cs-view');
          this.set('currentWeekSize', this.get('weekSize'));
          this.set('csFullView', true);
        }

        var $calendarDates = $('.calendar-dates.icon-status');
        if (this.get('currentWeekSize') === 2) {
          $calendarDates.addClass(' icon-status-' + this.get('currentWeekSize'));
          $calendarDates.removeClass(' icon-status-7');
        } else if (this.get('currentWeekSize') === 7) {
          $calendarDates.addClass(' icon-status-' + this.get('currentWeekSize'));
          $calendarDates.removeClass(' icon-status-2');
        } else {
          $calendarDates.removeClass(' icon-status-7');
          $calendarDates.removeClass(' icon-status-2');
        }

        calendar.toggleClass('show-campsites');

        this.calendarDates();
      },

      clear: function clear() {
        this.set('sd', '');
        this.set('page', 0);

        var exploreParams = $('#filterform .explore-params');
        if (exploreParams.length) {
          exploreParams[0].reset();
          exploreParams.find('input[type="checkbox"]').removeAttr('Checked');
        }

        this.campsitesAjax();
      },
      search: function search() {
        this.campsitesAjax();
        this.calendarDates();
      }

    },

    /**
     * Set start day of calendar
     */
    setStartDay: function setStartDay() {
      var dr = $.cookie('homefilter') ? JSON.parse($.cookie('homefilter')) : null;

      if (dr != null) {
        if (dr.sdate !== '') {
          this.set('sd', moment(dr.sdate));
        } else {
          this.set('sd', moment());
        }
      } else {

        var startOfSeason = this.get('cg.calendar_start_date');
        if (new Date(startOfSeason) > new Date()) {
          this.set('sd', moment(startOfSeason));
        } else {
          this.set('sd', moment());
        }
      }
    },

    /**
     * Return object of filters params
     */
    getFilters: function getFilters() {
      var filterForm = $('#filterform .explore-params');
      var search_form = filterForm.serializeArray();
      var formParams = {};
      $.map(search_form, function (n) {
        if (formParams[n['name']]) {
          formParams[n['name']] += '|' + n['value'];
        } else {
          formParams[n['name']] = n['value'];
        }
      });

      return formParams;
    },

    /**
     * Loop campsites for sort by matching type
     *
     * @param campsites
     * @returns {{extra_match: Array, partial_match: Array, not_match: Array}}
     */
    matchingCS: function matchingCS(campsites) {
      var extra_match = [],
          partial_match = [],
          not_match = [];

      $.each(campsites, function (id) {
        if (campsites[id].match_type == 'partial_match') {
          partial_match.push(campsites[id]);
        } else if (campsites[id].match_type == 'not_match') {
          not_match.push(campsites[id]);
        } else {
          extra_match.push(campsites[id]);
        }
      });

      return { 'extra_match': extra_match, 'partial_match': partial_match, 'not_match': not_match };
    },

    /**
     * Run campsites ajax
     */
    campsitesAjax: function campsitesAjax() {
      var self = this;
      var acUrl = '/campgrounds/' + this.cg.id + '/campsites';
      var filters = this.getFilters();

      this.get('api').sendReq({
        url: acUrl,
        type: "GET",
        data: filters
      }).then(function (data) {
        self.set('campsites', self.matchingCS(data));
      });
    },

    /**
     * Get reservation by params and set reservations property
     */
    availabilityReservationsAjax: function availabilityReservationsAjax() {
      var params,
          url,
          self = this;

      if (this.get('singleCampsite')) {
        url = '/campsites/' + this.get('singleCampsite.id') + '/availability';
      } else {
        url = '/campgrounds/' + this.get('cg.id') + '/availability';
      }

      params = {
        'week': this.page,
        'week_size': this.currentWeekSize,
        'start_date': this.sd.format('YYYY-MM-DD')
      };

      if (this.get('pickedDate')) {
        params.start_date = moment(this.get('pickedDate')).format('YYYY-MM-DD');
      }

      this.get('api').sendReq({
        url: url,
        type: "GET",
        data: params,
        async: false,
        beforeSend: function beforeSend(xhr) {
          if (self.get('currentUser.logged')) {
            xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
          }
        }
      }).then(function (data) {
        self.set('reservations', data);
      });
    },

    /**
     * Build all calendar without campsites
     */
    calendarDates: function calendarDates() {
      var today = '';

      this.setStartDay();

      if (this.get('pickedDate')) {
        today = new Date(this.get('pickedDate'));
      } else {
        today = new Date(this.sd);
      }

      this.set('dateRange', this.datesInRange(today));

      if ($.cookie('homefilter') != null && $.cookie('homefilter') !== undefined) {
        var cookieData = JSON.parse($.cookie('homefilter'));
        var _cookieDate = { start_date: cookieData.sdate, end_date: cookieData.edate };
        this.set('cookieDate', _cookieDate);
      }

      var headerDates = [];
      var cookieDate = this.get('cookieDate');
      var toDay = moment(new Date()).format('MM/DD/YYYY');
      var rangeDay = cookieDate ? cookieDate.end_date : toDay;

      this.get('dateRange').map(function (d) {
        var dayString = [];
        dayString['dname'] = d.toString().split(' ')[0];
        dayString['mname'] = d.toString().split(' ')[1];
        dayString['dnumber'] = d.getDate();

        if (moment(d).format('MM/DD/YYYY') <= rangeDay && toDay <= rangeDay && toDay <= moment(d).format('MM/DD/YYYY')) {
          dayString['range'] = 'active';
        } else {
          dayString['range'] = '';
        }
        dayString['className'] = dayString['dname'] === "Sat" || dayString['mname'] === "Sun" ? 'weekend' : '';
        headerDates.push(dayString);
      });

      this.set('headerDates', headerDates);

      this.availabilityReservationsAjax();
    },

    /**
     * Get date range from start calendar to end
     */
    datesInRange: function datesInRange(today) {
      var dStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() + this.get('page') * this.get('currentWeekSize'));
      var dEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() + this.get('currentWeekSize') + this.get('page') * this.get('currentWeekSize'));
      var aDates = [];
      if (dStart <= dEnd) {
        for (var d = dStart; d < dEnd; d.setDate(d.getDate() + 1)) {
          aDates.push(new Date(d.toString()));
        }
      }
      return aDates;
    },

    /**
     * Set sticky calendar header while scrolling
     */
    stickyRelocate: function stickyRelocate() {
      var calendar = undefined,
          navigationHeight = undefined,
          offsetCalendar = undefined,
          endCalendar = undefined,
          scrollHeight = undefined,
          startStiky = undefined;
      calendar = $('#availability-calendar');
      var heightCalendarHeader = undefined;

      function defineMainParams() {
        navigationHeight = $('#main-header').outerHeight();
        offsetCalendar = calendar.offset();
        endCalendar = calendar.outerHeight() + offsetCalendar.top - $('.calendar-block').outerHeight() - navigationHeight;
        startStiky = $('.info-statuses').outerHeight(true) + offsetCalendar.top - navigationHeight;
        heightCalendarHeader = $('.calendar-header').outerHeight();
      }

      defineMainParams();
      $(window).resize(defineMainParams);

      $(window).scroll(function () {
        scrollHeight = window.scrollY;

        if (scrollHeight > startStiky && scrollHeight < endCalendar) {
          calendar.addClass('sticky-header-enabled');
          $('.calendar-header').css({
            'position': 'fixed',
            'top': navigationHeight + 'px',
            'left': calendar.offset().left + 'px',
            'width': calendar.width(),
            'padding-bottom': '20px'
          });
          $('.info-statuses').css('padding-bottom', heightCalendarHeader + 20 + 'px');
        } else {
          calendar.removeClass('sticky-header-enabled');
          $('.calendar-header').attr('style', '');
          $('.info-statuses').attr('style', '');
        }
      });
    },

    /**
     * Register events
     */
    gtmEvent: function gtmEvent(cg, cs, date) {

      var initiatedFrom = { 'unknown': 'Unknown' };
      if (!this.get('cgView') && this.type == 'dash') {
        initiatedFrom = { 'admin_campsite_calendar': 'Admin: Campsite availability calendar' };
      } else if (!this.get('cgView') && this.type != 'dash') {
        initiatedFrom = { 'campsite_calendar': 'Campsite availability calendar' };
      } else if (this.get('cgView') && this.type == 'dash') {
        initiatedFrom = { 'admin_campground_calendar': 'Admin: Campground availability calendar' };
      } else if (this.get('cgView') && this.type != 'dash') {
        initiatedFrom = { 'campground_calendar': 'Campground availability calendar' };
      }

      _cnFrontUtilsGtm['default'].trackEvent({
        'event': 'productClick',
        'ecommerce': {
          'click': {
            'actionField': initiatedFrom,
            // Where product click was initiated. Options are:
            //
            // admin_campground_calendar: 'Admin: Campground availability calendar'
            // campground_calendar: 'Campground availability calendar'
            // campsite_list_book_now: 'Campsite list book now button'
            // admin_campsite_calendar: 'Admin: Campsite availability calendar'
            // campsite_calendar: 'Campsite popup availability calendar'
            // campsite_popup_book_now: 'Campsite popup book now button'

            'products': [{
              'name': cg.name, // Campground name
              'id': cs.id, // campsite ID
              'date': date // new reservation date
            }]
          }
        }
      });
    }
  });
});