define("cn-front/templates/components/partials/site-footer", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 28
              },
              "end": {
                "line": 20,
                "column": 59
              }
            },
            "moduleName": "cn-front/templates/components/partials/site-footer.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("About Us");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 28
              },
              "end": {
                "line": 22,
                "column": 59
              }
            },
            "moduleName": "cn-front/templates/components/partials/site-footer.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Partners");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 28
              },
              "end": {
                "line": 23,
                "column": 54
              }
            },
            "moduleName": "cn-front/templates/components/partials/site-footer.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Help");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 28
              },
              "end": {
                "line": 24,
                "column": 60
              }
            },
            "moduleName": "cn-front/templates/components/partials/site-footer.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Contact");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 28
              },
              "end": {
                "line": 29,
                "column": 62
              }
            },
            "moduleName": "cn-front/templates/components/partials/site-footer.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Find Camping");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 46
              },
              "end": {
                "line": 30,
                "column": 90
              }
            },
            "moduleName": "cn-front/templates/components/partials/site-footer.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("List Your Property");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 20
              },
              "end": {
                "line": 36,
                "column": 66
              }
            },
            "moduleName": "cn-front/templates/components/partials/site-footer.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Terms & Privacy");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child7 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 57,
                "column": 12
              },
              "end": {
                "line": 59,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/partials/site-footer.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "src", "/assets/img/whitelogo.png");
            dom.setAttribute(el1, "alt", "Camp Native Logo");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 63,
              "column": 0
            }
          },
          "moduleName": "cn-front/templates/components/partials/site-footer.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "footer");
          dom.setAttribute(el1, "class", "footer ");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "container");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-md-12");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-x-lg-6 l");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "border");
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-x-lg-6 r");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "border");
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("ul");
          dom.setAttribute(el7, "class", "footer-nav clearfix");
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("li");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("li");
          var el9 = dom.createElement("a");
          dom.setAttribute(el9, "href", "http://letsgo.campnative.com");
          var el10 = dom.createTextNode("Blog");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("li");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("li");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("li");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                    ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("ul");
          dom.setAttribute(el7, "class", "footer-nav-buttons clearfix");
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("li");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("li");
          dom.setAttribute(el8, "class", "list-land");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                        ");
          dom.appendChild(el8, el9);
          var el9 = dom.createElement("div");
          dom.setAttribute(el9, "class", "cursor");
          var el10 = dom.createElement("span");
          var el11 = dom.createTextNode("Free Forever");
          dom.appendChild(el10, el11);
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                        ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                    ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6, "class", "terms-n-copyright");
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("br");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7, "class", "copyright");
          var el8 = dom.createTextNode("© Camp Native 2015-2017");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("ul");
          dom.setAttribute(el7, "class", "social-links clearfix");
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("li");
          var el9 = dom.createElement("a");
          dom.setAttribute(el9, "href", "http://www.facebook.com/campnative");
          dom.setAttribute(el9, "target", "_blank");
          var el10 = dom.createElement("span");
          dom.setAttribute(el10, "class", "fa fa-facebook");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("li");
          var el9 = dom.createElement("a");
          dom.setAttribute(el9, "href", "http://twitter.com/campnative");
          dom.setAttribute(el9, "target", "_blank");
          var el10 = dom.createElement("span");
          dom.setAttribute(el10, "class", "fa fa-twitter");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                        ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("li");
          var el9 = dom.createElement("a");
          dom.setAttribute(el9, "href", "http://www.instagram.com/campnative");
          dom.setAttribute(el9, "target", "_blank");
          var el10 = dom.createElement("span");
          dom.setAttribute(el10, "class", "fa fa-instagram");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                        ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                        ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("li");
          var el9 = dom.createElement("a");
          dom.setAttribute(el9, "href", "http://www.pinterest.com/campnative");
          dom.setAttribute(el9, "target", "_blank");
          var el10 = dom.createElement("span");
          dom.setAttribute(el10, "class", "fa fa-pinterest");
          dom.appendChild(el9, el10);
          dom.appendChild(el8, el9);
          var el9 = dom.createTextNode("\n                        ");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                    ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0, 1]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element1, [3, 1]);
          var element3 = dom.childAt(element2, [1, 1]);
          var element4 = dom.childAt(element2, [3, 1]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 1]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element3, [5]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [7]), 0, 0);
          morphs[5] = dom.createMorphAt(dom.childAt(element3, [9]), 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[7] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
          morphs[8] = dom.createMorphAt(dom.childAt(element2, [5]), 1, 1);
          morphs[9] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
          return morphs;
        },
        statements: [["content", "dashboard/sign-up-form", ["loc", [null, [7, 16], [7, 42]]], 0, 0, 0, 0], ["content", "state-pages-list", ["loc", [null, [13, 16], [13, 36]]], 0, 0, 0, 0], ["block", "link-to", ["about-us"], [], 0, null, ["loc", [null, [20, 28], [20, 71]]]], ["block", "link-to", ["partners"], [], 1, null, ["loc", [null, [22, 28], [22, 71]]]], ["block", "link-to", ["support"], [], 2, null, ["loc", [null, [23, 28], [23, 66]]]], ["block", "link-to", ["contact-us"], [], 3, null, ["loc", [null, [24, 28], [24, 72]]]], ["block", "link-to", ["explore"], [], 4, null, ["loc", [null, [29, 28], [29, 74]]]], ["block", "link-to", ["camp-owners"], [], 5, null, ["loc", [null, [30, 46], [30, 102]]]], ["block", "link-to", ["terms-of-service"], [], 6, null, ["loc", [null, [36, 20], [36, 78]]]], ["block", "link-to", ["index"], ["class", "footer-logo"], 7, null, ["loc", [null, [57, 12], [59, 24]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 64,
            "column": 20
          }
        },
        "moduleName": "cn-front/templates/components/partials/site-footer.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" begin #footer ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" end #footer ");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["block", "if", [["get", "shown", ["loc", [null, [2, 6], [2, 11]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [63, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});