define('cn-front/controllers/dashboard/campground/edit/applications', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),

        allowAppForm: (function () {
            var appStatus = this.get('model').applicationStatus;
            if (!appStatus || appStatus.id != 1) {
                return true;
            }
            return false;
        }).property('model')
    });
});