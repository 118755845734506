define('cn-front/services/dashboard/res-change', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        available_to_cancel: null,
        can_cancel_change: null,
        camper_change_note: null,
        camper_note: null,
        campground_owner_change_note: null,
        cg_id: null,
        cg_name: null,
        cg_note: null,
        cs_id: null,
        cs_name: null,
        date_arrival: null,
        date_departure: null,
        financial_data: null,
        rate_daily: null,
        rate_weekly: null,
        rate_monthly: null,
        reservation_inadvance_max: null,
        reservation_inadvance_min: null,
        status: null,
        success: null,
        type: null,
        user: null,

        init: function init() {}
    });
});