define('cn-front/routes/how-it-works', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        title: 'Campground Reservations & Booking Management - Find Out How',
        headTags: function headTags() {
            // here we are pulling meta data from the model for this route
            return [{
                type: 'meta',
                tagId: 'meta-description-tag',
                attrs: {
                    name: 'description',
                    content: 'Learn how Camp Native can facilitate booking for campgrounds and offer the best experience at affordable rates for campers.'
                }
            }];
        }
    });
});