define('cn-front/routes/campground/campsite/new-reservation', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            var campgroundModel = this.modelFor('campground');
            var campsiteModel = this.modelFor('campground.campsite');

            return {
                campground: campgroundModel.campground,
                campsite: campsiteModel.campsite
            };
        }
    });
});