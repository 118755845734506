define('cn-front/helpers/break-lines', ['exports', 'ember'], function (exports, _ember) {
  exports.breakLines = breakLines;

  function breakLines(params) {
    if (params !== '' && params[0] != null) {
      var text = params[0];
      text = text.replace(/(\r\n|\n|\r)/gm, '<br/>');
      return _ember['default'].String.htmlSafe(text);
    } else {
      return '';
    }
  }

  exports['default'] = _ember['default'].Helper.helper(breakLines);
});