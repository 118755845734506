define('cn-front/components/campground/camp-info', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Component.extend({
        currentUser: inject.service('current-user'),
        onlineReservationDisabled: (function () {
            return this.get('model.campground.reservation_ccprocessing_id') == 1 || this.get('model.campground.reservation_ccprocessing_id') == 2;
        }).property('model.campground.reservation_ccprocessing_id'),

        onlineReservationEnabled: (function () {
            return this.get('model.campground.reservation_ccprocessing_id') == 3;
        }).property('model.campground.reservation_ccprocessing_id'),

        reservationRequiresConfirmation: (function () {
            return this.get('model.campground.reservation_confirmation_id') == 2;
        }).property('model.campground.reservation_confirmation_id'),

        infoRequestAvailable: (function () {
            var mode = this.get('model.campground.reservation_mode');
            return mode == 'information_request' || mode == 'revoked';
        }).property('model.campground.reservation_mode'),

        didReceiveAttrs: function didReceiveAttrs() {
            $('body').animate({
                scrollTop: 0
            }, 200);
        }
    });
});