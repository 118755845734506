define('cn-front/controllers/dashboard/modal/rescancel', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Controller.extend({
        reservation: inject.service('dashboard/reservation'),
        refresher: inject.service('dashboard/refresher'),
        currentUser: inject.service('current-user'),
        apiConnect: inject.service('api-resolver'),

        stepster: inject.service('dashboard/stepster'),

        currTab: 0,

        setters: (function () {

            _ember['default'].run.schedule('afterRender', this, function () {

                this.set('currTab', 1);
                this.observeCurrTab();

                this.set('stepster.ready', true);
                this.set('stepster.fallback', null);

                this.set('tabSize', $('.resnav>li>a').length);
                $(".resnav a[data-toggle=tab]").on("click", function (e) {
                    e.preventDefault();
                    return false;
                });

                $('#confirm').change(function () {

                    if ($(this).is(":checked")) {
                        $('.res-footer .next').prop('disabled', false);
                    } else {
                        $('.res-footer .next').prop('disabled', true);
                    }
                });
            });
        }).observes('model'),

        actions: {

            close: function close() {
                $('.modal').modal('hide');
            },
            updateModel: function updateModel() {
                this.send('refreshModel');
            },
            next: function next() {
                if (this.get('stepster.ready')) {
                    if (!$('.resnav li:last').hasClass('active')) {
                        var curr = $('.steps .tab-pane.active').attr('id').split('-')[1];
                        curr++;
                        this.set('currTab', curr);
                        $('.resnav a[href="#step-' + curr + '"]').tab('show');
                    }
                    //this.set('stepster.ready', false);
                } else {
                        this.get('stepster').fallback();
                    }
            },
            prev: function prev() {
                if (!$('.resnav li:first').hasClass('active')) {
                    var curr = $('.steps .tab-pane.active').attr('id').split('-')[1];
                    curr--;
                    this.set('currTab', curr);
                    $('.resnav a[href="#step-' + curr + '"]').tab('show');
                }
            },
            cancel: function cancel() {

                $("body").css("cursor", "progress");

                var url = this.get('apiConnect').getUrl("/reservations/cancel");
                var noteStr = $('#cancelation-note').val();

                var ajaxPromise = $.ajax({
                    type: "POST",
                    url: url,
                    data: {
                        reservation_id: this.get('model.reservation_id'),
                        type: this.get('model.reservation.type'),
                        note: noteStr,
                        initiator: this.get('model.initiator'),
                        initiator_token: $.cookie('token')
                    },
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    }
                });

                var self = this;
                _ember['default'].run(function () {
                    ajaxPromise.then(function (data) {
                        $("body").css("cursor", "default");
                        self.set('reservation.response', data);
                        self.get('refresher').ping('calendar');
                        self.send('next');
                    });
                });
            }
        },

        scrollToTop: (function () {
            $('.modal .modal-content').animate({
                scrollTop: 0
            }, 400);
        }).observes('currTab'),

        observeCurrTab: (function () {
            var c = this.get('currTab');

            this.set('stepster.currentStep', c);
            if (c == 1) {
                $('.res-footer .prev').hide();
            } else {
                if (this.get('tabSize') == c) {
                    $('.res-footer .submit').show();
                    $('.res-footer .prev').hide();
                    $('.res-footer .next').hide();
                } else {
                    $('.res-footer .submit').hide();
                    $('.res-footer .prev').show();
                    $('.res-footer .next').show();
                }
            }
        }).observes('currTab').on('init')
    });
});