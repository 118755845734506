define('cn-front/mixins/responsive-explorer', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        activate: function activate() {
            var self = this;
            this._super.apply(this, arguments); // Call super

            // TODO: refactor this according to new functionality
            $(window).on('resize', function () {
                var winWidth = $(this).width();
                // filters collapse and expand
                if (winWidth < 992) {
                    $(".filter-wrapper a[data-toggle='collapse']:not(.collapsed)").addClass('wasExpanded').click();
                    // ADD the height attribute to collapsed divs
                    $("#exploreFilters.collapse:not(.in),#campgroundList.collapse:not(.in),#exploreMap.collapse:not(.in)").css('height', '0px');
                    $("#exploreFilters.collapsed-xs,#campgroundList.collapsed-xs,#exploreMap.collapsed-xs").addClass('collapsed').removeClass('collapsed-xs');
                } else {
                    $(".wasExpanded.collapsed").click();
                    // REMOVE the height attribute to collapsed divs
                    $("#exploreFilters.collapse:not(.in),#campgroundList.collapse:not(.in),#exploreMap.collapse:not(.in)").css('height', '');
                    $("#exploreFilters.collapsed,#campgroundList.collapsed,#exploreMap.collapsed").addClass('collapsed-xs').removeClass('collapsed');
                }
            });

            _ember['default'].run.schedule('afterRender', this, function () {
                // need to change filters visible when resize
                $(window).trigger('resize');
                // bootstrap on show map trigger the resize event, this will cause it to redraw the map so it shows up correctly
                $("#exploreMap").on('shown.bs.collapse', function () {
                    self.get('gMaps').resize();
                });
            });
        }
    });
});