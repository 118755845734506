define('cn-front/initializers/facebook-sdk', ['exports'], function (exports) {
  /*globals FB*/
  exports['default'] = {
    name: 'facebook',

    initialize: function initialize() {
      var fbAsyncInit = function fbAsyncInit() {
        //6a58c2c4e473752a2eee3022608e0b19
        FB.init({
          // appId      : '1068835103188129',
          // xfbml      : false,
          // version    : 'v2.5'
          appId: '528479399358955',
          cookie: true,
          xfbml: false,
          version: 'v16.0'
        });
        FB.AppEvents.logPageView();
      };

      (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');

      window.fbAsyncInit = fbAsyncInit;
    }
  };
});