define('cn-front/services/dashboard/reservation', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        campsite: {},
        camp: {},
        restype: '',

        startdate: '',
        enddate: '',
        daterange: '',
        days: '',
        days_computed: '',

        type: '',
        rate_daily: 0,

        rate_weekly: 0,
        rate_monthly: 0,

        total_without_fee: '',
        camper_fee: '',
        camper_charged: '', // Needed for braintree transaction (amount)
        cg_fee: '',
        cg_received: '',
        svc_fee: '', // Needed for braintree transaction (service fee)

        remote_token: '',
        remote_user: '',
        braintree_token: '',
        response: '',
        type_desc: '',

        init: function init() {},
        clearAll: function clearAll() {
            var fields = ['campsite', 'camp', 'startdate', 'enddate', 'daterange', 'days', 'days_computed', 'type', 'rate_daily', 'rate_weekly', 'rate_monthly', 'restype', 'total_without_fee', 'camper_fee', 'camper_charged', 'cg_fee', 'cg_received', 'svc_fee', 'remote_user', 'remote_token', 'braintree_token', 'response', 'type_desc'];

            var self = this;
            fields.forEach(function (field) {
                self.set(field, null);
            });
        }

    });
});