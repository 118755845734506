define('cn-front/routes/dashboard/campground/edit', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        model: function model(params, transition) {
            var campground_id = transition.params['dashboard.campground'].id;

            _ember['default'].$.ajaxSetup({
                headers: {
                    'Authorization': 'bearer ' + $.cookie('token')
                }
            });
            return _ember['default'].$.getJSON(this.get('apiConnect').getUrl('/dashboard/campgrounds/' + campground_id + '/data'));
        },

        actions: {
            refreshModel: function refreshModel() {
                //display message in the console of your browser
                _ember['default'].Logger.log('Route is now refreshing...');
                //refresh the model
                this.refresh();
            }
        },

        beforeModel: function beforeModel(transition) {
            if (transition.targetName === 'dashboard.campground.edit.index') {
                this.transitionTo('dashboard.campground.edit.availability');
            }
        },
        scrollToTop: (function () {
            $('body,html').animate({
                scrollTop: 0
            }, 400);
        }).on('activate'),

        renderTemplate: function renderTemplate(controller, model) {
            // render the standard template
            this._super(controller, model);
            // Render the nav into the application template
            this.render('dashboard/campground/navigation', { // the template to render
                into: 'dashboard', // the template to render into
                outlet: 'top_nav', // the name of the outlet in this (dashboard) template
                controller: 'dashboard/campground/edit'
            });
        }
    });
});