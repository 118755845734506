define('cn-front/components/google-recaptcha', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['g-recaptcha'],
        attributeBindings: ['siteKey:data-sitekey', 'data-theme', 'data-size', 'data-callback', 'data-expired-callback', 'data-tabindex'],
        siteKey: '6LdZbQ0TAAAAAOTjZkr_L-v-e-4dVY6qAaKVkNC6',
        lang: 'en',
        resetTrigger: false,
        _isSetup: false,
        _attempts: 0,
        _maxAttempts: (function () {
            return 20;
        }).property().readOnly(),
        _interval: (function () {
            return 100; // Time between polls (in ms)
        }).property().readOnly(),

        verifyCallback: function verifyCallback(data) {
            _ember['default'].$.ajaxPrefilter(function (options, oriOpt, jqXHR) {
                jqXHR.setRequestHeader("X-Recaptcha-Token", data);
            });
        },
        setupGrecaptcha: function setupGrecaptcha() {
            grecaptcha.render(this.$().prop('id'), {
                'sitekey': this.get('siteKey'),
                'callback': this.verifyCallback
            });
            this.set('_isSetup', true);
        },
        resetGrecaptcha: (function () {
            if (this.get('_isSetup') === true && this.get('resetTrigger') === true) {
                grecaptcha.reset(this.$().prop('id'));
                this.set('resetTrigger', false);
            }
        }).observes('resetTrigger'),
        pollForObject: function pollForObject() {
            try {
                if (window.grecaptcha !== undefined) {
                    this.setupGrecaptcha();
                } else if (this.get('_attempts') < this.get('_maxAttempts')) {
                    this.set('_attempts', this.get('_attempts') + 1);
                    _ember['default'].run.later(this, function () {
                        this.pollForObject();
                    }, this.get('_interval'));
                } else {
                    _ember['default'].Logger.error("Failed to get grecapthca script");
                }
            } catch (e) {
                console.log(e);
            }
        },
        init: function init() {
            this._super();

            if (_ember['default'].testing) {
                return;
            }

            var self = this;
            $.getScript("https://www.google.com/recaptcha/api.js?&render=explicit&hl=" + self.get('lang'), function () /*data, textStatus, jqxhr*/{
                self.pollForObject();
            });
        }
    });
});