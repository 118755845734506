define('cn-front/services/gmaps', ['exports', 'ember'], function (exports, _ember) {

  var inject = _ember['default'].inject;

  exports['default'] = _ember['default'].Service.extend({
    markerBounds: new google.maps.LatLngBounds(),
    map: null,
    markersArray: [],
    markerOld: null,
    isDraggingMap: false,
    prev_infowindow: false,
    locationMarkId: false,
    preselect: inject.service(),
    markedCampId: false,
    markedCampPinId: false,

    defaultLocation: {
      latitude: 39,
      longitude: -98
    },

    /**
     * Initialize google map
     */
    initialize: function initialize() {

      if (_ember['default'].testing) {
        return;
      }

      var self = this;
      var mapOptions = {
        panControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        },
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        overviewMapControl: false,
        center: { lat: this.get('defaultLocation.latitude'), lng: this.get('defaultLocation.longitude') },
        zoom: 4
        //scrollwheel: false
      };

      // Reset markersArray on every map initialization
      this.set('markersArray', []);

      this.set('map', new google.maps.Map(document.getElementById('gmap'), mapOptions));

      google.maps.event.addListener(self.get('map'), 'dragend', function () {
        self.set('isDraggingMap', true);
      });
    },

    /**
     * Set map center
     *
     * @param newLocation
     * @param zoom
     */
    setCenter: function setCenter(newLocation, zoom) {

      if (_ember['default'].testing) {
        return;
      }

      if (typeof zoom === 'undefined') {
        zoom = 9;
      }

      this.get('map').setCenter(new google.maps.LatLng(newLocation.latitude, newLocation.longitude));
      this.get('map').setZoom(zoom);
    },

    /**
     * Get map center
     *
     * @returns {{latitude: *, longitude: *}}
     */
    getCenter: function getCenter() {
      return {
        latitude: this.get('map').getCenter().lat(),
        longitude: this.get('map').getCenter().lng()
      };
    },

    /**
     * Hepler: find if marker already exists on a map
     *
     * @param search
     * @returns {boolean}
     */
    isLocationFree: function isLocationFree(search) {
      var lookup = this.get('markersArray');
      for (var i = 0, l = lookup.length; i < l; i++) {
        if (parseFloat(lookup[i].position.lat().toFixed(8)) === parseFloat(search[0]) && parseFloat(lookup[i].position.lng().toFixed(8)) === parseFloat(search[1])) {
          return false;
        }
      }
      return true;
    },

    /**
     * Render map markers
     *
     * @param locations
     * @param userLocation
     * @param hideUserMarker
     */
    renderMarkers: function renderMarkers(locations, userLocation, hideUserMarker, locationMark) {

      if (_ember['default'].testing) {
        return;
      }

      if (typeof hideUserMarker === 'undefined') {
        hideUserMarker = false;
      }

      var locationInput = this.get('preselect').location;
      var locationMarker = this.get('preselect').locationObj;
      var locationEmpty = locationInput === '' && jQuery.isEmptyObject(locationMarker);
      var isOld = false;
      if (!locationEmpty && 'isCampground' in locationMarker) {
        this.set('markedCampId', locationMarker.id);
      } else {
        isOld = true;
      }
      /**
       * Declare variables
       */
      var self = this;

      var infoWindow = new google.maps.InfoWindow();
      var marker, i, position;

      /**
       * Add location markers
       */
      for (i = 0; i < locations.length; i++) {
        if (!this.isLocationFree([locations[i][1], locations[i][2]]) && this.get('markedCampId') !== locations[i][3]) {
          continue;
        }

        /**
         * Figure out marker position
         *
         * @type {google.maps.LatLng}
         */
        position = new google.maps.LatLng(locations[i][1], locations[i][2]);
        this.get('markerBounds').extend(position);

        /**
         * Create google maps marker and put it into markersArray
         *
         * @type {google.maps.Marker}
         */
        var markerImage = null;
        if (this.get('markedCampId') !== locations[i][3]) {
          markerImage = '/assets/images/explore/price.svg';
        } else if (isOld) {
          markerImage = '/assets/images/explore/price.svg';
          this.set('markedCampId', false);
          if (this.get('markersArray').length > 0) {
            this.get('markersArray')[this.get('markedCampPinId')].setMap(null);
          }
        } else {
          this.set('markedCampPinId', this.get('markersArray').length);
        }

        marker = new google.maps.Marker({
          position: position,
          map: self.get('map'),
          icon: markerImage,
          label: {
            text: locations[i][4],
            color: '#212322',
            fontSize: "16px",
            className: 'marker-label'
          },
          title: locations[i][4],
          cg_id: locations[i][3]
        });
        this.get('markersArray').push(marker);

        /**
         * Add marker click listener for info window to open
         */
        google.maps.event.addListener(marker, 'click', this.magicMarker(infoWindow, locations, marker, i, this));
      }

      if (!hideUserMarker) {

        /**
         * Add user's position on a map
         *
         * @type {google.maps.LatLng}
         */
        position = new google.maps.LatLng(userLocation.latitude, userLocation.longitude);
        // don't add user location to fitBounds

        /**
         * Create google maps marker and put it into markersArray
         *
         * @type {google.maps.Marker}
         */
        marker = new google.maps.Marker({
          position: position,
          map: self.get('map'),
          title: 'You are here.',
          icon: '/assets/images/blue-map-icon.png'
        });
        this.get('markersArray').push(marker);
      }

      if (locationEmpty && this.get('locationMarkId')) {
        //if no have location mark, try to kill old one
        if (this.get('markersArray').indexOf(this.get('locationMarkId')) !== -1) {
          this.get('markersArray')[this.get('locationMarkId')].setMap(null);
        }
        this.set('locationMarkId', false);
      } else if (locationMark !== 'undefined' && locationMark !== false && !locationEmpty) {
        position = new google.maps.LatLng(locationMark.latitude, locationMark.longitude);
        this.get('markerBounds').extend(position);

        marker = new google.maps.Marker({
          position: position,
          // icon: '/assets/images/explore/price.svg',
          map: self.get('map')
        });
        this.get('markersArray').push(marker);
        this.set('locationMarkId', this.get('markersArray').length - 1);
      }
    },

    magicMarker: function magicMarker(infoWindow, locations, marker, i, component) {
      var self = this;

      return function () {
        var _this = this;

        // if (self.prev_infowindow) {
        //   self.prev_infowindow.close();
        // }

        infoWindow.addListener('closeclick', function () {
          var label = _this.getLabel();
          marker.setIcon('/assets/images/explore/price.svg');
          label.color = '#212322';
          label.className = 'marker-label';
          marker.setLabel(label);
          self.prev_infowindow = infoWindow;
          infoWindow.setContent(locations[i][0]);
          infoWindow.close(self.get('map'), marker);
        });

        var markers = self.markersArray;
        var sizeArray = markers.length;
        var markerOld = component.get('markerOld');

        if (markerOld) {
          for (var j = 0; j < sizeArray; j++) {
            if (markerOld.cg_id === markers[j].cg_id) {
              var _label = markers[j].getLabel();
              var icon = markers[j].getIcon();
              if (_label) {
                _label.color = '#212322';
                _label.className = 'marker-label';
                icon = '/assets/images/explore/price.svg';
                markers[j].setLabel(_label);
                markers[j].setIcon(icon);
              }
              component.get('markersArray').push(markers[j]);
              break;
            }
          }
        }

        var label = this.getLabel();
        marker.setIcon('/assets/images/explore/price-a.svg');
        label.color = "white";
        label.className = 'marker-label-active';
        marker.setLabel(label);
        self.prev_infowindow = infoWindow;
        component.set('markerOld', marker);
        infoWindow.setContent(locations[i][0]);
        infoWindow.open(self.get('map'), marker);
      };
    },
    /**
     * Deletes all markers in the array by removing references to them
     */
    deleteMarkers: function deleteMarkers() {

      /**
       * Loop through markers and set map null
       */
      if (this.get('markersArray').length) {
        for (var i = 0; i < this.get('markersArray').length; i++) {
          this.get('markersArray')[i].setMap(null);
        }
        this.get('markersArray').length = 0;
        this.set('markerBounds', new google.maps.LatLngBounds());
      }
    },
    /**
     * trigger the map resize event.
     * this is useful if the map was hidden and needs to be redrawn.
     */
    resize: function resize() {
      google.maps.event.trigger(this.get('map'), 'resize');
    }
  });
});