define('cn-front/components/campground/header-slider', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Component.extend({
        campsite: null,
        camground: null,
        type: 'cg',
        currentUser: inject.service('current-user'),

        didReceiveAttrs: function didReceiveAttrs() {
            _ember['default'].run.schedule("afterRender", this, function () {

                console.log(this.get('campground'));

                // check if maps are loaded
                if (typeof google !== 'undefined') {
                    this.initMap();
                } else {
                    var sliderSelf = this;
                    var url = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDG9UtSuq19xaNOJwwg4j0P54UNslSDaTk";
                    var getMapPromise = _ember['default'].$.getScript(url);
                    // wait until "this" slider is rendered before continuing on.
                    _ember['default'].run(function () {
                        getMapPromise.then(function () {
                            _ember['default'].run(sliderSelf.initMap);
                        });
                    });
                }

                if (!_ember['default'].testing) {
                    this.calcHeight();
                    $(window).resize(this.calcHeight);
                }
            });
        },
        actions: {
            inforeq: function inforeq() {
                var cg = this.get('campground');
                var modalData = {
                    camp: cg,
                    type: 'front',
                    restype: 'info_req'
                };
                this.sendAction('showModal', 'new-info-request', modalData);
            },
            findcs: function findcs(e) {
                $("#" + e).click();
            },
            show: function show(id) {
                var photos;
                if (this.get('type') == 'cs') {
                    photos = this.get('campground.photos');
                    for (var i = 0; i < photos.length; i++) {
                        if (photos[i].is_cg_photo) {
                            photos.splice(i, 1);
                        }
                    }
                } else {
                    photos = this.get('campground.photos').concat(this.get('campground.cs_photos'));
                }
                var data = {
                    type: 'photo',
                    cg: this.get('campground'),
                    photos: photos,
                    clicked: id
                };
                this.sendAction('showModal', 'photo-popup', data);
            },

            goToMap: function goToMap() {
                $('.map_tab').click();
            }
        },

        initMap: (function () {
            /**
             * Load campground map
             */
            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                if (_ember['default'].testing || this.isDestroyed) {
                    return;
                }

                var mapElement = jQuery('#header-map-canvas');
                if (mapElement.length === 1) {
                    jQuery(mapElement).css('width:100%');

                    var lat = mapElement.attr('data-lat'),
                        lng = mapElement.attr('data-lng');

                    var center = new google.maps.LatLng(lat, lng);
                    var mapOptions = {
                        zoom: 12,
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        center: center,
                        disableDefaultUI: true,
                        zoomControl: false,
                        draggable: false,
                        disableDoubleClickZoom: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        overviewMapControl: false,
                        scrollwheel: false
                    };
                    var map = new google.maps.Map(mapElement[0], mapOptions);
                    var image = '/assets/images/map-icon.png';

                    new google.maps.Marker({
                        position: center,
                        map: map,
                        icon: image
                    });
                }
            });
        }).observes('results'),

        calcHeight: function calcHeight() {
            var w = $('#pagebanner').find('li').width();
            $('#pagebanner > ul > li').height(w / 4 * 3);
        },

        willDestroyElement: function willDestroyElement() {
            $('.photo').off('click');
            $('.map').off('click');
            $('.find-cs-button').off('click');
        }
    });
});