define('cn-front/controllers/auth/register', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Controller.extend({
        currentUser: inject.service('current-user'),
        ustates: inject.service('ustates'),
        registrationEmail: '',
        tos: true,
        emailValid: false,
        refererInfoRequest: false,
        creationType: 'Register',
        tosNotAccepted: false,

        bindEvents: (function () {
            var self = this;
            _ember['default'].run.schedule('afterRender', this, function () {
                if (self.get('previousTransition')) {
                    var referer = self.get('previousTransition').split('?', 1);
                    if (referer[0] == '/info-request') {
                        self.set('refererInfoRequest', true);
                    }
                }
            });
        }).on('init'),
        redirectToPrevious: function redirectToPrevious() {
            var referer = this.get('previousTransition');
            if (referer) {
                this.transitionToRoute(referer);
            } else {
                this.transitionToRoute('index');
            }
        },
        statesList: (function () {
            var array = $.map(this.get('ustates').data, function (value) {
                return [value];
            });
            return array.slice(0, -2);
        }).property(),

        checkEmail: (function () {
            if ($('#register-form').length) {
                _ember['default'].run.debounce(this, this.lookupCamper, 1000);
            }
        }).observes('registrationEmail').on('init'),

        lookupCamper: function lookupCamper() {
            var flashMessages = _ember['default'].get(this, 'flashMessages');
            var self = this;
            this.get('api').sendReq({
                type: "POST",
                url: "/lookup",
                data: $('#register-form').serializeJSON()
            }).then(function (data) {
                if (typeof data.error === 'undefined') {
                    $('.email-validation').html('');
                    $('#register-form [name="email"]').addClass('valid_email');
                    self.set('emailValid', true);
                } else {
                    $('.email-validation').html(data.error.email[0]);
                    $('#register-form [name="email"]').removeClass('valid_email');
                    self.set('emailValid', false);
                }
            }, function (data) {
                $('html').animate({
                    scrollTop: 0
                }, 400);
                $.each(data.responseJSON, function (key, value) {
                    flashMessages.danger(value[0]);
                });
            });
        },

        actions: {
            register: function register() {
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                var self = this;
                if (self.get('tos')) {

                    var data = $('#register-form').serializeArray();
                    data.push({
                        name: "create_method",
                        value: self.get('creationType'),
                        tos: self.get('tos')
                    });

                    this.get('api').sendReq({
                        type: "POST",
                        url: "/signup",
                        data: data
                    }).then(function (data) {
                        self.get('currentUser').login(data);
                        _cnFrontUtilsGtm['default'].trackEvent({ event: 'register' });
                        self.redirectToPrevious();
                    }, function (data) {
                        $.each(data.responseJSON, function (key, value) {
                            flashMessages.danger(value[0]);
                        });
                        $('html,body').animate({
                            scrollTop: 0
                        }, 400);
                    });
                }
            },

            handleTos: function handleTos(accept) {
                if (accept) {
                    this.redirectToPrevious();
                } else {
                    var flashMessages = _ember['default'].get(this, 'flashMessages');
                    flashMessages.danger('Terms of Service are not accepted');
                }

                this.set('tosNotAccepted', false);
            },

            showTos: function showTos() {
                this.set('tosNotAccepted', true);
            }
        }
    });
});