define('cn-front/routes/user/profile', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        currentUser: _ember['default'].inject.service('current-user'),

        redirect: (function () {
            if (!this.get('currentUser.logged')) {
                this.transitionTo('auth.login');
            }
        }).observes('currentUser.logged')
    });
});