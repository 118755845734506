define('cn-front/routes/application', ['exports', 'ember', 'cn-front/utils/gtm', 'cn-front/config/environment'], function (exports, _ember, _cnFrontUtilsGtm, _cnFrontConfigEnvironment) {
    // jshint ignore:line

    var origin = $(location).attr('origin');
    exports['default'] = _ember['default'].Route.extend({
        headTags: [{
            type: 'meta',
            tagId: 'meta-og-locale',
            attrs: {
                property: 'og:locale',
                content: 'en_GB'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-title',
            attrs: {
                property: 'og:title',
                content: 'Camp Native'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image',
            attrs: {
                property: 'og:image',
                content: origin + '/assets/images/logo.png'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-width',
            attrs: {
                property: 'og:image:width',
                content: '186'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-height',
            attrs: {
                property: 'og:image:height',
                content: '30'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-type',
            attrs: {
                property: 'og:image:type',
                content: 'image/png'
            }
        }, {
            type: 'meta',
            tagId: 'meta-fb-app_id',
            attrs: {
                property: 'fb:app_id',
                content: 1068835103188129
            }
        }, {
            type: 'meta',
            tagId: 'meta-twitter-image',
            attrs: {
                property: 'twitter:image',
                content: origin + '/assets/favicons/android-chrome-192x192.png'
            }
        },
        /*  slider images  */
        /*  cn-slider1.jpg  */
        {
            type: 'meta',
            tagId: 'meta-og-image-s1',
            attrs: {
                property: 'og:image',
                content: origin + '/assets/images/banners/cn-slider1.jpeg'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-width-s1',
            attrs: {
                property: 'og:image:width',
                content: '1716'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-height-s1',
            attrs: {
                property: 'og:image:height',
                content: '472'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-type-s1',
            attrs: {
                property: 'og:image:type',
                content: 'image/jpeg'
            }
        },
        /*  cn-slider2.jpg  */
        {
            type: 'meta',
            tagId: 'meta-og-image-s2',
            attrs: {
                property: 'og:image',
                content: origin + '/assets/images/banners/cn-slider2.jpeg'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-width-s2',
            attrs: {
                property: 'og:image:width',
                content: '1716'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-height-s2',
            attrs: {
                property: 'og:image:height',
                content: '472'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-type-s2',
            attrs: {
                property: 'og:image:type',
                content: 'image/jpeg'
            }
        },
        /*  cn-slider3.jpg  */
        {
            type: 'meta',
            tagId: 'meta-og-image-s3',
            attrs: {
                property: 'og:image',
                content: origin + '/assets/images/banners/cn-slider3.jpeg'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-width-s3',
            attrs: {
                property: 'og:image:width',
                content: '1716'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-height-s3',
            attrs: {
                property: 'og:image:height',
                content: '472'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-type-s3',
            attrs: {
                property: 'og:image:type',
                content: 'image/jpeg'
            }
        },
        /*  cn-slider4.jpg  */
        {
            type: 'meta',
            tagId: 'meta-og-image-s4',
            attrs: {
                property: 'og:image',
                content: origin + '/assets/images/banners/cn-slider4.jpeg'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-width-s4',
            attrs: {
                property: 'og:image:width',
                content: '1716'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-height-s4',
            attrs: {
                property: 'og:image:height',
                content: '472'
            }
        }, {
            type: 'meta',
            tagId: 'meta-og-image-type-s4',
            attrs: {
                property: 'og:image:type',
                content: 'image/jpeg'
            }
        }],
        actions: {
            showModal: function showModal(name, model) {
                console.log('show');
                $('.modal-backdrop').remove();
                this.render(name, {
                    into: 'application',
                    outlet: 'modal',
                    model: model
                });
            },
            removeModal: function removeModal() {
                $('.modal-backdrop').remove();
                $('body').removeClass('modal-open').css({ overflow: '' });
                this.disconnectOutlet({
                    outlet: 'modal',
                    parentView: 'application'
                });
            },
            error: function error() {
                this.transitionTo('404', '404');
            },
            didTransition: function didTransition() {
                var url = this.router.get('url');
                _ember['default'].run.scheduleOnce('afterRender', this, function () {
                    _cnFrontUtilsGtm['default'].trackPageView(url);
                });
                this.send('removeModal');

                if (url !== '/auth/login' && url !== '/auth/register' && url !== '/auth/forgot-password' && url !== '/auth/reset') {
                    var loginController = this.controllerFor('auth/login');
                    loginController.set('previousTransition', url);

                    var registerController = this.controllerFor('auth/register');
                    registerController.set('previousTransition', url);
                }
                return true;
            }
        }
    });
});