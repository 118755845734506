define('cn-front/components/seo/city-list-bar', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        didReceiveAttrs: function didReceiveAttrs() {
            _ember['default'].run.schedule('afterRender', this, function () {
                this.$('#cities-list').listnav({ includeNums: false });
            });
        },
        didUpdateAttrs: function didUpdateAttrs() {
            this.$('#cities-list-nav').remove();
        }
    });
});