define('cn-front/routes/campground', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            return _ember['default'].$.getJSON(this.get('api').getUrl('/campgrounds/' + params.country + '/' + params.state + '/' + params.city + '/' + params.slug));
        },

        afterModel: function afterModel(model) {
            // hide campground from campers if not active
            if (!parseInt(model.campground.is_active) && !this.get('currentUser.hasDashboardPermissions')) {
                // TODO: move this check to backend in the future
                this.transitionTo('404', '404');
            }

            $('html,body').animate({
                scrollTop: 0
            }, 50);

            this.setHeadTags(model);
        },

        setHeadTags: function setHeadTags(model) {
            var ret = {};
            // here we are pulling meta data from the model for this route
            var cg = model.campground;

            this.get('meta').update({
                title: cg.name + ' - ' + cg.city + ', ' + cg.state + ' - Campgrounds'
            });

            ret = [{
                type: 'meta',
                tagId: 'meta-description-tag',
                attrs: {
                    name: 'description',
                    content: 'Find campsites from ' + cg.name + ' in ' + cg.city + ', ' + cg.state + '. ' + 'Booking is fast and easy, reserve your spot today!'
                }
            }, {
                type: 'meta',
                tagId: 'meta-og-title',
                attrs: {
                    property: 'og:title',
                    content: cg.name
                }
            }];

            if ("photos" in cg && cg.photos[0] !== undefined) {

                // if no photo, just return nothing
                var photo = '';
                var height = '';
                var width = '';

                for (var i = 0, len = cg.photos.length; i < len && i < 5; i++) {
                    var indx = i + 1;
                    if ("large" in cg.photos[i]) {
                        photo = cg.photos[i].large;
                        width = 752;
                        height = 564;
                    } else if ("small" in cg.photos[i]) {
                        photo = cg.photos[i].small;
                        width = 500;
                        height = 375;
                    } else if ("thumbnail" in cg.photos[i]) {
                        photo = cg.photos[i].thumbnail;
                        width = 160;
                        height = 120;
                    }
                    // image
                    ret.push({
                        type: 'meta',
                        tagId: 'meta-og-image-s' + indx,
                        attrs: {
                            property: 'og:image',
                            content: photo
                        }
                    });
                    // width
                    ret.push({
                        type: 'meta',
                        tagId: 'meta-og-image-width-s' + indx,
                        attrs: {
                            property: 'og:image:width',
                            content: width
                        }
                    });
                    // height
                    ret.push({
                        type: 'meta',
                        tagId: 'meta-og-image-height-s' + indx,
                        attrs: {
                            property: 'og:image:height',
                            content: height
                        }
                    });
                    // jpeg
                    ret.push({
                        type: 'meta',
                        tagId: 'meta-og-image-type-s' + indx,
                        attrs: {
                            property: 'og:image:type',
                            content: photo
                        }
                    });
                }

                // add a twitter image if available.
                var thumb = '';
                if ("thumbnail" in cg.photos[0]) {
                    thumb = cg.photos[0].thumbnail;
                } else if ("small" in cg.photos[0]) {
                    thumb = cg.photos[0].small;
                }
                if (thumb) {
                    ret.push({
                        type: 'meta',
                        tagId: 'meta-twitter-image',
                        attrs: {
                            property: 'twitter:image',
                            content: thumb
                        }
                    });
                }

                this.set('headTags', ret);
            }
        }
    });
});