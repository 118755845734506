define("cn-front/templates/dashboard/campsite/edit/availability", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "cn-front/templates/dashboard/campsite/edit/availability.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h3");
        var el2 = dom.createTextNode("Availabilty");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["inline", "dashboard/ava-calendar", [], ["camp", ["subexpr", "@mut", [["get", "model.campground", ["loc", [null, [3, 30], [3, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "single", true, "showModal", "showModal", "csId", ["subexpr", "@mut", [["get", "model.campsite.id", ["loc", [null, [3, 86], [3, 103]]], 0, 0, 0, 0]], [], [], 0, 0], "cs", ["subexpr", "@mut", [["get", "model.campsite", ["loc", [null, [3, 107], [3, 121]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "dash"], ["loc", [null, [3, 0], [3, 135]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});