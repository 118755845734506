define('cn-front/components/campground/helpers/is-valid-season', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        startDate: false,
        endDate: false,
        isValid: false,
        isFullYear: false,

        didRender: function didRender() {
            var sd = moment(moment(this.get('startDate')).format('YYYY-MM-DD'), 'YYYY-MM-DD'),
                ed = moment(moment(this.get('endDate')).format('YYYY-MM-DD'), 'YYYY-MM-DD'),
                now = moment();

            this.set('isValid', ed.diff(now, 'days') > 0);
            this.set('isFullYear', now.isBetween(sd, ed) && ed.diff(sd, 'days') >= 364);
        }
    });
});