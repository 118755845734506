define('cn-front/controllers/campground/campsite/new-reservation', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        reservation: _ember['default'].inject.service('reservation/instance'),
        stepster: _ember['default'].inject.service('reservation/stepster'),

        queryParams: ['start_date', 'end_date'], //
        start_date: null,

        defaultInit: (function () {
            /**
               * Reset everything to defaults
               */
            var self = this;
            this.get('reservation').clearAll();
            this.get('stepster').reset();
            this.get('stepster').onSuccessReservation = function () {
                self.transitionToRoute('campground');
            };

            this.set('reservation.end_date', this.get('end_date')); //
            /**
             * Set campsite to of customer choice
             */
            if (this.get('model.campsite')) {
                this.set('reservation.campsite', this.get('model.campsite'));
                // this.set('reservation.rate_daily', this.get('reservation.campsite.rate_daily'));
                // this.set('reservation.rate_weekly', this.get('reservation.campsite.rate_weekly'));
                // this.set('reservation.rate_monthly', this.get('reservation.campsite.rate_monthly'));
                this.set('reservation.dow_rates', this.get('reservation.campsite.dowRates'));
            }

            /**
             * Map model to a reservation service properties
             */
            if (this.get('model.campground')) {
                this.set('reservation.campground', this.get('model.campground'));
            }
            this.set('reservation.start_date', this.get('start_date'));

            // TODO [future]: need to pass type in case of 'dash'
            this.set('reservation.type', this.get('model.type') ? this.get('model.type') : 'front');

            /**
             * Set allowed reservation type
             * TODO [future]: allow on_site reservations too
             */
            if (this.get('reservation.campground.reservation_mode') === 'confirmation_booking') {
                this.set('reservation.reservation_type', 'confirm');
            } else if (this.get('reservation.campground.reservation_mode') === 'instant_booking') {
                this.set('reservation.reservation_type', 'instant');
            }

            if (this.get('reservation.type') === 'front' || this.get('reservation.reservation_type') === 'on_site') {
                this.set('stepster.stepsTotal', 4);
            } else {
                this.set('stepster.stepsTotal', 3);
            }

            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                if (this.get('model.campground.disable_reservations')) {
                    $('.next-btn').hide();
                }
            });
        }).observes('model'),

        /**
         * Scroll to top on current step change
         */
        scrollToTop: (function () {
            $('html,body').animate({
                scrollTop: 0
            }, 400);
        }).observes('stepster.currentStep'),

        actions: {
            /**
             * Go to previous route
             */
            back: function back() {
                history.back();
            },
            nextStep: function nextStep() {
                this.get('stepster').nextStep();
            }
        }

    });
});