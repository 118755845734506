define('cn-front/controllers/dashboard/campgrounds', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        queryParams: ['search', 'page'],
        search: "",
        searchFilter: "",
        page: 1,
        orderBy: '',
        sort: 'ASC',

        onSearchChange: (function () {
            // wait 1 second before applying the filter
            _ember['default'].run.debounce(this, this.applySearchFilter, 1000);
        }).observes('searchFilter'),

        applySearchFilter: function applySearchFilter() {
            this.set('page', 1);
            this.set('search', this.get('searchFilter'));
        },

        actions: {
            pageChanged: function pageChanged(current) {
                this.set('page', current);
            }
        },

        toTheTop: (function () {
            $('body').animate({
                scrollTop: 0
            }, 200);
        }).observes('page')
    });
});