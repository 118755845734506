define('cn-front/routes/auth/register', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
    var inject = _ember['default'].inject;

    /*globals FB*/

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        currentUser: inject.service('current-user'),
        FB_AuthUser: function FB_AuthUser(userID) {
            var self = this;
            var flashMessages = _ember['default'].get(this, 'flashMessages');
            var params = { locale: 'en_US', fields: 'first_name, last_name, email, location' };
            var url = '/' + userID;
            FB.api(url, params, function (user) {
                var url = self.get('apiConnect').getUrl('/signin/social');

                $.ajax({
                    type: "POST",
                    url: url,
                    data: user,
                    success: function success(data) {
                        self.get('currentUser').login(data);
                        _cnFrontUtilsGtm['default'].trackEvent({ event: 'login' });
                        var referer = self.get('previousTransition');

                        if (referer) {
                            self.transitionTo(referer);
                        } else {
                            self.transitionTo('index');
                        }
                    },
                    error: function error(data) {
                        if (data.responseJSON.error === 'tos_not_accepted') {
                            self.get('currentUser').setTemporaryToken(data.responseJSON.token);
                            self.send('showModal', 'tos-modal', 'updateTOS');
                        } else {
                            flashMessages.danger(data.responseJSON.message);
                        }
                    }
                });
            });
        },
        actions: {
            acceptTOS: function acceptTOS() {
                this.send('showModal', 'tos-modal', 'register');
            },
            register: function register() {
                // Look for register handler in tos-modal controller
            },
            facebookLogin: function facebookLogin() {
                var self = this;
                FB.login(function (response) {
                    if (response.status === 'connected') {
                        self.FB_AuthUser(response.authResponse.userID);
                    }
                }, {
                    // scope: 'publish_actions, email',
                    scope: 'public_profile, email',
                    return_scopes: true
                });
            }
        }
    });
});