define('cn-front/controllers/dashboard/reports/reservations', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        queryParams: ['search', 'page'],
        search: "",
        searchFilter: "",
        page: 1,
        orderBy: '',
        sort: 'DESC',

        sdate: '',
        edate: '',
        statuses: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        types: ['instant', 'confirm', 'on_site'],
        acct_mgr: false,
        affiliates: false,
        selectedAffiliate: false,
        withoutDates: true,

        reportTitle: (function () {
            return 'Reservation List for ' + (this.get('model.startDate') ? 'Created Date ' + this.get('model.startDate') : 'all times');
        }).property('report_type', 'model'),

        reportTitleSlug: (function () {
            return this.get('reportTitle') ? this.get('reportTitle').replace(/\s+/g, '-').toLowerCase() : 'report';
        }).property('report_type', 'model'),

        restoreDefaults: function restoreDefaults() {
            this.set('page', 1);
            this.set('sdate', '');
            this.set('edate', '');
            this.set('orderBy', '');
            this.set('types', ['instant', 'confirm', 'on_site']);
            this.set('statuses', [1, 2, 3, 4, 5, 6, 7, 8, 9]);
            this.set('sort', 'DESC');
            this.set('withoutDates', true);
        },

        onSearchChange: (function () {
            // wait 1 second before applying the filter
            _ember['default'].run.debounce(this, this.applySearchFilter, 1000);
        }).observes('searchFilter'),

        applySearchFilter: function applySearchFilter() {
            this.set('page', 1);
            this.set('search', this.get('searchFilter'));
        },

        actions: {
            pageChanged: function pageChanged(current) {
                this.set('page', current);
            },
            seeDetails: function seeDetails(id) {
                var data = {
                    id: id,
                    type: "dash"
                };
                this.send('showModal', 'dashboard/modal/resinfo', data);
            },
            getReports: function getReports() {
                this.set('withoutDates', false);
                this.handleFilters();
                this.send('refreshModel');
            },
            exportTo: function exportTo(type) {
                this.handleFilters();
                var reportUrl = this.get('apiConnect').getUrl('/' + type + '/reports/reservations');
                var acctmgrEmail = $('#report-acctmgr').val();
                var affiliate = $('#report-affiliate').val();
                reportUrl += '?search=' + this.search + '&acct_mgr=' + acctmgrEmail + '&affiliate=' + affiliate + '&orderBy=' + this.orderBy + '&sort=' + this.sort;
                if (!this.withoutDates) {
                    reportUrl += '&sdate=' + encodeURIComponent(this.sdate) + '&edate=' + encodeURIComponent(this.edate);
                } else {
                    reportUrl += '&sdate=&edate=';
                }
                for (var i = 0; i < this.statuses.length; i++) {
                    reportUrl += '&statuses%5B%5D=' + this.statuses[i];
                }
                for (i = 0; i < this.types.length; i++) {
                    reportUrl += '&types%5B%5D=' + this.types[i];
                }
                reportUrl += '&token=' + $.cookie('token');

                var windLocation = type == 'pdf' ? '_blank' : '_self';
                window.open(reportUrl, windLocation);
            }
        },

        handleFilters: function handleFilters() {
            this.typeCheckboxHandler();
            this.statusesCheckboxHandler();
            // Each time getting new report reset pagination
            this.set('page', 1);

            // Get new report type and period
            var reportPeriod = $('#report-period').val(),
                sdate,
                edate;
            var dateCheck = moment($('#report-start-date').val(), 'MM/DD/YYYY');
            var month = dateCheck.format('MM');
            var year = dateCheck.format('YYYY');

            if (reportPeriod == 'yearly') {
                sdate = moment([year]).startOf('year').format('MM/DD/YYYY');
                edate = moment(sdate).endOf('year').format('MM/DD/YYYY');
            } else if (reportPeriod == 'monthly') {
                sdate = moment([year, month - 1]).format('MM/DD/YYYY');
                edate = moment(sdate).endOf('month').format('MM/DD/YYYY');
            } else if (reportPeriod == 'all_time') {
                this.set('withoutDates', true);
            } else {
                sdate = $('#report-start-date').val();

                if (reportPeriod == 'weekly') {
                    edate = moment(sdate).add(6, 'days').format('MM/DD/YYYY');
                } else {
                    edate = $('#report-start-date').val();
                }
            }

            this.set('sdate', sdate);
            this.set('edate', edate);
        },

        toTheTop: (function () {
            $('body').animate({
                scrollTop: 0
            }, 200);
        }).observes('page'),

        typeCheckboxHandler: function typeCheckboxHandler() {
            var checksArr = ['instant', 'confirm', 'on_site', 'info_req', 'in_use', 'maint', 'fcfs'];
            var results = [];
            checksArr.forEach(function (reservationType) {
                if (document.querySelector('#' + reservationType + '_type').checked) {
                    results.push(reservationType);
                }
            });
            this.set('types', results);
        },

        resetTypesCheckboxes: function resetTypesCheckboxes() {
            this.get('types').forEach(function (type) {
                var typeCheckbox = document.querySelector('#' + type + '_type');
                if (!typeCheckbox.checked) {
                    typeCheckbox.checked = true;
                }
            });
        },

        statusesCheckboxHandler: function statusesCheckboxHandler() {
            var checks = $('.reservations_statuses :checkbox');
            var results = [];
            checks.map(function () {
                if ($(this)[0].checked) {
                    results.push($(this).attr("data-status"));
                }
            });
            this.set('statuses', results);
        },

        resetStatusesCheckboxes: function resetStatusesCheckboxes() {
            this.get('statuses').forEach(function (status) {
                var statusCheckbox = document.querySelector('.reservations_statuses [data-status="' + status + '"]');
                if (!statusCheckbox.checked) {
                    statusCheckbox.checked = true;
                }
            });
        }
    });
});