define('cn-front/components/dashboard/radio-button', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'input',
        type: 'radio',
        attributeBindings: ['name', 'type', 'value', 'checked'],
        initial: (function () {
            _ember['default'].run.schedule('afterRender', this, function () {
                if (this.get('value') === parseInt(this.get('groupValue'))) {
                    this.set('checked', true);
                }
            });
        }).on('init'),
        change: function change() {
            this.set('groupValue', this.get('value'));
        }
    });
});