define('cn-front/components/partials/section-footer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    shown: true,
    didReceiveAttrs: function didReceiveAttrs() {
      var route = this.get('routeName');
      if (route === 'explore') {
        this.set('shown', false);
      } else {
        this.set('shown', true);
      }
    },
    actions: {
      slideToggle: function slideToggle(self) {
        var containerSlide = $(self.target).parent().find('ul');
        containerSlide.slideToggle("slow");
      }
    }
  });
});