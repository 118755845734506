define('cn-front/components/section-subscribe', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    ready: false,
    isError: false,
    errorMessage: '',
    actions: {
      subscribe: function subscribe() {
        var _this = this;

        var self = this;
        this.get('api').sendReq({
          url: "/subscribe",
          type: "POST",
          data: {
            email: $('#sub-email').val(),
            firstName: $('#sub-first-name').val(),
            lastName: $('#sub-last-name').val()
          }
        }).then(function (data) {
          _this.set("ready", true);
          console.log('data', data);
          window.open('https://helpfulcamping.net/ty/', '_blank');
        }, function (err) {
          self.set('isError', true);
          self.set('errorMessage', err.responseJSON.error);
          console.log('err', err.responseJSON.error);
        });
      }
    }
  });
});