define('cn-front/components/search-bar', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        header: false,
        withSearch: true,
        preselect: _ember['default'].inject.service(),

        didInsertElement: function didInsertElement() {
            var self = this;

            $('.arrival-date').datepicker({
                orientation: "bottom left",
                autoclose: true,
                clearBtn: true,
                startDate: new Date()
            }).on('changeDate', function (e) {
                self.get('preselect').setField('sdate', $('.arrival-date').val());

                if ($('.arrival-date').val() !== '') {
                    var target = new Date(e.date),
                        dep = $('.departure-date').val();
                    target.setDate(target.getDate() + 1);
                    if (new Date(dep) < target || dep === '') {
                        $('.departure-date').datepicker('setDate', target);
                    }
                    $('.departure-date').datepicker('setStartDate', target);
                }
            }).on('clearDate', function () {
                if ($('.departure-date').val() !== '') {
                    $('.departure-date').datepicker('setDate', '');
                    $('.departure-date').datepicker('setStartDate', new Date());
                }
            });
            $('.departure-date').datepicker({
                orientation: "bottom left",
                autoclose: true,
                clearBtn: true,
                startDate: new Date()
            }).on('changeDate', function () /*e*/{
                var d = $('.departure-date').val();
                self.get('preselect').setField('edate', d);
                if (d === '') {
                    $('.arrival-date').datepicker('setDate', '');
                }
            }).on('clearDate', function () /*e*/{
                if ($('.arrival-date').val() !== '') {
                    $('.arrival-date').val('setDate', '');
                    $('.departure-date').datepicker('setStartDate', new Date());
                }
            });

            if (this.get('preselect').location !== '') {
                $('.location-autocomplete-field').find('input').val(this.get('preselect').location);
            } else {
                $('.location-autocomplete-field').find('input').val('');
            }

            if (this.get('preselect').sdate !== '') {
                $('.arrival-date').val(this.get('preselect').sdate);
            }

            if (this.get('preselect').edate !== '') {
                $('.departure-date').val(this.get('preselect').edate);
            }

            if (this.get('preselect').shelter !== '') {
                $('.shelter').val(this.get('preselect').shelter);
            }

            $(document).on('change', '.shelter', function () {
                self.get('preselect').setField('shelter', $('.shelter').val());
            });
        },

        willClearRender: function willClearRender() {
            // remove the custom event
            this.$(document).off('change');
        },

        actions: {
            commit: function commit() {
                // if no results displayed, then "submit" the search.azxz
                if (jQuery('.hypersearch-results').css('display') === 'none') {
                    // use visible in case of 2 search bars or more with some hidden.
                    jQuery('.search-bar:visible a').click();
                }
            },
            explore: function explore() {
                jQuery('.search-bar:visible a').click();
            }
        }
    });
});