define('cn-front/services/user-browser-location', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {
        currentLocation: null,
        init: function init() {
            this._super.apply(this, arguments);
            //this.getLocation();
        },

        getLocation: function getLocation(geoOptions) {
            var _this = this;

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                // jshint ignore:line
                navigator.geolocation.getCurrentPosition(function (geoObject) {
                    _this.trigger('geolocationSuccess', geoObject, resolve);
                },
                // If fails
                function (reason) {
                    _this.trigger('geolocationFail', reason, reject);
                }, geoOptions);
            });
        },

        trackLocation: function trackLocation(geoOptions) {
            var _this2 = this;

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                navigator.geolocation.watchPosition(function (geoObject) {
                    _this2.trigger('geolocationSuccess', geoObject, resolve);
                }, function (reason) {
                    _this2.trigger('geolocationFail', reason, reject);
                }, geoOptions);
            });
        },

        geolocationDidSucceed: _ember['default'].on('geolocationSuccess', function (geoObject, resolve) {
            this.set('currentLocation', {
                lat: geoObject.coords.latitude,
                long: geoObject.coords.longitude
            });
            resolve(geoObject);
        }),

        geolocationDidFail: _ember['default'].on('geolocationFail', function (reason, reject) {
            console.log(reason);
            reject(reason);
        })
    });
});