define('cn-front/router', ['exports', 'ember', 'cn-front/config/environment', 'cn-front/mixins/reset-scroll', 'cn-front/mixins/bodyclass'], function (exports, _ember, _cnFrontConfigEnvironment, _cnFrontMixinsResetScroll, _cnFrontMixinsBodyclass) {

    var Router = _ember['default'].Router.extend({
        location: _cnFrontConfigEnvironment['default'].locationType,
        rootURL: _cnFrontConfigEnvironment['default'].rootURL
    });

    _ember['default'].Route.reopen(_cnFrontMixinsResetScroll['default']);

    _ember['default'].Route.reopen(_cnFrontMixinsBodyclass['default']);

    Router.map(function () {
        this.route('404', { path: '/*wildcard' });

        /**
         * Keeping only for redirect
         */
        this.route('ca');

        /**
         * Campgrounds by county/state/city
         */
        this.route('seo', { path: 'campgrounds' }, function () {
            this.route('country', { path: ':country' });
            this.route('state', { path: ':country/:state' });
            this.route('city', { path: ':country/:state/:city' });
        });

        /**
         * Static pages
         */
        // this.route('about-us');
        this.route('contact-us');
        this.route('camp-owners', function () {
            this.route('form');
        });
        this.route('how-it-works');
        this.route('terms-of-service');
        this.route('support');

        this.route('campground', { path: 'campgrounds/:country/:state/:city/:slug' }, function () {
            this.route('campsites');
            this.route('info-request');
            this.route('campsite', { path: 'campsite/:campsite_id' }, function () {
                this.route('new-reservation');
            });
        });

        /**
         * Dashboard
         */
        this.route('dashboard', function () {
            this.route('modal', function () {
                this.route('rescancel');
                this.route('reschange');
            });

            this.route('campgrounds');
            this.route('campground', { path: '/campground/:id' }, function () {
                this.route('edit', function () {
                    this.route('availability');
                    this.route('ginfo');
                    this.route('photos');
                    this.route('maps');
                    this.route('seasons');
                    this.route('loops');
                    this.route('campsites');
                    this.route('permissions');
                    this.route('applications');
                    this.route('admin');
                    this.route('reports');
                    this.route('pendings');

                    this.route('modal', function () {
                        this.route('assign-user');
                        this.route('unassign-user');
                        this.route('delete-season');
                        this.route('delete-loop');
                        this.route('edit-loop');
                        this.route('delete-cs');
                        this.route('edit-photo');
                        this.route('delete-photo');
                        this.route('delete-map');
                        this.route('edit-map');
                        this.route('copy_campsite');
                        this.route('pending-res');
                        this.route('rename-cs');
                        this.route('edit-season');
                        this.route('edit-cs-loop');
                        this.route('edit-cs-min-stay');
                        this.route('edit-rate');
                    });
                    this.route('email');
                    this.route('prices');
                    this.route('stays');
                });
            });

            this.route('campsite', function () {
                this.route('edit', { path: '/edit/:id' }, function () {
                    this.route('availability');
                    this.route('ginfo');
                    this.route('photos');

                    this.route('modal', function () {
                        this.route('edit-photo');
                        this.route('delete-photo');
                        this.route('set-photo');
                    });
                    this.route('prices');
                    this.route('stays');
                });
            });
            this.route('users', function () {
                this.route('index', { path: '/' });
                this.route('new', { path: '/new' });
            });

            this.route('user', function () {
                this.route('view', { path: '/:id' });
                this.route('edit', { path: '/:id/edit' });
            });
            this.route('system');

            this.route('reports', function () {
                this.route('reservations');
                this.route('onboards');
                this.route('financials');
                this.route('transactions');
            });
            this.route('emails-sending');

            this.route('calendar', function () {
                this.route('change-price');
                this.route('change-minstay');
            });
        });

        /**
         * Authentication
         */
        this.route('auth', function () {
            this.route('login');
            this.route('register');
            this.route('forgot-password');
            this.route('reset');
        });

        /**
         * User Profile
         */
        this.route('user', function () {
            this.route('profile');
        });

        this.route('explore');
        this.route('welcome-popover');

        /**
         * Reservation
         */
        this.route('welcome-popover');
        this.route('reservation', { path: '/reservation/:id' }, function () {
            this.route('cancel');
            this.route('change');
        });
        this.route('photo-popup');
        this.route('confirmation');
        this.route('partners');
    });

    exports['default'] = Router;
});