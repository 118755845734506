define('cn-front/components/dashboard/cgnote-selector', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        didInsertElement: function didInsertElement() {
            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                $(document).on('change', '#prefill', function () {
                    var v = $(this).val();
                    $('#change-note').val(v);
                });
            });
        },
        willClearRender: function willClearRender() {
            // remove the custom event
            $(document).off('change', '#prefill');
        }

    });
});