define('cn-front/helpers/format-date', ['exports', 'ember'], function (exports, _ember) {
  exports.formatDate = formatDate;

  function formatDate(params, hash) {
    var date = params[0],
        format = hash.format;

    if (date === null) {
      return;
    }

    return moment(date, 'YYYY-MM-DD').format(format);
  }

  exports['default'] = _ember['default'].Helper.helper(formatDate);
});