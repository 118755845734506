define('cn-front/helpers/is-selected', ['exports', 'ember'], function (exports, _ember) {
    exports.isSelected = isSelected;

    function isSelected(params /*, hash*/) {
        if (params[2]) {
            if (params[1]) {
                if (Array.isArray(params[1])) {
                    return params[1].indexOf(params[0]) > -1 ? 'selected' : false;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return params[0] == params[1] ? 'selected' : false;
        }
    }

    exports['default'] = _ember['default'].Helper.helper(isSelected);
});