define('cn-front/components/dashboard/step/res-confirm', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Component.extend({
        reservation: inject.service('dashboard/reservation'),
        currentUser: inject.service('current-user'),
        stepster: inject.service('dashboard/stepster'),

        onSelect: (function () {
            if (this.get('stepster.currentStep') == this.get('tabId')) {
                //firing every entering on this step

                if (this.get('stepster.finishedSteps') < this.get('stepster.currentStep')) {

                    if (this.isPaymentsEnabled()) {
                        _cnFrontUtilsGtm['default'].trackEvent({
                            'event': 'checkout',
                            'ecommerce': {
                                'purchase': {
                                    'actionField': {
                                        'id': this.get('reservation.response.reservation_id'),
                                        'revenue': this.get('reservation.camper_charged')
                                    },
                                    'products': [{
                                        'name': this.get('reservation.camp.name'),
                                        'id': this.get('reservation.campsite.id'),
                                        'price': this.get('reservation.camper_charged')
                                    }]
                                }
                            }
                        });
                    }

                    if (this.isPaymentsEnabled() || this.get('reservation.restype') == 'info_req') {
                        var user = {};
                        if (this.get('reservation.restype') == "on_site") {
                            user = this.get('reservation.remote_user').user;
                        } else {
                            user = this.get('currentUser').user;
                        }

                        _cnFrontUtilsGtm['default'].trackEvent({
                            'event': 'checkout_purchase',
                            'reservation_type': this.get('reservation.restype'),
                            'transactionId': this.get('reservation.response.reservation_id'), // string    CN reservation ID number
                            'transactionCG': this.get('reservation.camp.id'), // numeric   CG ID number

                            'transactionUser': user.id, // numeric   User ID number (camper)
                            'transactionUserEmail': user.email, // string    User Email (camper)
                            'transactionUserCity': user.city, // string    User City (camper)
                            'transactionUserState': user.state, // string    User State (camper)
                            'transactionUserZip': user.zip_code, // string    User Zip Code (camper)

                            'transactionCGFee': this.get('reservation.total_without_fee'), // if exists, numeric   Original Campground Fee
                            'transactionCNTransFee': this.get('reservation.camper_fee'), // if exists, numeric   Original CN Transaction Fee (due from camper)
                            'transactionTotal': this.get('reservation.camper_charged'), // if exists, numeric   Original Paid By Camper
                            'transactionCCFee': this.get('reservation.cg_fee'), // if exists, numeric   Original Credit Card Fee (due from campground)
                            'transactionPaidToCG': this.get('reservation.cg_received') // if exists, numeric   Original Paid To Campground
                        });
                    }

                    this.set('stepster.finishedSteps', this.get('stepster.currentStep'));
                }
            }
        }).observes('stepster.currentStep').on('init'),

        isPaymentsEnabled: function isPaymentsEnabled() {
            var disabledPaymentOn = ["in_use", "maint", "fcfs", "info_req"];
            return disabledPaymentOn.indexOf(this.get('reservation.restype')) == -1;
        },

        paymentsEnabled: (function () {
            return this.isPaymentsEnabled();
        }).property('reservation.restype')

    });
});