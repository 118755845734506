define('cn-front/controllers/dashboard/campsite/edit/ginfo', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        cgdata: inject.service('dashboard/cg-fields'),
        apiConnect: inject.service('api-resolver'),

        properties: {},

        onInit: (function () {
            this.set('properties', this.get('cgdata.csfields'));
        }).on('init'),

        currentRoute: 'dashboard.campsite.edit.ginfo',

        onModel: (function () {
            _ember['default'].run.schedule('afterRender', this, function () {
                $('select[multiple]').multipleSelect('refresh');
            });
        }).observes('model')
    });
});