define('cn-front/helpers/random-number', ['exports', 'ember'], function (exports, _ember) {
  exports.randomNumber = randomNumber;

  /**
   * Returns a random integer between hash.min (inclusive) and hash.max (inclusive)
   * defaults to min = 1 and max = 10
   * Using Math.round() will give you a non-uniform distribution!
   */

  function randomNumber(params, hash) {
    var min = hash.min === undefined ? 1 : hash.min;
    var max = hash.max === undefined ? 10 : hash.max;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  exports['default'] = _ember['default'].Helper.helper(randomNumber);
});