define('cn-front/routes/about-us', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    title: 'Meet Camp Native - Campground Reservation Superstars - Camp Native',
    headTags: function headTags() {
      // here we are pulling meta data from the model for this route
      return [{
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: 'Find out more about the team who has a vision for making campground booking easy. Over 12,000 campgrounds, free camping reservation booking app.  Meet the team behind the brand.'
        }
      }];
    }
  });
});