define('cn-front/components/user/cg-wishlist', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        currentUser: _ember['default'].inject.service('current-user'),
        apiConnect: _ember['default'].inject.service('api-connect'),
        page: 1,
        orderBy: 'id',
        sort: 'DESC',
        wishlist: null,

        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            this.get('api').sendReq({
                type: "GET",
                url: "/user/wishlist",
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                data: {
                    page: self.page,
                    orderBy: self.orderBy,
                    sort: self.sort
                }
            }).then(function (data) {
                self.set('wishlist', data);
            });
        },

        actions: {
            pageChanged: function pageChanged(current) {
                this.set('page', current);
                this.didReceiveAttrs();
            },
            applySort: function applySort(slug) {
                var sortHeader = $('.order-source.' + slug);
                sortHeader.removeClass('asc desc');
                var newOrder = 'ASC';

                if (sortHeader.hasClass('active-sort')) {
                    newOrder = this.get('sort') == 'ASC' ? 'DESC' : 'ASC';
                } else {
                    $('.order-source.active-sort').removeClass('active-sort asc desc');
                    sortHeader.addClass('active-sort');
                    this.set('orderBy', slug);
                }
                this.set('sort', newOrder);
                sortHeader.addClass(newOrder.toLowerCase());
                this.didReceiveAttrs();
            }
        }
    });
});