define('cn-front/routes/dashboard/user/view', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        page: 1,
        model: function model(params) {
            var response = {
                user: false,
                reservations: false
            };

            $.ajax({
                type: "GET",
                url: this.get('apiConnect').getUrl('/dashboard/user/' + params.id),
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                success: function success(data) {
                    response['user'] = data.user;
                }
            });

            $.ajax({
                type: "GET",
                url: this.get('apiConnect').getUrl('/dashboard/user/' + params.id + '/reservations?page=' + this.get('page')),
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                success: function success(data) {
                    response['reservations'] = data;
                }
            });

            return response;
        },

        actions: {
            pageChanged: function pageChanged(current) {
                this.set('page', current);
                _ember['default'].Logger.log('Route is now refreshing...');
                this.refresh();
            }
        }
    });
});