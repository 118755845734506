define('cn-front/controllers/dashboard/campground/edit/email', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        cgdata: inject.service('dashboard/cg-fields'),
        apiConnect: inject.service('api-resolver'),

        properties: {},

        onInit: (function () {
            this.set('properties', this.get('cgdata.fields'));
        }).on('init'),

        actions: {
            updateData: function updateData() {
                var mailTabData = $('form#mail-settings').serializeJSON();
                var value;
                var checksArr = ['notify_pending_res', 'notify_succes_res', 'notify_change_res', 'notify_cancel_res', 'notify_info_request', 'notify_financial_reports'];
                var url = this.get('apiConnect').getUrl('/dashboard/campgrounds/' + this.get('model.general.id') + '/update');
                var self = this;
                var flashMessages = _ember['default'].get(self, 'flashMessages');

                checksArr.forEach(function (checkbox) {
                    value = document.querySelector('#' + checkbox).checked ? 1 : 0;
                    mailTabData[checkbox] = value;
                });

                $.ajax({
                    type: "POST",
                    url: url,
                    data: mailTabData,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        if (data.success) {
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                        } else {
                            flashMessages.danger(_ember['default'].String.htmlSafe(data.error));
                        }
                        self.send('refreshModel');
                    }

                });
            }
        }

    });
});