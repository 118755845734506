define('cn-front/controllers/dashboard/campground/edit/modal/edit-rate', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        title: (function () {
            var s = this.get('model.type').split('_')[1];
            s = s[0].toUpperCase() + s.slice(1);
            return 'Change Campsite ' + s + ' Rate';
        }).property('model.type'),
        actions: {
            editRate: function editRate() {
                var newRate = $('form #rate').val(),
                    url = this.get('apiConnect').getUrl('/dashboard/campsites/' + this.get('model.cs.id') + '/update'),
                    self = this,
                    data = {};

                var flashMessages = _ember['default'].get(self, 'flashMessages');

                data[this.get('model.type')] = newRate;
                $.ajax({
                    type: "POST",
                    url: url,
                    data: data,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: true,
                    success: function success(data) {
                        if (data.success) {
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                        } else {
                            flashMessages.danger(_ember['default'].String.htmlSafe(data.error.errorInfo[2]));
                        }
                        self.send('refreshModel');
                    }
                });
            }
        }
    });
});