define('cn-front/components/campground-card', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Component.extend({
        gMaps: inject.service('gmaps'),
        source: null,
        type: 'similar_card', /* one of [featured_card, explore_list, similar_card] */
        classNameBindings: ['type'],
        withSlider: true,
        isEmpty: (function () {
            return parseInt(this.get('camp.cs_total')) === 0;
        }).property('camp'),
        isSingle: (function () {
            return parseInt(this.get('camp.cs_total')) === 1;
        }).property('camp'),

        distance: (function () {
            return !!this.get('sortBy') && this.get('sortBy') === 'distmap' ? this.get('camp.distance_from_map_center') : this.get('camp.distance');
        }).property('sortBy'),

        didReceiveAttrs: function didReceiveAttrs() {
            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                if (this.get('withSlider')) {
                    //init main carousel
                    var owlTP = $('#' + this.get('featuredId') + ' .top-properties-carousel');
                    var prevArrow = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 30.25L25.05 29.2L20.65 24.75H30.75V23.25H20.65L25.05 18.8L24 17.75L17.75 24L24 30.25ZM24 42C21.5333 42 19.2083 41.525 17.025 40.575C14.8417 39.625 12.9333 38.3333 11.3 36.7C9.66667 35.0667 8.375 33.1667 7.425 31C6.475 28.8333 6 26.5 6 24C6 21.5 6.475 19.1583 7.425 16.975C8.375 14.7917 9.66667 12.8917 11.3 11.275C12.9333 9.65833 14.8333 8.375 17 7.425C19.1667 6.475 21.5 6 24 6C26.5 6 28.8417 6.475 31.025 7.425C33.2083 8.375 35.1083 9.65833 36.725 11.275C38.3417 12.8917 39.625 14.7917 40.575 16.975C41.525 19.1583 42 21.5 42 24C42 26.4667 41.525 28.7917 40.575 30.975C39.625 33.1583 38.3417 35.0667 36.725 36.7C35.1083 38.3333 33.2083 39.625 31.025 40.575C28.8417 41.525 26.5 42 24 42ZM24 40.45C28.5667 40.45 32.45 38.85 35.65 35.65C38.85 32.45 40.45 28.5667 40.45 24C40.45 19.4333 38.85 15.55 35.65 12.35C32.45 9.15 28.5667 7.55 24 7.55C19.4333 7.55 15.55 9.15 12.35 12.35C9.15 15.55 7.55 19.4333 7.55 24C7.55 28.5667 9.15 32.45 12.35 35.65C15.55 38.85 19.4333 40.45 24 40.45Z" fill="#CACACA"/></g></svg>';
                    var nextArrow = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 30.25L30.25 24L24 17.75L22.95 18.8L27.35 23.25H17.25V24.75H27.35L22.95 29.2L24 30.25ZM24 42C21.5333 42 19.2083 41.525 17.025 40.575C14.8417 39.625 12.9333 38.3333 11.3 36.7C9.66667 35.0667 8.375 33.1667 7.425 31C6.475 28.8333 6 26.5 6 24C6 21.5 6.475 19.1583 7.425 16.975C8.375 14.7917 9.66667 12.8917 11.3 11.275C12.9333 9.65833 14.8333 8.375 17 7.425C19.1667 6.475 21.5 6 24 6C26.5 6 28.8417 6.475 31.025 7.425C33.2083 8.375 35.1083 9.65833 36.725 11.275C38.3417 12.8917 39.625 14.7917 40.575 16.975C41.525 19.1583 42 21.5 42 24C42 26.4667 41.525 28.7917 40.575 30.975C39.625 33.1583 38.3417 35.0667 36.725 36.7C35.1083 38.3333 33.2083 39.625 31.025 40.575C28.8417 41.525 26.5 42 24 42ZM24 40.45C28.5667 40.45 32.45 38.85 35.65 35.65C38.85 32.45 40.45 28.5667 40.45 24C40.45 19.4333 38.85 15.55 35.65 12.35C32.45 9.15 28.5667 7.55 24 7.55C19.4333 7.55 15.55 9.15 12.35 12.35C9.15 15.55 7.55 19.4333 7.55 24C7.55 28.5667 9.15 32.45 12.35 35.65C15.55 38.85 19.4333 40.45 24 40.45Z" fill="#93A444"/></g></svg>';

                    owlTP.owlCarousel({
                        loop: false,
                        autoWidth: false,
                        margin: 30,
                        nav: true,
                        smartSpeed: 800,
                        navContainer: '.slide-arrow.top-properties-slide-arrow',
                        navClass: ['slide-prev', 'slide-next'],
                        navText: [prevArrow, nextArrow],
                        dotClass: 'owl-line',
                        dotsEach: true,
                        items: 4,
                        dots: true,
                        responsive: {
                            0: {
                                items: 1
                            },
                            769: {
                                items: 4
                            }
                        }
                    });

                    //init sub carousel
                    var owl = $('#' + this.get('elementId') + ' .photos');
                    owl.owlCarousel({
                        loop: true,
                        margin: 0,
                        nav: true,
                        navText: ['', ''],
                        navClass: ['prev', 'next'],
                        items: 1,
                        dots: false,
                        lazyLoad: true
                    });

                    // $('#' + this.get('elementId') + ' .next').click(function (e) {
                    //   e.preventDefault();
                    //     owl.find('.owl-next').click();
                    // });
                    //
                    // $('#' + this.get('elementId') + ' .prev').click(function (e) {
                    //   e.preventDefault();
                    //   owl.find('.owl-prev').click();
                    // });
                }

                $('#' + this.get('elementId') + ' .desc').dotdotdot({
                    watch: "window"
                });

                if (this.get('type') == 'explore_list') {
                    var self = this;
                    var $this = $('#' + this.get('elementId') + ' .gem');
                    var id = self.get('camp.id');
                    $this.on({
                        mouseenter: function mouseenter() {
                            var marker = $.grep(self.get('gMaps.markersArray'), function (e) {
                                return e.cg_id == id;
                            })[0];
                            if (marker) {
                                marker.setIcon('/assets/images/yellow-map-icon.png');
                            }
                        },
                        mouseleave: function mouseleave() {
                            var marker = $.grep(self.get('gMaps.markersArray'), function (e) {
                                return e.cg_id == id;
                            })[0];
                            if (marker) {
                                marker.setIcon('/assets/images/map-icon.png');
                            }
                        }
                    });
                }
            });
        },

        willClearRender: function willClearRender() {
            if (this.get('withSlider')) {
                // remove the custom event
                this.$('.next, .prev').off('click');
            }
        },

        actions: {
            goToCampground: function goToCampground() {
                var params = this.get('camp.url_params');
                this.get('router').transitionTo('campground', params.country, params.state, params.city, params.slug, { queryParams: { source: this.get('source') } });
            }
        }
    });
});