define('cn-front/components/dashboard/util-calendar', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Component.extend({
        cgId: 0,
        startDate: '',
        apiConnect: inject.service('api-resolver'),
        csTotal: 0,
        initDependencies: function initDependencies() {
            var self = this;
            if (this.get('cgId') > 0) {
                var defaultDate = this.get('startDate');
                $.get(this.get('apiConnect').getUrl('/campground/reservations/utilization/' + this.get('cgId'))).done(function (data) {
                    data = JSON.parse(data);
                    $('.spinner').hide();
                    self.set('csTotal', data.cs_total);
                    $('#calendar').fullCalendar({
                        defaultDate: defaultDate,
                        header: '',
                        selectable: true,
                        selectHelper: true,
                        droppable: false,
                        editable: false,
                        events: self.getEvents(data.days)
                    });
                });
            } else {
                console.log('invalid campground ID', this.get('cgId'));
            }
        },
        didInsertElement: function didInsertElement() {
            this.initDependencies();
        },
        onStartDateChange: (function () {
            this.initDependencies();
        }).observes('startDate'),
        getEvents: function getEvents(data) {
            var self = this;
            var arr = [];
            data.map(function (day) {
                var d = day.date.split('-');
                d = new Date(d[0], d[1] - 1, d[2]);
                //if cg not closed for this day
                if (day.utilization !== -1) {
                    var p = day.utilization;
                    var placeholder = 'Total Campsites: ' + self.get('csTotal') + '\n';
                    if (day.instant) {
                        placeholder += 'Reserved Online: ' + day.instant + '\n';
                    }
                    if (day.on_site) {
                        placeholder += 'Reserved On Site: ' + day.on_site + '\n';
                    }
                    if (day.in_use) {
                        placeholder += 'Marked In Use: ' + day.in_use + '\n';
                    }
                    if (day.fcfs) {
                        placeholder += 'First Arrived: ' + day.fcfs + '\n';
                    }
                    if (day.maint) {
                        placeholder += 'Maintenance: ' + day.maint + '\n';
                    }
                    placeholder += 'Utilization: ' + day.utilization + '%';
                    var className = 'bg-';
                    //choose color of plate
                    if (p <= 49) {
                        className += 'red';
                    } else if (p >= 70) {
                        className += 'green';
                    } else {
                        className += 'orange';
                    }
                    arr.push({
                        title: placeholder,
                        start: d,
                        end: d,
                        className: className
                    });
                } else {
                    arr.push({
                        title: 'Out of season',
                        start: d,
                        end: d,
                        className: 'bg-black'
                    });
                }
            });
            return arr;
        }
    });
});