define('cn-front/routes/camp-owners', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        title: 'Free Campground Reservation System - RV & Camping Booking App',
        headTags: function headTags() {
            // here we are pulling meta data from the model for this route
            return [{
                type: 'meta',
                tagId: 'meta-description-tag',
                attrs: {
                    name: 'description',
                    content: 'Find out how Camp Native can make reservation nightmares go away, at no cost to you. See why hundreds of campgrounds are signing up every month!'
                }
            }];
        }
    });
});