define('cn-front/components/reservation/new-reservation/verify-user', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
  exports['default'] = _ember['default'].Component.extend({
    reservation: _ember['default'].inject.service('reservation/instance'),
    stepster: _ember['default'].inject.service('reservation/stepster'),
    states: _ember['default'].inject.service('ustates'),
    currentUser: _ember['default'].inject.service('current-user'),
    disabledButton: true,
    tos: true,
    tosNotAccepted: false,
    registrationEmail: '',
    emailValid: false,
    creationType: 'Res',
    verifyingUser: false,
    checkEmail: (function () {
      if (this.registrationEmail !== '') {
        _ember['default'].run.debounce(this, this.lookupCamper, 1000);
      }
    }).observes('registrationEmail').on('init'),

    /**
     * Get list of usa states for reg form
     */
    statesList: (function () {
      var array = $.map(this.get('states').data, function (value) {
        return [value];
      });
      return array.slice(0, -2);
    }).property(),

    verifyUser: function verifyUser(next) {
      /**
       * Firing every entering on this step
       */
      if (this.get('completeProfile')) {
        /**
         * Get braintree token, for payment step DEMO
         */
        var self = this;
        if (this.get('reservation').type !== 'dash') {
          $('.overlay').css('display', 'block'); // TODO: add non-modal overlay
          self.set('verifyingUser', true);
          var url = this.get('api').getUrl('/reservations/braintree-token');
          var ajaxPromise = $.ajax({
            type: "GET",
            url: url,
            data: { token: $.cookie('token') },
            async: true
          });
          ajaxPromise.then(function (data) {
            self.set('reservation.braintree_token', data);
            self.set('stepster.ready', true);

            $('.green-header .next-btn').prop('disabled', false);
            self.set('disabledButton', false);

            $('.overlay').css('display', 'none'); // TODO: add non-modal overlay
            self.set('verifyingUser', false);

            if (next === true) {
              $('.green-header .next-btn').click();
              $('body').find('.block-left .group-button button.btn').click();
            }
          });
        } else {
          /**
           * Skip getting token
           */
          self.set('stepster.ready', true);
          $('.green-header .next-btn').prop('disabled', false);
          self.set('disabledButton', false);
        }
      } else {
        this.set('stepster.ready', false);

        $('.green-header .next-btn').prop('disabled', true);
        this.set('disabledButton', true);
      }
    },
    didInsertElement: function didInsertElement() {
      this.verifyUser();
    },
    stepInitialize: (function () {
      if (this.get('stepster.finishedSteps') < this.get('step')) {
        if (this.get('reservation').isPaymentsEnabled()) {
          _cnFrontUtilsGtm['default'].trackEvent({
            'event': 'checkout',
            'ecommerce': {
              'checkout': {
                'actionField': { 'step': 2, 'option': 'Verify Camper' },
                'products': [{
                  'name': this.get('reservation.campground.name'),
                  'id': this.get('reservation.campsite.id')
                }]
              }
            }
          });
        }

        if (this.get('reservation').isPaymentsEnabled()) {
          if (this.get('currentUser.logged')) {
            _cnFrontUtilsGtm['default'].trackEvent({
              'event': 'checkout_step_2',
              'reservation_type': this.get('reservation.reservation_type'),
              'transactionCG': this.get('reservation.campground.id'), // numeric   CG ID number
              'transactionUser': this.get('currentUser').user.id, // numeric   User ID number (camper)
              'transactionUserEmail': this.get('currentUser').user.email, // string    User Email (camper)
              'transactionUserCity': this.get('currentUser').user.city, // string    User City (camper)
              'transactionUserState': this.get('currentUser').user.state, // string    User State (camper)
              'transactionUserZip': this.get('currentUser').user.zip_code // string    User Zip Code (camper)
            });
          } else {
              _cnFrontUtilsGtm['default'].trackEvent({
                'event': 'checkout_step_2',
                'reservation_type': this.get('reservation.reservation_type'),
                'transactionCG': this.get('reservation.campground.id') // numeric   CG ID number
              });
            }
        }

        this.set('stepster.finishedSteps', this.get('step'));
      }
    }).observes('stepster.currentStep'),

    /**
     * Check profile
     */
    completeProfile: (function () {
      if (this.get('currentUser.logged')) {
        var u = this.get('currentUser').user;

        if (u.name_first == '' || u.name_last == '' || u.name_first == null || u.name_last == null || u.email == '' || u.email == null || !u.phone) {
          // jshint ignore:line
          this.set('stepster.ready', false);
          return false;
        } else {
          this.set('stepster.ready', true);
          return true;
        }
      } else {
        this.set('stepster.ready', false);
        return false;
      }
    }).property('currentUser.user'),

    missedFields: (function () {
      var u = this.get('currentUser').user;
      var arr = [];

      if (u.name_first === '' || u.name_first === null) {
        arr.push('first name');
      }
      if (u.name_last === '' || u.name_last === null) {
        arr.push('last name');
      }

      return arr;
    }).property('currentUser.user'),
    lookupCamper: function lookupCamper() {
      var flashMessages = _ember['default'].get(this, 'flashMessages');
      flashMessages.clearMessages();

      var url = this.get('api').getUrl('/lookup');
      var self = this;
      var ajaxPromise = $.post(url, $('#register-form').serializeJSON());
      ajaxPromise.then(function (data) {
        if (typeof data.error === 'undefined') {
          $('.email-validation').html('');
          $('#register-form [name="email"]').addClass('valid_email');
          self.set('emailValid', true);
        } else {

          $('.email-validation').html(data.error.email[0] + '<br>' + '<a href="/auth/forgot-password" target="_blank">Forgot Your Password?</a>');
          $('#register-form [name="email"]').removeClass('valid_email');
          self.set('emailValid', false);
        }
      }, function (data) {
        $('html,body').animate({
          scrollTop: 0
        }, 400);
        $.each(data.responseJSON, function (key, value) {
          flashMessages.danger(value[0]);
        });
      });
    },
    actions: {
      login: function login() {
        var flashMessages = _ember['default'].get(this, 'flashMessages');
        flashMessages.clearMessages();

        var url = this.get('api').getUrl('/signin');
        var self = this;
        var ajaxPromise = $.post(url, $('#login-form').serializeJSON());
        ajaxPromise.then(function (data) {
          self.get('currentUser').login(data);

          if (self.get('reservation').isPaymentsEnabled()) {
            _cnFrontUtilsGtm['default'].trackEvent({
              'event': 'checkout_step_2_login',
              'reservation_type': self.get('reservation.reservation_type'),
              'transactionCG': self.get('reservation.campground.id'), // numeric   CG ID number
              'transactionUser': self.get('currentUser').user.id, // numeric   User ID number (camper)
              'transactionUserEmail': self.get('currentUser').user.email, // string    User Email (camper)
              'transactionUserCity': self.get('currentUser').user.city, // string    User City (camper)
              'transactionUserState': self.get('currentUser').user.state, // string    User State (camper)
              'transactionUserZip': self.get('currentUser').user.zip_code // string    User Zip Code (camper)
            });
          }

          self.verifyUser();
        }, function (data) {
          if (data.responseJSON.error === 'tos_not_accepted') {
            self.get('currentUser').setTemporaryToken(data.responseJSON.token);
            self.set('tosNotAccepted', true);
          } else {
            flashMessages.danger(data.responseJSON.message);
            $('html,body').animate({
              scrollTop: 0
            }, 400);
          }
        });
      },
      register: function register() {
        var flashMessages = _ember['default'].get(this, 'flashMessages');
        flashMessages.clearMessages();
        if (this.get('tos')) {
          var url = this.get('api').getUrl('/quick-registration');

          var self = this;
          var data = $('#register-form').serializeArray();
          data.push({
            name: "create_method",
            value: self.get('creationType'),
            tos: self.get('tos')
          });

          var ajaxPromise = $.post(url, data);
          ajaxPromise.then(function (data) {
            self.get('currentUser').login(data);

            if (self.get('reservation').isPaymentsEnabled()) {
              _cnFrontUtilsGtm['default'].trackEvent({
                'event': 'checkout_step_2_register',
                'reservation_type': self.get('reservation.reservation_type'),
                'transactionCG': self.get('reservation.campground.id'), // numeric   CG ID number
                'transactionUser': self.get('currentUser').user.id, // numeric   User ID number (camper)
                'transactionUserEmail': self.get('currentUser').user.email, // string    User Email (camper)
                'transactionUserCity': self.get('currentUser').user.city, // string    User City (camper)
                'transactionUserState': self.get('currentUser').user.state, // string    User State (camper)
                'transactionUserZip': self.get('currentUser').user.zip_code // string    User Zip Code (camper)
              });
            }

            self.verifyUser(true);
          }, function (data) {
            $('html,body').animate({
              scrollTop: 0
            }, 400);
            $.each(data.responseJSON, function (key, value) {
              flashMessages.danger(value[0]);
            });
          });
        } else {
          $('html,body').animate({
            scrollTop: 0
          }, 400);
          flashMessages.danger("You must accept the Terms of Service.");
        }
      },
      update: function update() {
        var flashMessages = _ember['default'].get(this, 'flashMessages');
        flashMessages.clearMessages();
        var url = this.get('api').getUrl('/user/update');
        var self = this;
        var ajaxPromise = $.ajax({
          type: "put",
          url: url,
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
          },
          async: false,
          data: $('#profile-info').serializeJSON()
        });
        ajaxPromise.then(function (data) {
          $('html,body').animate({
            scrollTop: 0
          }, 400);
          flashMessages.success(data.success);
          self.get('currentUser').setUser(data.user);

          if (self.get('reservation').isPaymentsEnabled()) {
            _cnFrontUtilsGtm['default'].trackEvent({
              'event': 'checkout_step_2_update',
              'reservation_type': self.get('reservation.reservation_type'),
              'transactionCG': self.get('reservation.campground.id'), // numeric   CG ID number
              'transactionUser': self.get('currentUser').user.id, // numeric   User ID number (camper)
              'transactionUserEmail': self.get('currentUser').user.email, // string    User Email (camper)
              'transactionUserCity': self.get('currentUser').user.city, // string    User City (camper)
              'transactionUserState': self.get('currentUser').user.state, // string    User State (camper)
              'transactionUserZip': self.get('currentUser').user.zip_code // string    User Zip Code (camper)
            });
          }

          self.verifyUser();
        }, function (data) {
          $('html,body').animate({
            scrollTop: 0
          }, 400);
          $.each(data.responseJSON, function (key, value) {
            flashMessages.danger(value[0]);
          });
        });
      },

      handleTos: function handleTos(accept) {
        if (accept) {
          this.verifyUser();
        } else {
          var flashMessages = _ember['default'].get(this, 'flashMessages');
          flashMessages.danger('Terms of Service are not accepted');
        }

        this.set('tosNotAccepted', false);
      },
      showTos: function showTos() {
        this.set('tosNotAccepted', true);
      },
      nextStep: function nextStep() {
        this.sendAction('nextStep');
      }
    }

  });
});