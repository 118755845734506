define('cn-front/controllers/dashboard/modal/reserv-modal', ['exports', 'ember'], function (exports, _ember) {

    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        currentUser: inject.service('current-user'),
        apiConnect: inject.service('api-resolver'),

        reservation: inject.service('dashboard/reservation'),
        stepster: inject.service('dashboard/stepster'),

        currTab: 0,

        setters: (function () {

            this.get('reservation').clearAll();
            this.get('stepster').reset();
            $('.res-footer .prev').hide();
            var cs = this.get('model.camp.campsites');
            //check if single campsite calendar
            if (this.model.cs !== undefined) {
                this.set('reservation.campsite', this.get('model.cs'));
            } else {
                this.set('reservation.campsite', cs.filter((function (site) {
                    return site.id == this.get('model.csid');
                }).bind(this))[0]);
            }

            //map model to reservation service
            this.set('reservation.camp', this.get('model.camp'));
            this.set('reservation.startdate', this.get('model.start'));
            this.set('reservation.rate_daily', this.get('model.rate_daily'));
            this.set('reservation.rate_weekly', this.get('model.rate_weekly'));
            this.set('reservation.rate_monthly', this.get('model.rate_monthly'));

            this.set('reservation.type', this.get('model.type'));
            this.set('reservation.restype', this.get('model.restype'));

            _ember['default'].run.schedule('afterRender', this, function () {

                this.set('currTab', 1);
                this.observeCurrTab();

                this.set('tabSize', $('.resnav>li>a').length);
                $(".resnav a[data-toggle=tab]").on("click", function (e) {
                    e.preventDefault();
                    return false;
                });
            });
        }).observes('model'),

        actions: {

            close: function close() {
                $('.modal').modal('hide');
            },
            next: function next() {
                if (this.get('stepster.ready')) {
                    if (!$('.resnav li:last').hasClass('active')) {
                        var curr = $('#new-reservation-popup .tab-pane.active').attr('id').split('-')[1];
                        curr++;
                        this.set('currTab', curr);
                        $('.resnav a[href="#step-' + curr + '"]').tab('show');
                    }
                    //this.set('stepster.ready', false);
                } else {
                        this.get('stepster').fallback();
                    }
            },
            prev: function prev() {
                if (!$('.resnav li:first').hasClass('active')) {
                    var curr = $('#new-reservation-popup .tab-pane.active').attr('id').split('-')[1];
                    curr--;
                    this.set('currTab', curr);
                    $('.resnav a[href="#step-' + curr + '"]').tab('show');
                }
            }
        },

        scrollToTop: (function () {
            $('.modal .modal-content').animate({
                scrollTop: 0
            }, 400);
        }).observes('currTab'),

        observeCurrTab: (function () {
            var c = this.get('currTab');

            this.set('stepster.currentStep', c);
            if (c == 1) {
                $('.res-footer .prev').hide();
            } else {
                if (this.get('tabSize') == c) {
                    $('.res-footer .submit').show();
                    $('.res-footer .prev').hide();
                    $('.res-footer .next').hide();
                    $('.res-footer .cancel').hide();
                } else {
                    $('.res-footer .submit').hide();
                    $('.res-footer .prev').show();
                    $('.res-footer .next').show();
                    $('.res-footer .cancel').show();
                }
            }
        }).observes('currTab').on('init')

    });
});