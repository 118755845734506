define("cn-front/templates/dashboard/campground/edit/email", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 63,
            "column": 71
          }
        },
        "moduleName": "cn-front/templates/dashboard/campground/edit/email.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h2");
        var el2 = dom.createTextNode("Email Notification Settings");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("form");
        dom.setAttribute(el1, "id", "mail-settings");
        dom.setAttribute(el1, "autocomplete", "off");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("table");
        dom.setAttribute(el2, "class", "table");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("thead");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createTextNode("Field name");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("th");
        var el6 = dom.createTextNode("Field value");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tbody");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createElement("label");
        dom.setAttribute(el6, "for", "acctmgr_email");
        var el7 = dom.createTextNode("Account Manager Email");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createElement("label");
        dom.setAttribute(el6, "for", "notification_email");
        var el7 = dom.createTextNode("Notification Email Address(es)");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6, "type", "text");
        dom.setAttribute(el6, "name", "notification_email");
        dom.setAttribute(el6, "id", "notification_email");
        dom.setAttribute(el6, "class", "form-control");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createElement("label");
        dom.setAttribute(el6, "for", "notify_pending_res");
        var el7 = dom.createTextNode("Notify Of Pending Reservation");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6, "type", "checkbox");
        dom.setAttribute(el6, "class", "js-switch");
        dom.setAttribute(el6, "name", "notify_pending_res");
        dom.setAttribute(el6, "id", "notify_pending_res");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createElement("label");
        dom.setAttribute(el6, "for", "notify_succes_res");
        var el7 = dom.createTextNode("Notify Of Successful Reservation");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6, "type", "checkbox");
        dom.setAttribute(el6, "class", "js-switch");
        dom.setAttribute(el6, "name", "notify_succes_res");
        dom.setAttribute(el6, "id", "notify_succes_res");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createElement("label");
        dom.setAttribute(el6, "for", "notify_change_res");
        var el7 = dom.createTextNode("Notify Of Reservation Change");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6, "type", "checkbox");
        dom.setAttribute(el6, "class", "js-switch");
        dom.setAttribute(el6, "name", "notify_change_res");
        dom.setAttribute(el6, "id", "notify_change_res");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createElement("label");
        dom.setAttribute(el6, "for", "notify_cancel_res");
        var el7 = dom.createTextNode("Notify Of Reservation Cancellation");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6, "type", "checkbox");
        dom.setAttribute(el6, "class", "js-switch");
        dom.setAttribute(el6, "name", "notify_cancel_res");
        dom.setAttribute(el6, "id", "notify_cancel_res");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createElement("label");
        dom.setAttribute(el6, "for", "notify_cancel_res");
        var el7 = dom.createTextNode("Notify Of Information Requests");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6, "type", "checkbox");
        dom.setAttribute(el6, "class", "js-switch");
        dom.setAttribute(el6, "name", "notify_info_request");
        dom.setAttribute(el6, "id", "notify_info_request");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("tr");
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createElement("label");
        dom.setAttribute(el6, "for", "notify_cancel_res");
        var el7 = dom.createTextNode("Send Weekly/Monthly Financial Reports");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("td");
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("input");
        dom.setAttribute(el6, "type", "checkbox");
        dom.setAttribute(el6, "class", "js-switch");
        dom.setAttribute(el6, "name", "notify_financial_reports");
        dom.setAttribute(el6, "id", "notify_financial_reports");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "btn btn-success");
        var el2 = dom.createTextNode("Update");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1, 3]);
        var element1 = dom.childAt(element0, [3, 3, 1]);
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element0, [5, 3, 1]), [], true);
        }
        var element2 = dom.childAt(element0, [5, 3, 1]);
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element0, [7, 3, 1]), [], true);
        }
        var element3 = dom.childAt(element0, [7, 3, 1]);
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element0, [9, 3, 1]), [], true);
        }
        var element4 = dom.childAt(element0, [9, 3, 1]);
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element0, [11, 3, 1]), [], true);
        }
        var element5 = dom.childAt(element0, [11, 3, 1]);
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element0, [13, 3, 1]), [], true);
        }
        var element6 = dom.childAt(element0, [13, 3, 1]);
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element0, [15, 3, 1]), [], true);
        }
        var element7 = dom.childAt(element0, [15, 3, 1]);
        var element8 = dom.childAt(fragment, [4]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]), 1, 1);
        morphs[1] = dom.createAttrMorph(element1, 'value');
        morphs[2] = dom.createAttrMorph(element2, 'checked');
        morphs[3] = dom.createAttrMorph(element3, 'checked');
        morphs[4] = dom.createAttrMorph(element4, 'checked');
        morphs[5] = dom.createAttrMorph(element5, 'checked');
        morphs[6] = dom.createAttrMorph(element6, 'checked');
        morphs[7] = dom.createAttrMorph(element7, 'checked');
        morphs[8] = dom.createElementMorph(element8);
        return morphs;
      },
      statements: [["content", "model.emailNotifications.acctmgr_email", ["loc", [null, [14, 16], [14, 58]]], 0, 0, 0, 0], ["attribute", "value", ["get", "model.emailNotifications.notification_email", ["loc", [null, [20, 93], [20, 136]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "checked", ["concat", [["subexpr", "if", [["get", "model.emailNotifications.notify_pending_res", ["loc", [null, [26, 121], [26, 164]]], 0, 0, 0, 0], "checked", ""], [], ["loc", [null, [26, 116], [26, 179]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "checked", ["concat", [["subexpr", "if", [["get", "model.emailNotifications.notify_succes_res", ["loc", [null, [32, 119], [32, 161]]], 0, 0, 0, 0], "checked", ""], [], ["loc", [null, [32, 114], [32, 176]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "checked", ["concat", [["subexpr", "if", [["get", "model.emailNotifications.notify_change_res", ["loc", [null, [38, 119], [38, 161]]], 0, 0, 0, 0], "checked", ""], [], ["loc", [null, [38, 114], [38, 176]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "checked", ["concat", [["subexpr", "if", [["get", "model.emailNotifications.notify_cancel_res", ["loc", [null, [44, 120], [44, 162]]], 0, 0, 0, 0], "checked", ""], [], ["loc", [null, [44, 115], [44, 177]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "checked", ["concat", [["subexpr", "if", [["get", "model.emailNotifications.notify_info_request", ["loc", [null, [50, 124], [50, 168]]], 0, 0, 0, 0], "checked", ""], [], ["loc", [null, [50, 119], [50, 183]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "checked", ["concat", [["subexpr", "if", [["get", "model.emailNotifications.notify_financial_reports", ["loc", [null, [56, 134], [56, 183]]], 0, 0, 0, 0], "checked", ""], [], ["loc", [null, [56, 129], [56, 198]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["updateData"], [], ["loc", [null, [63, 32], [63, 55]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});