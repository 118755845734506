define('cn-front/controllers/dashboard/system', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        currentUser: inject.service('current-user'),

        actions: {
            save: function save() {
                //var self = this;
                var flashMessages = _ember['default'].get(this, 'flashMessages');

                var url = this.get('apiConnect').getUrl('/dashboard/system/update');
                $.ajax({
                    type: "put",
                    url: url,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    data: $('#system-settings').serializeJSON(),
                    success: function success(data) {
                        flashMessages.success(data.message);
                        $('.dirty-change').removeClass('dirty-change');
                        $('.btn-save').prop("disabled", true);
                    },
                    error: function error(data) {
                        flashMessages.danger(data.responseJSON.error);
                    }
                });
            }
        }
    });
});