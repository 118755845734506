define('cn-front/controllers/dashboard/campground/edit/modal/copy-campsite', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        actions: {
            copyCs: function copyCs() {
                var cs = this.get('model');
                var formdata = {
                    campsite_id: cs.id,
                    count: $('#copies_count').val()
                };

                var url = this.get('apiConnect').getUrl('/dashboard/campsites/copy');
                var self = this;
                var flashMessages = _ember['default'].get(self, 'flashMessages');
                $('.overlay').css('display', 'block');
                $.ajax({
                    type: "POST",
                    url: url,
                    data: formdata,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: true,
                    success: function success(data) {

                        if (data.success) {
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                        } else {
                            flashMessages.danger(_ember['default'].String.htmlSafe(data.error));
                        }
                        self.send('refreshModel');
                    }
                });
            }
        }
    });
});