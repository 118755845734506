define('cn-front/controllers/dashboard/calendar/change-price', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            confirm: function confirm() {
                $('.overlay').show();
                var formdata = $('form#change-price').serializeJSON();

                var url = '/dashboard/rates/campsites/' + this.get('model.campsite.id') + '/update',
                    self = this;

                var flashMessages = _ember['default'].get(self, 'flashMessages');

                this.get('api').sendReq({
                    url: url,
                    data: formdata,
                    type: "POST",
                    beforeSend: function beforeSend(xhr) {
                        if (self.get('currentUser.logged')) {
                            xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                        }
                    }
                }).then(function (data) {
                    if (data.success) {
                        flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                    } else {
                        flashMessages.danger(_ember['default'].String.htmlSafe(data.error.errorInfo[2]));
                    }
                    self.send('refreshModel');
                });
            }
        },

        onModel: (function () {
            if (this.get('model.type') == 'override') {
                var m = moment(this.get('model.data.date'), "YYYY-MM-DD").add(1, 'd').format("YYYY-MM-DD");
                _ember['default'].run.later(this, function () {
                    $('#dpicker').datepicker({
                        orientation: "bottom left",
                        autoclose: true,
                        format: 'yyyy-mm-dd',
                        startDate: m,
                        clearBtn: true
                    });
                }, 500);
            }
        }).observes('model'),

        value: (function () {
            var t = this.get('model.data.type');
            if (t == 'override' || t == 'dow' || this.get('model.type') == 'daily' && this.get('model.campsite.own_rate_daily')) {
                return this.get('model.data.rate');
            } else {
                return '';
            }
        }).property('model'),

        textValue: (function () {
            return this.get('value') !== '' ? "$" + this.get('value') : 'None';
        }).property('value'),

        title: (function () {
            return "Change Campsite Rate: " + this.get('computedType');
        }).property('model'),

        computedType: (function () {
            switch (this.get('model.type')) {
                case 'daily':
                    return 'Daily';
                case 'dow':
                    var s = this.get('model.data.date');
                    return s.charAt(0).toUpperCase() + s.slice(1);
                case 'override':
                    var m = moment(this.get('model.data.date'), "YYYY-MM-DD");
                    return m.format("ddd MMM Do");
            }
        }).property('model')
    });
});