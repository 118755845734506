define('cn-front/routes/dashboard/campsite/edit/photos', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),

        leavingRoute: (function () {

            var self = this;
            _ember['default'].run.later(function () {
                self.send('refreshModel');
            }, 500);
        }).on('deactivate'),

        actions: {
            editPhoto: function editPhoto(photo) {
                photo.camp_id = this.modelFor('dashboard.campsite.edit').campsite.id;
                this.send('showModal', 'dashboard.campsite.edit.modal.edit-photo', photo);
            },
            assignPhoto: function assignPhoto() {
                var campsite = this.modelFor('dashboard.campsite.edit').campsite;
                this.send('showModal', 'dashboard.campsite.edit.modal.set-photo', campsite);
            },
            deletePhoto: function deletePhoto(photo) {
                photo.camp_id = this.modelFor('dashboard.campsite.edit').campsite.id;
                this.send('showModal', 'dashboard.campsite.edit.modal.delete-photo', photo);
            }
        }
    });
});