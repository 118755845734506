define('cn-front/routes/dashboard/reports/transactions', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),

        search: "",
        searchFilter: "",
        page: 1,
        orderBy: 'transaction_id',
        sort: 'DESC',

        sdate: '',
        edate: '',
        statuses: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        types: ['instant', 'confirm', 'on_site'],
        acct_mgr: false,
        affiliates: false,
        selectedAffiliate: false,
        withoutDates: true,

        thisController: function thisController() {
            return this.controllerFor('dashboard/reports/transactions');
        },

        queryParams: {
            page: {
                refreshModel: true
            }
        },

        model: function model(params) {

            var reportUrl = this.get('apiConnect').getUrl('/dashboard/reports/transactions');
            var acctmgrEmail = $('#report-acctmgr').val();
            var affiliate = $('#report-affiliate').val();

            // Response object
            var response = {
                reports: false,
                startDate: this.get('sdate'),
                endDate: this.get('edate')
            };

            $.ajax({
                type: "GET",
                url: reportUrl,
                data: {
                    'page': params.page,
                    'sdate': this.get('sdate'),
                    'edate': this.get('edate'),
                    'orderBy': this.get('orderBy'),
                    'sort': this.get('sort'),
                    'statuses': this.get('statuses'),
                    'types': this.get('types'),
                    'acct_mgr': acctmgrEmail,
                    'affiliate': affiliate
                },
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                success: function success(data) {
                    response['reports'] = data;
                }
            });

            return response;
        },

        onInit: (function () {

            _ember['default'].run.schedule('afterRender', this, function () {

                // Initialize datepicker
                $('#report-start-date').datepicker();
                $('#report-start-date').datepicker('setDate', new Date());

                this.resetTypesCheckboxes();
                this.resetStatusesCheckboxes();
            });
        }).on('activate'),

        onOut: (function () {
            this.restoreDefaults();
        }).on('deactivate'),

        actions: {
            applySort: function applySort(slug) {
                var sortHeader = $('.order-source.' + slug);
                sortHeader.removeClass('asc desc');
                var newOrder = 'ASC';

                if (sortHeader.hasClass('active-sort')) {
                    newOrder = this.get('sort') == 'ASC' ? 'DESC' : 'ASC';
                } else {
                    $('.order-source.active-sort').removeClass('active-sort asc desc');
                    sortHeader.addClass('active-sort');
                    this.set('orderBy', slug);
                }
                this.set('sort', newOrder);
                sortHeader.addClass(newOrder.toLowerCase());
                this.refresh();
            },
            refreshModel: function refreshModel() {
                //display message in the console of your browser
                _ember['default'].Logger.log('Route is now refreshing...');
                //refresh the model
                this.refresh();
            },
            getReports: function getReports() {
                this.set('withoutDates', false);
                this.handleFilters();
                this.send('refreshModel');
            },
            exportTo: function exportTo(type) {
                this.handleFilters();
                var reportUrl = this.get('apiConnect').getUrl('/' + type + '/reports/transaction');
                var acctmgrEmail = $('#report-acctmgr').val();
                var affiliate = $('#report-affiliate').val();
                reportUrl += '?search=' + this.thisController().get('search') + '&acct_mgr=' + acctmgrEmail + '&affiliate=' + affiliate + '&orderBy=' + this.get('orderBy') + '&sort=' + this.get('sort');
                if (!this.withoutDates) {
                    reportUrl += '&sdate=' + encodeURIComponent(this.sdate) + '&edate=' + encodeURIComponent(this.edate);
                } else {
                    reportUrl += '&sdate=&edate=';
                }
                for (var i = 0; i < this.get('statuses').length; i++) {
                    reportUrl += '&statuses%5B%5D=' + this.get('statuses')[i];
                }
                for (i = 0; i < this.get('types').length; i++) {
                    reportUrl += '&types%5B%5D=' + this.get('types')[i];
                }
                reportUrl += '&token=' + $.cookie('token');

                var windLocation = type == 'pdf' ? '_blank' : '_self';
                window.open(reportUrl, windLocation);
            }
        },

        handleFilters: function handleFilters() {
            this.typeCheckboxHandler();
            this.statusesCheckboxHandler();
            // Each time getting new report reset pagination
            this.thisController().set('page', 1);

            // Get new report type and period
            var reportPeriod = $('#report-period').val(),
                sdate,
                edate;
            var dateCheck = moment($('#report-start-date').val(), 'MM/DD/YYYY');
            var month = dateCheck.format('MM');
            var year = dateCheck.format('YYYY');

            if (reportPeriod == 'yearly') {
                sdate = moment([year]).startOf('year').format('MM/DD/YYYY');
                edate = moment(sdate).endOf('year').format('MM/DD/YYYY');
            } else if (reportPeriod == 'monthly') {
                sdate = moment([year, month - 1]).format('MM/DD/YYYY');
                edate = moment(sdate).endOf('month').format('MM/DD/YYYY');
            } else if (reportPeriod == 'all_time') {
                this.set('withoutDates', true);
            } else {
                sdate = $('#report-start-date').val();

                if (reportPeriod == 'weekly') {
                    edate = moment(sdate).add(6, 'days').format('MM/DD/YYYY');
                } else {
                    edate = $('#report-start-date').val();
                }
            }

            this.set('sdate', sdate);
            this.set('edate', edate);
        },
        typeCheckboxHandler: function typeCheckboxHandler() {
            var checksArr = ['instant', 'confirm', 'on_site', 'info_req', 'in_use', 'maint', 'fcfs'];
            var results = [];
            checksArr.forEach(function (reservationType) {
                if ($('#' + reservationType + '_type').prop('checked')) {
                    results.push(reservationType);
                }
            });
            this.set('types', results);
        },
        statusesCheckboxHandler: function statusesCheckboxHandler() {
            var checks = $('.reservations_statuses :checkbox');
            var results = [];
            checks.map(function () {
                if ($(this)[0].checked) {
                    results.push($(this).attr("data-status"));
                }
            });
            this.set('statuses', results);
        },

        resetTypesCheckboxes: function resetTypesCheckboxes() {
            this.get('types').forEach(function (type) {
                var typeCheckbox = $('#' + type + '_type');
                if (!typeCheckbox.prop('checked')) {
                    typeCheckbox.prop('checked', true);
                }
            });
        },
        resetStatusesCheckboxes: function resetStatusesCheckboxes() {
            this.get('statuses').forEach(function (status) {
                var statusCheckbox = $('.reservations_statuses [data-status="' + status + '"]');
                if (!statusCheckbox.prop('checked')) {
                    statusCheckbox.prop('checked', true);
                }
            });
        },

        restoreDefaults: function restoreDefaults() {
            this.thisController().set('page', 1);
            this.set('sdate', '');
            this.set('edate', '');
            this.set('orderBy', '');
            this.set('types', ['instant', 'confirm', 'on_site']);
            this.set('statuses', [1, 2, 3, 4, 5, 6, 7, 8, 9]);
            this.set('sort', 'DESC');
            this.set('withoutDates', true);
        },

        onSearchChange: (function () {
            // wait 1 second before applying the filter
            _ember['default'].run.debounce(this, this.applySearchFilter, 1000);
        }).observes('searchFilter'),

        applySearchFilter: function applySearchFilter() {
            this.thisController().set('page', 1);
            this.thisController().set('search', this.get('searchFilter'));
        }
    });
});