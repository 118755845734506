define('cn-front/routes/dashboard/campsite/edit/ginfo', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        bindEvents: (function () {
            _ember['default'].run.schedule('afterRender', this, function () {
                $('select[multiple]').multipleSelect();
            });
        }).on('activate'),
        actions: {
            updateData: function updateData() {
                var formdata = $('form#campsite-edit').serializeJSON();

                $('select[multiple]').each(function () {
                    var selectValues = $(this).multipleSelect('getSelects');
                    formdata[$(this).attr('name')] = selectValues.length > 0 ? selectValues : null;
                });
                console.log(formdata);

                var cs = this.modelFor('dashboard.campsite.edit').campsite;
                var url = this.get('apiConnect').getUrl('/dashboard/campsites/' + cs.id + '/update');
                var self = this;
                var flashMessages = _ember['default'].get(self, 'flashMessages');
                $.ajax({
                    type: "POST",
                    url: url,
                    data: formdata,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        console.log(data);
                        if (data.success) {
                            self.send('refreshModel');
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                            $('body,html').animate({
                                scrollTop: 0
                            }, 400);
                        } else {
                            $('body,html').animate({
                                scrollTop: 0
                            }, 400);
                            // TODO: rewrite error handler
                            if (data.error) {
                                flashMessages.danger(_ember['default'].String.htmlSafe(data.error.errorInfo[2]));
                            }
                        }
                    }

                });
            }
        }
    });
});