define('cn-front/controllers/auth/reset', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        currentUser: inject.service('current-user'),

        queryParams: ['token'],
        token: null,

        actions: {
            completeResetPassword: function completeResetPassword() {
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                flashMessages.clearMessages();
                var self = this;

                $.fn.serializeObject = function () {
                    var o = {};
                    var a = this.serializeArray();
                    $.each(a, function () {
                        if (o[this.name]) {
                            if (!o[this.name].push) {
                                o[this.name] = [o[this.name]];
                            }
                            o[this.name].push(this.value || '');
                        } else {
                            o[this.name] = this.value || '';
                        }
                    });
                    return o;
                };

                var url = this.get('apiConnect').getUrl('/password/reset');

                $.ajax({
                    type: "post",
                    url: url,
                    data: $('#forgot-password-form').serializeObject(),
                    success: function success(data) {
                        flashMessages.success(data.success);

                        _ember['default'].run.later(function () {
                            self.transitionToRoute('index');
                        }, 3000);
                    },
                    error: function error(data) {
                        flashMessages.danger(data.responseJSON.error);
                        $('body,html').animate({
                            scrollTop: 0
                        }, 400);
                    }
                });
            }
        }
    });
});