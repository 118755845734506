define('cn-front/controllers/dashboard/modal/choose-restype', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            chooseType: function chooseType() {
                var type = $('input[name="resType"]:checked', 'form#type').val();
                var data = this.get('model');
                data.restype = type;

                var m = Object.assign({}, data);
                this.send('showModal', 'dashboard/modal/reserv-modal', m);
            }
        }
    });
});