define('cn-front/services/dashboard/refresher', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        calendar: 0,
        init: function init() {
            this.set('calendar', 0);
        },
        ping: function ping(target) {
            var inc = this.get(target) + 1;
            this.set(target, inc);
        }

    });
});