define('cn-front/components/dashboard/step/verify-payment', ['exports', 'ember', 'npm:braintree-web', 'cn-front/utils/gtm'], function (exports, _ember, _npmBraintreeWeb, _cnFrontUtilsGtm) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Component.extend({
        reservation: inject.service('dashboard/reservation'),
        stepster: inject.service('dashboard/stepster'),
        refresher: inject.service('dashboard/refresher'),
        currentUser: inject.service('current-user'),
        apiConnect: inject.service('api-resolver'),

        isPaymentsEnabled: function isPaymentsEnabled() {
            var disabledPaymentOn = ["in_use", "maint", "fcfs", "info_req"];
            return disabledPaymentOn.indexOf(this.get('reservation.restype')) == -1;
        },

        paymentsEnabled: (function () {
            return this.isPaymentsEnabled();
        }).property('reservation.restype'),

        onSelect: (function () {
            if (this.get('stepster.currentStep') == this.get('tabId')) {
                //firing every entering on this step

                if (this.get('stepster.finishedSteps') < this.get('stepster.currentStep')) {

                    if (this.isPaymentsEnabled()) {
                        _cnFrontUtilsGtm['default'].trackEvent({
                            'event': 'checkout',
                            'ecommerce': {
                                'checkout': {
                                    'actionField': { 'step': 3, 'option': 'Verify Payment/Add Note' },
                                    'products': [{
                                        'name': this.get('reservation.camp.name'),
                                        'id': this.get('reservation.campsite.id')
                                    }]
                                }
                            }
                        });
                    }

                    if (this.isPaymentsEnabled() || this.get('reservation.restype') == 'info_req') {
                        var user = {};
                        if (this.get('reservation.restype') == "on_site") {
                            user = this.get('reservation.remote_user').user;
                        } else {
                            user = this.get('currentUser').user;
                        }
                        _cnFrontUtilsGtm['default'].trackEvent({
                            'event': 'checkout_step_3',
                            'reservation_type': this.get('reservation.restype'),
                            'transactionCG': this.get('reservation.camp.id'), // numeric   CG ID number
                            'transactionUser': user.id, // numeric   User ID number (camper)
                            'transactionUserEmail': user.email, // string    User Email (camper)
                            'transactionUserCity': user.city, // string    User City (camper)
                            'transactionUserState': user.state, // string    User State (camper)
                            'transactionUserZip': user.zip_code // string    User Zip Code (camper)
                        });
                    }

                    this.set('stepster.finishedSteps', this.get('stepster.currentStep'));
                }

                _ember['default'].run.schedule('afterRender', this, function () {
                    var self = this;
                    if (self.get('paymentsEnabled') && self.get('reservation.braintree_token').length) {

                        //clean up old braintree integrations
                        $('#dropin').html('');
                        $('#braintree-dropin-modal-frame').remove();

                        $('.modal .overlay').css('display', 'block');

                        // braintree integration
                        _npmBraintreeWeb['default'].setup(self.get('reservation.braintree_token'), 'dropin', {
                            container: "dropin",
                            onReady: function onReady() {
                                $('.modal .overlay').css('display', 'none');
                            },
                            paymentMethodNonceReceived: function paymentMethodNonceReceived(event, nonce) {
                                //this fires after user submit form
                                $('#nonce').val(nonce);
                                self.reserv();
                            },
                            onError: function onError(error) {
                                console.log('braintree error: ' + error.message);
                                $('.modal .overlay').css('display', 'none');
                            }
                        });
                    }
                    // "block" next button and fire create reservation instead next slide
                    self.set('stepster.ready', false);
                    self.set('stepster.fallback', (function () {
                        this.createReservation();
                    }).bind(this));
                });
            }
        }).observes('stepster.currentStep').on('init'),

        createReservation: function createReservation() {
            $('.modal .overlay').css('display', 'block');
            //if type == "online"
            if ((this.get('reservation.type') == 'front' || this.get('reservation.restype') == "on_site") && this.get('reservation.restype') != "info_req") {
                //innitiate payment process throu braintree
                $('#new-reservation').find('input[type="submit"]').click();
            } else {
                //reserv without payment
                this.reserv();
            }
        },

        reserv: function reserv() {
            var self = this;
            var res_info = $('#new-reservation').serializeJSON();
            var url = self.get('apiConnect').getUrl('/reservations/create');
            var token = $.cookie('token');
            if (this.get('reservation.remote_token')) {
                res_info.initiator_token = $.cookie('token');
                token = this.get('reservation.remote_token');
                console.log('using remote user token');
            }
            $.ajax({
                type: "POST",
                url: url,
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + token);
                },
                data: res_info,
                async: false,
                success: function success(data) {
                    console.log(data);
                    //set response in reservationobject
                    self.set('reservation.response', data);
                    var type = self.get('reservation.restype');
                    var modalTitle;
                    if (type == 'maint') {
                        modalTitle = 'New Calendar Item Created';
                        self.set('reservation.type_desc', 'Maintenance (campsite is temporarily closed)');
                    } else if (type == 'in_use') {
                        modalTitle = 'New Calendar Item Created';
                        self.set('reservation.type_desc', 'In Use (campsite has a prior reservation)');
                    } else if (type == 'fcfs') {
                        modalTitle = 'New Calendar Item Created';
                        self.set('reservation.type_desc', 'First Come First Served (campsite is open but may not be reserved)');
                    } else if (['confirm', 'instant', 'on_site'].indexOf(type) !== -1) {
                        modalTitle = 'New Reservation';
                    } else if (type == 'info_req') {
                        modalTitle = 'New Information Request';
                    }

                    $('.modal .modal-title').html(modalTitle);
                    //refresh calendar
                    self.get('refresher').ping('calendar');
                    //remove overlay
                    $('.modal .overlay').css('display', 'none');
                    //clean up next button block
                    self.set('stepster.ready', true);
                    self.set('stepster.fallback', null);
                    //go to next step
                    $('.res-footer .next').click();
                }
            });
        }
    });
});