define('cn-front/components/calendar-gen/core', ['exports', 'ember'], function (exports, _ember) {

    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Component.extend({
        preselect: inject.service(),
        currentUser: inject.service('current-user'),
        currentWeekSize: 8,

        pickedDate: '',
        headerDates: [],
        sd: '',
        page: 0,
        type: 'front',
        singleCampsite: false,
        dateRange: '',
        campsites: null,
        reservations: null,
        csFullView: false,
        cgView: true,
        filters: null,
        onlineReservations: true,

        staysMode: (function () {
            return this.get('mode') == 'stays';
        }).property('mode'),

        didReceiveAttrs: function didReceiveAttrs() {

            this.calendarDates();
            this.getWeeklyPrices();
        },

        didInsertElement: function didInsertElement() {
            var self = this;
            var datePicker = $('.ava_date_picker');

            datePicker.datepicker({
                orientation: "bottom left",
                autoclose: true,
                startDate: self.get('type') == "dash" ? false : new Date()
            }).on('changeDate', function () {
                if (datePicker.val() !== '') {
                    self.set('pickedDate', datePicker.val());
                    self.set('page', 0);
                    self.calendarDates();
                }
            });
        },

        didRender: function didRender() {
            this._super.apply(this, arguments);
            console.log(this.get('cgView'));
            if (!this.get('singleCampsite')) {
                this.stickyRelocate();
            }
        },

        actions: {
            prev: function prev() {
                this.page--;
                this.calendarDates();
            },
            next: function next() {
                this.page++;
                this.calendarDates();
            },
            changePrice: function changePrice(attr) {
                attr.cg = this.get('camp');
                this.sendAction('showModal', 'dashboard.calendar.change-price', attr);
            },
            changeStay: function changeStay(attr) {
                attr.cg = this.get('camp');
                this.sendAction('showModal', 'dashboard.calendar.change-minstay', attr);
            }
        },

        /**
         * Set start day of calendar
         */
        setStartDay: function setStartDay() {
            var dr = $.cookie('homefilter') ? JSON.parse($.cookie('homefilter')) : null;

            if (dr != null) {
                if (dr.sdate !== '') {
                    this.set('sd', moment(dr.sdate));
                } else {
                    this.set('sd', moment());
                }
            } else {
                var startOfSeason = this.get('cg.calendar_start_date');
                if (new Date(startOfSeason) > new Date()) {
                    this.set('sd', moment(startOfSeason));
                } else {
                    this.set('sd', moment());
                }
            }
        },

        /**
         * Get reservation by params and set reservations property
         */
        availabilityReservationsAjax: function availabilityReservationsAjax() {
            var params,
                url,
                self = this;

            if (this.get('mode') == 'stays') {
                if (this.get('singleCampsite')) {
                    url = '/dashboard/durations/campsites/' + this.get('singleCampsite.id') + '/by_dates';
                } else {
                    url = '/dashboard/durations/campgrounds/' + this.get('camp.general.id') + '/by_dates';
                }
            } else {
                if (this.get('singleCampsite')) {
                    url = '/dashboard/rates/campsites/' + this.get('singleCampsite.id') + '/by_dates';
                } else {
                    url = '/dashboard/rates/campgrounds/' + this.get('camp.general.id') + '/by_dates';
                }
            }

            params = {
                'week': this.page,
                'week_size': this.currentWeekSize,
                'start_date': this.sd.format('YYYY-MM-DD')
            };

            if (this.get('pickedDate')) {
                params.start_date = moment(this.get('pickedDate')).format('YYYY-MM-DD');
            }

            this.get('api').sendReq({
                url: url,
                type: "GET",
                data: params,
                beforeSend: function beforeSend(xhr) {
                    if (self.get('currentUser.logged')) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    }
                }
            }).then(function (data) {
                self.set('prices', data);
            });
        },

        /**
         * Build all calendar without campsites
         */
        calendarDates: function calendarDates() {
            var today = '';

            this.setStartDay();

            if (this.get('pickedDate')) {
                today = new Date(this.get('pickedDate'));
            } else {
                today = new Date(this.sd);
            }

            this.set('dateRange', this.datesInRange(today));

            var headerDates = [];

            this.get('dateRange').map(function (d) {
                var dayString = [];
                dayString['dname'] = d.toString().split(' ')[0];
                dayString['mname'] = d.toString().split(' ')[1];
                dayString['dnumber'] = d.getDate();
                dayString['className'] = dayString['dname'] == "Sat" || dayString['mname'] == "Sun" ? 'weekend' : '';
                headerDates.push(dayString);
            });

            this.set('headerDates', headerDates);
            this.availabilityReservationsAjax();
        },

        /**
         * Get date range from start calendar to end
         */
        datesInRange: function datesInRange(today) {
            var dStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() + this.get('page') * this.get('currentWeekSize'));
            var dEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() + this.get('currentWeekSize') + this.get('page') * this.get('currentWeekSize'));
            var aDates = [];
            if (dStart <= dEnd) {
                for (var d = dStart; d < dEnd; d.setDate(d.getDate() + 1)) {
                    aDates.push(new Date(d.toString()));
                }
            }
            return aDates;
        },

        /**
         * Set sticky calendar header while scrolling
         */
        stickyRelocate: function stickyRelocate() {
            var calendar, navigationHeight, offsetCalendar, endCalendar, scrollHeight, startStiky;
            calendar = $('#availability-calendar');

            function defineMainParams() {
                navigationHeight = $('.navbar-fixed-top').outerHeight();
                offsetCalendar = calendar.offset();
                endCalendar = calendar.outerHeight() + offsetCalendar.top - $('.cs-item').last().outerHeight() - navigationHeight;
                startStiky = $('.color-key-legend').outerHeight(true) + offsetCalendar.top - navigationHeight;
            }

            defineMainParams();
            $(window).resize(defineMainParams);

            $(window).scroll(function () {
                scrollHeight = $(this).scrollTop();

                if (scrollHeight > startStiky && scrollHeight < endCalendar) {
                    calendar.addClass('sticky-header-enabled');
                    $('.ava-header').css({
                        'top': navigationHeight + 'px',
                        'left': calendar.offset().left + 'px',
                        'width': calendar.width()
                    });
                } else {
                    calendar.removeClass('sticky-header-enabled');
                    $('.ava-header').css({
                        'width': 'auto'
                    });
                }

                if (calendar.outerWidth() > 0) {
                    $('.ava-header').css({
                        'max-width': calendar.outerWidth()
                    });
                }
            });
        },

        getWeeklyPrices: function getWeeklyPrices() {
            var url,
                self = this;

            if (this.get('mode') == 'stays') {
                if (this.get('singleCampsite')) {
                    url = '/dashboard/durations/campsites/' + this.get('singleCampsite.id');
                } else {
                    url = '/dashboard/durations/campgrounds/' + this.get('camp.general.id');
                }
            } else {
                if (this.get('singleCampsite')) {
                    url = '/dashboard/rates/campsites/' + this.get('singleCampsite.id');
                } else {
                    url = '/dashboard/rates/campgrounds/' + this.get('camp.general.id');
                }
            }

            this.get('api').sendReq({
                url: url,
                type: "GET",
                beforeSend: function beforeSend(xhr) {
                    if (self.get('currentUser.logged')) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    }
                }
            }).then(function (data) {
                self.set('weeklyPrices', data);
            });
        }

    });
});