define('cn-front/controllers/dashboard/campground/edit/modal/delete-map', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        actions: {
            deleteMap: function deleteMap() {
                var self = this,
                    model = self.get('model');
                var flashMessages = _ember['default'].get(this, 'flashMessages');

                var url = this.get('apiConnect').getUrl('/dashboard/maps/delete/campgrounds/' + model.id);
                $.ajax({
                    type: "delete",
                    url: url,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    success: function success(data) {
                        flashMessages.success(data.success);

                        $('[data-map-id="' + model.id + '"]').remove();
                        $('html, body').animate({ scrollTop: 0 }, 'slow');
                        self.send('removeModal');
                    },
                    error: function error(data) {
                        flashMessages.danger(data.responseJSON.error);
                        self.send('removeModal');
                    }
                });
            }
        }
    });
});