define('cn-front/routes/campground/campsite', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            return _ember['default'].$.getJSON(this.get('api').getUrl('/campsites/' + params.campsite_id));
        },

        afterModel: function afterModel(model) {
            this.setHeadTags(model);
        },

        bindEvents: (function () {
            _ember['default'].run.schedule("afterRender", this, function () {
                var owl = $('#campsite-photo .owl-carousel');
                owl.owlCarousel({
                    loop: true,
                    margin: 0,
                    nav: false,
                    items: 4,
                    dots: false
                });

                $('#campsite-photo .next').click(function () {
                    owl.trigger('next.owl.carousel');
                });

                $('#campsite-photo .prev').click(function () {
                    owl.trigger('prev.owl.carousel');
                });
            });
        }).on('activate'),

        setHeadTags: function setHeadTags(model) {
            var ret = {};
            // here we are pulling meta data from the model for this route
            var campsite = model.campsite;

            this.get('meta').update({
                title: campsite.campground.name + ' - ' + campsite.name + ' - Camp Native'
            });

            ret = [{
                type: 'meta',
                tagId: 'meta-description-tag',
                attrs: {
                    name: 'description',
                    content: 'Book now at ' + campsite.campground.name + '\'s ' + campsite.name + '. Find the most properties available for booking online only on CampNative.com.'
                }
            }, {
                type: 'meta',
                tagId: 'meta-og-title',
                attrs: {
                    property: 'og:title',
                    content: campsite.campground.name + ' - ' + campsite.name + ' - Camp Native'
                }
            }];

            this.set('headTags', ret);
        },
        actions: {
            show: function show(id) {
                var model = this.controllerFor(this.routeName).get('model.campsite');
                var data = {
                    type: 'photo',
                    cg: model,
                    photos: model.photos,
                    clicked: id
                };
                this.send('showModal', 'photo-popup', data);
            }
        }
    });
});