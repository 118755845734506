define('cn-front/components/bs-modal', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        okLabel: 'OK',
        closeLabel: 'Close',
        needsApproval: false,
        autoclose: true,
        withFooter: true,
        actions: {
            ok: function ok() {
                if (this.get('autoclose')) {
                    this.sendAction('close');
                }
                this.sendAction('ok');
            },
            close: function close() {
                this.sendAction('close');
            }
        },
        show: (function () {
            this.$('.modal').modal().on('hidden.bs.modal', (function () {
                this.sendAction('finishClose');
            }).bind(this));

            if (this.get('needsApproval')) {
                this.$('.modal #submit').attr("disabled", 'disabled');

                this.$('.modal .approve-checkbox input').on('change', function () {
                    $('.modal #submit').attr("disabled", !this.checked);
                });
            }
        }).on('didInsertElement')
    });
});