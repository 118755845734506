define('cn-front/controllers/dashboard/campground/edit/pendings', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        resId: null,
        actions: {
            view: function view(reservation) {
                console.log(reservation);
                this.send('showModal', 'dashboard/campground/edit/modal/pending-res', reservation);
            }
        }
    });
});