define('cn-front/routes/dashboard/system', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),

        model: function model() {
            _ember['default'].$.ajaxSetup({
                headers: {
                    'Authorization': 'bearer ' + $.cookie('token')
                }
            });
            return _ember['default'].$.getJSON(this.get('apiConnect').getUrl('/dashboard/system'));
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('initialData', JSON.parse(JSON.stringify(model)));
        },

        listenFormChanges: (function () {

            /*var self = this;*/
            _ember['default'].run.schedule('afterRender', this, function () {
                $('#system-settings input, #system-settings textarea, #system-settings select').each(function () {
                    $(this).on('change', function () {

                        var initial_value = $(this).attr('data-initial-value');
                        if ($(this).attr('type') == 'radio') {
                            initial_value = $(this).closest('.radio-wrapper').attr('data-initial-value');
                        }

                        var closest_row = $(this).closest('tr');

                        if (initial_value != $(this).val() && !closest_row.hasClass('dirty-change')) {
                            closest_row.addClass('dirty-change');
                        }

                        if (initial_value == $(this).val() && closest_row.hasClass('dirty-change')) {
                            closest_row.removeClass('dirty-change');
                        }

                        if ($(this).closest('form').find('.dirty-change').length > 0) {
                            $('.btn-save').prop("disabled", false);
                        } else {
                            $('.btn-save').prop("disabled", true);
                        }
                    });
                });
            });
        }).on('activate')
    });
});