define('cn-front/components/dashboard/sign-up-form', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        ready: false,
        actions: {
            subscribe: function subscribe() {
                var _this = this;

                this.get('api').sendReq({ url: "/subscribe", type: "POST", data: { email: $('#sub-email').val() } }).then(function (data) {
                    _this.set("ready", true);
                    console.log(data);
                });
            }
        }
    });
});