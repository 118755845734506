define('cn-front/routes/explore', ['exports', 'ember', 'cn-front/mixins/responsive-explorer'], function (exports, _ember, _cnFrontMixinsResponsiveExplorer) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Route.extend(_cnFrontMixinsResponsiveExplorer['default'], {
        gMaps: inject.service('gmaps'),
        apiConnect: inject.service('api-resolver'),
        preselect: inject.service(),

        escapeString: function escapeString(string) {
            string = string.replace(/\s+/g, ""); // remove spaces
            string = string.replace(/\-+/g, ""); // remove hyphen
            string = string.replace(/_+/g, ""); // remove underscore
            string = string.toLowerCase(); // make lowercase

            return string;
        },

        beforeModel: function beforeModel(transition) {

            var self = this;

            /**
             * Check query params
             */
            var locationParam = transition.queryParams.location;

            if (typeof locationParam !== "undefined" && locationParam !== "") {

                _ember['default'].run.schedule('afterRender', this, function () {
                    //js magick for clear get parameter
                    window.history.pushState("object or string", "Title", "/" + window.location.href.substring(document.location.origin.length + 1).split("?")[0]);

                    var campgroundsFetchUrl = this.get('apiConnect').host + "/api/v2/locations/autocomplete/name";
                    var googleFetchUrl = this.get('apiConnect').host + "/api/v2/locations/autocomplete/google";

                    var ajaxCampgroundPromise = _ember['default'].$.ajax({
                        url: campgroundsFetchUrl,
                        type: "GET",
                        data: {
                            q: locationParam,
                            exact_match: true
                        }
                    });

                    _ember['default'].run(function () {
                        ajaxCampgroundPromise.then(function (data) {
                            if (data.length) {
                                var found_object = data[0];

                                self.get('preselect').setField('location', found_object.description);
                                self.get('preselect').setField('locationObj', found_object);
                                $(".hypersearch-input").val(found_object.description);

                                self.get('gMaps').initialize();
                                self.controllerFor(self.routeName).send('checkUserLocation');
                            } else {

                                var ajaxLocationPromise = _ember['default'].$.ajax({
                                    url: googleFetchUrl,
                                    type: "GET",
                                    data: {
                                        q: locationParam
                                    }
                                });

                                ajaxLocationPromise.then(function (data) {
                                    if (data.length) {

                                        var found_object = data[0];

                                        self.get('preselect').setField('location', found_object.description);
                                        self.get('preselect').setField('locationObj', found_object);
                                        $(".hypersearch-input").val(found_object.description);
                                    }

                                    self.get('gMaps').initialize();
                                    self.controllerFor(self.routeName).send('checkUserLocation');
                                });
                            }
                        });
                    });
                });
            } else {
                _ember['default'].run.schedule('afterRender', this, function () {
                    self.get('gMaps').initialize();
                    self.controllerFor(this.routeName).send('checkUserLocation');
                });
            }
        }

    });
});
//bindEvents: function () {
//     Ember.run.scheduleOnce('afterRender', this, function() {
//         var self = this;
//         self.showHideMapOnMobile();
//
//         $( window ).resize(function() {
//             self.showHideMapOnMobile();
//         });
//     });
// }.on('activate'),
//
// showHideMapOnMobile: function () {
//     let w = $(window).width();
//     if (w<415){
//         console.log('mobile-on');
//         $('#exploreMap').collapse('hide');
//     } else {
//         console.log('mobile-off');
//         $('#exploreMap').collapse('show');
//     }
// }