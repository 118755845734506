define('cn-front/components/reservation/change-reservation/verify-reservation', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		reservation: _ember['default'].inject.service('reservation/instance'),
		stepster: _ember['default'].inject.service('reservation/stepster'),
		sites: [],
		longDays: false,
		loadingShown: true,
		loadingText: 'Loading Reservation',
		currentMinDuration: '',
		paymentsEnabled: (function () {
			return this.get('reservation').isPaymentsEnabled() && this.get('reservation.model.mode') != "no_change";
		}).property('reservation.model.mode'),
		isReservationMinDurationValid: (function () {
			var validTypes = ["instant", "confirm"];
			return validTypes.indexOf(this.get('reservation.model.type')) != -1 && this.get('reservation.initiator') == "camper";
		}).property('reservation.initiator'),
		didInsertElement: function didInsertElement() {
			"use strict";

			var self = this;
			if (this.get('reservation.model.type') != 'confirm' || this.get('reservation.type') == 'dash') {
				if (this.get('reservation.type') != 'dash' && this.get('refuseToCancel')) {
					this.set('stepster.ready', false);
					$('.green-header .next-btn').css('visibility', 'hidden');
				} else {
					var url = this.get('api').getUrl('/campground/' + this.get('reservation.model.cg_id') + '/campsites/data');
					$.ajax({
						type: "GET",
						url: url,
						async: true,
						beforeSend: function beforeSend() {
							self.overlayText('Fetching Campsite Information');
						},
						complete: function complete() {
							if ($.active <= 1) {
								self.overlayHide('didInsertElement');
							}
						},
						success: function success(data) {
							self.set('sites', data.sort(function (a, b) {
								return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
							}));
							self.refreshInitialRates();
							self.getUserBrToken();
							self.initDatepickers(true);
						}
						// TODO: add error handling
					});
				}
			}
			if (this.get('reservation.type') == 'front' && (this.get('reservation.model.type') == 'confirm' || this.get('refuseToCancel'))) {
				$('.green-header .next-btn').css('visibility', 'hidden');
			}
		},
		overlayHide: function overlayHide(from) {
			"use strict";
			console.log('v-r::overlayHide', from, $.active);
			if ($.active <= 1) {
				this.set('loadingShown', false);
			}
		},
		overlayText: function overlayText(txt) {
			"use strict";
			console.log('v-r::overlayText', txt);
			this.set('loadingText', txt);
		},
		willDestroyElement: function willDestroyElement() {
			$('.green-header .next-btn').css('visibility', 'visible');
		},
		refreshInitialRates: function refreshInitialRates() {
			var id = this.get('reservation.model.cs_id');
			var cs = $.grep(this.get('sites'), function (e) {
				return e.id == id;
			})[0];
			//change cs rates
			this.set('reservation.model.rate_daily', cs.rate_daily);
			this.set('reservation.model.rate_weekly', cs.rate_weekly);
			this.set('reservation.model.rate_monthly', cs.rate_monthly);
			this.set('reservation.model.reservation_min_duration', parseInt(cs.reservation_min_duration));
			/**
    * Get defined min reservation duration
    */
			this.setCurrentMinDuration(moment(this.get('reservation.model.date_arrival')).format('MM/DD/YYYY'));
		},
		getUserBrToken: function getUserBrToken() {
			var self = this,
			    url;
			if (this.get('reservation.type') != 'dash') {
				url = this.get('api').getUrl('/reservations/braintree-token');
				var ajaxPromise = $.ajax({
					type: "GET",
					url: url,
					data: { token: $.cookie('token') },
					async: false,
					beforeSend: function beforeSend() {
						self.overlayText('Fetching Payment Token');
					},
					complete: function complete() {
						self.overlayHide('getUserBrToken');
					}
				});
				ajaxPromise.then(function (data) {
					self.set('reservation.braintree_token', data);
					console.log('braintree-token', self.get('reservation'), data);
				});
			} else {
				url = self.get('api').getUrl('/dashboard/users/lookup');
				self.overlayText('Fetching User Information');
				var ajaxPromise = $.ajax({
					type: "POST",
					url: url,
					beforeSend: function beforeSend(xhr) {
						xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
						self.overlayText('Fetching Payment Token');
					},
					complete: function complete() {
						self.overlayHide('getUserBrToken 1');
					},
					data: { email: self.get('reservation.model.user.email') }
				});
				ajaxPromise.then(function (data) {
					self.set('remoteUser', data);
					console.log('dashboard/users/lookup', self.get('reservation'), data);
					url = self.get('api').getUrl('/reservations/braintree-token');
					var innerSelf = self;

					var ajaxPromise = $.ajax({
						type: "GET",
						url: url,
						beforeSend: function beforeSend(xhr) {
							xhr.setRequestHeader('Authorization', 'bearer ' + self.get('remoteUser.token'));
							self.overlayText('Fetching Payment Token');
						},
						complete: function complete() {
							self.overlayHide('getUserBrToken 2');
						},
						data: { token: self.get('remoteUser.token') },
						async: false
					});
					ajaxPromise.then(function (data) {
						innerSelf.set('reservation.braintree_token', data);
						innerSelf.set('reservation.remote_token', innerSelf.get('remoteUser.token'));
						innerSelf.set('reservation.remote_user', innerSelf.get('remoteUser'));
						innerSelf.set('stepster.ready', true);
						console.log('reservations/braintree-token 2', innerSelf.get('reservation'), data);
					});
					ajaxPromise['catch'](function () /*reason*/{
						innerSelf.set('stepster.ready', false);
						alert('Something went wrong, please try again later.');
					});
				});
			}
		},
		destroyPickers: function destroyPickers() {
			var arrivalDatePicker = $('#res-arrival');
			var departureDatePicker = $('#res-departure');
			departureDatePicker.off();
			arrivalDatePicker.off();
			departureDatePicker.datepicker('destroy');
			arrivalDatePicker.datepicker('destroy');
		},
		initDatepickers: function initDatepickers() {
			var init = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

			var url = this.get('api').getUrl('/campground/reservations/future/' + this.get('reservation.model.cs_id') + '/' + this.get('reservation.model.id'));
			var self = this;
			var ajaxPromise = $.ajax({
				type: "GET",
				url: url,
				async: false,
				beforeSend: function beforeSend() /*xhr*/{
					self.overlayText('Loading Site Availabilities');
				},
				complete: function complete() {
					self.overlayHide('initDatepickers');
				}
			});
			var arrivalDatePicker = $('#res-arrival');
			var departureDatePicker = $('#res-departure');
			ajaxPromise.then(function (data) {
				"use strict";

				var innerSelf = self;
				/**
     * Get current start/end date and minDuration
     */
				var start = moment(self.get('reservation.model.date_arrival')).format('MM/DD/YYYY');
				var end = moment(self.get('reservation.model.date_departure')).format('MM/DD/YYYY');
				/**
     * Check min duration validity
     */
				var minDate,
				    maxInDate,
				    minDuration = 1;
				/*
     if (self.get('isReservationMinDurationValid')) {
     minDuration = parseInt(self.get('reservation.model.reservation_min_duration'));
     }*/
				if (self.get('isReservationMinDurationValid')) {
					minDuration = self.get('reservation.min_duration.value');
				}
				/**
     * Check in advance validity
     */
				if (self.get('in_process') || self.get('reservation.type') == 'dash') {
					minDate = self.get('reservation.model.season_open');
					maxInDate = self.get('reservation.model.season_close');
				} else {
					if (self.get('isReservationMinDurationValid')) {
						minDate = moment().add(parseInt(self.get('reservation.model.reservation_inadvance_min')) + parseInt(self.get('reservation.min_duration.value')), 'd').format('MM/DD/YYYY');
					} else {
						minDate = moment().add(parseInt(self.get('reservation.model.reservation_inadvance_min')), 'd').format('MM/DD/YYYY');
					}
					maxInDate = moment().add(parseInt(self.get('reservation.model.reservation_inadvance_max')), 'M').format('MM/DD/YYYY');
				}
				/**
     * Save disabledDates for later use
     */
				self.set('disabledDates', data);
				/**
     * Initialize arrival date picker based on specific rules
     */
				arrivalDatePicker.datepicker({
					orientation: "bottom left",
					format: 'mm/dd/yyyy',
					autoclose: true,
					startDate: minDate,
					endDate: maxInDate,
					datesDisabled: self.get('disabledDates')
				}).on('changeDate', function (e) {
					minDuration = innerSelf.get('reservation.min_duration.value');
					/**
      * On change: reset start/end date
      */
					departureDatePicker.datepicker('setEndDate', null);
					departureDatePicker.datepicker('setStartDate', null);
					/**
      * On change: validate chosen arrival date
      */
					var target = new Date(e.date),
					    departureDate = departureDatePicker.val(),
					    disabledDates = innerSelf.get('disabledDates'),
					    maxDate = '';
					if (disabledDates.length) {
						var positiveArr = disabledDates.filter(function (date) {
							return new Date(date) > target;
						});
						if (positiveArr.length) {
							maxDate = new Date(positiveArr.reduce(function (a, b) {
								return new Date(a) < new Date(b) ? a : b;
							}));
							// maxDate.setDate(maxDate.getDate() + 1); // TODO [future]: remove this in next phase if not needed
							departureDatePicker.datepicker('setEndDate', maxDate);
						} else {
							departureDatePicker.datepicker('setEndDate', maxInDate);
						}
					} else {
						departureDatePicker.datepicker('setEndDate', maxInDate);
					}
					target.setDate(target.getDate() + minDuration);
					if (maxDate !== '') {
						if (new Date(departureDate) < target || departureDate === '' || maxDate < new Date(departureDate)) {
							departureDatePicker.datepicker('setDate', target);
						}
						departureDatePicker.datepicker('setStartDate', target);
					} else {
						if (new Date(departureDate) < target || departureDate === '') {
							departureDatePicker.datepicker('setDate', target);
						}
						departureDatePicker.datepicker('setStartDate', target);
					}
					/**
      * On change: re-run cost calculations
      */
					if (!init) {
						innerSelf.recalculateCost();
					}
				});
				departureDatePicker.datepicker({
					orientation: "bottom left",
					autoclose: true,
					format: 'mm/dd/yyyy',
					startDate: minDate,
					endDate: maxInDate
				}).on('changeDate', function () {
					/**
      * On change: re-run cost calculations
      */
					if (!init) {
						innerSelf.recalculateCost();
					}
				});
				console.log('datepicker::init', init, start, end);
				if (init) {
					//set start-end dates
					arrivalDatePicker.datepicker('setDate', start);
					departureDatePicker.datepicker('setDate', end);
					self.recalculateCost();
				} else {
					//null datepickers
					arrivalDatePicker.datepicker('setDate', null);
					departureDatePicker.datepicker('setDate', null);
				}
				init = false;
			});
			if (this.get('in_process') && this.get('reservation.type') == 'front') {
				arrivalDatePicker.prop("disabled", true);
			} else {
				arrivalDatePicker.prop("disabled", false);
			}
		},
		setCurrentMinDuration: function setCurrentMinDuration(arrival) {
			var format_arrival = moment(arrival).format('MM/DD/YYYY');
			var url = this.get('api').getUrl('/campsites/' + this.get('reservation.model.cs_id')) + '/min-duration';
			var self = this;
			var ajaxPromise = $.ajax({
				url: url,
				type: "GET",
				data: { arrival: format_arrival },
				async: false,
				beforeSend: function beforeSend() /*xhr*/{
					self.overlayText('Finding Minimum Stay');
				},
				complete: function complete() {
					self.overlayHide('setCurrentMinDuration');
				}
			});
			/*		ajaxPromise.then((data) => {
    self.set('reservation.min_duration', data);
    console.log('setCurrentMinDuration', self.get('reservation'));
    });*/
			ajaxPromise.then(function (new_minimum_duration) {
				console.log('setCurrentMinDuration::promise');
				var old_minimum_duration = self.get('reservation.min_duration.value');
				self.set('reservation.min_duration', new_minimum_duration);
				self.set('longer_stay', false);
				self.set('shorter_stay', false);
				if (typeof old_minimum_duration !== 'undefined' && old_minimum_duration !== new_minimum_duration.value) {
					console.log('setCurrentMinDuration', old_minimum_duration, new_minimum_duration.value);
					if (old_minimum_duration < new_minimum_duration.value) {
						// the minimum duration is increased, so we need to update the end date and alert the user
						var ed = moment(self.get('reservation.model.date_arrival')).add('days', new_minimum_duration.value).format('MM/DD/YYYY');
						console.log('ed 1', ed);
						self.set('reservation.model.date_departure', ed);
						self.set('longer_stay', true);
					} else if (old_minimum_duration < new_minimum_duration.value) {
						// alert the user that they can reduce their stay duration if they need to.
						self.set('shorter_stay', true);
					}
				}
			});
		},
		recalculateCost: function recalculateCost() {
			var sd = $('#res-arrival').val(),
			    ed = $('#res-departure').val();
			console.log('recalculateCost dates', sd, ed);
			if (sd && sd !== "Invalid date") {
				this.set('reservation.model.date_arrival', sd);
			} else {
				sd = moment(this.get('reservation.model.date_arrival')).format('MM/DD/YYYY');
			}
			if (ed && ed !== "Invalid date") {
				console.log('ed 2', ed);
				this.set('reservation.model.date_departure', ed);
			} else {
				ed = moment(this.get('reservation.model.date_departure')).format('MM/DD/YYYY');
			}
			if (sd && sd !== 'Invalid date' && ed && ed !== 'Invalid date') {
				this.set('reservation.daterange', sd + ' - ' + ed);
			}
			sd = moment(sd, 'MM/DD/YYYY');
			ed = moment(ed, 'MM/DD/YYYY');
			var days = ed.diff(sd, 'days');
			this.set('reservation.days', days);
			days = days !== days ? 0 : days;
			if (days > 0) {
				this.set('stepster.ready', true);
				//clear error message
				$('.error').fadeOut();
			} else {
				this.set('stepster.ready', false);
				$('.error').fadeIn();
			}
			if (days > 14) {
				this.set('longDays', true);
			} else {
				this.set('longDays', false);
			}
			console.log('recalculateCost', this.get('reservation'));
			this.calculate();
		},
		calculate: function calculate() {
			var self = this;
			var url = this.get('api').getUrl("/reservations/calculate/change_res");
			var ajaxPromise = $.ajax({
				url: url,
				type: "GET",
				data: {
					reservation_id: this.get('reservation.model.id'),
					initiator: this.get('reservation.initiator'),
					cs_id: this.get('reservation.model.cs_id'),
					daterange: this.get('reservation.daterange')
				},
				async: false,
				beforeSend: function beforeSend() /*xhr*/{
					self.overlayText('Calculating Payments & Fees');
				},
				complete: function complete() {
					self.overlayHide('calculate');
				}
			});
			ajaxPromise.then(function (data) {
				for (var k in data) {
					if (data.hasOwnProperty(k)) {
						self.set('reservation.model.' + k, data[k]);
					}
				}
				console.log('calculate', self.get('reservation'));
				self.get('reservation').calculateDuration('change');
			});
		},
		actions: {
			changeCampsite: function changeCampsite() {
				var id = $('#chooseCs').val();
				var cs = $.grep(this.get('sites'), function (e) {
					return e.id == id;
				})[0];
				//change cs creds
				this.set('reservation.model.cs_id', cs.id);
				this.set('reservation.model.cs_name', cs.name);
				this.set('reservation.model.rate_daily', cs.rate_daily);
				this.set('reservation.model.rate_weekly', cs.rate_weekly);
				this.set('reservation.model.rate_monthly', cs.rate_monthly);
				this.set('reservation.model.dow_rates', cs.dow_rates);
				this.set('reservation.model.reservation_min_duration', parseInt(cs.reservation_min_duration));
				/**
     * Get defined min reservation duration
     */
				this.setCurrentMinDuration(moment(this.get('reservation.model.date_arrival')).format('MM/DD/YYYY'));
				/**
     * check if the site is free
     */
				var url = this.get('api').getUrl('/reservations/campsite/' + this.get('reservation.model.cg_id') + '/is-free-range');
				var self = this;
				/*
     var minDurationFlag = true;
     if (self.get('isReservationMinDurationValid') && self.get('reservation.days') < parseInt(cs.reservation_min_duration)) {
     minDurationFlag = false;
     }*/
				var ajaxPromise = $.ajax({
					url: url,
					type: "GET",
					data: { 'date_range': self.get('reservation.daterange') },
					async: false,
					beforeSend: function beforeSend() /*xhr*/{
						self.overlayText('Changing Site');
					},
					complete: function complete() {
						self.overlayHide('changeCampsite');
					}
				});
				ajaxPromise.then(function (data) {
					self.destroyPickers();
					if (data /*&& minDurationFlag*/) {
							self.initDatepickers(true);
						} else {
						self.initDatepickers();
					}
				});
			}
		}
	});
});