define('cn-front/mixins/search-header', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        activate: function activate() {
            this._super.apply(this, arguments); // Call super
            // collapse menus when page "reloads"
            jQuery('.collapse.navbar-collapse.in').collapse('hide');
            // TODO: refactor this according to new functionality
            if (this.routeName === 'explore' || this.routeName === 'index') {
                _ember['default'].run.scheduleOnce('afterRender', this, function () {
                    jQuery('#header .navbar-search, #header .search-bar').removeClass('showRouteElement').addClass('hideRouteElement');
                    // hide the class named fields when duplicate forms (home and explore pages)
                    jQuery('#search-navbar .arrival-date').removeClass('arrival-date').addClass('arrival-date-hidden');
                    jQuery('#search-navbar .departure-date').removeClass('departure-date').addClass('departure-date-hidden');
                    jQuery('#search-navbar .shelter').removeClass('shelter').addClass('shelter-hidden');
                    jQuery('#search-navbar .hypersearch-input').removeClass('hypersearch-input').addClass('hypersearch-input-hidden');
                    jQuery('#search-navbar .hypersearch-results').removeClass('hypersearch-results').addClass('hypersearch-results-hidden');
                });
            } else {
                _ember['default'].run.scheduleOnce('afterRender', this, function () {
                    jQuery('#header .navbar-search, #header .search-bar').addClass('showRouteElement').removeClass('hideRouteElement');
                    // hide the class named fields when duplicate forms (home and explore pages)
                    jQuery('#search-navbar .arrival-date-hidden').removeClass('arrival-date-hidden').addClass('arrival-date');
                    jQuery('#search-navbar .departure-date-hidden').removeClass('departure-date-hidden').addClass('departure-date');
                    jQuery('#search-navbar .shelter-hidden').removeClass('shelter-hidden').addClass('shelter');
                    jQuery('#search-navbar .hypersearch-input-hidden').removeClass('hypersearch-input-hidden').addClass('hypersearch-input');
                    jQuery('#search-navbar .hypersearch-results-hidden').removeClass('hypersearch-results-hidden').addClass('hypersearch-results');
                });
            }
        }
    });
});