define('cn-front/routes/dashboard/campground/edit/permissions', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        actions: {
            editUser: function editUser(user) {
                user.campground_id = this.modelFor('dashboard.campground.edit').general.id;
                user.campground_name = this.modelFor('dashboard.campground.edit').general.name;
                this.send('showModal', 'dashboard.campground.edit.modal.unassign-user', user);
            },
            findUser: function findUser() {
                var formdata = $('form#form-add-user').serializeJSON();

                var url = this.get('apiConnect').getUrl('/dashboard/campgrounds/assign/find');
                var self = this;
                var flashMessages = _ember['default'].get(self, 'flashMessages');
                $.ajax({
                    type: "POST",
                    url: url,
                    data: formdata,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        if (data.status == 'success') {
                            data.campground_id = self.modelFor('dashboard.campground.edit').general.id;
                            data.campground_name = self.modelFor('dashboard.campground.edit').general.name;
                            self.send('showModal', 'dashboard.campground.edit.modal.assign-user', data);
                        } else {
                            flashMessages.danger(data.error);
                        }
                    }

                });
            }
        }
    });
});