define('cn-front/routes/seo/city', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            return _ember['default'].$.getJSON(this.get('api').getUrl('/campgrounds/usa/' + params.state + '/' + params.city));
        },
        afterModel: function afterModel(model, transition) {
            "use strict";
            this.set('state', transition.params['seo.city'].state);
            var m = this.modelFor(this.routeName),
                name = m.city,
                state = m.state_name;

            this.get('meta').update({
                title: 'Campgrounds in ' + name + ', ' + state + ' - Camp Native',
                description: 'Find the best campgrounds in ' + name + ', ' + state + '. ' + 'The easiest way to book your trip online. '
            });

            _ember['default'].run.schedule('afterRender', this, function () {
                $('html,body').animate({ scrollTop: 0 }, 200);
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.set('state', this.get('state'));
        }
    });
});