define('cn-front/components/tos-accept', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Component.extend({
        currentUser: inject.service('current-user'),

        actions: {
            agreeWithTos: function agreeWithTos() {
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                var self = this;

                this.get('api').sendReq({
                    type: 'put',
                    url: '/user/update/tos',
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('temp_token'));
                    }
                }).then(function (data) {
                    self.get('currentUser').login(data);
                    self.get('currentUser').clearTemporaryToken();
                    self.sendAction('handleTos', true);
                }, function (data) {
                    self.get('currentUser').clearTemporaryToken();
                    flashMessages.danger(data.message);
                });
            },

            disagreeTos: function disagreeTos() {
                this.sendAction('handleTos', false);
            }
        }
    });
});