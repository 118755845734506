define('cn-front/components/dashboard/changestep/verify-res', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Component.extend({
        apiConnect: inject.service('api-resolver'),
        reservation: inject.service('dashboard/res-change'),
        stepster: inject.service('dashboard/stepster'),
        sites: [],

        isPaymentsEnabled: function isPaymentsEnabled() {
            var disabledPaymentOn = ["in_use", "maint", "fcfs", "info_req"];
            return disabledPaymentOn.indexOf(this.get('data.reservation.type')) == -1;
        },

        isReservationMinDurationValid: (function () {
            var validTypes = ["instant", "confirm"];
            return validTypes.indexOf(this.get('reservation.type')) != -1 && this.get('reservation.initiator') == "camper";
        }).property('reservation.initiator'),

        paymentsEnabled: (function () {
            return this.isPaymentsEnabled() && this.get('reservation.mode') != "no_change";
        }).property('reservation.mode'),

        refuseToChange: (function () {
            var sd = moment(this.get('data.reservation.date_arrival'));
            var now = moment();

            var days = sd.diff(now, 'days');
            // console.log(sd, now, days, days < 14);
            return days < 14;
        }).property('data.reservation.date_arrival'),

        onSelect: (function () {
            if (this.get('stepster.currentStep') == this.get('tabId')) {
                console.log(this.get('reservation'));
                //firing every entering on this step
                var d = this.get('data.reservation');
                for (var k in d) {
                    if (d.hasOwnProperty(k)) {
                        this.set('reservation.' + k, d[k]);
                    }
                }
                this.set('reservation.reservation_id', this.get('data.reservation_id'));
                this.set('reservation.initiator', this.get('data.initiator'));
                this.set('reservation.dashOrFront', this.get('data.dashOrFront'));

                //console.log(this.get('data.reservation'));
                _ember['default'].run.schedule('afterRender', this, function () {
                    if (this.get('reservation.type') != 'confirm' || this.get('reservation.dashOrFront') == 'dash') {

                        var url = this.get('apiConnect').getUrl('/campground/' + this.get('reservation.cg_id') + '/campsites/data');
                        var self = this;
                        $.ajax({
                            type: "GET",
                            url: url,
                            async: true,
                            success: function success(data) {
                                self.set('sites', data.sort(function (a, b) {
                                    return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
                                }));

                                self.refreshInitialRates();
                                self.getUserBrToken();
                                self.initDatepickers(true);
                            }
                            // TODO: add error handling
                        });
                    }
                });
            }
        }).observes('stepster.currentStep').on('init'),

        destroyPickers: function destroyPickers() {
            $('#res-departure').off();
            $('#res-arrival').off();
            $('#res-departure').datepicker('destroy');
            $('#res-arrival').datepicker('destroy');
        },

        initDatepickers: function initDatepickers() {
            var init = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

            var url = this.get('apiConnect').getUrl('/campground/reservations/future/' + this.get('reservation.cs_id') + '/' + this.get('reservation.reservation_id'));
            var self = this;

            var start = moment(self.get('reservation.date_arrival')).format('MM/DD/YYYY');
            var end = moment(self.get('reservation.date_departure')).format('MM/DD/YYYY');

            $.ajax({
                type: "GET",
                url: url,
                async: false,
                success: function success(data) {

                    var minDate,
                        maxInDate,
                        minDur = 1;
                    if (self.get('isReservationMinDurationValid')) {
                        minDur = parseInt(self.get('reservation.reservation_min_duration'));
                    }
                    console.log(minDur);

                    if (self.get('data.in_process') || self.get('reservation.dashOrFront') == 'dash') {
                        minDate = self.get('reservation.season_open');
                        //maxInDate = self.get('reservation.season_close');
                    } else {
                            if (self.isReservationMinDurationValid) {
                                minDate = moment().add(parseInt(self.get('reservation.camp.reservation_inadvance_min')) + parseInt(self.get('reservation.campsite.reservation_min_duration')), 'd').format('MM/DD/YYYY');
                            } else {
                                minDate = moment().add(parseInt(self.get('reservation.camp.reservation_inadvance_min')), 'd').format('MM/DD/YYYY');
                            }
                            maxInDate = moment().add(parseInt(self.get('reservation.reservation_inadvance_max')), 'M').format('MM/DD/YYYY');
                        }

                    self.set('disabledDates', data);
                    //initialize datpeckers with rules

                    $('#res-arrival').datepicker({
                        orientation: "bottom left",
                        format: 'mm/dd/yyyy',
                        autoclose: true,
                        startDate: minDate,
                        endDate: maxInDate,
                        datesDisabled: self.get('disabledDates')
                    }).on('changeDate', function (e) {
                        $('#res-departure').datepicker('setEndDate', null);
                        $('#res-departure').datepicker('setStartDate', null);

                        var target = new Date(e.date),
                            dep = $('#res-departure').val();
                        var dis = self.get('disabledDates');
                        var maxDate = '';
                        if (dis.length) {
                            var positiveArr = dis.filter(function (date) {
                                return new Date(date) > target;
                            });
                            if (positiveArr.length) {
                                maxDate = new Date(positiveArr.reduce(function (a, b) {
                                    return new Date(a) < new Date(b) ? a : b;
                                }));

                                // maxDate.setDate(maxDate.getDate() + 1);
                                $('#res-departure').datepicker('setEndDate', maxDate);
                            } else {
                                $('#res-departure').datepicker('setEndDate', maxInDate);
                            }
                        } else {
                            $('#res-departure').datepicker('setEndDate', maxInDate);
                        }
                        target.setDate(target.getDate() + minDur);

                        if (maxDate !== '') {
                            if (new Date(dep) < target || dep === '' || maxDate < new Date(dep)) {
                                $('#res-departure').datepicker('setDate', target);
                            }
                            $('#res-departure').datepicker('setStartDate', target);
                        } else {
                            if (new Date(dep) < target || dep === '') {
                                $('#res-departure').datepicker('setDate', target);
                            }
                            $('#res-departure').datepicker('setStartDate', target);
                        }
                        if (!init) {
                            self.reCalc();
                        }
                    });
                    $('#res-departure').datepicker({
                        orientation: "bottom left",
                        autoclose: true,
                        format: 'mm/dd/yyyy',
                        startDate: minDate,
                        endDate: maxInDate
                    }).on('changeDate', function () {
                        if (!init) {
                            self.reCalc();
                        }
                    });

                    if (init) {
                        //set start-end dates
                        $('#res-arrival').datepicker('setDate', start);
                        $('#res-departure').datepicker('setDate', end);
                        self.reCalc();
                    } else {
                        //null datepickers
                        $('#res-arrival').datepicker('setDate', null);
                        $('#res-departure').datepicker('setDate', null);
                    }

                    init = false;
                }
            });

            if (this.get('data.in_process') && this.get('reservation.dashOrFront') == 'front') {
                $('#res-arrival').prop("disabled", true);
            } else {
                $('#res-arrival').prop("disabled", false);
            }
        },

        reCalc: function reCalc() {
            var wr = this.get('reservation.rate_weekly');
            var mr = this.get('reservation.rate_monthly');

            var sd = $('#res-arrival').val(),
                ed = $('#res-departure').val();

            this.set('reservation.daterange', sd + ' - ' + ed);
            this.set('reservation.date_arrival', sd);
            this.set('reservation.date_departure', ed);
            sd = moment(sd, 'MM/DD/YYYY');
            ed = moment(ed, 'MM/DD/YYYY');

            var days = ed.diff(sd, 'days');

            this.set('reservation.days', days);
            days = days !== days ? 0 : days;
            if (days > 0) {
                this.set('stepster.ready', true);
                //clear error message
                $('.reservation-daterange').css('border-color', ' #ccd0d4');
                $('.error').fadeOut();
            } else {
                this.set('stepster.ready', false);
            }

            var computed = '';
            var day, weeks, month;

            if (days >= 7 && wr !== 0) {
                month = Math.floor(days / 28);
                if (month > 0 && mr !== 0) {
                    day = days - month * 28;
                    weeks = Math.floor(day / 7);
                    day = day - weeks * 7;
                    computed = month + ' month(s)';
                    computed = weeks > 0 ? computed + ', ' + weeks + ' week(s) ' : computed;
                    computed = day > 0 ? computed + ', ' + day + ' day(s)' : computed;
                } else {
                    weeks = Math.floor(days / 7);
                    day = days - weeks * 7;
                    computed = weeks + ' week(s), ' + day + ' day(s)';
                }
            } else {
                computed = days + ' day(s)';
            }

            this.set('reservation.days_computed', computed);

            this.calculate();
        },

        getUserBrToken: function getUserBrToken() {
            var self = this,
                url;
            if (this.get('reservation.dashOrFront') != 'dash') {
                $('.modal .overlay').css('display', 'block');
                url = this.get('apiConnect').getUrl('/reservations/braintree-token');
                $.ajax({
                    type: "GET",
                    url: url,
                    data: { token: $.cookie('token') },
                    async: true,
                    success: function success(data) {
                        self.set('reservation.braintree_token', data);
                        self.set('stepster.ready', true);
                        //$('.res-footer .next').prop('disabled', false);
                        $('.modal .overlay').css('display', 'none');
                    }
                });
            } else {
                url = self.get('apiConnect').getUrl('/dashboard/users/lookup');
                $.ajax({
                    type: "POST",
                    url: url,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    data: { email: self.get('reservation.user.email') },
                    success: function success(data) {
                        self.set('remoteUser', data);

                        url = self.get('apiConnect').getUrl('/reservations/braintree-token');
                        $.ajax({
                            type: "GET",
                            url: url,
                            beforeSend: function beforeSend(xhr) {
                                xhr.setRequestHeader('Authorization', 'bearer ' + self.get('remoteUser.token'));
                            },
                            data: { token: self.get('remoteUser.token') },
                            async: false,
                            success: function success(data) {
                                self.set('reservation.braintree_token', data);
                                self.set('reservation.remote_token', self.get('remoteUser.token'));
                                self.set('reservation.remote_user', self.get('remoteUser'));

                                self.set('stepster.ready', true);
                                //$('.res-footer .next').prop('disabled', false);
                                $('.modal .overlay').css('display', 'none');
                            },
                            error: function error(data) {
                                $('.modal .overlay').css('display', 'none');
                                console.log(data);
                                alert('500');
                            }
                        });
                    }
                });
            }
        },

        calculate: function calculate() {

            var url = this.get('apiConnect').getUrl("/reservations/calculate/change_res");
            var ajaxPromise = $.get(url, {
                reservation_id: this.get('data.reservation_id'),
                initiator: this.get('data.initiator'),
                cs_id: this.get('reservation.cs_id'),
                daterange: this.get('reservation.daterange')
            });
            var self = this;
            _ember['default'].run(function () {
                ajaxPromise.then(function (data) {
                    for (var k in data) {
                        if (data.hasOwnProperty(k)) {
                            self.set('reservation.' + k, data[k]);
                        }
                    }
                });
            });
        },

        refreshInitialRates: function refreshInitialRates() {
            var id = this.get('reservation.cs_id');
            var cs = $.grep(this.get('sites'), function (e) {
                return e.id == id;
            })[0];
            console.log(id, cs);

            //change cs rates
            this.set('reservation.rate_daily', cs.rate_daily);
            this.set('reservation.rate_weekly', cs.rate_weekly);
            this.set('reservation.rate_monthly', cs.rate_monthly);
            this.set('reservation.reservation_min_duration', parseInt(cs.reservation_min_duration));
        },

        actions: {
            changeCs: function changeCs() {
                var id = $('#chooseCs').val();
                var cs = $.grep(this.get('sites'), function (e) {
                    return e.id == id;
                })[0];
                //change cs creds
                this.set('reservation.cs_id', cs.id);
                this.set('reservation.cs_name', cs.name);
                this.set('reservation.rate_daily', cs.rate_daily);
                this.set('reservation.rate_weekly', cs.rate_weekly);
                this.set('reservation.rate_monthly', cs.rate_monthly);
                this.set('reservation.reservation_min_duration', parseInt(cs.reservation_min_duration));

                var url = this.get('apiConnect').getUrl('/reservations/campsite/' + this.get('reservation.cg_id') + '/is-free-range');
                var self = this;

                var minDurationFlag = true;
                if (self.get('isReservationMinDurationValid') && self.get('reservation.days') < parseInt(cs.reservation_min_duration)) {
                    minDurationFlag = false;
                }

                $.ajax({
                    type: "GET",
                    url: url,
                    async: true,
                    data: { 'date_range': self.get('reservation.daterange') },
                    success: function success(data) {

                        self.destroyPickers();
                        if (data && minDurationFlag) {
                            self.initDatepickers(true);
                        } else {
                            self.initDatepickers();
                        }
                    }
                });
            }
        }

    });
});