define("cn-front/templates/components/ava-calendar/calendar", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "filterable");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "explore/filter-form", [], ["type", "campsites", "clear", ["subexpr", "action", ["clear"], [], ["loc", [null, [3, 54], [3, 70]]], 0, 0], "search", ["subexpr", "action", ["search"], [], ["loc", [null, [3, 78], [3, 95]]], 0, 0]], ["loc", [null, [3, 8], [3, 97]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 4
            },
            "end": {
              "line": 16,
              "column": 4
            }
          },
          "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "pick-ava-day-left");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "pick-ava-day");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3, "for", "ava_date_picker");
          var el4 = dom.createTextNode("Pick a date");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3, "type", "text");
          dom.setAttribute(el3, "name", "ava_date_picker");
          dom.setAttribute(el3, "id", "ava_date_picker");
          dom.setAttribute(el3, "class", "ava_date_picker");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 20
              },
              "end": {
                "line": 32,
                "column": 20
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "break-lines", ["< Show Calendar"], [], ["loc", [null, [31, 24], [31, 57]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 20
              },
              "end": {
                "line": 34,
                "column": 20
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "break-lines", ["Show Campsites >"], [], ["loc", [null, [33, 24], [33, 58]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 8
            },
            "end": {
              "line": 42,
              "column": 8
            }
          },
          "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "cs-view");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "calendar-view-btn");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "pick-ava-day");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3, "for", "ava_date_picker");
          var el4 = dom.createTextNode("Pick a date");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3, "type", "text");
          dom.setAttribute(el3, "name", "ava_date_picker");
          dom.setAttribute(el3, "id", "ava_date_picker");
          dom.setAttribute(el3, "class", "ava_date_picker");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["campsiteView"], [], ["loc", [null, [29, 48], [29, 74]]], 0, 0], ["block", "if", [["get", "csFullView", ["loc", [null, [30, 26], [30, 36]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [30, 20], [34, 27]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 12
            },
            "end": {
              "line": 47,
              "column": 12
            }
          },
          "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "date-block");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element0, 0, 0);
          morphs[1] = dom.createMorphAt(element0, 2, 2);
          morphs[2] = dom.createMorphAt(element0, 4, 4);
          return morphs;
        },
        statements: [["content", "day.dname", ["loc", [null, [46, 41], [46, 54]]], 0, 0, 0, 0], ["content", "day.mname", ["loc", [null, [46, 58], [46, 71]]], 0, 0, 0, 0], ["content", "day.dnumber", ["loc", [null, [46, 75], [46, 90]]], 0, 0, 0, 0]],
        locals: ["day"],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 8
            },
            "end": {
              "line": 60,
              "column": 8
            }
          },
          "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "ava-calendar/cs-row", [], ["campsite", ["subexpr", "@mut", [["get", "singleCampsite", ["loc", [null, [54, 25], [54, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "reservations", ["subexpr", "@mut", [["get", "reservations", ["loc", [null, [55, 29], [55, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "goToReservation", "goToReservation", "cgView", ["subexpr", "@mut", [["get", "cgView", ["loc", [null, [57, 25], [57, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "onlineReservations", ["subexpr", "@mut", [["get", "onlineReservations", ["loc", [null, [58, 37], [58, 55]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [53, 12], [59, 14]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 16
                },
                "end": {
                  "line": 70,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "ava-calendar/cs-row", [], ["campsite", ["subexpr", "@mut", [["get", "campsite", ["loc", [null, [64, 33], [64, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "reservations", ["subexpr", "@mut", [["get", "reservations", ["loc", [null, [65, 37], [65, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "goToReservation", "goToReservation", "cgView", ["subexpr", "@mut", [["get", "cgView", ["loc", [null, [67, 33], [67, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "onlineReservations", ["subexpr", "@mut", [["get", "onlineReservations", ["loc", [null, [68, 45], [68, 63]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [63, 20], [69, 22]]], 0, 0]],
            locals: ["campsite"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 61,
                "column": 12
              },
              "end": {
                "line": 71,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "campsites.extra_match", ["loc", [null, [62, 24], [62, 45]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [62, 16], [70, 25]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 75,
                  "column": 16
                },
                "end": {
                  "line": 83,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "ava-calendar/cs-row", [], ["campsite", ["subexpr", "@mut", [["get", "campsite", ["loc", [null, [77, 33], [77, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "reservations", ["subexpr", "@mut", [["get", "reservations", ["loc", [null, [78, 37], [78, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "goToReservation", "goToReservation", "cgView", ["subexpr", "@mut", [["get", "cgView", ["loc", [null, [80, 33], [80, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "onlineReservations", ["subexpr", "@mut", [["get", "onlineReservations", ["loc", [null, [81, 45], [81, 63]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [76, 20], [82, 22]]], 0, 0]],
            locals: ["campsite"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 73,
                "column": 12
              },
              "end": {
                "line": 84,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "cs-row");
            var el2 = dom.createElement("h4");
            var el3 = dom.createTextNode("Partial match");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "campsites.partial_match", ["loc", [null, [75, 24], [75, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [75, 16], [83, 25]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 88,
                  "column": 16
                },
                "end": {
                  "line": 96,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "ava-calendar/cs-row", [], ["campsite", ["subexpr", "@mut", [["get", "campsite", ["loc", [null, [90, 33], [90, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "reservations", ["subexpr", "@mut", [["get", "reservations", ["loc", [null, [91, 37], [91, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "goToReservation", "goToReservation", "cgView", ["subexpr", "@mut", [["get", "cgView", ["loc", [null, [93, 33], [93, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "onlineReservations", ["subexpr", "@mut", [["get", "onlineReservations", ["loc", [null, [94, 45], [94, 63]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [89, 20], [95, 22]]], 0, 0]],
            locals: ["campsite"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 86,
                "column": 12
              },
              "end": {
                "line": 97,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "cs-row");
            var el2 = dom.createElement("h4");
            var el3 = dom.createTextNode("No match");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "campsites.not_match", ["loc", [null, [88, 24], [88, 43]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [88, 16], [96, 25]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 8
            },
            "end": {
              "line": 98,
              "column": 8
            }
          },
          "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "campsites.extra_match", ["loc", [null, [61, 18], [61, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [61, 12], [71, 19]]]], ["block", "if", [["get", "campsites.partial_match", ["loc", [null, [73, 18], [73, 41]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [73, 12], [84, 19]]]], ["block", "if", [["get", "campsites.not_match", ["loc", [null, [86, 18], [86, 37]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [86, 12], [97, 19]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 101,
            "column": 6
          }
        },
        "moduleName": "cn-front/templates/components/ava-calendar/calendar.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "availability-calendar");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "description-statuses");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "icon-available resinfo");
        dom.setAttribute(el4, "id", "15534");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Available");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "icon-reserved resinfo");
        dom.setAttribute(el4, "id", "15534");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Reserved");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "icon-fcfs resinfo");
        dom.setAttribute(el4, "id", "15534");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("First Come/First Served");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "icon-out-of-season resinfo");
        dom.setAttribute(el4, "id", "15534");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Out Of Season");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "icon-maint resinfo");
        dom.setAttribute(el4, "id", "15534");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Maintenance");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ava-header");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "calendar-dates");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "ava-calendar-back");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "fa fa-chevron-left");
        dom.setAttribute(el5, "aria-hidden", "true");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "ava-calendar-next");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "fa fa-chevron-right");
        dom.setAttribute(el5, "aria-hidden", "true");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ava-content");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [2]);
        var element3 = dom.childAt(element2, [5]);
        var element4 = dom.childAt(element3, [3]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element4, [5]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createAttrMorph(element2, 'class');
        morphs[2] = dom.createMorphAt(element2, 1, 1);
        morphs[3] = dom.createMorphAt(element3, 1, 1);
        morphs[4] = dom.createElementMorph(element5);
        morphs[5] = dom.createMorphAt(element4, 3, 3);
        morphs[6] = dom.createElementMorph(element6);
        morphs[7] = dom.createMorphAt(dom.childAt(element2, [7]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "cgView", ["loc", [null, [1, 6], [1, 12]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [5, 7]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "cgView", ["loc", [null, [7, 44], [7, 50]]], 0, 0, 0, 0], "full-cs-view"], [], ["loc", [null, [7, 39], [7, 67]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "not", [["get", "cgView", ["loc", [null, [9, 15], [9, 21]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 10], [9, 22]]], 0, 0]], [], 1, null, ["loc", [null, [9, 4], [16, 11]]]], ["block", "if", [["get", "cgView", ["loc", [null, [27, 14], [27, 20]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [27, 8], [42, 15]]]], ["element", "action", ["prev"], [], ["loc", [null, [44, 46], [44, 64]]], 0, 0], ["block", "each", [["get", "headerDates", ["loc", [null, [45, 20], [45, 31]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [45, 12], [47, 21]]]], ["element", "action", ["next"], [], ["loc", [null, [48, 46], [48, 64]]], 0, 0], ["block", "if", [["get", "singleCampsite", ["loc", [null, [52, 14], [52, 28]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [52, 8], [98, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});