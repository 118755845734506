define('cn-front/controllers/dashboard/users/new', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    /*globals flashMessages*/
    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        currentUser: inject.service('current-user'),
        ustates: inject.service('ustates'),
        registrationEmail: '',
        emailValid: false,
        creationType: 'CN',

        statesList: (function () {
            var array = $.map(this.get('ustates').data, function (value) {
                return [value];
            });
            return array.slice(0, -2);
        }).property(),

        checkEmail: (function () {
            if ($('#add-new-user').length) {
                _ember['default'].run.debounce(this, this.lookupCamper, 1000);
            }
        }).observes('registrationEmail').on('init'),

        lookupCamper: function lookupCamper() {
            var url = this.get('apiConnect').getUrl('/lookup');
            var self = this;
            $.ajax({
                type: "POST",
                url: url,
                data: $('#add-new-user').serializeJSON(),
                success: function success(data) {
                    if (typeof data.error === 'undefined') {
                        $('.email-validation').html('');
                        $('#add-new-user [name="email"]').addClass('valid_email');
                        self.set('emailValid', true);
                    } else {
                        $('.email-validation').html(data.error.email[0]);
                        $('#add-new-user [name="email"]').removeClass('valid_email');
                        self.set('emailValid', false);
                    }
                },
                error: function error(data) {
                    $('.modal').animate({
                        scrollTop: 0
                    }, 400);
                    $.each(data.responseJSON, function (key, value) {
                        flashMessages.danger(value[0]);
                    });
                }
            });
        },

        actions: {
            create: function create() {
                var self = this;
                var flashMessages = _ember['default'].get(this, 'flashMessages');

                if (self.get('emailValid')) {
                    var url = this.get('apiConnect').getUrl('/dashboard/users/create');

                    var data = $('#add-new-user').serializeArray();
                    data.push({
                        name: "create_method",
                        value: self.get('creationType')
                    });

                    $.ajax({
                        type: "post",
                        url: url,
                        beforeSend: function beforeSend(xhr) {
                            xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                        },
                        data: data,
                        success: function success(data) {
                            flashMessages.success(data.success);

                            if (data.user.id) {
                                self.transitionToRoute('dashboard.user.view', data.user.id);
                            }
                        },
                        error: function error(data) {
                            if (data.responseJSON) {
                                if (data.responseJSON.error) {
                                    flashMessages.danger(data.responseJSON.error);
                                } else {
                                    $.each(data.responseJSON, function (key, value) {
                                        flashMessages.danger(value[0]);
                                    });
                                }
                                $('html, body').animate({ scrollTop: 0 }, 'slow');
                            }
                        }
                    });
                }
            }
        }
    });
});