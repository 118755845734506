define('cn-front/routes/dashboard/reports/reservations', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        thisController: function thisController() {
            return this.controllerFor('dashboard/reports/reservations');
        },

        queryParams: {
            search: {
                refreshModel: true
            },
            page: {
                refreshModel: true
            }
        },
        beforeModel: function beforeModel() {
            var self = this;
            var acctMgrUrl = this.get('apiConnect').getUrl('/dashboard/users/acct_mgr');
            $.ajax({
                type: "GET",
                url: acctMgrUrl,
                async: false,
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                success: function success(data) {
                    self.controllerFor(self.routeName).set('acct_mgr', data);
                }
            });

            var affiliates = _ember['default'].$.ajax({
                type: "GET",
                url: self.get('apiConnect').getUrl('/dashboard/campgrounds/affiliates'),
                async: false,
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                }
            });

            // wait until "this" slider is rendered before continuing on.
            _ember['default'].run(function () {
                affiliates.then(function (data) {
                    self.controllerFor(self.routeName).set('affiliates', data);
                });
            });
        },
        model: function model(params) {

            var ctrlData = this.thisController();
            var reportUrl = this.get('apiConnect').getUrl('/dashboard/reports/reservations');
            var totalResUrl = this.get('apiConnect').getUrl('/dashboard/reports/reservations/total');
            var acctmgrEmail = $('#report-acctmgr').val();
            var affiliate = $('#report-affiliate').val();

            // Response object
            var response = {
                totalres: false,
                reports: false,
                startDate: ctrlData.sdate,
                endDate: ctrlData.edate
            };

            $.ajax({
                type: "GET",
                url: totalResUrl,
                data: {
                    'sdate': ctrlData.sdate,
                    'edate': ctrlData.edate,
                    'statuses': ctrlData.statuses,
                    'types': ctrlData.types,
                    'acct_mgr': acctmgrEmail,
                    'affiliate': affiliate
                },
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                success: function success(data) {
                    response['totalres'] = data;
                }
            });

            $.ajax({
                type: "GET",
                url: reportUrl,
                data: {
                    'page': params.page,
                    'search': params.search,
                    'sdate': ctrlData.sdate,
                    'edate': ctrlData.edate,
                    'statuses': ctrlData.statuses,
                    'types': ctrlData.types,
                    'acct_mgr': acctmgrEmail,
                    'affiliate': affiliate,
                    'orderBy': ctrlData.orderBy,
                    'sort': ctrlData.sort
                },
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                success: function success(data) {
                    response['reports'] = data;
                }
            });

            return response;
        },

        onInit: (function () {

            _ember['default'].run.schedule('afterRender', this, function () {

                // Initialize datepicker
                $('#report-start-date').datepicker();
                $('#report-start-date').datepicker('setDate', new Date());

                this.thisController().resetTypesCheckboxes();
                this.thisController().resetStatusesCheckboxes();
            });
        }).on('activate'),

        onOut: (function () {
            this.thisController().restoreDefaults();
        }).on('deactivate'),

        actions: {
            applySort: function applySort(slug) {
                var ctrlData = this.thisController();
                var sortHeader = $('.order-source.' + slug);
                sortHeader.removeClass('asc desc');
                var newOrder = 'ASC';

                if (sortHeader.hasClass('active-sort')) {
                    newOrder = ctrlData.get('sort') == 'ASC' ? 'DESC' : 'ASC';
                } else {
                    $('.order-source.active-sort').removeClass('active-sort asc desc');
                    sortHeader.addClass('active-sort');
                    ctrlData.set('orderBy', slug);
                }
                ctrlData.set('sort', newOrder);
                sortHeader.addClass(newOrder.toLowerCase());
                this.refresh();
            },
            refreshModel: function refreshModel() {
                //display message in the console of your browser
                _ember['default'].Logger.log('Route is now refreshing...');
                //refresh the model
                this.refresh();
            }
        }
    });
});