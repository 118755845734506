define("cn-front/templates/dashboard/calendar/change-minstay", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 8
              },
              "end": {
                "line": 10,
                "column": 79
              }
            },
            "moduleName": "cn-front/templates/dashboard/calendar/change-minstay.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Campsite Minimum Stay: ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "textValue", ["loc", [null, [10, 62], [10, 75]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 8
              },
              "end": {
                "line": 11,
                "column": 94
              }
            },
            "moduleName": "cn-front/templates/dashboard/calendar/change-minstay.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Campsite Minimum Stay ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(": ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["content", "computedType", ["loc", [null, [11, 59], [11, 75]]], 0, 0, 0, 0], ["content", "textValue", ["loc", [null, [11, 77], [11, 90]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 8
              },
              "end": {
                "line": 12,
                "column": 91
              }
            },
            "moduleName": "cn-front/templates/dashboard/calendar/change-minstay.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Campsite Minimum Stay Override: ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "textValue", ["loc", [null, [12, 74], [12, 87]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 8
              },
              "end": {
                "line": 40,
                "column": 8
              }
            },
            "moduleName": "cn-front/templates/dashboard/calendar/change-minstay.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createTextNode("Clear the field contents to make this change for ONLY the date above, or enter a date to make this change\n                from the date above to the date specified below.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("label");
            dom.setAttribute(el1, "class", "date-range ");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2, "type", "text");
            dom.setAttribute(el2, "name", "end_date");
            dom.setAttribute(el2, "placeholder", "End date");
            dom.setAttribute(el2, "class", "form-control");
            dom.setAttribute(el2, "id", "dpicker");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-calendar");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 45,
              "column": 0
            }
          },
          "moduleName": "cn-front/templates/dashboard/calendar/change-minstay.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("You are editing the\n        Campsite minimum stay: ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(".\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Select the value desired from the dropdown.");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        Campground: ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("br");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("form");
          dom.setAttribute(el1, "id", "change-minstay");
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2, "type", "hidden");
          dom.setAttribute(el2, "name", "start_date");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2, "type", "hidden");
          dom.setAttribute(el2, "name", "type");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "form-group");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "form-group");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("Make these changes to:");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("select");
          dom.setAttribute(el3, "name", "target");
          dom.setAttribute(el3, "class", "form-control");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("option");
          dom.setAttribute(el4, "value", "");
          var el5 = dom.createTextNode("This campsite (");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(")");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("option");
          dom.setAttribute(el4, "value", "shelters");
          var el5 = dom.createTextNode("All campsites with this Shelter Type (");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(")");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("option");
          dom.setAttribute(el4, "value", "all");
          var el5 = dom.createTextNode("All campsites");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var element4 = dom.childAt(element1, [7, 3]);
          var morphs = new Array(11);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(element0, 8, 8);
          morphs[2] = dom.createMorphAt(element0, 11, 11);
          morphs[3] = dom.createMorphAt(element0, 13, 13);
          morphs[4] = dom.createMorphAt(element0, 15, 15);
          morphs[5] = dom.createAttrMorph(element2, 'value');
          morphs[6] = dom.createAttrMorph(element3, 'value');
          morphs[7] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
          morphs[8] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
          morphs[9] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
          morphs[10] = dom.createMorphAt(element1, 9, 9);
          return morphs;
        },
        statements: [["content", "computedType", ["loc", [null, [6, 31], [6, 47]]], 0, 0, 0, 0], ["content", "model.cg.general.reservation_min_duration", ["loc", [null, [9, 20], [9, 65]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "model.type", ["loc", [null, [10, 18], [10, 28]]], 0, 0, 0, 0], "daily"], [], ["loc", [null, [10, 14], [10, 37]]], 0, 0]], [], 0, null, ["loc", [null, [10, 8], [10, 86]]]], ["block", "if", [["subexpr", "eq", [["get", "model.type", ["loc", [null, [11, 18], [11, 28]]], 0, 0, 0, 0], "dow"], [], ["loc", [null, [11, 14], [11, 35]]], 0, 0]], [], 1, null, ["loc", [null, [11, 8], [11, 101]]]], ["block", "if", [["subexpr", "eq", [["get", "model.type", ["loc", [null, [12, 18], [12, 28]]], 0, 0, 0, 0], "override"], [], ["loc", [null, [12, 14], [12, 40]]], 0, 0]], [], 2, null, ["loc", [null, [12, 8], [12, 98]]]], ["attribute", "value", ["concat", [["get", "model.data.date", ["loc", [null, [16, 56], [16, 71]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["concat", [["get", "model.type", ["loc", [null, [17, 50], [17, 60]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "in-days", [], ["val", ["subexpr", "@mut", [["get", "value", ["loc", [null, [20, 26], [20, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "name", "duration", "withEmpty", true], ["loc", [null, [20, 12], [20, 64]]], 0, 0], ["content", "model.campsite.name", ["loc", [null, [26, 48], [26, 71]]], 0, 0, 0, 0], ["content", "model.campsite.shelter.name", ["loc", [null, [27, 79], [27, 110]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "model.type", ["loc", [null, [33, 18], [33, 28]]], 0, 0, 0, 0], "override"], [], ["loc", [null, [33, 14], [33, 40]]], 0, 0]], [], 3, null, ["loc", [null, [33, 8], [40, 15]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 13
          }
        },
        "moduleName": "cn-front/templates/dashboard/calendar/change-minstay.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "bs-modal", [], ["title", ["subexpr", "@mut", [["get", "title", ["loc", [null, [1, 18], [1, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "okLabel", "Save", "closeLabel", "Cancel", "ok", "confirm", "close", "", "finishClose", "removeModal", "id", "calendar-gen-modal"], 0, null, ["loc", [null, [1, 0], [45, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});