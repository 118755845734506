define('cn-front/controllers/dashboard/campsite/edit/photos', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        currentRoute: 'dashboard.campsite.edit.photos',

        photos: _ember['default'].computed.alias('model.campsite.photos'),
        sortPhotos: (function () {
            this.get('photos').sort(function (a, b) {
                return b.order - a.order;
            });
        }).observes('photos'),

        onInit: (function () {

            var flashMessages = _ember['default'].get(this, 'flashMessages');

            var uploadUrl = this.get('apiConnect').getUrl('/dashboard/images/upload/campsites/' + this.get('model.campsite.id'));
            var destroyUrl = this.get('apiConnect').getUrl('/dashboard/images/delete/campsites/uuid');

            _ember['default'].run.schedule('afterRender', this, function () {
                console.log('initing');
                var me = this;
                var uploader = $('#fine-uploader-manual-trigger');

                uploader.fineUploader({
                    template: $('#qq-template-manual-trigger'),
                    request: {
                        customHeaders: {
                            'Authorization': 'bearer ' + $.cookie('token')
                        },
                        inputName: 'files',
                        endpoint: uploadUrl
                    },
                    deleteFile: {
                        enabled: false,
                        forceConfirm: true,
                        endpoint: destroyUrl,
                        customHeaders: {
                            'Authorization': 'bearer ' + $.cookie('token')
                        }
                    },
                    cors: {
                        expected: true
                    },
                    autoUpload: false,
                    validation: {
                        allowedExtensions: ['jpeg', 'jpg', 'png']
                    },
                    callbacks: {
                        onAllComplete: function onAllComplete(succeeded, failed) {
                            if (failed.length === 0) {
                                flashMessages.success('All files uploaded successfully.');
                                me.send('refreshModel');
                                _ember['default'].run.later(function () {
                                    $('.qq-upload-list .qq-upload-success').remove();
                                }, 1000);
                            }
                        },
                        onError: function onError(id, name, errorReason) {
                            flashMessages.danger(name + ' - ' + errorReason);
                        }
                    }
                });

                $('.template-wrapp #qq-template-manual-trigger').hide();

                $(document).off('click', '#trigger-upload').on('click', '#trigger-upload', function () {
                    uploader.fineUploader('uploadStoredFiles');
                });

                var photos = this.get('photos');

                $(document).off('click', '.order-up').on('click', '.order-up', function (e) {
                    e.preventDefault();
                    var order = $(this).next('.order');
                    var photoId = order.closest('tr').attr('data-photo-id');

                    var photo = photos.find(function (x) {
                        return x.id == photoId;
                    });
                    var newOrder = parseInt(photo.order) + 1;
                    _ember['default'].set(photo, 'order', newOrder);
                    me.propertyDidChange('photos');
                    me.saveOrder(photoId, newOrder);
                });

                $(document).off('click', '.order-down').on('click', '.order-down', function (e) {
                    e.preventDefault();
                    var order = $(this).prev('.order');
                    var photoId = order.closest('tr').attr('data-photo-id');

                    var photo = photos.find(function (x) {
                        return x.id == photoId;
                    });
                    var newOrder = parseInt(photo.order) - 1;
                    if (newOrder >= 0) {
                        _ember['default'].set(photo, 'order', newOrder);
                        me.propertyDidChange('photos');
                        me.saveOrder(photoId, newOrder);
                    }
                });
            });
        }).observes('model'),

        saveOrder: function saveOrder(photoId, order) {
            var self = this;
            var url = self.get('apiConnect').getUrl('/dashboard/images/update_order/campsites/' + photoId);

            $.ajax({
                type: "POST",
                url: url,
                data: { order: order },
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                success: function success(data) {
                    console.log(data);
                }
            });
        }
    });
});