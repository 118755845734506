define('cn-front/routes/campground/info-request', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            var model = this.modelFor('campground').campground;
            if (model.reservation_mode != 'information_request') {
                console.log('Info request disabled for this campground');
                this.transitionTo('404', '404');
            }
            return model;
        },

        activate: function activate() {
            _ember['default'].run.schedule('afterRender', this, function () {
                $('.green-header .next-btn').hide();
            });
        }
    });
});