define('cn-front/services/reservation/stepster', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        ready: '',
        fallback: '',
        onSuccessReservation: '',
        currentStep: null,
        finishedSteps: null,
        stepsTotal: null,
        init: function init() {
            this.reset();
        },
        reset: function reset() {
            this.set('ready', false);
            this.set('finishedSteps', 0);
            this.set('fallback', function () {});
            this.set('currentStep', 1);
        },

        nextStep: function nextStep() {
            if (this.get('ready')) {
                var step = this.get('currentStep');
                step++;
                if (this.get('stepsTotal') >= step) {
                    this.set('currentStep', step);
                } else {
                    this.onSuccessReservation();
                }
            } else {
                this.fallback();
            }
        },

        prevStep: function prevStep() {
            var step = this.get('currentStep');
            if (step > 1) {
                step--;
                this.set('currentStep', step);
            }
        },

        onStepChange: (function () {
            var step, next, back, cancel;
            step = this.get('currentStep');
            back = $('.green-header .back-btn');
            next = $('.green-header .next-btn');
            cancel = $('.section-title .cancel');

            if (step == 1) {
                back.hide();
            } else {
                next.show();
                if (this.get('stepsTotal') != step) {
                    $('.section-title .cancel').show();
                    back.show();
                    next.html('Next <i class="fa fa-chevron-right" aria-hidden="true"></i>');
                }
            }

            if (this.get('stepsTotal') == step) {
                //last step
                next.html('FINISH');
                if (this.get('stepsTotal') == this.get('finishedSteps')) {
                    //last success
                    back.hide();
                    cancel.hide();
                    next.attr("disabled", false);
                } else {
                    //last field
                    next.attr("disabled", true);
                }
            }
        }).observes('currentStep'),

        onReadyChange: (function () {
            var next = $('.green-header .next-btn');
            return this.get('ready') ? next.attr("disabled", false) : next.attr("disabled", true);
        }).observes('ready')
    });
});