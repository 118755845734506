define('cn-front/initializers/user-browser-location', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'browser-location', 'service:user-browser-location');
    application.inject('component', 'browser-location', 'service:user-browser-location');
    application.inject('controller', 'browser-location', 'service:user-browser-location');
  }

  exports['default'] = {
    name: 'user-browser-location',
    initialize: initialize
  };
});