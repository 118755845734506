define('cn-front/controllers/dashboard/campground/edit/loops', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        sortedLoops: (function () {
            var loops = this.get('model.loops');
            return loops.sort(function (a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
        }).property('model')
    });
});