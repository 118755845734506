define('cn-front/routes/dashboard/campground/edit/loops', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        actions: {
            deleteLoop: function deleteLoop(loop) {
                //loop.campground_id = this.modelFor('dashboard.campground.edit').general.id;
                //loop.campground_name = this.modelFor('dashboard.campground.edit').general.name;
                this.send('showModal', 'dashboard.campground.edit.modal.delete-loop', loop);
            },
            editLoop: function editLoop(loop) {
                //loop.campground_id = this.modelFor('dashboard.campground.edit').general.id;
                //loop.campground_name = this.modelFor('dashboard.campground.edit').general.name;
                this.send('showModal', 'dashboard.campground.edit.modal.edit-loop', loop);
            },
            addLoop: function addLoop() {

                var formdata = $('form#add-loop').serializeJSON();
                console.log(formdata);

                var url = this.get('apiConnect').getUrl('/dashboard/campgrounds/loop/create');
                var self = this;
                var flashMessages = _ember['default'].get(self, 'flashMessages');
                $.ajax({
                    type: "POST",
                    url: url,
                    data: formdata,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        console.log(data);
                        if (data.success) {
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                        } else {
                            flashMessages.danger(_ember['default'].String.htmlSafe(data.error));
                        }
                        self.send('refreshModel');
                    }

                });
            }

        }
    });
});