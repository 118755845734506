define('cn-front/controllers/dashboard/user/edit', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        currentUser: inject.service('current-user'),
        ustates: inject.service('ustates'),
        emailValid: false,

        statesList: (function () {
            var array = $.map(this.get('ustates').data, function (value) {
                return [value];
            });
            return array.slice(0, -2);
        }).property(),

        checkEmail: (function () {
            var self = this;
            if (this.get('model.email') == '') {
                // jshint ignore:line
                $('.email-validation').html('Email address is required');
                $('#user-info [name="email"]').removeClass('valid_email');
                self.set('emailValid', false);
            } else {
                $('.email-validation').html('');
                $('#user-info [name="email"]').addClass('valid_email');
                self.set('emailValid', true);
            }
        }).observes('model.email').on('init'),

        actions: {
            update: function update() {
                var self = this;
                var flashMessages = _ember['default'].get(this, 'flashMessages');

                var url = this.get('apiConnect').getUrl('/dashboard/user/' + this.get('model.id') + '/update');
                $.ajax({
                    type: "put",
                    url: url,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    data: $('#user-info').serializeJSON(),
                    success: function success(data) {
                        flashMessages.success(data.success);

                        self.transitionToRoute('dashboard.user.view', self.get('model.id'));
                    },
                    error: function error(data) {
                        flashMessages.danger(data.responseJSON.error);
                    }
                });
            }
        }
    });
});