define('cn-front/controllers/dashboard/campsite/edit/modal/set-photo', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        actions: {
            setPhoto: function setPhoto() {
                var self = this,
                    model = self.get('model');
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                var url = self.get('apiConnect').getUrl('/dashboard/campgrounds/' + model.campground.id + '/update-thumbnail/' + model.id);
                $.ajax({
                    type: "post",
                    url: url,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    success: function success(data) {
                        if (data.error) {
                            flashMessages.danger(data.error);
                        } else {
                            flashMessages.success(data.success);
                        }
                    },
                    error: function error(data) {
                        flashMessages.danger(data.error);
                    }
                });
                this.send('removeModal');
            }
        }
    });
});