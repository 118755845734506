define('cn-front/components/featured-cgs', ['exports', 'ember'], function (exports, _ember) {

  var inject = _ember['default'].inject;

  exports['default'] = _ember['default'].Component.extend({
    ustates: inject.service(),
    currentUser: inject.service('current-user'),
    geolocation: inject.service(),
    featuredId: '',
    featured: [],
    isEmpty: true,
    distanceLat: 0,
    distanceLong: 0,
    userDistance: true,
    limit: null,
    source: null,
    type: 'featured_card', /* one of [featured_card, similar_card] */

    didReceiveAttrs: function didReceiveAttrs() {
      if (_ember['default'].testing) {
        return;
      }
      switch (this.get('centerBy')) {
        case 'user':
          this.getUserLocation();
          break;
        case 'state':
          var fullStateName = this.get('ustates.data')[this.get('state').toUpperCase()].name;
          this.set('fullStateName', fullStateName);
          this.getCampgroundsInLocation(this.get('state'));
          break;
        case 'city':
          this.getCampgroundsInLocation(this.get('city'));
          break;
        case 'cg':
          this.getCampgroundsNear();
          break;
        default:
          this.getCampgrounds();
      }

      this.set('featuredId', this.get('elementId'));
    },

    getCampgrounds: function getCampgrounds() {
      var _this = this;

      var url = '/campgrounds/featured';
      url = this.get('limit') ? url + '/' + this.get('limit') : url;
      if (this.get('distanceLat') !== 0 && this.get('distanceLong') !== 0) {
        url += '?latitude=' + this.get('distanceLat') + '&longitude=' + this.get('distanceLong');
      }

      this.get('api').sendReq({ type: 'GET', url: url }).then(function (data) {
        if (data.length) {
          _this.set('featured', data);
          _this.set('isEmpty', false);
        } else {
          _this.set('isEmpty', true);
        }
      });
    },

    /**
     * Get user position either by:
     * - user address (from account)
     * - browser geolocation
     * - or geolocation by user's IP
     */
    getUserLocation: function getUserLocation() {
      var _this2 = this;

      this.get('browser-location').getLocation().then(function (geoObject) {
        _this2.setFoundUserLocation(geoObject.coords.latitude, geoObject.coords.longitude);
      }, function (error) {
        // jshint ignore:line
        if (_this2.get('currentUser.logged') && _this2.get('currentUser').user.latitude !== null && parseInt(_this2.get('currentUser').user.latitude) > 0) {
          var user = _this2.get('currentUser').user;
          _this2.setFoundUserLocation(user.latitude, user.longitude);
        } else {
          _this2.get('api').sendReq({ url: "/geolocations", type: "GET" }).then(function (data) {
            if (data && data.lat !== '') {
              _this2.setFoundUserLocation(data.lat, data.lon);
            } else {
              _this2.setFoundUserLocation(47, -122);
            }
          });
        }
      });
    },

    /**
     * Set user location
     */
    setFoundUserLocation: function setFoundUserLocation(latitude, longitude) {
      this.set('distanceLat', latitude);
      this.set('distanceLong', longitude);

      // this.set('distanceLat', 47);
      // this.set('distanceLong', -122);

      // var url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latitude + "," + longitude + "&key=AIzaSyDG9UtSuq19xaNOJwwg4j0P54UNslSDaTk";
      // var p = new Ember.RSVP.Promise(function (resolve, reject) {
      //     Ember.$.ajax({type: "GET", url: url}).done(
      //         (response) => resolve(response)).fail(
      //         (reason) => reject(reason));
      // });
      //
      // p.then((data) => {
      //     //if no state setted, use state of user
      //     if (this.get('state') === undefined) {
      //         if (typeof data.results !== undefined && data.results.length) {
      //             for (let i = 0; i < data.results.length; i++) {
      //                 if (data.results[i].types.length) {
      //                     for (let k = 0; k < data.results[i].types.length; k++) {
      //                         if (data.results[i].types[k] === 'administrative_area_level_1') {
      //                             this.set('state', data.results[i].address_components[0].short_name);
      //                         }
      //                     }
      //                 }
      //             }
      //         }
      //         //if state name not valid set default
      //         var states = this.get('ustates').data;
      //         if (this.get('state') === '' || states[this.get('state')] === undefined) {
      //             this.set('state', 'WA');
      //         }
      //     }

      this.getCampgrounds();
      // });
    },

    getCampgroundsInLocation: function getCampgroundsInLocation(location) {
      var _this3 = this;

      this.get('api').sendReq({
        type: "GET",
        url: "https://maps.google.com/maps/api/geocode/json?sensor=false&address=" + encodeURIComponent(location)
      }, true).then(function (data) {
        var location = data.results[0].geometry.location;
        _this3.set('distanceLat', location.lat);
        _this3.set('distanceLong', location.lng);
        _this3.getCampgrounds();
      });
    },
    getCampgroundsNear: function getCampgroundsNear() {
      this.set('distanceLat', this.get('cg.latitude'));
      this.set('distanceLong', this.get('cg.longitude'));
      this.getCampgrounds();
    }
  });
});