define('cn-front/routes/dashboard/campground/edit/email', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    /*globals Switchery*/

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),

        onInit: (function () {
            _ember['default'].run.schedule('afterRender', this, function () {
                var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
                elems.forEach(function (html) {
                    new Switchery(html, { size: 'small' });
                });
            });
        }).on('activate')
    });
});