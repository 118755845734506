define('cn-front/routes/reservation', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        reservation: _ember['default'].inject.service('reservation/instance'),

        model: function model(params) {
            var _this = this;

            /**
             * Reset everything to defaults
             */
            this.get('reservation').clearAll();
            this.set('reservation.type', 'front');

            var self = this;
            var reservation = _ember['default'].$.ajax({
                type: "GET",
                url: this.get('api').getUrl('/reservations/get/' + params.id),
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                }
            });

            reservation.then(function (data) {
                self.set('reservation.model', data);

                /**
                 * Compute duration based on reservation dates
                 */
                var sd = _this.get('reservation.model.date_arrival');
                var ed = _this.get('reservation.model.date_departure');
                sd = moment(sd, 'YYYY-MM-DD');
                ed = moment(ed, 'YYYY-MM-DD');

                var days = ed.diff(sd, 'days');
                _this.set('reservation.days', days);
                _this.get('reservation').calculateDuration('model');
            });

            return reservation;
        }
    });
});