define('cn-front/components/camp-owners/list-land-form', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
    exports['default'] = _ember['default'].Component.extend({
        ready: false,

        actions: {
            send: function send() {
                var _this = this;

                var flashMessages = _ember['default'].get(this, 'flashMessages');
                this.get('api').sendReq({
                    type: "POST",
                    url: '/contact-form',
                    data: $('#cgowners-form').serializeJSON()
                }).then(function () {
                    _cnFrontUtilsGtm['default'].trackEvent({
                        'event': 'list_property_form_submit'
                    });
                    _this.set('ready', true);
                }, function (data) {
                    $.each(data.responseJSON, function (key, value) {
                        flashMessages.danger(value[0]);
                    });
                    $('html,body').animate({
                        scrollTop: 0
                    }, 400);
                });
            }
        }
    });
});