define('cn-front/components/dashboard/reports/transactions/req-form', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Component.extend({

        apiConnect: inject.service('api-resolver'),

        didInsertElement: function didInsertElement() {
            var self = this;

            var acct_mgr = _ember['default'].$.ajax({
                type: "GET",
                url: this.get('apiConnect').getUrl('/dashboard/users/acct_mgr'),
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                }
            });

            var affiliates = _ember['default'].$.ajax({
                type: "GET",
                url: self.get('apiConnect').getUrl('/dashboard/campgrounds/affiliates'),
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                }
            });

            acct_mgr.then(function (data) {
                self.set('acct_mgr', data);
            });
            affiliates.then(function (data) {
                self.set('affiliates', data);
            });
        },

        actions: {
            getReportsAct: function getReportsAct() {
                console.log('click');
                this.sendAction('getReports');
            }
        }
    });
});