define('cn-front/mixins/bodyclass', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        activate: function activate() {
            this._super.apply(this, arguments); // Call super
            var cssClass = this.toCssClass();
            // you probably don't need the application class
            // to be added to the body
            if (cssClass != 'application') {
                _ember['default'].$('body').addClass(cssClass);
            }
        },
        deactivate: function deactivate() {
            this._super.apply(this, arguments); // Call super
            _ember['default'].$('body').removeClass(this.toCssClass());
        },
        toCssClass: function toCssClass() {
            this._super.apply(this, arguments); // Call super
            return this.routeName.replace(/\./g, '-').dasherize();
        }
    });
});