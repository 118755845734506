define('cn-front/controllers/dashboard/campground/edit/ginfo', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        cgdata: inject.service('dashboard/cg-fields'),
        apiConnect: inject.service('api-resolver'),
        ustates: inject.service('ustates'),
        currentHost: (function () {
            return window.location.protocol + '//' + window.location.host;
        }).property('currentPath'),

        properties: {},

        statesList: (function () {
            var array = $.map(this.get('ustates').data, function (value) {
                return [value];
            });
            return array.slice(0, -2);
        }).property(),

        onInit: (function () {
            this.set('properties', this.get('cgdata.fields'));
        }).on('init')
    });
});