define('cn-front/routes/seo/country', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        title: 'Campgrounds in the USA',
        model: function model() {
            return _ember['default'].$.getJSON(this.get('api').getUrl('/states/data'));
        },

        setupController: function setupController(controller, model) {
            var row = [],
                i = 1,
                res = [];
            model.map(function (s) {
                if (i <= 4) {
                    i++;
                    row.push(s);
                } else {
                    res.push(row);
                    row = [];
                    i = 1;
                }
            });
            controller.set('model', res);
        },

        toTheTop: (function () {
            _ember['default'].run.schedule('afterRender', this, function () {
                $('html,body').animate({
                    scrollTop: 0
                }, 200);
            });
        }).on('model')
    });
});