define('cn-front/routes/dashboard/campground/edit/maps', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),

        onInit: (function () {

            var flashMessages = _ember['default'].get(this, 'flashMessages');

            var uploadUrl = this.get('apiConnect').getUrl('/dashboard/maps/upload/campgrounds/' + this.modelFor('dashboard.campground.edit').general.id);
            var destroyUrl = this.get('apiConnect').getUrl('/dashboard/maps/delete/campgrounds/uuid');

            _ember['default'].run.schedule('afterRender', this, function () {
                var me = this;
                var uploader = $('#fine-uploader-manual-trigger');

                uploader.fineUploader({
                    template: $('#qq-template-manual-trigger'),
                    request: {
                        customHeaders: {
                            'Authorization': 'bearer ' + $.cookie('token')
                        },
                        inputName: 'files',
                        endpoint: uploadUrl
                    },
                    deleteFile: {
                        enabled: false,
                        forceConfirm: true,
                        endpoint: destroyUrl,
                        customHeaders: {
                            'Authorization': 'bearer ' + $.cookie('token')
                        }
                    },
                    cors: {
                        expected: true
                    },
                    autoUpload: false,
                    validation: {
                        allowedExtensions: ['jpeg', 'jpg', 'png']
                    },
                    callbacks: {
                        onAllComplete: function onAllComplete(succeeded, failed) {
                            if (failed.length === 0) {
                                flashMessages.success('All files uploaded successfully.');
                                me.send('refreshModel');
                            }
                        },
                        onError: function onError(id, name, errorReason) {
                            flashMessages.danger(name + ' - ' + errorReason);
                        }
                    }
                });

                $('.template-wrapp #qq-template-manual-trigger').hide();

                $(document).off('click', '#trigger-upload').on('click', '#trigger-upload', function () {
                    uploader.fineUploader('uploadStoredFiles');
                });

                $(document).off('click', '.order-up').on('click', '.order-up', function (e) {
                    e.preventDefault();
                    var order = $(this).next('.order');
                    var photoId = order.closest('tr').attr('data-map-id');
                    var orderNumber = parseInt(order.html());
                    var newOrder = orderNumber + 1;
                    order.html(newOrder);
                    me.sortTable();
                    me.saveOrder(photoId, newOrder);
                });

                $(document).off('click', '.order-down').on('click', '.order-down', function (e) {
                    e.preventDefault();
                    var order = $(this).prev('.order');
                    var photoId = order.closest('tr').attr('data-map-id');
                    var orderNumber = parseInt(order.html());
                    var newOrder = orderNumber - 1;
                    if (newOrder >= 0) {
                        order.html(newOrder);
                        me.sortTable();
                        me.saveOrder(photoId, newOrder);
                    }
                });
            });
        }).on('activate'),

        sortTable: function sortTable() {
            var tbl = document.getElementsByClassName("table")[0].tBodies[0];
            var store = [],
                i,
                len;
            for (i = 0, len = tbl.rows.length; i < len; i++) {
                var row = tbl.rows[i];
                var sortnr = parseFloat(row.cells[4].textContent || row.cells[4].innerText);
                if (!isNaN(sortnr)) {
                    store.push([sortnr, row]);
                }
            }
            store.sort(function (x, y) {
                return y[0] - x[0];
            });
            for (i = 0, len = store.length; i < len; i++) {
                tbl.appendChild(store[i][1]);
            }
            store = null;
        },

        saveOrder: function saveOrder(photoId, order) {
            var self = this;
            var url = self.get('apiConnect').getUrl('/dashboard/maps/update_order/campgrounds/' + photoId);

            $.ajax({
                type: "POST",
                url: url,
                data: { order: order },
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                success: function success(data) {
                    console.log(data);
                }
            });
        },

        leavingRoute: (function () {

            var self = this;
            _ember['default'].run.later(function () {
                self.send('refreshModel');
            }, 500);
        }).on('deactivate'),

        actions: {
            editMap: function editMap(map) {
                map.camp_id = this.modelFor('dashboard.campground.edit').general.id;
                this.send('showModal', 'dashboard.campground.edit.modal.edit-map', map);
            },
            deleteMap: function deleteMap(map) {
                map.camp_id = this.modelFor('dashboard.campground.edit').general.id;
                this.send('showModal', 'dashboard.campground.edit.modal.delete-map', map);
            }
        }
    });
});