define('cn-front/routes/dashboard/campground/edit/seasons', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),

        onActivate: (function () {
            _ember['default'].run.schedule('afterRender', this, function () {
                var params = {
                    format: 'm/d/yyyy',
                    orientation: "bottom left",
                    autoclose: true,
                    clearBtn: true
                };

                $('#season_open.input-group.date').datepicker(params);
                $('#season_closed.input-group.date').datepicker(params);
            });
        }).on('activate'),

        actions: {
            editSeason: function editSeason(season) {
                this.send('showModal', 'dashboard.campground.edit.modal.edit-season', {
                    id: season.id,
                    season_open: moment(season.season_open).format("M/D/YYYY"),
                    season_close: moment(season.season_close).format("M/D/YYYY")
                });
            },

            deleteSeason: function deleteSeason(season) {
                this.send('showModal', 'dashboard.campground.edit.modal.delete-season', season);
            },

            addSeason: function addSeason(isYear) {
                var self = this;
                var flashMessages = _ember['default'].get(self, 'flashMessages');

                var season_open = $('#s_open').val();
                var season_closed = $('#s_closed').val();

                if (typeof isYear != "undefined") {
                    var chosen_year = new Date(season_open).getFullYear();
                    season_open = new Date(chosen_year, 0, 1);
                    season_open = moment(season_open).format('MM/DD/YYYY');
                    season_closed = new Date(chosen_year, 11, 31);
                    season_closed = moment(season_closed).format('MM/DD/YYYY');
                }

                var incorrectDate = new Date(season_closed).getTime() < new Date(season_open).getTime();

                if (season_open === '' || season_closed === '' || incorrectDate) {
                    flashMessages.danger('You specified incorrect range, please double check your entry.');
                    return;
                }

                $.ajax({
                    type: "POST",
                    url: this.get('apiConnect').getUrl('/dashboard/campgrounds/seasons'),
                    data: {
                        campground_id: this.modelFor('dashboard.campground.edit').general.id,
                        season_open: season_open,
                        season_close: season_closed
                    },
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        console.log(data);
                        if (data.success) {
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                        } else {
                            flashMessages.danger(_ember['default'].String.htmlSafe(data.error));
                        }
                        self.send('refreshModel');
                    }
                });
            }
        }
    });
});