define('cn-front/components/calendar-gen/cs-row', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        campsite: null,
        reservations: null,
        classNames: ['cs-row'],
        cgView: true,
        onlineReservations: true,
        staysMode: (function () {
            return this.get('mode') == 'stays';
        }).property('mode'),

        didReceiveAttrs: function didReceiveAttrs() {
            var prices = 0;
            if (this.get('prices')) {
                prices = this.get('prices')[this.get('campsite.id')];
                this.set('campsite.prices', prices);
            }

            if (this.get('dow')) {
                prices = this.get('dow')[this.get('campsite.id')];
                this.set('campsite.dow', prices);
            }
        },

        actions: {
            change: function change(campsite, data, type) {

                if (this.get('mode') == 'stays') {

                    if (type == 'daily') {
                        this.sendAction("changeStay", { data: { duration: data, type: 'daily' }, campsite: campsite, type: type });
                    } else {
                        this.sendAction("changeStay", { data: data, campsite: campsite, type: type });
                    }
                } else {

                    if (type == 'daily') {
                        this.sendAction("changePrice", { data: { rate: data, type: 'daily' }, campsite: campsite, type: type });
                    } else {
                        this.sendAction("changePrice", { data: data, campsite: campsite, type: type });
                    }
                }
            },

            toCampsite: function toCampsite(id) {
                this.get('router').transitionTo('campground.campsite', id);
            }
        }
    });
});