define('cn-front/routes/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        title: 'Find Campgrounds Near Me - RV Parks & Tent Camping - Camp Native',
        afterModel: function afterModel() /*model*/{
            this.get('meta').update({
                title: 'Find Campgrounds Near Me - RV Parks & Tent Camping - Camp Native'
            });
        },
        headTags: function headTags() {
            // here we are pulling meta data from the model for this route
            return [{
                type: 'meta',
                tagId: 'meta-description-tag',
                attrs: {
                    name: 'description',
                    content: 'Camp Native is the ultimate website for finding campgrounds. Find over 12,000 campgrounds in the US, ready for your trip. Book now and save today.'
                }
            }];
        },
        actions: {
            didTransition: function didTransition() {
                // clear the input when going back to homepage
                //  ----  this.get('preselect').resetPreselect();
                _ember['default'].run.schedule('afterRender', this, function () {
                    // and make sure the field is empty if already rendered.
                    //   $('.location-autocomplete-field input').val('');
                    // and focus on the search field
                    // $('.location-autocomplete-field input:visible').focus();
                });
                return true; // Bubble the didTransition event
            }
        }
    });
});