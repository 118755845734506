define('cn-front/components/partials/site-header', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    currentUser: _ember['default'].inject.service('current-user'),
    stepster: _ember['default'].inject.service('reservation/stepster'),
    type: 'static',
    subType: '',
    routeName: false,
    mainRoute: 'index',
    classNames: ['header-component'],
    isOpaque: true,
    greenHeaderRoutes: ['auth',
    // 'camp-owners.form',
    'reservation.index', 'confirmation'],
    reservationRoutes: [
    // 'campground.campsite.new-reservation',
    'reservation.cancel', 'reservation.change', 'campground.info-request'],
    newReservationRoutes: ['campground.campsite.new-reservation'],

    // 'reservation.cancel',
    // 'reservation.change',
    // 'campground.info-request'
    withOutHeaderRoutes: ['camp-owners.form'],

    didReceiveAttrs: function didReceiveAttrs() {
      var route = this.get('routeName').split('.');

      if (this.get('routeName') === 'index' || this.get('routeName') === 'camp-owners.index') {
        this.set('type', 'index');
        this.set('isOpaque', false);
      } else {
        if (this.get('greenHeaderRoutes').indexOf(this.get('routeName')) !== -1) {
          this.set('type', 'green');
          this.set('subType', route[1]);
        } else if (this.get('reservationRoutes').indexOf(this.get('routeName')) !== -1) {
          this.set('type', 'reservation');
        } else if (this.get('newReservationRoutes').indexOf(this.get('routeName')) !== -1) {
          this.set('type', 'new-reservation');
        } else if (this.get('withOutHeaderRoutes').indexOf(this.get('routeName')) !== -1) {
          this.set('type', 'without-header');
        } else {
          this.set('type', 'static');
        }
      }

      var $body = $('body');
      $body.on('click', '#navbarNavDropdown a', function () {
        $body.removeClass('overflow-hidden');
      });
    },

    didRender: function didRender() {
      // if (this.get('routeName') === 'index' || this.get('routeName') === 'camp-owners.index' ) {
      var header = $('#main-header');
      var self = this;
      if (!this.get('isOpaque')) {
        header.removeClass('opaque');
      }
      // var heroHeight = $('#index-hero').outerHeight();
      var headerHeight = $('#main-header').outerHeight();
      var isOpaque = this.get('isOpaque');
      $(window).scroll(function () {
        var scrollHeight = $(this).scrollTop();

        if (scrollHeight < headerHeight) {
          header.removeClass('opaque');
          isOpaque = false;
        } else {
          header.addClass('opaque');
          isOpaque = true;
        }
        self.set('isOpaque', isOpaque);
      });
      //hack for ios safari
      $(window).scroll(function () {
        if ($('#main-header .btn-group-menu').hasClass('open')) {
          $('.frontend-wrapper').click();
        }
      });
      $('.frontend-wrapper').on('mousedown', function () {});
    },

    didInsertElement: function didInsertElement() {
      // here your view is in rendered state, but the child views no
      // collapse any other nav menu that is currently expanded.
      $('.navbar-header button, .navbar a').click(function () {
        jQuery('.collapse.navbar-collapse.in').collapse('hide');
      });

      // TODO: adjust to new search form
      // hide on form submit.
      $('#search-navbar a.ember-view').click(function () {
        jQuery('.collapse.navbar-collapse.in').collapse('hide');
      });

      // on show menu make sure it is scrolled up
      $('#header-navbar').on('show.bs.collapse', function () {
        // scroll menu to top
        $('#header-navbar').animate({
          scrollTop: 0
        }, 50);
      });
    },

    actions: {
      commit: function commit() {
        // if no results displayed, then "submit" the search.azxz
        if (jQuery('.hypersearch-results').css('display') === 'none') {
          // use visible in case of 2 search bars or more with some hidden.
          jQuery('.search-bar:visible a').click();
          this.get('router').transitionTo('explore');
        }
      },
      explore: function explore() {
        jQuery('.search-bar:visible a').click();
        this.get('router').transitionTo('explore');
      },
      logout: function logout() {
        this.get('currentUser').logout();
      },
      back: function back() {
        history.back();
      },
      openModal: function openModal(isShow) {
        var div = document.createElement('div');

        div.style.overflowY = 'scroll';
        div.style.width = '50px';
        div.style.height = '50px';

        document.body.append(div);
        var scrollWidth = div.offsetWidth - div.clientWidth;

        div.remove();

        var $body = $('body');
        if (isShow) {
          $body.addClass('overflow-hidden');
          $('.menu-modal-wrap').css('padding-right', scrollWidth + 'px');
        } else {
          $body.removeClass('overflow-hidden');
          $('.menu-modal-wrap').attr('style', false);
        }
      },

      /**
       * Go to the next step
       */
      nextStep: function nextStep() {
        this.get('stepster').nextStep();
      },

      /**
       * Go to previous step
       */
      prevStep: function prevStep() {
        this.get('stepster').prevStep();
      }
    }

  });
});