define('cn-front/routes/dashboard/campground/edit/applications', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        currentUser: inject.service('current-user'),
        onInit: (function () {
            _ember['default'].run.schedule('afterRender', this, function () {
                $('#user_id').val(this.get('currentUser.user').id);
                $('.datepicker').daterangepicker({
                    singleDatePicker: true,
                    showDropdowns: true,
                    minDate: "1/1/1920",
                    maxDate: new Date(),
                    locale: {
                        format: 'MM/DD/YYYY'
                    }
                }).val('');

                var self = this;
                $("#appform").validate({
                    rules: {
                        tos: "required"
                    },
                    submitHandler: function submitHandler() {
                        var adminTabData = $('form#appform').serializeJSON();

                        var url = self.get('apiConnect').getUrl('/dashboard/campgrounds/application');
                        var flashMessages = _ember['default'].get(self, 'flashMessages');
                        $.ajax({
                            type: "POST",
                            url: url,
                            beforeSend: function beforeSend(xhr) {
                                xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                            },
                            data: adminTabData,
                            async: false,
                            success: function success(data) {

                                if (data.success) {
                                    self.send('refreshModel');
                                    flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                                    $('body,html').animate({
                                        scrollTop: 0
                                    }, 400);
                                } else {
                                    $('body,html').animate({
                                        scrollTop: 0
                                    }, 400);
                                    data.map(function (e) {
                                        if (e.error) {
                                            flashMessages.danger(_ember['default'].String.htmlSafe(e.message));
                                        }
                                        if (e.warning) {
                                            flashMessages.warning(_ember['default'].String.htmlSafe(e.message));
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
            });
        }).on('activate'),

        model: function model() {
            var model = this.modelFor('dashboard.campground.edit');
            return model;
        }
    });
});