define('cn-front/components/section-testimonials', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    testimonialsData: '',
    didReceiveAttrs: function didReceiveAttrs() {
      var testimonialsData = [];
      if (this.get('router.currentRouteName') === 'index') {
        testimonialsData = [{
          "message": "Camp Native helped me discover hidden gems! I found a stunning campsite nestled by a lake that I wouldn't have known about otherwise. and accurate campground information made my camping trip unforgettable.",
          "image": "assets/images/testimonials/avatar-default.png",
          "name": "Laura K.",
          "date": "October 27, 2019"
        }, {
          "message": "Camp Native took the hassle out of finding a campsite. Their platform is straightforward to navigate, and their customer support team is responsive and helpful. I highly recommend using Camp Native.",
          "image": "assets/images/testimonials/avatar-default.png",
          "name": "John P.",
          "date": "September 29, 2021"
        }, {
          "message": "I had a fantastic camping trip thanks to Camp Native. Their site recommendations were spot-on, and they provided photos and descriptions that gave us a clear picture of what to expect. Amazing experience!",
          "image": "assets/images/testimonials/avatar-default.png",
          "name": "Rachel W.",
          "date": "May 29, 2022"
        }, {
          "message": "I've used Camp Native multiple times, and they never disappoint. Their search filters helped me find campsites with specific amenities like hiking trails and pet-friendly areas. I appreciate the convenience and reliability they offer.",
          "image": "assets/images/testimonials/avatar-default.png",
          "name": "Mark D.",
          "date": "July 12, 2022"
        }, {
          "message": "Booking with Camp Native was a game-changer! Their platform made it incredibly easy to search for and book the perfect campsite. We had a fantastic camping experience and can't wait to use Camp Native again!",
          "image": "assets/images/testimonials/avatar-default.png",
          "name": "Sarah M.",
          "date": "August 14, 2022"
        }, {
          "message": "Camp Native is a lifesaver for last-minute camping plans. Their real-time availability feature helped me secure a campsite on short notice. I'm grateful for their efficient service and will definitely use them again in the future.",
          "image": "assets/images/testimonials/avatar-default.png",
          "name": "David S.",
          "date": "September 30, 2022"
        }];
      } else {
        testimonialsData = [{
          "message": "Camp Native reached out to us and the process to join has been great. They have been very patient with me and all my other projects. They walked me through with simple explanations and examples.",
          "image": "assets/images/testimonials/imgt1.png",
          "name": "Laurie & Jim Preston",
          "date": "May 10, 2023"
        }, {
          "message": "Absolutely loved working with the Camp Native team.  Friendly, professional and I feel like I made lifelong friends as well as business partners.  Excited to start working together! Every campground should use Camp Native!",
          "image": "assets/images/testimonials/imgt2.png",
          "name": "Starla Heller",
          "date": "May 5, 2023"
        }, {
          "message": "We are very pleased to be associated with Camp Native. It is super convenient. Customers can book and pay through the website and it frees us up so we don't have to be answering the phone and tied up in the office.",
          "image": "assets/images/testimonials/avatar-default.png",
          "name": "Sandra Barfield",
          "date": "Apr 22, 2023"
        }, {
          "message": "Thanks Camp Native.....looking forward to future business and exposure on your site. We have appreciated your assistance, helping us through all the details of the set-up process.",
          "image": "assets/images/testimonials/imgt4.png",
          "name": "Deborah",
          "date": "Apr 12, 2023"
        }, {
          "message": "Camp Native is so helpful and has had lots of patience with us as we are not very computer smart thanks a million for all your help",
          "image": "assets/images/testimonials/imgt5.png",
          "name": "sarah allyn",
          "date": "Apr 7, 2023"
        }, {
          "message": "I am a small RV park owner, Camp Native is willing to help to keep my RV park full in the off season.They were very patient and helped me get approved and list all amenities of my park.",
          "image": "assets/images/testimonials/avatar-default.png",
          "name": "Robert Sevon",
          "date": "Mar 25, 2023"
        }, {
          "message": "We have just starting using Camp Native and have found them to be very helpful and successful with reservations at Happy Trails RV Park in Lebanon, Mo.",
          "image": "assets/images/testimonials/avatar-default.png",
          "name": "Robyn Ziegler",
          "date": "Mar 16, 2023"
        }, {
          "message": "Thank you guys for making available a site with such a strong emphasis on campgrounds and making it easy to locate them. Thank you!",
          "image": "assets/images/testimonials/imgt8.png",
          "name": "Black Lantern Resort",
          "date": "Feb 24, 2023"
        }];
      }

      this.set('testimonialsData', testimonialsData);
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        //init main carousel
        // let elementId = $('#' + this.get('elementId'));
        var owlT = $('#' + this.get('elementId') + ' .testimonials-carousel');
        var prevArrow = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 30.25L25.05 29.2L20.65 24.75H30.75V23.25H20.65L25.05 18.8L24 17.75L17.75 24L24 30.25ZM24 42C21.5333 42 19.2083 41.525 17.025 40.575C14.8417 39.625 12.9333 38.3333 11.3 36.7C9.66667 35.0667 8.375 33.1667 7.425 31C6.475 28.8333 6 26.5 6 24C6 21.5 6.475 19.1583 7.425 16.975C8.375 14.7917 9.66667 12.8917 11.3 11.275C12.9333 9.65833 14.8333 8.375 17 7.425C19.1667 6.475 21.5 6 24 6C26.5 6 28.8417 6.475 31.025 7.425C33.2083 8.375 35.1083 9.65833 36.725 11.275C38.3417 12.8917 39.625 14.7917 40.575 16.975C41.525 19.1583 42 21.5 42 24C42 26.4667 41.525 28.7917 40.575 30.975C39.625 33.1583 38.3417 35.0667 36.725 36.7C35.1083 38.3333 33.2083 39.625 31.025 40.575C28.8417 41.525 26.5 42 24 42ZM24 40.45C28.5667 40.45 32.45 38.85 35.65 35.65C38.85 32.45 40.45 28.5667 40.45 24C40.45 19.4333 38.85 15.55 35.65 12.35C32.45 9.15 28.5667 7.55 24 7.55C19.4333 7.55 15.55 9.15 12.35 12.35C9.15 15.55 7.55 19.4333 7.55 24C7.55 28.5667 9.15 32.45 12.35 35.65C15.55 38.85 19.4333 40.45 24 40.45Z" fill="#CACACA"/></g></svg>';
        var nextArrow = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 30.25L30.25 24L24 17.75L22.95 18.8L27.35 23.25H17.25V24.75H27.35L22.95 29.2L24 30.25ZM24 42C21.5333 42 19.2083 41.525 17.025 40.575C14.8417 39.625 12.9333 38.3333 11.3 36.7C9.66667 35.0667 8.375 33.1667 7.425 31C6.475 28.8333 6 26.5 6 24C6 21.5 6.475 19.1583 7.425 16.975C8.375 14.7917 9.66667 12.8917 11.3 11.275C12.9333 9.65833 14.8333 8.375 17 7.425C19.1667 6.475 21.5 6 24 6C26.5 6 28.8417 6.475 31.025 7.425C33.2083 8.375 35.1083 9.65833 36.725 11.275C38.3417 12.8917 39.625 14.7917 40.575 16.975C41.525 19.1583 42 21.5 42 24C42 26.4667 41.525 28.7917 40.575 30.975C39.625 33.1583 38.3417 35.0667 36.725 36.7C35.1083 38.3333 33.2083 39.625 31.025 40.575C28.8417 41.525 26.5 42 24 42ZM24 40.45C28.5667 40.45 32.45 38.85 35.65 35.65C38.85 32.45 40.45 28.5667 40.45 24C40.45 19.4333 38.85 15.55 35.65 12.35C32.45 9.15 28.5667 7.55 24 7.55C19.4333 7.55 15.55 9.15 12.35 12.35C9.15 15.55 7.55 19.4333 7.55 24C7.55 28.5667 9.15 32.45 12.35 35.65C15.55 38.85 19.4333 40.45 24 40.45Z" fill="#93A444"/></g></svg>';
        // const itemCount = testimonialsData.length;
        owlT.owlCarousel({
          dots: true,
          loop: false,
          autoWidth: false,
          margin: 30,
          nav: true,
          smartSpeed: 800,
          navContainer: '.slide-arrow.testimonials-slide-arrow',
          navClass: ['slide-prev', 'slide-next'],
          navText: [prevArrow, nextArrow],
          dotClass: 'owl-line',
          dotsEach: true,
          slideBy: 1,
          items: 3,
          responsive: {
            0: {
              items: 1
            },
            769: {
              items: 2
            },
            993: {
              items: 3
            }
          }
        });
      });
    }

  });
});