define('cn-front/controllers/dashboard/users/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        queryParams: ['search', 'page'],
        search: "",
        searchFilter: "",
        page: 1,
        orderBy: '',
        sort: 'ASC',

        onSearchChange: (function () {
            // wait 1 second before applying the filter
            _ember['default'].run.debounce(this, this.applySearchFilter, 1000);
        }).observes('searchFilter'),

        applySearchFilter: function applySearchFilter() {
            this.set('page', 1);
            this.set('search', this.get('searchFilter'));
        },

        actions: {
            pageChanged: function pageChanged(current) {
                this.set('page', current);
            },
            exportTo: function exportTo() {
                var reportUrl = this.get('apiConnect').getUrl('/csv/reports/users') + '?search=' + this.search // jshint ignore:line
                 + '&orderBy=' + this.orderBy // jshint ignore:line
                 + '&sort=' + this.sort // jshint ignore:line
                 + '&token=' + $.cookie('token'); // jshint ignore:line

                window.open(reportUrl, '_self');
            }
        }
    });
});