define('cn-front/components/loading-overlay', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		shown: true,
		text: 'Loading...'
	});
});
//
//	init: function() {
//		this._super(...arguments);
//		console.info('overlay init', this.get('shown'), this.get('text'));
//	},
//	didReceiveAttrs: function() {
//		this._super(...arguments);
//		console.info('overlay didReceiveAttrs', this.get('shown'), this.get('text'));
//	},
//	willRender: function() {
//		this._super(...arguments);
//		console.info('overlay willRender', this.get('shown'), this.get('text'));
//	},
//	didInsertElement: function() {
//		this._super(...arguments);
//		console.info('overlay didInsertElement', this.get('shown'), this.get('text'));
//	},
//	didRender: function() {
//		this._super(...arguments);
//		console.info('overlay didRender', this.get('shown'), this.get('text'));
//	},
//	didUpdateAttrs: function() {
//		this._super(...arguments);
//		console.info('overlay didUpdateAttrs', this.get('shown'), this.get('text'));
//	},
//	willUpdate: function() {
//		this._super(...arguments);
//		console.info('overlay willUpdate', this.get('shown'), this.get('text'));
//	},
//	didUpdate: function() {
//		this._super(...arguments);
//		console.info('overlay didUpdate', this.get('shown'), this.get('text'));
//	},
//	willDestroyElement: function() {
//		this._super(...arguments);
//		console.info('overlay willDestroyElement', this.get('shown'), this.get('text'));
//	},
//	willClearRender: function() {
//		this._super(...arguments);
//		console.info('overlay willClearRender', this.get('shown'), this.get('text'));
//	},
//	didDestroyElement: function() {
//		this._super(...arguments);
//		console.info('overlay didDestroyElement', this.get('shown'), this.get('text'));
//	},