define('cn-front/helpers/css-styles', ['exports', 'ember'], function (exports, _ember) {
  exports.cssStyles = cssStyles;

  /*
  
   Template:
   <div style={{css-styles background-url='url("this/is/my/url")' color="red"}}>My Fill Color</div>
   OR
   <li id="{{index}}" style={{css-styles background-url=(concat 'url("' photo.thumbnail '")') }}></li>
  
   */

  function cssStyles(params, hash) {
    var styles = '';
    if (params !== undefined) {
      styles = joinStyles(params);
    }
    if (hash !== undefined) {
      styles = joinStyles(hash);
    }
    return _ember['default'].String.htmlSafe(styles);
  }

  exports['default'] = _ember['default'].Helper.helper(cssStyles);

  /*
  
   var options = { color : 'red', border : 'solid gray 1px', font-size : '12px'};
  
   joinStyles(options);
   > "color:red;border:solid gray 1px;font-size:12px;
  
   joinStyles(options,'=>', ' '));
   > "id=>1 name=>lucas country=>brasil"
  
   */
  var joinStyles = function joinStyles(object, glue, separator) {
    if (glue === undefined) {
      glue = ':';
    }
    if (separator === undefined) {
      separator = ';';
    }
    return $.map(Object.getOwnPropertyNames(object), function (k) {
      return [k, object[k]].join(glue);
    }).join(separator);
  };
});