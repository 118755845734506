define('cn-front/components/dashboard/step/verify-user-dash', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
    var inject = _ember['default'].inject;

    /*globals flashMessages*/
    exports['default'] = _ember['default'].Component.extend({
        //setup services
        reservation: inject.service('dashboard/reservation'),
        stepster: inject.service('dashboard/stepster'),
        ustates: inject.service('ustates'),
        apiConnect: inject.service('api-resolver'),
        currentUser: inject.service('current-user'),
        registrationEmail: '',
        emailValid: false,
        creationType: 'Onsite',

        remoteUser: {},
        //get list of usa states for reg form
        statesList: (function () {
            var array = $.map(this.get('ustates').data, function (value) {
                return [value];
            });
            return array.slice(0, -2);
        }).property(),

        checkEmail: (function () {
            if (this.registrationEmail !== '') {
                _ember['default'].run.debounce(this, this.lookupCamper, 1000);
            }
        }).observes('registrationEmail').on('init'),

        lookupCamper: function lookupCamper() {
            var url = this.get('apiConnect').getUrl('/lookup');
            var self = this;
            $.ajax({
                type: "POST",
                url: url,
                data: $('#register-form').serializeJSON(),
                success: function success(data) {
                    console.log(data);
                    if (typeof data.error === 'undefined') {
                        $('.email-validation').html('');
                        $('#register-form [name="email"]').addClass('valid_email');
                        self.set('emailValid', true);
                    } else {
                        $('.email-validation').html(data.error.email[0]);
                        $('#register-form [name="email"]').removeClass('valid_email');
                        self.set('emailValid', false);
                    }
                },
                error: function error(data) {
                    $('.modal').animate({
                        scrollTop: 0
                    }, 400);
                    $.each(data.responseJSON, function (key, value) {
                        flashMessages.danger(value[0]);
                    });
                }
            });
        },
        isPaymentsEnabled: function isPaymentsEnabled() {
            var disabledPaymentOn = ["in_use", "maint", "fcfs", "info_req"];
            return disabledPaymentOn.indexOf(this.get('reservation.restype')) == -1;
        },
        onSelect: (function (flag) {
            if (flag === undefined || flag !== true) {
                this.set('remoteUser', {});
            }
            if (this.get('stepster.currentStep') == this.get('tabId')) {

                if (this.get('stepster.finishedSteps') < this.get('stepster.currentStep')) {
                    if (this.isPaymentsEnabled()) {
                        _cnFrontUtilsGtm['default'].trackEvent({
                            'event': 'checkout',
                            'ecommerce': {
                                'checkout': {
                                    'actionField': { 'step': 2, 'option': 'Verify Camper' },
                                    'products': [{
                                        'name': this.get('reservation.camp.name'),
                                        'id': this.get('reservation.campsite.id')
                                    }]
                                }
                            }
                        });

                        _cnFrontUtilsGtm['default'].trackEvent({
                            'event': 'checkout_step_2',
                            'reservation_type': this.get('reservation.restype'),
                            'transactionCG': this.get('reservation.camp.id') // numeric   CG ID number
                        });
                    }

                    this.set('stepster.finishedSteps', this.get('stepster.currentStep'));
                }

                var self = this;
                //firing every entering on this step
                $(document).on('submit', '#login-form', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    self.send('find');
                });

                if (this.get('completeProfile')) {
                    //get braintree token
                    if (this.get('reservation').type == 'front' || this.get('reservation.restype') == "on_site") {
                        $('.modal .overlay').css('display', 'block');
                        var url = this.get('apiConnect').getUrl('/reservations/braintree-token');

                        $.ajax({
                            type: "GET",
                            url: url,
                            beforeSend: function beforeSend(xhr) {
                                xhr.setRequestHeader('Authorization', 'bearer ' + self.get('remoteUser.token'));
                            },
                            data: { token: self.get('remoteUser.token') },
                            async: false,
                            success: function success(data) {
                                self.set('reservation.braintree_token', data);
                                self.set('reservation.remote_token', self.get('remoteUser.token'));
                                self.set('reservation.remote_user', self.get('remoteUser'));

                                self.set('stepster.ready', true);
                                $('.res-footer .next').prop('disabled', false);
                                $('.modal .overlay').css('display', 'none');
                            },
                            error: function error(data) {
                                $('.modal .overlay').css('display', 'none');
                                console.log(data);
                                alert('500');
                            }
                        });
                    } else {
                        //skip getting token
                        self.set('stepster.ready', true);
                        $('.res-footer .next').prop('disabled', false);
                    }
                } else {
                    this.set('stepster.ready', false);
                    $('.res-footer .next').prop('disabled', true);
                }
            }
        }).observes('stepster.currentStep').on('init'),

        //check profile
        completeProfile: (function () {
            if (this.get('remoteUser.user')) {
                var u = this.get('remoteUser').user;

                if (u.name_first == '' // jshint ignore:line
                 || u.name_last == '' // jshint ignore:line
                 || u.address == '' // jshint ignore:line
                 || u.city == '' // jshint ignore:line
                 || u.state == '' // jshint ignore:line
                 || u.zip_code == '' // jshint ignore:line
                 || u.phone == '' // jshint ignore:line
                ) {
                        this.set('stepster.ready', false);
                        return false;
                    } else {
                    this.set('stepster.ready', true);
                    return true;
                }
            } else {
                this.set('stepster.ready', false);
                return false;
            }
        }).property('remoteUser.user'),
        missedFields: (function () {
            var u = this.get('remoteUser').user;
            var arr = [];

            if (u.name_first == '') {
                // jshint ignore:line
                arr.push('first name');
            }
            if (u.name_last == '') {
                // jshint ignore:line
                arr.push('last name');
            }
            if (u.address == '') {
                // jshint ignore:line
                arr.push('address');
            }
            if (u.city == '') {
                // jshint ignore:line
                arr.push('city');
            }
            if (u.state == '') {
                // jshint ignore:line
                arr.push('state');
            }
            if (u.zip_code == '') {
                // jshint ignore:line
                arr.push('zip code');
            }

            return arr;
        }).property('remoteUser.user'),

        actions: {
            find: function find() {
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                flashMessages.clearMessages();
                var url = this.get('apiConnect').getUrl('/dashboard/users/lookup');
                var self = this;
                $.ajax({
                    type: "POST",
                    url: url,
                    data: $('#login-form').serializeJSON(),
                    success: function success(data) {
                        console.log(data);

                        if (data.error) {
                            if (data.error.email) {
                                data.error.email.forEach(function (message) {
                                    flashMessages.danger(message);
                                });
                            } else {
                                $('body,html').animate({
                                    scrollTop: 0
                                }, 400);
                                flashMessages.danger(data.error);
                            }
                        } else {
                            self.set('remoteUser', data);

                            if (self.isPaymentsEnabled()) {
                                _cnFrontUtilsGtm['default'].trackEvent({
                                    'event': 'checkout_step_2_login',
                                    'reservation_type': self.get('reservation.restype'),
                                    'transactionCG': self.get('reservation.camp.id'), // numeric   CG ID number
                                    'transactionUser': self.get('remoteUser').user.id, // numeric   User ID number (camper)
                                    'transactionUserEmail': self.get('remoteUser').user.email, // string    User Email (camper)
                                    'transactionUserCity': self.get('remoteUser').user.city, // string    User City (camper)
                                    'transactionUserState': self.get('remoteUser').user.state, // string    User State (camper)
                                    'transactionUserZip': self.get('remoteUser').user.zip_code // string    User Zip Code (camper)
                                });
                            }

                            self.onSelect(true);
                        }
                    },
                    error: function error(data) {
                        flashMessages.danger(data.responseJSON.message);
                    }
                });
            },
            registernew: function registernew() {
                var url = this.get('apiConnect').getUrl('/dashboard/users/create-guest');
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                flashMessages.clearMessages();
                var self = this;
                var data = $('#register-form').serializeArray();
                data.push({
                    name: "create_method",
                    value: self.get('creationType')
                });
                if (self.get('emailValid')) {
                    $.ajax({
                        type: "POST",
                        url: url,
                        beforeSend: function beforeSend(xhr) {
                            xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                        },
                        data: data,
                        success: function success(data) {
                            self.set('remoteUser', data);

                            if (self.isPaymentsEnabled()) {
                                _cnFrontUtilsGtm['default'].trackEvent({
                                    'event': 'checkout_step_2_register',
                                    'reservation_type': self.get('reservation.restype'),
                                    'transactionCG': self.get('reservation.camp.id'), // numeric   CG ID number
                                    'transactionUser': self.get('remoteUser').user.id, // numeric   User ID number (camper)
                                    'transactionUserEmail': self.get('remoteUser').user.email, // string    User Email (camper)
                                    'transactionUserCity': self.get('remoteUser').user.city, // string    User City (camper)
                                    'transactionUserState': self.get('remoteUser').user.state, // string    User State (camper)
                                    'transactionUserZip': self.get('remoteUser').user.zip_code // string    User Zip Code (camper)
                                });
                            }

                            self.onSelect(true);
                        },
                        error: function error(data) {
                            $.each(data.responseJSON, function (key, value) {
                                flashMessages.danger(value[0]);
                            });
                        }
                    });
                }
            },
            update: function update() {
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                flashMessages.clearMessages();

                var self = this;
                var id = this.get('remoteUser.user.id');
                var url = this.get('apiConnect').getUrl('/dashboard/user/' + id + '/update-info');

                $.ajax({
                    type: "put",
                    url: url,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    data: $('#profile-info').serializeJSON(),
                    success: function success(data) {
                        $('body,html').animate({
                            scrollTop: 0
                        }, 400);
                        flashMessages.success(data.success);
                        self.set('remoteUser.user', data.user);

                        if (self.isPaymentsEnabled()) {
                            _cnFrontUtilsGtm['default'].trackEvent({
                                'event': 'checkout_step_2_update',
                                'reservation_type': self.get('reservation.restype'),
                                'transactionCG': self.get('reservation.camp.id'), // numeric   CG ID number
                                'transactionUser': self.get('remoteUser').user.id, // numeric   User ID number (camper)
                                'transactionUserEmail': self.get('remoteUser').user.email, // string    User Email (camper)
                                'transactionUserCity': self.get('remoteUser').user.city, // string    User City (camper)
                                'transactionUserState': self.get('remoteUser').user.state, // string    User State (camper)
                                'transactionUserZip': self.get('remoteUser').user.zip_code // string    User Zip Code (camper)
                            });
                        }

                        self.onSelect(true);
                    },
                    error: function error(data) {
                        $('body,html').animate({
                            scrollTop: 0
                        }, 400);
                        $.each(data.responseJSON, function (key, value) {
                            flashMessages.danger(value[0]);
                        });
                    }
                });
            },
            dropRemoteUser: function dropRemoteUser() {
                this.onSelect(false);
            }
        }
    });
});