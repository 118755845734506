define('cn-front/components/campground/calendar-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    campsite: null,
    reservations: null,
    classNames: ['cs-row'],
    cgView: true,
    onlineReservations: true,

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('reservations')) {
        var csReservationsc = this.get('reservations')[this.get('campsite.id')];
        this.set('campsite.reservations', csReservationsc);
      }
    },
    didRender: function didRender() {
      var self = this;
      $(function () {
        $('[data-toggle="tooltip"]').tooltip();
      });
      $(document).find('#' + self.get('elementId') + ' .btn').on('click', function (e) {
        e.preventDefault();
        var currentTarget = e.currentTarget;
        if ($(currentTarget).attr('disabled') !== 'disabled') {
          var dataCampsite = self.get('campsite');
          var container = $(e.currentTarget).parents('.calendar-dates.icon-status');
          var firstChecked = $(container.find('.active-day')[0]).data('key');
          var lengthActiveDay = container.find('.active-day').length;
          var lastChecked = $(container.find('.active-day')[lengthActiveDay - 1]).data('key');
          // console.log('data', lengthActiveDay, firstChecked, lastChecked, dataCampsite);

          self.sendAction("goToReservation", firstChecked, dataCampsite, lastChecked);
        }
      });
    },

    actions: {
      checkDay: function checkDay(key) {
        $(function () {
          $('[data-toggle="tooltip"] + .tooltip').remove();
        });

        var self = this;

        var currentTarget = $('#' + self.get('elementId') + ' .available-day[data-key="' + key + '"]');
        var currentClassActive = currentTarget.hasClass('active-day');
        currentTarget.addClass('active-day');

        var container = $('#' + self.get('elementId') + ' .calendar-dates.icon-status');
        var currentCheckedDate = key;
        var lengthCheckedDate = $('#' + self.get('elementId') + ' .available-day.active-day').length;
        var disabledFirstElement = true;
        var removedLast = true;
        var firstElement = currentCheckedDate;

        container.find('.date-block[data-key]').each(function (i, el) {
          var elDate = $(el).data('key');

          if ($(el).hasClass('active-day') && disabledFirstElement) {
            firstElement = elDate;
            disabledFirstElement = false;
            if (currentClassActive) {
              currentTarget.removeClass('active-day');
            }
          } else if (!disabledFirstElement && currentCheckedDate > firstElement && currentCheckedDate >= elDate && removedLast) {
            $(el).addClass('active-day');
          } else if (!disabledFirstElement && currentCheckedDate < elDate && lengthCheckedDate > 1) {
            $(el).removeClass('active-day');
            if (currentClassActive) {
              currentTarget.removeClass('active-day');
            }
            removedLast = false;
          }
        });

        if (container.find('.active-day').length > 0) {
          container.find('.btn').attr('disabled', false);
        } else {
          container.find('.btn').attr('disabled', true);
        }
      },
      //
      // toReservation: function (campsite) {
      //
      //   // this.sendAction("goToReservation", data, campsite);
      // },

      toCampsite: function toCampsite(id) {
        this.get('router').transitionTo('campground.campsite', id);
      }
    }
  });
});