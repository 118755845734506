define('cn-front/components/reservation/change-reservation/verify-payment', ['exports', 'ember', 'npm:braintree-web'], function (exports, _ember, _npmBraintreeWeb) {
    exports['default'] = _ember['default'].Component.extend({
        reservation: _ember['default'].inject.service('reservation/instance'),
        stepster: _ember['default'].inject.service('reservation/stepster'),
        currentUser: _ember['default'].inject.service('current-user'),
        confirmed: false,
        disableConfirmationBox: true,
        loading: false,
        loadingText: "Please wait, we're changing your reservation...",

        paymentsEnabled: (function () {
            return this.get('reservation').isPaymentsEnabled() && this.get('reservation.model.mode') != "no_change";
        }).property('reservation.model.mode'),

        didInsertElement: function didInsertElement() {
            var self = this;
            var confirmationCheckbox = $('#confirm');

            confirmationCheckbox.change(function () {
                if ($(this).is(":checked")) {
                    self.set('confirmed', true);
                } else {
                    self.set('confirmed', false);
                }
            });

            $('.green-header .next-btn').css('visibility', 'hidden');

            if (self.get('paymentsEnabled') && self.get('reservation.braintree_token').length) {

                //clean up old braintree integrations
                $('#dropin').html('');
                $('#braintree-dropin-modal-frame').remove();

                $('.overlay').css('display', 'block'); // TODO: add non-modal overlay

                // braintree integration
                _npmBraintreeWeb['default'].setup(self.get('reservation.braintree_token'), 'dropin', {
                    container: "dropin",
                    onReady: function onReady() {
                        $('#overlay').css('display', 'none'); // TODO: add non-modal overlay
                        $('.green-header .next-btn').attr('disabled', false);
                        self.set('disableConfirmationBox', false);
                    },
                    paymentMethodNonceReceived: function paymentMethodNonceReceived(event, nonce) {
                        //this fires after user submit form
                        $('#nonce').val(nonce);
                        self.reserv();
                    },
                    onError: function onError(error) {
                        console.log('braintree error: ' + error.message);
                        $('#overlay').css('display', 'none'); // TODO: add non-modal overlay
                    }
                });
            }

            // "block" next button and fire create reservation instead next slide
            self.set('stepster.ready', false);
            self.set('stepster.fallback', (function () {
                this.createReservation();
            }).bind(this));

            if (!self.get('paymentsEnabled')) {
                $('.green-header .next-btn').attr('disabled', false);
                this.set('disableConfirmationBox', false);
            }
        },

        willDestroyElement: function willDestroyElement() {
            $('.green-header .next-btn').css('visibility', 'visible');
        },

        confirmationBoxListener: (function () {
            if (!this.get('disableConfirmationBox')) {
                if ($('#confirm').is(":checked")) {
                    this.set('confirmed', true);
                } else {
                    this.set('confirmed', false);
                }
            }
        }).observes('disableConfirmationBox'),

        createReservation: function createReservation() {
            if (this.get('paymentsEnabled') && (this.get('reservation.type') == 'front' || this.get('reservation.reservation_type') == "on_site")) {
                //innitiate payment process throu braintree
                $('#change-reservation').find('input[type="submit"]').click();
            } else {
                //reserv without payment
                this.reserv();
            }
        },

        reserv: function reserv() {
            var _this = this;

            var self = this;
            var res_info = $('#change-reservation').serializeJSON();
            var url = self.get('api').getUrl('/reservations/change');
            var token = $.cookie('token');
            if (this.get('reservation.remote_token')) {
                res_info.initiator_token = $.cookie('token');
                token = this.get('reservation.remote_token');
                console.log('using remote user token');
            }

            var ajaxPromise = $.ajax({
                type: "POST",
                url: url,
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + token);
                },
                data: res_info,
                async: false
            });

            ajaxPromise.then(function (data) {
                "use strict";
                console.log(data);

                //set response in reservation object
                self.set('reservation.response', data);

                // var modalTitle = 'Reservation Changed';
                // $('.modal .modal-title').html(modalTitle); // TODO: modalTitle is not changing because title is in the view template

                //remove overlay
                _this.set('loading', false);

                //clean up next button block
                self.set('stepster.ready', true);
                self.set('stepster.fallback', null);
                //go to next step
                $('.green-header .next-btn').click();
            });
        },

        actions: {
            createReservation: function createReservation() {
                this.set('loading', true);
                this.createReservation();
            }
        }
    });
});