define('cn-front/routes/support', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        title: 'Camp Native Help Center - Campground Owners - Campers',
        headTags: function headTags() {
            // here we are pulling meta data from the model for this route
            return [{
                type: 'meta',
                tagId: 'meta-description-tag',
                attrs: {
                    name: 'description',
                    content: 'Get help from Camp Native representatives. Our friendly staff is based in South Dakota and are always happy to help with your questions!'
                }
            }];
        }
    });
});