define('cn-front/controllers/dashboard/user/view', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            seeDetails: function seeDetails(id) {
                var data = {
                    id: id,
                    type: "dash"
                };
                this.send('showModal', 'dashboard/modal/resinfo', data);
            }
        }
    });
});