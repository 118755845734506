define('cn-front/components/section-blog', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    posts: [],
    firstPost: {},
    didReceiveAttrs: function didReceiveAttrs() {
      var feed = "https://blog.campnative.com/feed/";
      var firstPost = {};
      var posts = [];
      var colors = [{
        tag: 'Campground Experiences',
        color: 'rgb(89, 143, 234)',
        bgColor: 'rgba(89, 143, 234, 0.1)'
      }, {
        tag: 'Camping Fun',
        color: 'rgb(221, 153, 51)',
        bgColor: 'rgba(221, 153, 51, 0.1)'
      }, {
        tag: 'Camping Adventures',
        color: 'rgb(143, 174, 54)',
        bgColor: 'rgba(143, 174, 54, 0.1)'
      }, {
        tag: 'Camping Tips',
        color: 'rgb(254, 172, 73)',
        bgColor: 'rgba(254, 172, 73, 0.1)'
      }, {
        tag: 'Camping Locations',
        color: 'rgb(33, 35, 34)',
        bgColor: 'rgba(33, 35, 34, 0.1)'
      }];
      var self = this;

      $.ajax(feed, {
        accepts: {
          xml: "application/rss+xml"
        },
        dataType: "xml",
        success: function success(data) {
          var parser = new DOMParser();
          $(data).find("item").each(function (i, item) {
            var el = $(item);
            var title = el.find("title").text();
            var link = el.find("link").text();
            var desc = el.find("description").text();
            var date = el.find("pubDate").text();
            var tag = el.find("category").text();
            var colorTag = 'rgb(89, 143, 234)';
            var bgColorTag = 'rgba(89, 143, 234, 0.1)';

            var parserDesc = parser.parseFromString(desc, 'text/html');
            desc = $(parserDesc).find('p:first-of-type').text();

            var img = $(parserDesc).find('div:first-child img').attr('src');
            img = img ? img : '/assets/images/blog/img1.jpg';

            colors.forEach(function (color) {
              if (tag === color.tag) {
                colorTag = color.color;
                bgColorTag = color.bgColor;
              }
            });

            if (i === 0) {
              firstPost = {
                title: title,
                link: link,
                desc: desc,
                date: moment(date).format('MMM DD, YYYY'),
                tag: tag,
                colorTag: colorTag,
                bgColorTag: bgColorTag,
                img: img
              };
              self.set('firstPost', firstPost);
            } else if (i <= 4) {
              posts.push({ title: title,
                link: link,
                desc: desc,
                date: moment(date).format('MMM DD, YYYY'),
                tag: tag,
                colorTag: colorTag,
                bgColorTag: bgColorTag,
                img: img
              });
            }
          });
          self.set('posts', posts);
        }
      });
    }

  });
});