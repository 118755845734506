define('cn-front/controllers/reservation/change', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        reservation: _ember['default'].inject.service('reservation/instance'),
        stepster: _ember['default'].inject.service('reservation/stepster'),
        currentUser: _ember['default'].inject.service('current-user'),
        in_process: false,

        defaultInit: (function () {
            var self = this;
            this.get('stepster').reset();
            this.set('stepster.stepsTotal', 3);
            this.set('stepster.finishedSteps', 1);
            this.get('stepster').onSuccessReservation = function () {
                self.transitionToRoute('user.profile');
            };

            if (this.get('reservation.model.type') == 'confirm' && this.get('reservation.type') == 'front') {
                this.set('stepster.stepsTotal', 1);
                this.set('stepster.ready', true);
                _ember['default'].run.scheduleOnce('afterRender', this, function () {
                    "use strict";
                    self.get('stepster').onStepChange();
                });
            }

            this.set('in_process', moment(this.get('reservation.model.date_arrival')).valueOf() <= moment().valueOf());

            if (!this.get('reservation.initiator')) {
                this.set('reservation.initiator', 'camper');
            }
        }).observes('model'),

        stepChange: (function () {
            if (this.get('stepster.currentStep') == 2 && this.get('stepster.finishedSteps') == 1) {
                this.set('reservation.camper_change_note', $('#change-note').val());
                this.set('stepster.finishedSteps', 2);
            }

            if (this.get('stepster.currentStep') == 3 && this.get('stepster.finishedSteps') == 2 && this.get('reservation.response.success')) {
                this.set('stepster.finishedSteps', 3);
                this.get('stepster').onStepChange();
            }
        }).observes('stepster.currentStep'),

        scrollToTop: (function () {
            $('html,body').animate({
                scrollTop: 0
            }, 400);
        }).observes('stepster.currentStep')
    });
});