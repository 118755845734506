define('cn-front/components/reservation/cancel-reservation/verify-reservation', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        reservation: _ember['default'].inject.service('reservation/instance'),
        stepster: _ember['default'].inject.service('reservation/stepster'),

        paymentsEnabled: (function () {
            return this.get('reservation').isPaymentsEnabled();
        }).property('reservation.type'),

        confirmed: false,

        calculateCancelFinancial: function calculateCancelFinancial() {
            var url = this.get('api').getUrl("/reservations/calculate/cancel_res");
            var ajaxPromise = $.get(url, {
                reservation_id: this.get('reservation.model.id'),
                initiator: this.get('reservation.initiator')
            });
            var self = this;
            ajaxPromise.then(function (data) {
                self.get('reservation').setCancelInformation(data);
            });
        },

        didReceiveAttrs: function didReceiveAttrs() {
            this.calculateCancelFinancial();

            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                "use strict";

                var self = this,
                    confirmationCheckbox = $('#confirm');

                this.set('confirmed', false);
                if (this.get('stepster.ready')) {
                    confirmationCheckbox.prop('checked', true);
                    this.set('confirmed', true);
                }

                confirmationCheckbox.change(function () {
                    if ($(this).is(":checked")) {
                        self.set('stepster.ready', true);
                        self.set('confirmed', true);
                    } else {
                        self.set('stepster.ready', false);
                        self.set('confirmed', false);
                    }
                });

                $('.green-header .next-btn').css('visibility', 'hidden');
            });
        },

        willDestroyElement: function willDestroyElement() {
            $('.green-header .next-btn').css('visibility', 'visible');
        },

        actions: {
            cancelReservation: function cancelReservation() {
                //go to next step
                $('.green-header .next-btn').click();
            }
        }
    });
});