define('cn-front/components/reservation/new-reservation/confirmed-payment', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Component.extend({
        reservation: _ember['default'].inject.service('reservation/instance'),
        stepster: _ember['default'].inject.service('reservation/stepster'),
        currentUser: inject.service('current-user'),

        paymentsEnabled: (function () {
            return this.get('reservation').isPaymentsEnabled();
        }).property('reservation.reservation_type'),

        didInsertElement: function didInsertElement() {
            if (this.get('stepster.finishedSteps') < this.get('step') && this.get('reservation.response.success')) {

                if (this.get('reservation').isPaymentsEnabled()) {
                    _cnFrontUtilsGtm['default'].trackEvent({
                        'event': 'checkout',
                        'ecommerce': {
                            'purchase': {
                                'actionField': {
                                    'id': this.get('reservation.response.reservation_id'),
                                    'revenue': this.get('reservation.camper_charged')
                                },
                                'products': [{
                                    'name': this.get('reservation.campground.name'),
                                    'id': this.get('reservation.campsite.id'),
                                    'price': this.get('reservation.camper_charged')
                                }]
                            }
                        }
                    });
                }

                if (this.get('reservation').isPaymentsEnabled()) {
                    var user = {};
                    if (this.get('reservation.reservation_type') == "on_site") {
                        user = this.get('reservation.remote_user').user;
                    } else {
                        user = this.get('currentUser').user;
                    }

                    _cnFrontUtilsGtm['default'].trackEvent({
                        'event': 'checkout_purchase',
                        'reservation_type': this.get('reservation.reservation_type'),
                        'transactionId': this.get('reservation.response.reservation_id'), // string    CN reservation ID number
                        'transactionCG': this.get('reservation.campground.id'), // numeric   CG ID number

                        'transactionUser': user.id, // numeric   User ID number (camper)
                        'transactionUserEmail': user.email, // string    User Email (camper)
                        'transactionUserCity': user.city, // string    User City (camper)
                        'transactionUserState': user.state, // string    User State (camper)
                        'transactionUserZip': user.zip_code, // string    User Zip Code (camper)

                        'transactionCGFee': this.get('reservation.total_without_fee'), // if exists, numeric   Original Campground Fee
                        'transactionCNTransFee': this.get('reservation.camper_fee'), // if exists, numeric   Original CN Transaction Fee (due from camper)
                        'transactionTotal': this.get('reservation.camper_charged'), // if exists, numeric   Original Paid By Camper
                        'transactionCCFee': this.get('reservation.cg_fee'), // if exists, numeric   Original Credit Card Fee (due from campground)
                        'transactionPaidToCG': this.get('reservation.cg_received') // if exists, numeric   Original Paid To Campground
                    });
                }

                this.set('stepster.finishedSteps', this.get('step'));
                this.get('stepster').onStepChange();
            }
        }
    });
});