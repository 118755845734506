define('cn-front/controllers/dashboard/campground/edit/reports', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        page: 1,
        report_type: '',
        sdate: '',
        edate: '',
        orderBy: '',
        sort: 'ASC',
        shouldGetReport: false,
        reservation_type: false,

        restoreDefaults: function restoreDefaults() {

            this.set('page', 1);
            this.set('report_type', '');
            this.set('sdate', '');
            this.set('edate', '');
            this.set('orderBy', '');
            this.set('sort', 'ASC');
            this.set('shouldGetReport', false);
        },

        isExportable: (function () {
            return this.get('report_type') == 'reservation' || this.get('report_type') == 'financial' || this.get('report_type') == 'camper';
        }).property('report_type'),

        isReceipt: (function () {
            return this.get('report_type') == 'receipt';
        }).property('report_type'),

        isReservation: (function () {
            return this.get('report_type') == 'reservation';
        }).property('report_type'),

        isCamper: (function () {
            return this.get('report_type') == 'camper';
        }).property('report_type'),

        isAvailability: (function () {
            return this.get('report_type') == 'availability';
        }).property('report_type'),

        isUtilization: (function () {
            return this.get('report_type') == 'utilization';
        }).property('report_type'),

        isFinancial: (function () {
            return this.get('report_type') == 'financial';
        }).property('report_type'),

        reportTitle: (function () {
            if (this.get('report_type') == 'receipt') {
                return 'Reservation Receipts for Arrival Date ' + this.get('model.startDate');
            }
            if (this.get('report_type') == 'reservation') {
                return 'Reservation List for ' + (this.get('model.startDate') ? 'Arrival Date ' + this.get('model.startDate') : 'all times');
            }
            if (this.get('report_type') == 'camper') {
                return 'Camper List for ' + (this.get('model.startDate') ? 'Arrival Date ' + this.get('model.startDate') : 'all times');
            }
            if (this.get('report_type') == 'availability') {
                return 'Availability Report for Arrival Date ' + this.get('model.startDate');
            }
            if (this.get('report_type') == 'utilization') {
                return 'Utilization Report for Arrival Date ' + this.get('model.startDate');
            }
            if (this.get('report_type') == 'financial') {
                return 'Financial Report for Created Date ' + this.get('model.startDate');
            }
            return false;
        }).property('report_type', 'model'),

        reportTitleSlug: (function () {
            return this.get('reportTitle') ? this.get('reportTitle').replace(/\s+/g, '-').toLowerCase() : 'report';
        }).property('report_type', 'model'),

        handleFilters: function handleFilters(forPrint) {
            // Each time getting new report reset pagination
            this.set('page', 1);

            // Reset utilization report
            $('#calendar').fullCalendar('destroy');
            $('.spinner').show();

            // Get new report type and period
            var reportType = $('#report-type').val();
            var reportPeriod = $('#report-period').val();

            if (!forPrint) {
                // If switching report type, restore sorting defaults
                if (reportType !== this.get('report_type')) {
                    this.set('orderBy', '');
                    this.set('sort', 'ASC');
                }

                if (reportType === 'reservation' || reportType === 'financial') {
                    this.set('sort', 'DESC');
                }
            }

            var sdate = '',
                edate = ''; // jshint ignore:line
            var datepicker = $('#report-start-date');
            var dateCheck = moment(datepicker.val(), 'MM/DD/YYYY');
            var month = dateCheck.format('MM');
            var year = dateCheck.format('YYYY');

            if (reportPeriod == 'yearly') {
                sdate = moment([year]).startOf('year').format('MM/DD/YYYY');
                edate = moment(sdate).endOf('year').format('MM/DD/YYYY');
            } else if (reportPeriod == 'monthly') {
                sdate = moment([year, month - 1]).format('MM/DD/YYYY');
                edate = moment(sdate).endOf('month').format('MM/DD/YYYY');
            } else if (reportPeriod == 'weekly' || reportPeriod == 'daily') {
                sdate = datepicker.val();

                if (reportPeriod == 'weekly') {
                    edate = moment(sdate).add(6, 'days').format('MM/DD/YYYY');
                } else {
                    edate = datepicker.val();
                }
            }

            this.set('report_type', reportType);

            if (reportType == 'camper') {
                this.set('sdate', '');
                this.set('edate', '');
            } else {
                this.set('sdate', sdate);
                this.set('edate', edate);
            }

            this.set('shouldGetReport', true);
        },

        actions: {
            changePage: function changePage(current) {
                this.set('page', current);
                this.send('refreshModel');
            },

            reviewOrder: function reviewOrder(slug) {
                var sortHeader = $('.order-source.' + slug);
                sortHeader.removeClass('asc desc');
                var newOrder = 'ASC';

                if (sortHeader.hasClass('active-sort')) {
                    newOrder = this.get('sort') == 'ASC' ? 'DESC' : 'ASC';
                } else {
                    $('.order-source.active-sort').removeClass('active-sort asc desc');
                    sortHeader.addClass('active-sort');
                    this.set('orderBy', slug);
                }
                this.set('sort', newOrder);
                sortHeader.addClass(newOrder.toLowerCase());
                this.send('refreshModel');
            },

            getReports: function getReports() {
                this.handleFilters();
                this.send('refreshModel');
            },
            seeDetails: function seeDetails(id) {
                var data = {
                    id: id,
                    type: "dash"
                };
                this.send('showModal', 'dashboard/modal/resinfo', data);
            }
        }
    });
});