define('cn-front/controllers/dashboard/modal/resinfo', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        currentUser: inject.service('current-user'),
        reservation: {},
        computedDuration: '',
        isPaymentsEnabled: function isPaymentsEnabled() {
            var disabledPaymentOn = ["in_use", "maint", "fcfs", "info_req"];
            return disabledPaymentOn.indexOf(this.get('reservation.type')) == -1;
        },
        paymentsEnabled: (function () {
            return this.isPaymentsEnabled();
        }).property('reservation.type'),

        reservationTypeLabel: (function () {
            var resType = this.get('reservation.type');
            console.log(resType);
            if (resType === 'in_use') {
                return 'In Use (campsite has a prior reservation)';
            } else if (resType === 'maint') {
                return 'Maintenance (campsite is temporarily closed)';
            } else if (resType === 'fcfs') {
                return 'First Come First Served (campsite is open but may not be reserved)';
            } else if (resType === 'on_site') {
                return 'Onsite Booking (phone-in, drive-up, etc.)';
            } else if (resType === 'instant' || resType === 'confirm') {
                return 'Online Booking (through Camp Native)';
            } else if (resType === 'info_req') {
                return 'Information Request';
            } else {
                return 'Undefined';
            }
        }).property('reservation.type'),

        computeDuration: (function () {
            var sd = this.get('reservation.date_arrival');
            var ed = this.get('reservation.date_departure');
            sd = moment(sd, 'YYYY-MM-DD');
            ed = moment(ed, 'YYYY-MM-DD');

            var days = ed.diff(sd, 'days');
            var day, weeks, month;

            var computed = '';
            if (days >= 7) {
                month = Math.floor(days / 28);
                if (month > 0) {
                    day = days - month * 28;
                    weeks = Math.floor(day / 7);
                    day = day - weeks * 7;
                    computed = month + ' month(s)';
                    computed = weeks > 0 ? computed + ', ' + weeks + ' week(s) ' : computed;
                    computed = day > 0 ? computed + ', ' + day + ' day(s)' : computed;
                } else {
                    weeks = Math.floor(days / 7);
                    day = days - weeks * 7;
                    computed = weeks + ' week(s), ' + day + ' day(s)';
                }
            } else {
                computed = days + ' days';
            }

            this.set('reservation.days_computed', computed);
        }).observes('reservation.date_arrival'),

        onOpen: (function () {
            var url = this.get('apiConnect').getUrl('/reservations/get/' + this.get('model.id'));
            var self = this;
            $.ajax({
                type: "GET",
                url: url,
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                success: function success(data) {
                    self.set('reservation', data);
                    if (self.get('model.type') == 'dash' && !self.get('paymentsEnabled')) {
                        _ember['default'].run.later(function () {
                            $('.note').editable({
                                ajaxOptions: {
                                    type: 'POST',
                                    dataType: 'json'
                                },
                                type: 'textarea',
                                escape: false,
                                url: self.get('apiConnect').getUrl('/reservations/note/update'),
                                success: function success(response) {
                                    if (jQuery.isArray(response)) {
                                        return "Can not be empty";
                                    } //msg will be shown in editable form
                                }
                            });
                        }, 50);
                    }
                }
            });
        }).observes('model.id'),
        actions: {
            cancel: function cancel(initiator) {

                if (this.isPaymentsEnabled()) {
                    var url = this.get('apiConnect').getUrl("/reservations/calculate/cancel_res");
                    var ajaxPromise = $.get(url, {
                        reservation_id: this.get('model.id'),
                        initiator: initiator
                    });
                    var self = this;
                    _ember['default'].run(function () {
                        ajaxPromise.then(function (data) {
                            console.log(data);
                            self.fetchFinancialInformationCallback(initiator, data);
                            self.openCancellationPopup(initiator);
                        });
                    });
                } else {
                    this.openCancellationPopup(initiator);
                }
            },
            change: function change(initiator) {

                if (this.isPaymentsEnabled()) {
                    var url = this.get('apiConnect').getUrl("/reservations/calculate/cancel_res");
                    var ajaxPromise = $.get(url, {
                        reservation_id: this.get('model.id'),
                        initiator: initiator
                    });
                    var self = this;
                    _ember['default'].run(function () {
                        ajaxPromise.then(function (data) {
                            console.log(data);
                            self.fetchFinancialInformationCallback(initiator, data);
                            self.openChangePopup(initiator);
                        });
                    });
                } else {
                    this.openChangePopup(initiator);
                }
            }
        },

        openCancellationPopup: function openCancellationPopup(initiator) {
            var sd = this.get('reservation.date_arrival');

            var model = {
                reservation: this.get('reservation'),
                reservation_id: this.get('model.id'),
                initiator: initiator,
                type: this.get('model.type'),
                in_process: moment(sd).valueOf() <= moment().valueOf()
            };
            this.send('showModal', 'dashboard/modal/rescancel', model);
        },
        openChangePopup: function openChangePopup(initiator) {
            var sd = this.get('reservation.date_arrival');
            var model = {
                reservation: this.get('reservation'),
                reservation_id: this.get('model.id'),
                initiator: initiator,
                dashOrFront: this.get('model.type'),
                in_process: moment(sd).valueOf() <= moment().valueOf()
            };
            this.send('showModal', 'dashboard/modal/reschange', model);
        },
        fetchFinancialInformationCallback: function fetchFinancialInformationCallback(initiator, data) {
            if (initiator == 'campground') {
                this.set('reservation.cg_penalty', data.cg_fee);
            } else {
                this.set('reservation.cg_fee', data.cg_fee);
                this.set('reservation.camper_fee', data.camper_fee);
            }
            this.set('reservation.camper_refunded', data.camper_refunded);
            this.set('reservation.cg_charged', data.cg_charged);
        }
    });
});