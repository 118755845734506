define('cn-front/components/dashboard/step/verify-camp', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Component.extend({
        reservation: inject.service('dashboard/reservation'),
        stepster: inject.service('dashboard/stepster'),
        apiConnect: inject.service('api-resolver'),
        currentUser: inject.service('current-user'),
        daterange: '',

        isPaymentsEnabled: function isPaymentsEnabled() {
            var disabledPaymentOn = ["in_use", "maint", "fcfs", "info_req"];
            return disabledPaymentOn.indexOf(this.get('reservation.restype')) == -1;
        },

        isReservationMinDurationValid: (function () {
            var validTypes = ["instant", "confirm"];
            return validTypes.indexOf(this.get('reservation.restype')) != -1;
        }).property('reservation.restype'),

        paymentsEnabled: (function () {
            return this.isPaymentsEnabled();
        }).property('reservation.restype'),

        rules: (function () {
            var rules = false;
            if (this.get('reservation.camp.description_rules')) {
                rules = this.get('reservation.camp.description_rules') + "<br>";
            }
            if (this.get('reservation.campsite.description_rules')) {
                rules += '<br>' + this.get('reservation.campsite.description_rules');
            }

            return rules;
        }).property(),

        reservationTypeLabel: (function () {
            if (this.get('reservation.restype') === 'in_use') {
                return 'In Use (campsite has a prior reservation)';
            } else if (this.get('reservation.restype') === 'maint') {
                return 'Maintenance (campsite is temporarily closed)';
            } else if (this.get('reservation.restype') === 'fcfs') {
                return 'First Come First Served (campsite is open but may not be reserved)';
            } else {
                return 'Undefined';
            }
        }).property('reservation.restype'),

        setProps: (function () {

            if (this.get('reservation.restype') === 'info_req') {
                this.set('stepster.ready', true);

                _ember['default'].run.schedule('afterRender', this, function () {
                    var modalTitle = 'Request Information';
                    $('.modal .modal-title').html(modalTitle);
                });
            } else {
                this.set('stepster.ready', false);
                this.set('stepster.fallback', function () {
                    $('.reservation-daterange').css('border-color', 'red');
                    $('.error').fadeIn();
                });
                var params = this.get('isReservationMinDurationValid') ? { with_min_duration: true } : '';
                _ember['default'].run.schedule('afterRender', this, function () {
                    var url = this.get('apiConnect').getUrl('/campground/reservations/future/' + this.get('reservation.campsite.id'));
                    var self = this;
                    $.ajax({
                        type: "GET",
                        url: url,
                        data: params,
                        async: true,
                        success: function success(data) {

                            var minDate,
                                maxInDate,
                                minDur = 1;
                            if (self.get('isReservationMinDurationValid')) {
                                minDur = parseInt(self.get('reservation.campsite.reservation_min_duration'));
                            }
                            console.log(minDur);
                            console.log(self.get('reservation'));
                            if (self.get('reservation.type') == "front") {
                                minDate = moment().add(parseInt(self.get('reservation.camp.reservation_inadvance_min')), 'd').format('MM/DD/YYYY');
                                maxInDate = moment().add(parseInt(self.get('reservation.camp.reservation_inadvance_max')), 'M').format('MM/DD/YYYY');
                            }

                            self.set('disabledDates', data);
                            //initialize datepickers with rules
                            $('#res-arrival').datepicker({
                                orientation: "bottom left",
                                format: 'mm/dd/yyyy',
                                autoclose: true,
                                startDate: minDate,
                                endDate: maxInDate,
                                datesDisabled: self.get('disabledDates')
                            }).on('changeDate', function (e) {
                                $('#res-departure').datepicker('setEndDate', null);
                                $('#res-departure').datepicker('setStartDate', null);

                                var target = new Date(e.date),
                                    dep = $('#res-departure').val();
                                var dis = self.get('disabledDates');
                                var maxDate = '';
                                if (dis.length) {
                                    var positiveArr = dis.filter(function (date) {
                                        return new Date(date) > target;
                                    });
                                    if (positiveArr.length) {
                                        maxDate = new Date(positiveArr.reduce(function (a, b) {
                                            return new Date(a) < new Date(b) ? a : b;
                                        }));

                                        // maxDate.setDate(maxDate.getDate() + 1);
                                        $('#res-departure').datepicker('setEndDate', maxDate);
                                    } else {
                                        $('#res-departure').datepicker('setEndDate', maxInDate);
                                    }
                                } else {
                                    $('#res-departure').datepicker('setEndDate', maxInDate);
                                }
                                target.setDate(target.getDate() + minDur);

                                if (maxDate !== '') {
                                    if (new Date(dep) < target || dep === '' || maxDate < new Date(dep)) {
                                        $('#res-departure').datepicker('setDate', target);
                                    }
                                    $('#res-departure').datepicker('setStartDate', target);
                                } else {
                                    $('#res-departure').datepicker('setStartDate', target);
                                }

                                self.reCalc();
                            });

                            $('#res-departure').datepicker({
                                orientation: "bottom left",
                                autoclose: true,
                                format: 'mm/dd/yyyy',
                                startDate: minDate,
                                endDate: maxInDate
                            }).on('changeDate', function () {
                                self.reCalc();
                            });

                            //set start-end dates
                            var start = self.get('reservation.startdate');
                            $('#res-arrival').datepicker('setDate', start);
                            var end = new Date(start);
                            end.setDate(end.getDate() + minDur);
                            self.set('reservation.enddate', moment(end).format('MM/DD/YYYY'));
                            // set departure date
                            // $('#res-departure').datepicker('setDate', null);
                        }
                    });
                });
            }
        }).on('init'),

        onSelect: (function () {
            $('.res-footer .next').prop('disabled', false);
            $('.res-footer .close').prop('disabled', false);
            if (this.get('stepster.currentStep') == this.get('tabId')) {

                if (this.get('stepster.finishedSteps') < this.get('stepster.currentStep')) {
                    if (this.isPaymentsEnabled()) {
                        _cnFrontUtilsGtm['default'].trackEvent({
                            'event': 'checkout',
                            'ecommerce': {
                                'checkout': {
                                    'actionField': { 'step': 1, 'option': 'Verify Camper' },
                                    'products': [{
                                        'name': this.get('reservation.camp.name'),
                                        'id': this.get('reservation.campsite.id')
                                    }]
                                }
                            }
                        });
                    }

                    if (this.isPaymentsEnabled() || this.get('reservation.restype') == 'info_req') {
                        if (this.get('currentUser.logged') && this.get('reservation.restype') != 'on_site') {
                            _cnFrontUtilsGtm['default'].trackEvent({
                                'event': 'checkout_step_1',
                                'reservation_type': this.get('reservation.restype'),
                                'transactionCG': this.get('reservation.camp.id'), // numeric   CG ID number
                                'transactionUser': this.get('currentUser').user.id, // numeric   User ID number (camper)
                                'transactionUserEmail': this.get('currentUser').user.email, // string    User Email (camper)
                                'transactionUserCity': this.get('currentUser').user.city, // string    User City (camper)
                                'transactionUserState': this.get('currentUser').user.state, // string    User State (camper)
                                'transactionUserZip': this.get('currentUser').user.zip_code // string    User Zip Code (camper)
                            });
                        } else {
                                _cnFrontUtilsGtm['default'].trackEvent({
                                    'event': 'checkout_step_1',
                                    'reservation_type': this.get('reservation.restype'),
                                    'transactionCG': this.get('reservation.camp.id') // numeric   CG ID number
                                });
                            }
                    }

                    this.set('stepster.finishedSteps', this.get('stepster.currentStep'));
                }

                if (this.get('reservation.days') > 0) {
                    this.set('stepster.ready', true);
                    $('.reservation-daterange').css('border-color', ' #ccd0d4');
                    $('.error').fadeOut();
                }
            }
        }).observes('stepster.currentStep').on('init'),

        reCalc: function reCalc() {

            var wr = this.get('reservation.rate_weekly');
            var mr = this.get('reservation.rate_monthly');

            var sd = $('#res-arrival').val(),
                ed = $('#res-departure').val();

            this.set('reservation.daterange', sd + ' - ' + ed);

            this.set('reservation.startdate', sd);
            this.set('reservation.enddate', ed);

            sd = moment(sd, 'MM/DD/YYYY');
            ed = moment(ed, 'MM/DD/YYYY');

            var days = ed.diff(sd, 'days');

            this.set('reservation.days', days);
            days = days !== days ? 0 : days;

            if (days > 0) {
                this.set('stepster.ready', true);
                //clear error message
                $('.reservation-daterange').css('border-color', ' #ccd0d4');
                $('.error').fadeOut();
            } else {
                this.set('stepster.ready', false);
            }

            var computed = '';
            var day, weeks, month;

            if (days >= 7 && wr !== 0) {
                month = Math.floor(days / 28);
                if (month > 0 && mr !== 0) {
                    day = days - month * 28;
                    weeks = Math.floor(day / 7);
                    day = day - weeks * 7;
                    computed = month + ' month(s)';
                    computed = weeks > 0 ? computed + ', ' + weeks + ' week(s) ' : computed;
                    computed = day > 0 ? computed + ', ' + day + ' day(s)' : computed;
                } else {
                    weeks = Math.floor(days / 7);
                    day = days - weeks * 7;
                    computed = weeks + ' week(s), ' + day + ' day(s)';
                }
            } else {
                computed = days + ' day(s)';
            }

            this.set('reservation.days_computed', computed);

            if (this.isPaymentsEnabled()) {
                this.fetchFinancialInformation();
            }
        },

        fetchFinancialInformation: function fetchFinancialInformation() {
            var url = this.get('apiConnect').getUrl("/reservations/calculate/create_res");
            var ajaxPromise = $.get(url, {
                reservation_type: this.get('reservation.restype'),
                cs_id: this.get('reservation.campsite.id'),
                daterange: this.get('reservation.daterange')
            });
            var self = this;
            _ember['default'].run(function () {
                ajaxPromise.then(function (data) {
                    self.fetchFinancialInformationCallback(data);
                });
            });
        },

        fetchFinancialInformationCallback: function fetchFinancialInformationCallback(data) {
            this.set('reservation.total_without_fee', data.total_without_fee);
            this.set('reservation.camper_fee', data.camper_fee);
            this.set('reservation.camper_charged', data.camper_charged);
            this.set('reservation.cg_fee', data.cg_fee);
            this.set('reservation.cg_received', data.cg_received);
            this.set('reservation.cn_fee_cap', data.cn_fee_cap);
            this.set('reservation.svc_fee', data.svc_fee);
        }

    });
});