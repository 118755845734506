define("cn-front/templates/components/dashboard/reports/transactions/req-form", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 8
            },
            "end": {
              "line": 19,
              "column": 8
            }
          },
          "moduleName": "cn-front/templates/components/dashboard/reports/transactions/req-form.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'value');
          morphs[1] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["attribute", "value", ["concat", [["get", "mgr", ["loc", [null, [18, 29], [18, 32]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "mgr", ["loc", [null, [18, 36], [18, 43]]], 0, 0, 0, 0]],
        locals: ["mgr"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 8
            },
            "end": {
              "line": 27,
              "column": 8
            }
          },
          "moduleName": "cn-front/templates/components/dashboard/reports/transactions/req-form.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'value');
          morphs[1] = dom.createAttrMorph(element0, 'selected');
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "value", ["get", "prop.id", ["loc", [null, [26, 28], [26, 35]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "selected", ["subexpr", "is-selected", [["get", "prop.id", ["loc", [null, [26, 61], [26, 68]]], 0, 0, 0, 0], ["get", "selectedAffiliate", ["loc", [null, [26, 69], [26, 86]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [26, 88]]], 0, 0], 0, 0, 0, 0], ["content", "prop.name", ["loc", [null, [26, 89], [26, 102]]], 0, 0, 0, 0]],
        locals: ["prop"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 69,
            "column": 7
          }
        },
        "moduleName": "cn-front/templates/components/dashboard/reports/transactions/req-form.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("form");
        dom.setAttribute(el1, "class", "reports-request-form");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "for", "report-period");
        var el3 = dom.createTextNode("Report period: ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("select");
        dom.setAttribute(el2, "id", "report-period");
        dom.setAttribute(el2, "class", "form-control");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("option");
        dom.setAttribute(el3, "value", "all_time");
        var el4 = dom.createTextNode("All time");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("option");
        dom.setAttribute(el3, "value", "yearly");
        var el4 = dom.createTextNode("Yearly");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("option");
        dom.setAttribute(el3, "value", "monthly");
        var el4 = dom.createTextNode("Monthly");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("option");
        dom.setAttribute(el3, "value", "weekly");
        var el4 = dom.createTextNode("Weekly");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("option");
        dom.setAttribute(el3, "value", "daily");
        var el4 = dom.createTextNode("Daily");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "for", "report-start-date");
        var el3 = dom.createTextNode("Created Date: ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2, "id", "report-start-date");
        dom.setAttribute(el2, "class", "form-control");
        dom.setAttribute(el2, "type", "text");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "for", "report-acctmgr");
        var el3 = dom.createTextNode("Account Mgr: ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("select");
        dom.setAttribute(el2, "id", "report-acctmgr");
        dom.setAttribute(el2, "class", "form-control");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("option");
        dom.setAttribute(el3, "value", "all");
        var el4 = dom.createTextNode("All");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("option");
        dom.setAttribute(el3, "value", "");
        var el4 = dom.createTextNode("Empty");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "for", "report-affiliate");
        var el3 = dom.createTextNode("Affiliate: ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("select");
        dom.setAttribute(el2, "id", "report-affiliate");
        dom.setAttribute(el2, "class", "form-control");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("option");
        dom.setAttribute(el3, "value", "");
        var el4 = dom.createTextNode("Empty");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-info");
        var el3 = dom.createTextNode("Get Report");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "filters");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "reservations_types");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "filter-label");
        var el5 = dom.createTextNode("Type:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "checkbox-inline");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "checkbox");
        dom.setAttribute(el5, "name", "instant_type");
        dom.setAttribute(el5, "id", "instant_type");
        dom.setAttribute(el5, "checked", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Instant\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "checkbox-inline");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "checkbox");
        dom.setAttribute(el5, "name", "confirm_type");
        dom.setAttribute(el5, "id", "confirm_type");
        dom.setAttribute(el5, "checked", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Confirmation\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "checkbox-inline");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "checkbox");
        dom.setAttribute(el5, "name", "on_site_type");
        dom.setAttribute(el5, "id", "on_site_type");
        dom.setAttribute(el5, "checked", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" On Site\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "reservations_statuses");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "filter-label");
        var el5 = dom.createTextNode("Status:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "checkbox-inline");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "checkbox");
        dom.setAttribute(el5, "name", "paid_status");
        dom.setAttribute(el5, "data-status", "2");
        dom.setAttribute(el5, "checked", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Paid\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "checkbox-inline");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "checkbox");
        dom.setAttribute(el5, "name", "pending_status");
        dom.setAttribute(el5, "data-status", "1");
        dom.setAttribute(el5, "checked", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Pending\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "checkbox-inline");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "checkbox");
        dom.setAttribute(el5, "name", "denied_status");
        dom.setAttribute(el5, "data-status", "9");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Denied\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "checkbox-inline");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "checkbox");
        dom.setAttribute(el5, "name", "expired_status");
        dom.setAttribute(el5, "data-status", "6");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Expired\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "checkbox-inline");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "checkbox");
        dom.setAttribute(el5, "name", "cancelled_status");
        dom.setAttribute(el5, "data-status", "4");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Cancelled\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "checkbox-inline");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "checkbox");
        dom.setAttribute(el5, "name", "changed_status");
        dom.setAttribute(el5, "data-status", "3");
        dom.setAttribute(el5, "checked", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Changed\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "checkbox-inline");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "checkbox");
        dom.setAttribute(el5, "name", "chargeback_status");
        dom.setAttribute(el5, "data-status", "5");
        dom.setAttribute(el5, "checked", "");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Chargeback\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [15]);
        var element4 = dom.childAt(element2, [17]);
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element2, [19, 1, 3, 1]), [], true);
        }
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element2, [19, 1, 5, 1]), [], true);
        }
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element2, [19, 1, 7, 1]), [], true);
        }
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element2, [19, 3, 3, 1]), [], true);
        }
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element2, [19, 3, 5, 1]), [], true);
        }
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element2, [19, 3, 13, 1]), [], true);
        }
        if (this.cachedFragment) {
          dom.repairClonedNode(dom.childAt(element2, [19, 3, 15, 1]), [], true);
        }
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [11]), 5, 5);
        morphs[1] = dom.createAttrMorph(element3, 'onchange');
        morphs[2] = dom.createMorphAt(element3, 3, 3);
        morphs[3] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [["block", "each", [["get", "acct_mgr", ["loc", [null, [17, 16], [17, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 8], [19, 17]]]], ["attribute", "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "selectedAffiliate", ["loc", [null, [23, 78], [23, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 73], [23, 96]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [23, 119]]], 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "affiliates", ["loc", [null, [25, 16], [25, 26]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [25, 8], [27, 17]]]], ["element", "action", ["getReportsAct"], [], ["loc", [null, [30, 33], [30, 59]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});