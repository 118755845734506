define('cn-front/components/dashboard/reports/onboards/req-form', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Component.extend({

        apiConnect: inject.service('api-resolver'),
        ustates: inject.service('ustates'),
        statesList: (function () {
            var array = $.map(this.get('ustates').data, function (value) {
                return [value];
            });
            return array.slice(0, -2);
        }).property(),

        didInsertElement: function didInsertElement() {
            var self = this;

            var acct_mgr = _ember['default'].$.ajax({
                type: "GET",
                url: this.get('apiConnect').getUrl('/dashboard/users/acct_mgr'),
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                }
            });

            var fields = _ember['default'].$.ajax({
                type: "GET",
                url: this.get('apiConnect').getUrl('/dashboard/reports/filter/params'),
                data: { fields: 'affiliate,reservation,application,confirmation,page_type,owner_group' },
                async: false,
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                }
            });

            fields.then(function (data) {
                self.set('data', data);
                acct_mgr.then(function (data) {
                    self.set('data.acct_mgr', data);
                    console.log(self.get("data"));
                });
            });
        },

        actions: {
            getReportsTo: function getReportsTo() {
                this.sendAction('getReports');
            },
            resetReportsTo: function resetReportsTo() {
                this.sendAction('resetReports');
            }
        }
    });
});