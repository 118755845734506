define('cn-front/controllers/photo-popup', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        shown: {},
        curr: 0,
        max: 0,
        photos: [],
        compHeight: 0,
        compWidth: 'inherit',
        largeImgUrl: (function () {
            return new _ember['default'].Handlebars.SafeString(this.get('shown.large'));
        }).property('shown.large'),
        shownPhotoStyle: (function () {
            return new _ember['default'].Handlebars.SafeString("height: " + this.get('compHeight') + "px; width : " + this.get('compWidth') + ";");
        }).property('compHeight'),
        format: "MMM. DD, YYYY",
        formattedDate: (function () {
            var format = this.get('format');
            var date = this.get('shown.date.date');
            return moment(date).format(format);
        }).property('shown'),

        onInit: (function () {
            _ember['default'].run.schedule("afterRender", this, function () {
                var _this = this;

                var self = this;
                var obj = this.get('model');

                this.set('curr', parseInt(obj.clicked));
                this.set('shown', obj.photos[parseInt(obj.clicked)]);
                this.set('max', obj.photos.length);
                this.set('photos', obj.photos);

                $(document).on('click', '#all-photos .cs-thumbnail-middle', function () {
                    var id = parseInt($(this).attr('id'));
                    self.set('shown', obj.photos[id]);
                    self.set('curr', id);
                });

                if (!_ember['default'].testing) {
                    $(window).resize(function () {
                        return _this.calcHeight();
                    });

                    $(document).keyup(function (e) {
                        if (e.keyCode === 27) {
                            $('.close').click();
                        } // esc
                    });

                    $("#shown").swipe({
                        swipe: function swipe(event, direction /*, distance, duration, fingerCount, fingerData*/) {
                            switch (direction) {
                                case 'left':
                                    self.send('next');
                                    break;
                                case 'right':
                                    self.send('prev');
                                    break;
                            }
                        }
                    });

                    _ember['default'].run.later(function () {

                        var owl = $('#all-photos');
                        owl.owlCarousel({
                            loop: false,
                            margin: 0,
                            nav: false,
                            dots: false,
                            responsive: {
                                0: { items: 3 },
                                440: { items: 4 },
                                560: { items: 5 },
                                680: { items: 6 },
                                780: { items: 7 },
                                910: { items: 8 }
                            }
                        });

                        $('.all-photos .next').click(function () {
                            owl.trigger('next.owl.carousel');
                        });

                        $('.all-photos .prev').click(function () {
                            owl.trigger('prev.owl.carousel');
                        });

                        self.calcHeight();
                    }, 400);
                }
            });
        }).observes('model'),

        calcHeight: function calcHeight() {
            var height = $('#shown').width() / 4 * 3;
            if (height > $(window).height() - 200) {
                height = $(window).height() - 200;
            }
            var imageHeight = $('#shown img').height();
            if (height > imageHeight && imageHeight !== 0) {
                height = imageHeight;
            }

            var width = "inherit";
            if ($(window).height() < $(window).width() && $(window).height() < 769) {
                var w = height / 3 * 4;
                width = w + "px; margin: 0 auto;";
            }

            this.set('compWidth', width);
            this.set('compHeight', height);
        },

        formatId: (function () {
            this.set('shown.formatedId', this.get('shown.indexId') + 1);
        }).observes('shown.indexId'),

        actions: {
            next: function next() {
                var photos = this.get('photos');
                var q = this.get('curr') + 1;
                if (q == this.get('max')) {
                    q = 0;
                }
                this.set('shown', photos[q]);
                this.set('curr', q);
            },
            prev: function prev() {
                var photos = this.get('photos');
                var q = this.get('curr') - 1;
                if (q == -1) {
                    q = this.get('max') - 1;
                }
                this.set('shown', photos[q]);
                this.set('curr', q);
            }
        }

    });
});