define('cn-front/helpers/format-bytes', ['exports', 'ember'], function (exports, _ember) {
    exports.formatBytes = formatBytes;

    function formatBytes(params /*, hash*/) {
        var kiloBytes = Math.round(params[0] / 1024);

        return kiloBytes > 0 ? kiloBytes + ' Kb' : '';
    }

    exports['default'] = _ember['default'].Helper.helper(formatBytes);
});