define('cn-front/components/reservation/new-reservation/section-verify-campground', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
  exports['default'] = _ember['default'].Component.extend({
    reservation: _ember['default'].inject.service('reservation/instance'),
    stepster: _ember['default'].inject.service('reservation/stepster'),
    currentUser: _ember['default'].inject.service('current-user'),
    longDays: false,
    step: '',
    currentCalculationTime: 0,

    /**
     * Helper function
     */
    isReservationMinDurationValid: (function () {
      var validTypes = ["instant", "confirm"];
      return validTypes.indexOf(this.get('reservation.reservation_type')) !== -1;
    }).property('reservation.reservation_type'),

    /**
     * Helper property
     */
    paymentsEnabled: (function () {
      return this.get('reservation').isPaymentsEnabled();
    }).property('reservation.reservation_type'),

    /**
     * Property: rules
     */
    rules: (function () {
      var rules = false;
      if (this.get('reservation.campground.description_rules')) {
        rules = this.get('reservation.campground.description_rules') + "<br>";
      }
      if (this.get('reservation.campsite.description_rules')) {
        rules += '<br>' + this.get('reservation.campsite.description_rules');
      }

      return rules;
    }).property(),

    /**
     * Property: reservation type label (human readable)
     */
    reservationTypeLabel: (function () {
      if (this.get('reservation.reservation_type') === 'in_use') {
        return 'In Use (campsite has a prior reservation)';
      } else if (this.get('reservation.reservation_type') === 'maint') {
        return 'Maintenance (campsite is temporarily closed)';
      } else if (this.get('reservation.reservation_type') === 'fcfs') {
        return 'First Come First Served (campsite is open but may not be reserved)';
      } else {
        return 'Undefined';
      }
    }).property('reservation.reservation_type'),

    didReceiveAttrs: function didReceiveAttrs() {

      this.set('stepster.ready', false);
      this.set('stepster.fallback', function () {
        $('.error').fadeIn();
      });
    },

    didInsertElement: function didInsertElement() {
      var url = this.get('api').getUrl('/campground/reservations/future/' + this.get('reservation.campsite.id'));
      var params = this.get('isReservationMinDurationValid') ? { with_min_duration: true } : '';
      var self = this;
      var ajaxPromise = _ember['default'].$.ajax({
        url: url,
        type: "GET",
        data: params,
        async: true
      });

      ajaxPromise.then(function (data) {

        /**
         * Setup start date
         */
        var start = moment(self.get('reservation.start_date')).format('MM/DD/YYYY');
        var end_date = moment(self.get('reservation.end_date')).format('MM/DD/YYYY');
        var rangeDate = moment(self.get('reservation.end_date')).diff(moment(self.get('reservation.start_date')), 'days');

        // console.log('start_date ======= ', rangeDate, self.get('reservation.start_date'), self.get('reservation.end_date'));
        /**
         * Choose valid date if current is invalid
         */
        var disabledDates = data;
        if (disabledDates.length && disabledDates.indexOf(moment(end_date).format('MM/DD/YYYY')) !== -1) {
          var f = 0;
          while (f < 365) {
            f++;
            start = moment(end_date).format('MM/DD/YYYY');
            if (disabledDates.indexOf(start) === -1) {
              f = 366;
            }
          }
        }

        /**
         * Check min duration validity
         */
        var minDate,
            maxInDate,
            minDuration = 1;

        if (self.get('isReservationMinDurationValid')) {
          minDuration = parseInt(self.get('reservation.campsite.reservation_min_duration.value')) || 1;
        }
        // console.log('rangeDate',rangeDate, minDuration);
        if (rangeDate >= minDuration) {
          minDuration = rangeDate;
        }

        /**
         * Check in advance validity
         */
        if (self.get('reservation.type') === "front" || self.get('reservation.reservation_type') === "on_site") {
          minDate = moment().add(parseInt(self.get('reservation.campground.reservation_inadvance_min')), 'd').format('MM/DD/YYYY');
          maxInDate = moment().add(parseInt(self.get('reservation.campground.reservation_inadvance_max')), 'M').format('MM/DD/YYYY');
        }
        /**
         * Save disabledDates for later use
         */
        self.set('disabledDates', data);
        /**
         * Initialize arrival date picker based on specific rules
         */
        var arrivalDatePicker = $('#res-arrival');
        var departureDatePicker = $('#res-departure');

        arrivalDatePicker.datepicker({
          orientation: "bottom left",
          format: 'mm/dd/yyyy',
          autoclose: true,
          startDate: minDate,
          endDate: maxInDate,
          datesDisabled: self.get('disabledDates')
        }).on('changeDate', function (e) {

          /**
           * Define dynamic min reservation duration
           */
          // console.log('minDuration111', minDuration);

          self.setCurrentMinDuration(e.date);
          if (self.get('reservation.min_duration.value') >= minDuration) {
            minDuration = self.get('reservation.min_duration.value');
          }
          // console.log('minDuration222', minDuration);
          /**
           * On change: reset start/end date
           */
          departureDatePicker.datepicker('setEndDate', null);
          departureDatePicker.datepicker('setStartDate', null);

          /**
           * On change: validate chosen arrival date
           */
          var target = new Date(e.date),
              departureDate = departureDatePicker.val(),
              disabledDates = self.get('disabledDates'),
              maxDate = '';

          if (disabledDates.length) {
            var positiveArr = disabledDates.filter(function (date) {
              return new Date(date) > target;
            });

            if (positiveArr.length) {
              maxDate = new Date(positiveArr.reduce(function (a, b) {
                return new Date(a) < new Date(b) ? a : b;
              }));

              // maxDate.setDate(maxDate.getDate() + 1); // TODO [future]: remove this in next phase if not needed
              departureDatePicker.datepicker('setEndDate', maxDate);
            } else {
              departureDatePicker.datepicker('setEndDate', maxInDate);
            }
          } else {
            departureDatePicker.datepicker('setEndDate', maxInDate);
          }

          target.setDate(target.getDate() + minDuration);

          if (maxDate !== '') {
            if (new Date(departureDate) < target || departureDate === '' || maxDate < new Date(departureDate)) {
              departureDatePicker.datepicker('setDate', target);
            }
            departureDatePicker.datepicker('setStartDate', target);
          } else {
            departureDatePicker.datepicker('setStartDate', target);
          }

          /**
           * On change: re-run cost calculations
           */
          self.recalculateCost();
        });

        /**
         * Initialize departure date picker based on specific rules
         */
        departureDatePicker.datepicker({
          orientation: "bottom left",
          autoclose: true,
          format: 'mm/dd/yyyy',
          startDate: minDate,
          endDate: maxInDate
        }).on('changeDate', function (e) {
          /**
           * On change: re-run cost calculations
           */
          self.recalculateCost();
        });

        /**
         * Update arrival date in case date is invalid
         */
        arrivalDatePicker.datepicker('setDate', start);

        var end = new Date(start);
        end.setDate(end.getDate() + minDuration); //add custom duration end
        console.log('start = ', start, ' end = ', end);
        departureDatePicker.datepicker('setDate', end);
        self.set('reservation.end_date', moment(end).format('MM/DD/YYYY'));
      });
    },

    setCurrentMinDuration: function setCurrentMinDuration(arrival) {
      arrival = moment(arrival).format('MM/DD/YYYY');
      var url = this.get('api').getUrl('/campsites/' + this.get('reservation.campsite.id')) + '/min-duration';
      var self = this;
      var ajaxPromise = _ember['default'].$.ajax({
        url: url,
        type: "GET",
        data: { arrival: arrival },
        async: false
      });

      ajaxPromise.then(function (data) {
        self.set('reservation.min_duration', data);
      });
    },

    stepInitialize: (function () {
      if (this.get('stepster.finishedSteps') < this.get('step')) {
        if (this.get('reservation').isPaymentsEnabled()) {
          _cnFrontUtilsGtm['default'].trackEvent({
            'event': 'checkout',
            'ecommerce': {
              'checkout': {
                'actionField': { 'step': 1, 'option': 'Verify Camper' },
                'products': [{
                  'name': this.get('reservation.campground.name'),
                  'id': this.get('reservation.campsite.id')
                }]
              }
            }
          });
        }

        if (this.get('reservation').isPaymentsEnabled()) {
          if (this.get('currentUser.logged') && this.get('reservation.reservation_type') !== 'on_site') {
            _cnFrontUtilsGtm['default'].trackEvent({
              'event': 'checkout_step_1',
              'reservation_type': this.get('reservation.reservation_type'),
              'transactionCG': this.get('reservation.campground.id'), // numeric   CG ID number
              'transactionUser': this.get('currentUser').user.id, // numeric   User ID number (camper)
              'transactionUserEmail': this.get('currentUser').user.email, // string    User Email (camper)
              'transactionUserCity': this.get('currentUser').user.city, // string    User City (camper)
              'transactionUserState': this.get('currentUser').user.state, // string    User State (camper)
              'transactionUserZip': this.get('currentUser').user.zip_code // string    User Zip Code (camper)
            });
          } else {
              _cnFrontUtilsGtm['default'].trackEvent({
                'event': 'checkout_step_1',
                'reservation_type': this.get('reservation.reservation_type'),
                'transactionCG': this.get('reservation.campground.id') // numeric   CG ID number
              });
            }
        }

        this.set('stepster.finishedSteps', this.get('step'));
      }

      if (this.get('reservation.days') > 0) {
        this.set('stepster.ready', true);
        $('.error').fadeOut();
      }
    }).observes('stepster.currentStep'),

    recalculateCost: function recalculateCost() {
      var sd = $('#res-arrival').val(),
          ed = $('#res-departure').val();

      this.set('reservation.daterange', sd + ' - ' + ed);

      this.set('reservation.start_date', sd);
      this.set('reservation.end_date', ed);

      sd = moment(sd, 'MM/DD/YYYY');
      ed = moment(ed, 'MM/DD/YYYY');

      var days = ed.diff(sd, 'days');

      days = days !== days ? 0 : days;
      this.set('reservation.days', days);

      if (days > 0) {
        this.set('stepster.ready', true);
        // clear error message
        $('.error').fadeOut();
      } else {
        this.set('stepster.ready', false);
      }

      if (days > 14) {
        this.set('longDays', true);
      } else {
        this.set('longDays', false);
      }

      if (this.get('reservation').isPaymentsEnabled()) {
        this.fetchFinancialInformation();
      }
    },

    fetchFinancialInformation: function fetchFinancialInformation() {
      var _this = this;

      var self = this;
      var time = moment().valueOf();
      this.set('currentCalculationTime', time);
      var url = this.get('api').getUrl("/reservations/calculate/create_res");
      var ajaxPromise = $.get(url, {
        reservation_type: this.get('reservation.reservation_type'),
        cs_id: this.get('reservation.campsite.id'),
        daterange: this.get('reservation.daterange')

      });
      ajaxPromise.then(function (data) {
        if (time == _this.get('currentCalculationTime')) {
          self.get('reservation').setFinancialInformation(data);
          self.get('reservation').calculateDuration('new');
        }
      });
    },
    actions: {
      nextStep: function nextStep() {
        this.sendAction('nextStep');
      }
    }

  });
});