define("cn-front/templates/components/dashboard/step/res-confirm", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 16
                },
                "end": {
                  "line": 26,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "form-group");
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              var el3 = dom.createTextNode("Camper Details");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              var el3 = dom.createTextNode("Name: ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              var el3 = dom.createTextNode("Address: ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(", ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(", ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(", ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              var el3 = dom.createTextNode("Phone: ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              var el3 = dom.createTextNode("Email: ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var element6 = dom.childAt(element5, [3]);
              var element7 = dom.childAt(element5, [5]);
              var morphs = new Array(8);
              morphs[0] = dom.createMorphAt(element6, 1, 1);
              morphs[1] = dom.createMorphAt(element6, 3, 3);
              morphs[2] = dom.createMorphAt(element7, 1, 1);
              morphs[3] = dom.createMorphAt(element7, 3, 3);
              morphs[4] = dom.createMorphAt(element7, 5, 5);
              morphs[5] = dom.createMorphAt(element7, 7, 7);
              morphs[6] = dom.createMorphAt(dom.childAt(element5, [7]), 1, 1);
              morphs[7] = dom.createMorphAt(dom.childAt(element5, [9]), 1, 1);
              return morphs;
            },
            statements: [["content", "reservation.response.user.name_first", ["loc", [null, [21, 33], [21, 75]]], 0, 0, 0, 0], ["content", "reservation.response.user.name_last", ["loc", [null, [21, 76], [21, 117]]], 0, 0, 0, 0], ["content", "reservation.response.user.address", ["loc", [null, [22, 36], [22, 75]]], 0, 0, 0, 0], ["content", "reservation.response.user.city", ["loc", [null, [22, 77], [22, 113]]], 0, 0, 0, 0], ["content", "reservation.response.user.state", ["loc", [null, [22, 115], [22, 152]]], 0, 0, 0, 0], ["content", "reservation.response.user.zip_code", ["loc", [null, [22, 154], [22, 194]]], 0, 0, 0, 0], ["content", "reservation.response.user.phone", ["loc", [null, [23, 34], [23, 71]]], 0, 0, 0, 0], ["content", "reservation.response.user.email", ["loc", [null, [24, 34], [24, 71]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 12
              },
              "end": {
                "line": 27,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "form-group");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createTextNode("Transaction Details");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Transaction Date: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("CN Transaction #: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("CN Reservation #: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Payment Method: Credit card ending in ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Status: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element8, [5]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element8, [7]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element8, [9]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element8, [11]), 1, 1);
            morphs[5] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "reservation.response.transaction_date", ["loc", [null, [10, 41], [10, 84]]], 0, 0, 0, 0], ["content", "reservation.response.transaction_id", ["loc", [null, [11, 41], [11, 82]]], 0, 0, 0, 0], ["content", "reservation.response.reservation_id", ["loc", [null, [12, 41], [12, 82]]], 0, 0, 0, 0], ["content", "reservation.response.payment_method", ["loc", [null, [13, 61], [13, 102]]], 0, 0, 0, 0], ["content", "reservation.response.status", ["loc", [null, [14, 31], [14, 64]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "reservation.type", ["loc", [null, [17, 26], [17, 42]]], 0, 0, 0, 0], "dash"], [], ["loc", [null, [17, 22], [17, 51]]], 0, 0]], [], 0, null, ["loc", [null, [17, 16], [26, 23]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 12
              },
              "end": {
                "line": 34,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "form-group");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createTextNode("Property Details");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Campground: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
            return morphs;
          },
          statements: [["content", "reservation.camp.name", ["loc", [null, [32, 35], [32, 62]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 46,
                  "column": 20
                },
                "end": {
                  "line": 48,
                  "column": 20
                }
              },
              "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createTextNode("Type: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["content", "reservation.type_desc", ["loc", [null, [47, 33], [47, 58]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 12
              },
              "end": {
                "line": 50,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "form-group");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createTextNode("Property Details");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Campground: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Campsite: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "form-group");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createTextNode("Dates");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "arrival-label");
            var el4 = dom.createTextNode("Arrival:");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "departure-label");
            var el4 = dom.createTextNode("Departure:");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Total duration: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(fragment, [3]);
            var element4 = dom.childAt(element3, [3]);
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [5]), 1, 1);
            morphs[2] = dom.createMorphAt(element4, 2, 2);
            morphs[3] = dom.createMorphAt(element4, 6, 6);
            morphs[4] = dom.createMorphAt(dom.childAt(element3, [5]), 1, 1);
            morphs[5] = dom.createMorphAt(element3, 7, 7);
            return morphs;
          },
          statements: [["content", "reservation.camp.name", ["loc", [null, [37, 35], [37, 62]]], 0, 0, 0, 0], ["content", "reservation.campsite.name", ["loc", [null, [38, 33], [38, 64]]], 0, 0, 0, 0], ["content", "reservation.startdate", ["loc", [null, [44, 67], [44, 92]]], 0, 0, 0, 0], ["content", "reservation.enddate", ["loc", [null, [44, 141], [44, 164]]], 0, 0, 0, 0], ["content", "reservation.days_computed", ["loc", [null, [45, 39], [45, 68]]], 0, 0, 0, 0], ["block", "if", [["get", "reservation.type_desc", ["loc", [null, [46, 26], [46, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [46, 20], [48, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 20
                },
                "end": {
                  "line": 64,
                  "column": 20
                }
              },
              "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createTextNode("Credit Card Fee: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createTextNode("Paid To Property: ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
              return morphs;
            },
            statements: [["inline", "format-currency", [["get", "reservation.cg_fee", ["loc", [null, [62, 62], [62, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [62, 44], [62, 82]]], 0, 0], ["inline", "format-currency", [["get", "reservation.cg_received", ["loc", [null, [63, 63], [63, 86]]], 0, 0, 0, 0]], [], ["loc", [null, [63, 45], [63, 88]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 12
              },
              "end": {
                "line": 66,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "form-group");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createTextNode("Cost");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("All prices include tax");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Property Fee: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Transaction Fee: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createTextNode("Total: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element1, [7]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element1, [9]), 1, 1);
            morphs[3] = dom.createMorphAt(element1, 11, 11);
            return morphs;
          },
          statements: [["inline", "format-currency", [["get", "reservation.total_without_fee", ["loc", [null, [57, 55], [57, 84]]], 0, 0, 0, 0]], [], ["loc", [null, [57, 37], [57, 86]]], 0, 0], ["inline", "format-currency", [["get", "reservation.camper_fee", ["loc", [null, [58, 58], [58, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [58, 40], [58, 82]]], 0, 0], ["inline", "format-currency", [["get", "reservation.camper_charged", ["loc", [null, [59, 48], [59, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [59, 30], [59, 76]]], 0, 0], ["block", "if", [["subexpr", "eq", [["get", "reservation.type", ["loc", [null, [61, 30], [61, 46]]], 0, 0, 0, 0], "dash"], [], ["loc", [null, [61, 26], [61, 55]]], 0, 0]], [], 0, null, ["loc", [null, [61, 20], [64, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 72,
                  "column": 20
                },
                "end": {
                  "line": 75,
                  "column": 20
                }
              },
              "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("Campground:");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
              return morphs;
            },
            statements: [["inline", "break-lines", [["get", "reservation.response.cg_note", ["loc", [null, [74, 42], [74, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [74, 28], [74, 72]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 20
                },
                "end": {
                  "line": 80,
                  "column": 20
                }
              },
              "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createElement("b");
              var el3 = dom.createTextNode("Camper: ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
              return morphs;
            },
            statements: [["inline", "break-lines", [["get", "reservation.response.camper_note", ["loc", [null, [79, 42], [79, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [79, 28], [79, 76]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 68,
                "column": 12
              },
              "end": {
                "line": 82,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "form-group");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createTextNode("Notes");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element0, 3, 3);
            morphs[1] = dom.createMorphAt(element0, 5, 5);
            return morphs;
          },
          statements: [["block", "if", [["get", "reservation.response.cg_note", ["loc", [null, [72, 26], [72, 54]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [72, 20], [75, 27]]]], ["block", "if", [["get", "reservation.response.camper_note", ["loc", [null, [77, 26], [77, 58]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [77, 20], [80, 27]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 84,
              "column": 8
            }
          },
          "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
          return morphs;
        },
        statements: [["content", "reservation.response.success", ["loc", [null, [4, 16], [4, 48]]], 0, 0, 0, 0], ["block", "if", [["get", "paymentsEnabled", ["loc", [null, [6, 18], [6, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 12], [27, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "reservation.restype", ["loc", [null, [29, 22], [29, 41]]], 0, 0, 0, 0], "info_req"], [], ["loc", [null, [29, 18], [29, 53]]], 0, 0]], [], 1, 2, ["loc", [null, [29, 12], [50, 19]]]], ["block", "if", [["get", "paymentsEnabled", ["loc", [null, [52, 18], [52, 33]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [52, 12], [66, 19]]]], ["block", "if", [["subexpr", "or", [["get", "reservation.response.cg_note", ["loc", [null, [68, 22], [68, 50]]], 0, 0, 0, 0], ["get", "reservation.response.camper_note", ["loc", [null, [68, 51], [68, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [68, 18], [68, 84]]], 0, 0]], [], 4, null, ["loc", [null, [68, 12], [82, 19]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 12
              },
              "end": {
                "line": 87,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "alert alert-danger");
            dom.setAttribute(el1, "role", "alert");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "mess.error", ["loc", [null, [86, 61], [86, 75]]], 0, 0, 0, 0]],
          locals: ["mess"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 84,
              "column": 8
            },
            "end": {
              "line": 88,
              "column": 8
            }
          },
          "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "reservation.response", ["loc", [null, [85, 20], [85, 40]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [85, 12], [87, 21]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 90,
            "column": 6
          }
        },
        "moduleName": "cn-front/templates/components/dashboard/step/res-confirm.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-12");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "reservation.response.success", ["loc", [null, [3, 14], [3, 42]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [3, 8], [88, 15]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});