define('cn-front/components/reservation/view-reservation', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        currentUser: _ember['default'].inject.service('current-user'),
        reservation: _ember['default'].inject.service('reservation/instance'),
        computedDuration: '',
        paymentsEnabled: (function () {
            return this.get('reservation').isPaymentsEnabled();
        }).property('reservation.model.type'),

        reservationTypeLabel: (function () {
            var resType = this.get('reservation.model.type');
            if (resType === 'in_use') {
                return 'In Use (campsite has a prior reservation)';
            } else if (resType === 'maint') {
                return 'Maintenance (campsite is temporarily closed)';
            } else if (resType === 'fcfs') {
                return 'First Come First Served (campsite is open but may not be reserved)';
            } else if (resType === 'on_site') {
                return 'Onsite Booking (phone-in, drive-up, etc.)';
            } else if (resType === 'instant' || resType === 'confirm') {
                return 'Online Booking (through Camp Native)';
            } else if (resType === 'info_req') {
                return 'Information Request';
            } else {
                return 'Undefined';
            }
        }).property('reservation.model.type'),

        didInsertElement: function didInsertElement() {

            /**
             * Make notes ediable
             */
            var self = this;
            if (this.get('reservation.type') == 'dash' && !this.get('paymentsEnabled')) {
                $('.note').editable({
                    ajaxOptions: {
                        type: 'POST',
                        dataType: 'json'
                    },
                    type: 'textarea',
                    escape: false,
                    url: self.get('api').getUrl('/reservations/note/update'),
                    success: function success(response) {
                        if (jQuery.isArray(response)) {
                            return "Can not be empty";
                        } //msg will be shown in editable form
                    }
                });
            }
        },

        setInitiatorAndRedirect: function setInitiatorAndRedirect(initiator, type) {
            type = typeof type !== 'undefined' ? type : 'cancel';

            if (this.get('reservation').isPaymentsEnabled()) {
                this.get('reservation').setInitiator(initiator);
            }
            this.get('router').transitionTo('reservation.' + type);
        },

        actions: {
            cancel: function cancel(initiator) {
                var self = this;
                self.setInitiatorAndRedirect(initiator);
            },
            change: function change(initiator) {
                var self = this;
                self.setInitiatorAndRedirect(initiator, 'change');
            }
        }
    });
});