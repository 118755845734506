define("cn-front/components/campground/section-activities", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      _ember["default"].run.schedule("afterRender", this, function () {

        // const $body = $('body');
        // $body.on('click', '.show-more', function (e){
        //   e.preventDefault();
        //   $(this).parent().find('.wrap-info').toggleClass('active');
        // });

      });
    }
  });
});