define('cn-front/components/confirm-reserv', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        resId: null,
        resToken: null,
        disclaimer: false,
        resInfo: {},
        transInfo: false,
        computedDuration: '',

        getResInfo: (function () {

            var self = this;
            var resInfoUrl = this.get('api').getUrl('/reservations/get/' + this.get('resId'));
            $.ajax({
                type: 'GET',
                url: resInfoUrl,
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                success: function success(data) {
                    self.set('resInfo', data);
                    console.log(data);
                },
                error: function error(xhr, ajaxOptions, thrownError) {
                    console.log(thrownError);
                }
            });
        }).on('init'),

        computeDuration: (function () {

            var sd = this.get('resInfo.date_arrival');
            var ed = this.get('resInfo.date_departure');
            sd = moment(sd, 'YYYY-MM-DD');
            ed = moment(ed, 'YYYY-MM-DD');

            var days = ed.diff(sd, 'days');

            var computed = '';
            if (days >= 7) {
                var weeks = Math.floor(days / 7);
                var day = days - weeks * 7;
                computed = weeks + ' weeks, ' + day + ' days';
            } else {
                computed = days + ' days';
            }
            this.set('computedDuration', computed);
        }).observes('resInfo'),

        actions: {
            respondReservation: function respondReservation(action) {

                var self = this;

                // Cashing selectors
                var responseArea = $('#response-area');
                var noteStr = $('#reservation-note').val();
                var showOnSuccess = $('#show-on-success');

                // Define request Url
                var responseUrl = this.get('api').getUrl('/reservations/confirmation/' + action);

                // Making request
                $.ajax({
                    type: 'POST',
                    url: responseUrl,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    data: {
                        reservation_id: this.get('resId'),
                        reservation_token: this.get('resToken'),
                        note: noteStr
                    },
                    async: true,
                    success: function success(data) {
                        responseArea.empty();
                        if (data.error) {
                            responseArea.append('<p class="response error">' + data.error + '</p>');
                        } else {
                            responseArea.append('<p class="response">' + data.success + '</p>');
                            self.set('transInfo', data);
                            showOnSuccess.show();
                        }
                    },
                    error: function error(xhr, ajaxOptions, thrownError) {
                        responseArea.empty().append('<p class="response error">' + thrownError + '</p>');
                    }
                });
            }
        }
    });
});