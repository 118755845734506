define('cn-front/helpers/input-banner-number', ['exports', 'ember'], function (exports, _ember) {
  exports.pageNumber = pageNumber;

  function pageNumber(params /*, hash*/) {
    var index = params[0] + 1;
    var check = false;
    index = index - 4;
    if (index % 7 === 0) {
      check = true;
    }
    return check;
  }

  exports['default'] = _ember['default'].Helper.helper(pageNumber);
});