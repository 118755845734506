define('cn-front/helpers/to-string', ['exports', 'ember'], function (exports, _ember) {
  exports.toString = toString;

  function toString(params) {
    //object or array what need convert to string
    var objects = params[0];
    //property what need use
    var property = params[1];

    if (Array.isArray(objects) && objects.length > 0) {

      if (property) {
        var values = [];
        for (var i = 0; i < objects.length; i++) {
          values.push(objects[i][property]);
        }

        return values.join(', ');
      }
      return objects.join(', ');
    }

    return '';
  }

  exports['default'] = _ember['default'].Helper.helper(toString);
});