define('cn-front/controllers/campground/info-request', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
    exports['default'] = _ember['default'].Controller.extend({
        currentUser: _ember['default'].inject.service('current-user'),
        states: _ember['default'].inject.service('ustates'),
        stepster: _ember['default'].inject.service('reservation/stepster'),
        tosNotAccepted: false,
        registrationEmail: '',
        creationType: 'Info Req',
        emailValid: false,
        submitSuccess: false,

        resetProps: (function () {
            var self = this;
            this.get('stepster').reset();
            this.set('stepster.stepsTotal', 2);
            this.set('stepster.finishedSteps', 1);
            this.get('stepster').onSuccessReservation = function () {
                self.transitionToRoute('campground');
            };

            this.set('tosNotAccepted', false);
            this.set('registrationEmail', '');
            this.set('emailValid', false);
            this.set('submitSuccess', false);
            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                self.set('stepster.ready', true);
            });
            _cnFrontUtilsGtm['default'].trackEvent({
                'event': 'info_request_page'
            });
        }).observes('model'),

        checkEmail: (function () {
            if (this.registrationEmail !== '') {
                _ember['default'].run.debounce(this, this.lookupCamper, 1000);
            }
        }).observes('registrationEmail').on('init'),

        statesList: (function () {
            var array = $.map(this.get('states').data, function (value) {
                return [value];
            });
            return array.slice(0, -2);
        }).property(),

        lookupCamper: function lookupCamper() {
            var flashMessages = _ember['default'].get(this, 'flashMessages');
            flashMessages.clearMessages();
            var self = this;
            this.get('api').sendReq({
                type: "POST",
                url: '/lookup',
                data: $('#register-form').serializeJSON()
            }).then(function (data) {
                if (typeof data.error === 'undefined') {
                    $('.email-validation').html('');
                    $('#register-form [name="email"]').addClass('valid_email');
                    self.set('emailValid', true);
                } else {

                    $('.email-validation').html(data.error.email[0] + '<br>' + '<a href="/auth/forgot-password">Forgot Your Password?</a>');
                    $('#register-form [name="email"]').removeClass('valid_email');
                    self.set('emailValid', false);
                }
            }, function (data) {
                $('.modal').animate({
                    scrollTop: 0
                }, 400);
                $.each(data.responseJSON, function (key, value) {
                    flashMessages.danger(value[0]);
                });
            });
        },

        actions: {
            login: function login() {
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                flashMessages.clearMessages();
                var self = this;
                this.get('api').sendReq({
                    type: "POST",
                    url: '/signin',
                    data: $('#login-form').serializeJSON()
                }).then(function (data) {
                    self.get('currentUser').login(data);
                    self.registerGtnEvent('checkout_step_2_login');
                    self.onSelect();
                }, function (data) {
                    if (data.responseJSON.error === 'tos_not_accepted') {
                        self.get('currentUser').setTemporaryToken(data.responseJSON.token);
                        self.set('tosNotAccepted', true);
                    } else {
                        flashMessages.danger(data.responseJSON.message);
                    }
                });
            },
            register: function register() {
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                flashMessages.clearMessages();
                if (this.get('emailValid')) {

                    var self = this;
                    var data = $('#register-form').serializeArray();
                    data.push({
                        name: "create_method",
                        value: self.get('creationType')
                    });
                    this.get('api').sendReq({
                        type: "POST",
                        url: '/quick-registration',
                        data: data
                    }).then(function (data) {
                        self.get('currentUser').login(data);
                        self.registerGtnEvent('checkout_step_2_register');
                        self.onSelect(true);
                    }, function (data) {
                        $('html').animate({
                            scrollTop: 0
                        }, 400);
                        $.each(data.responseJSON, function (key, value) {
                            flashMessages.danger(value[0]);
                        });
                    });
                } else {
                    $('.modal').animate({
                        scrollTop: 0
                    }, 400);
                    flashMessages.danger("You must accept the Terms of Service.");
                }
            },
            handleTos: function handleTos(accept) {
                if (!accept) {
                    var flashMessages = _ember['default'].get(this, 'flashMessages');
                    flashMessages.danger('Terms of Service are not accepted');
                }

                this.set('tosNotAccepted', false);
            },
            showTos: function showTos() {
                this.set('tosNotAccepted', true);
            },

            submitRequest: function submitRequest() {

                this.set('submitSuccess', 'Sending email...');
                var self = this;
                var url = this.get('api').getUrl('/reservations/create/inforeq');
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                var infoRequestPromise = _ember['default'].$.ajax({
                    type: "POST",
                    url: url,
                    beforeSend: function beforeSend(xhr) {
                        if (self.get('currentUser.logged')) {
                            xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                        }
                    },
                    async: false,
                    data: { campground_id: self.get('model.id') }
                });

                _ember['default'].run(function () {
                    infoRequestPromise.then(function (data) {
                        if (data.success) {
                            self.set('stepster.finishedSteps', 2);
                            self.get('stepster').nextStep();
                            self.set('submitSuccess', data.success);
                            $('.green-header .back-btn').hide();
                            $('body').animate({
                                scrollTop: 0
                            }, 200);
                            self.registerGtnEvent('info_request_submit');
                        } else {
                            flashMessages.danger(data.error);
                        }
                    });
                });
            }
        },

        registerGtnEvent: function registerGtnEvent(event) {
            _cnFrontUtilsGtm['default'].trackEvent({
                'event': event,
                'reservation_type': 'info_request',
                'CG': this.get('model.id'), // numeric   CG ID number
                'User': this.get('currentUser').user.id, // numeric   User ID number (camper)
                'UserEmail': this.get('currentUser').user.email, // string    User Email (camper)
                'UserCity': this.get('currentUser').user.city, // string    User City (camper)
                'State': this.get('currentUser').user.state, // string    User State (camper)
                'Zip': this.get('currentUser').user.zip_code // string      // string    User Zip Code (camper)
            });
        }

    });
});