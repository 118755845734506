define('cn-front/controllers/application', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        routeName: '',

        routsWithoutFooter: ['auth',
        // 'explore',
        'camp-owners.form', 'new-reservation'],

        isDashboard: (function () {
            this.set('routeName', this.get('currentRouteName'));
            return this.get('currentRouteName').indexOf("dashboard") > -1;
        }).property('currentRouteName'),

        withFooter: (function () {
            // var routes = this.get('currentRouteName').split('.');
            var routes = this.get('currentRouteName');
            // return this.get('routsWithoutFooter').indexOf(routes[0]) < 0;
            return this.get('routsWithoutFooter').indexOf(routes) < 0;
        }).property('currentRouteName')
    });
});