define('cn-front/components/explore/filter-form', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    /* jshint loopfunc:true */
    exports['default'] = _ember['default'].Component.extend({
        preselect: inject.service(),
        data: [],
        campsitesData: [],
        selected: [],
        readyToApply: {},
        datepickerPlaceholderArrival: moment().format('MM/DD/YYYY'),
        datepickerPlaceholderDepature: moment().add(1, 'd').format('MM/DD/YYYY'),

        sortOrder: (function () {
            if (this.get('type') === 'campsites') {
                return [{ type: "shelters", q: 3 }, { type: "accesses", q: 6 }, { type: "services", q: 3 }, { type: "environments", q: 1 }];
            } else {
                return [{ type: "reservable", q: false }, { type: "shelters", q: 3 }, { type: "accesses", q: 6 }, { type: "services", q: 6 }, { type: "activities", q: 6 }, { type: "environs", q: 3 }, { type: "agency", q: false }, { type: "specialty", q: false }];
            }
        }).property('type'),

        getFilterLabel: function getFilterLabel(key) {
            var labels = this.get('preselect').getFilterLabels();
            return labels[key];
        },

        prepareDataForDisplay: function prepareDataForDisplay(array, type, open) {
            var q = this.get('sortOrder').find(function (e) {
                return e.type == type;
            }).q;

            var filter = {};

            filter.open = open;
            filter.label = this.getFilterLabel(type);
            filter.type = type;
            filter.allowNone = filter.type != 'reservable';

            if (q && array.length > q) {
                filter.additionalArray = array.slice(q);
                filter.array = array.slice(0, q);
            } else {
                filter.array = array;
            }
            this.get('data').pushObject(filter);
        },

        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            this.set('data', []);
            this.set('selected', []);

            var url = this.get('type') === 'campsites' ? '/campsites/form' : '/explore-form';

            this.get('api').sendReq({ type: 'GET', url: url }).then(function (data) {
                var order = _this.get('sortOrder');
                for (var i = 0; i < order.length; i++) {
                    // var showOpen = i < 1; //show first one only (zero index)
                    var showOpen = true;
                    _this.prepareDataForDisplay(data[order[i].type], order[i].type, showOpen);
                }

                var filterProperties = _this.get('preselect').getFilterLabels();

                _ember['default'].run.schedule('afterRender', _this, function () {

                    this.initDatepickers();
                    this.initParentCheckboxes();

                    // $('#' + this.get('elementId') + ' .explore-params input').change(()=>this.sendAction('search'));
                    // perform your jQuery logic here
                    if ($.cookie('homefilter') != null && $.cookie('homefilter') !== undefined) {
                        var cookie_data = JSON.parse($.cookie('homefilter'));

                        $('.location-autocomplete-field input').val(cookie_data.location);

                        if (cookie_data.sdate !== '') {
                            $('input.arrival').val(cookie_data.sdate);
                        } else {
                            $('input.arrival').val('');
                        }
                        if (cookie_data.edate !== '') {
                            $('input.departure').val(cookie_data.edate);
                        } else {
                            $('input.departure').val('');
                        }

                        for (var type in filterProperties) {
                            if (filterProperties.hasOwnProperty(type) && $('#' + type + '-wrap').length) {

                                if (cookie_data.hasOwnProperty(type) && cookie_data[type].length > 0) {
                                    var foundCheckboxes = $('input[name="' + type + '"]');

                                    if (type === 'agency' || type === 'specialty' || type === 'reservable') {
                                        $('#' + type).val(cookie_data[type][0]);
                                    } else {
                                        for (var propertyId in cookie_data[type]) {
                                            if (cookie_data[type].hasOwnProperty(propertyId)) {
                                                var option = foundCheckboxes.filter('[value="' + cookie_data[type][propertyId] + '"]')[0];
                                                if (option) {
                                                    if (this.get('selected').indexOf(type) === -1) {
                                                        this.get('selected').pushObject(type);
                                                    }
                                                    $(option).attr('checked', 'checked');
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    var self = this;
                    for (var fileType in filterProperties) {
                        if (filterProperties.hasOwnProperty(fileType)) {
                            $(document).on('change', 'input[name="' + fileType + '"], select[name="' + fileType + '"]', function () {
                                self.setChecked(this);
                            });
                        }
                    }

                    $('#reservable').val('true').change(); // default reservable filter to TRUE
                });
            });
        },

        setChecked: function setChecked(e) {
            var inputName = $(e).attr('name'),
                rta = this.get('readyToApply');
            var checked_properties = [];

            if ($('#' + inputName).prop("tagName") === "SELECT") {
                var old_value = this.get('preselect')[inputName][0];
                if (!rta[inputName]) {
                    rta[inputName] = { e: e, old_value: old_value };
                }

                if ($('select[name="' + inputName + '"]').val() !== '') {
                    checked_properties.push($('select[name="' + inputName + '"]').val());
                }
            } else {
                $('input[name="' + inputName + '"]').filter(":checkbox:checked").each(function () {
                    checked_properties.push($(this).val());
                });
                if (!rta[inputName]) {
                    rta[inputName] = this.get('preselect')[inputName];
                }
            }
            this.set('readyToApply', rta);

            if (this.get('selected').indexOf(inputName) === -1) {
                this.get('selected').pushObject(inputName);
            } else if (checked_properties.length === 0) {
                this.get('selected').removeObject(inputName);
            }

            this.get('preselect').setField(inputName, checked_properties);
        },

        initDatepickers: function initDatepickers() {
            var self = this;
            $('#filter-arrival').datepicker({
                orientation: "bottom left",
                autoclose: true,
                clearBtn: true,
                startDate: new Date()
            }).on('changeDate', function (e) {
                self.get('preselect').setField('sdate', $('#filter-arrival').val());

                if ($('#filter-arrival').val() !== '') {
                    var target = new Date(e.date),
                        dep = $('#filter-departure').val();
                    target.setDate(target.getDate() + 1);
                    if (new Date(dep) < target || dep === '') {
                        $('#filter-departure').datepicker('setDate', target);
                    }
                    $('#filter-departure').datepicker('setStartDate', target);
                }
            }).on('clearDate', function () {
                if ($('#filter-departure').val() !== '') {
                    $('#filter-departure').datepicker('setDate', '');
                    $('#filter-departure').datepicker('setStartDate', new Date());
                }
            });
            $('#filter-departure').datepicker({
                orientation: "bottom left",
                autoclose: true,
                clearBtn: true,
                startDate: new Date()
            }).on('changeDate', function () {
                var d = $('#filter-departure').val();
                self.get('preselect').setField('edate', d);
                if (d === '') {
                    $('#filter-arrival').datepicker('setDate', '');
                }
            }).on('clearDate', function () {
                if ($('#filter-arrival').val() !== '') {
                    $('#filter-arrival').val('setDate', '');
                    $('#filter-departure').datepicker('setStartDate', new Date());
                }
            });
        },

        syncWithHeader: (function () {
            if (this.get('preselect.locationObj') !== '' && Object.keys(this.get('preselect.locationObj')).length !== 0) {
                this.sendAction('search');
            }
        }).observes('preselect.locationObj'),

        initParentCheckboxes: function initParentCheckboxes() {
            //check - uncheck all child if clicked on parent checkbox
            $(document).on('click', '.cat', function () {
                var type = $(this).attr('name'),
                    childs_val = $(this).data().ids.split('|'),
                    childs = [];

                childs_val.forEach(function (e) {
                    return childs.push($('#' + type + '-wrap :input[value="' + e + '"]'));
                });

                if ($(this).is(':checked')) {
                    childs.forEach(function (e) {
                        return $(e).prop('checked', true);
                    });
                } else {
                    childs.forEach(function (e) {
                        return $(e).prop('checked', false);
                    });
                }
            });

            var self = this;
            //sync child and parent checkboxes state
            $(document).on('click', '.child', function () {
                self.syncParentChildCheckboxes(this);
            });
        },

        syncParentChildCheckboxes: function syncParentChildCheckboxes(self) {
            var groupId = $(self).data().parentid,
                childs = $(".child[data-parentid='" + groupId + "']"),
                checked = 0;

            childs.each(function () {
                if ($(this).is(':checked')) {
                    checked++;
                }
            });

            if (childs.length == checked) {
                $('#cat_' + groupId).prop('checked', true);
            } else {
                $('#cat_' + groupId).prop('checked', false);
            }
        },

        actions: {
            commit: function commit() {
                //if (jQuery('.hypersearch-results').css('display') == 'none') {
                // if we want to catch the autocomplete commit.

                //}
                this.set('readyToApply', {});
                this.sendAction('search');
                //return false;
            },
            clearButton: function clearButton() {
                this.set('readyToApply', {});
                this.set('selected', []);
                if ($('#collapsedFilters').hasClass('in')) {
                    $('#filters').click();
                    $('.left').animate({
                        scrollTop: 0
                    }, 400);
                }
                this.sendAction('clear');
            },

            removeSelection: function removeSelection(e) {
                var withSearch = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

                this.get('selected').removeObject(e);
                if ($('#' + e).prop("tagName") === "SELECT" || $('#' + e).prop("tagName") === "select") {
                    $("#" + e + " option:selected").prop("selected", false);
                } else {
                    $('#' + e + '-wrap input[type="checkbox"]').each(function () {
                        $(this).prop("checked", false);
                    });
                }
                if (withSearch) {
                    this.set('readyToApply', {});
                    this.sendAction('search');
                }
            },
            apply: function apply(noSearch) {
                if (!noSearch) {
                    this.set('readyToApply', {});
                    this.sendAction('search');
                }

                $('#filters').click();
                $('.left').animate({
                    scrollTop: 0
                }, 400);
            },
            cancel: function cancel() {
                var rtl = this.get('readyToApply');

                for (var k in rtl) {
                    if (rtl.hasOwnProperty(k)) {
                        //if "select", simply set an old value
                        if (rtl[k].old_value) {
                            $(rtl[k].e).val(rtl[k].old_value);
                        } else {
                            //if checkbox, renew old props
                            this.send('removeSelection', k, false);
                            rtl[k].forEach(function (e) {
                                $('#' + k + '_' + e).click();
                            });
                        }
                    }
                }
                $('#filters').click();
                $('.left').animate({
                    scrollTop: 0
                }, 400);
            }

        },

        willClearRender: function willClearRender() {
            // remove the custom event
            this.$(document).off('change');
            //this.$(document).off('click');
        }

    });
});