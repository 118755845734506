define('cn-front/routes/dashboard/reports/onboards', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        thisController: function thisController() {
            return this.controllerFor('dashboard/reports/onboards');
        },

        queryParams: {
            page: {
                refreshModel: true
            }
        },

        model: function model(params) {

            var ctrlData = this.thisController();
            var reportUrl = this.get('apiConnect').getUrl('/dashboard/reports/onboards');
            var totalResUrl = this.get('apiConnect').getUrl('/dashboard/reports/onboards/total');

            var response = {
                totalres: false,
                reports: false
            };

            $.ajax({
                type: "GET",
                url: totalResUrl,
                data: {
                    'statuses': ctrlData.statuses,
                    'types': ctrlData.types,
                    'state': ctrlData.state,
                    'processing': ctrlData.processing,
                    'application': ctrlData.application,
                    'confirmation': ctrlData.confirmation,
                    'page_type': ctrlData.pageType,
                    'acct_mgr': ctrlData.acctMgrVal,
                    'affiliate': ctrlData.affiliate,
                    'owner_group': ctrlData.owner_group
                },
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                success: function success(data) {
                    console.log(data);
                    response['totalres'] = data;
                }
            });

            $.ajax({
                type: "GET",
                url: reportUrl,
                data: {
                    'page': params.page,
                    'state': ctrlData.state,
                    'processing': ctrlData.processing,
                    'application': ctrlData.application,
                    'confirmation': ctrlData.confirmation,
                    'page_type': ctrlData.pageType,
                    'orderBy': ctrlData.orderBy,
                    'sort': ctrlData.sort,
                    'statuses': ctrlData.statuses,
                    'types': ctrlData.types,
                    'acct_mgr': ctrlData.acctMgrVal,
                    'affiliate': ctrlData.affiliate,
                    'owner_group': ctrlData.owner_group

                },
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                success: function success(data) {
                    console.log(data);
                    response['reports'] = data;
                }
            });

            return response;
        },

        actions: {
            applySort: function applySort(slug) {
                var ctrlData = this.thisController();
                var sortHeader = $('.order-source.' + slug);
                sortHeader.removeClass('asc desc');
                var newOrder = 'ASC';

                if (sortHeader.hasClass('active-sort')) {
                    newOrder = ctrlData.get('sort') == 'ASC' ? 'DESC' : 'ASC';
                } else {
                    $('.order-source.active-sort').removeClass('active-sort asc desc');
                    sortHeader.addClass('active-sort');
                    ctrlData.set('orderBy', slug);
                }
                ctrlData.set('sort', newOrder);
                sortHeader.addClass(newOrder.toLowerCase());
                this.refresh();
            },
            refreshModel: function refreshModel() {
                //display message in the console of your browser
                _ember['default'].Logger.log('Route is now refreshing...');
                //refresh the model
                this.refresh();
            }
        }
    });
});