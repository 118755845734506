define('cn-front/controllers/dashboard/campground/edit/modal/pending-res', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Controller.extend({

        currentUser: inject.service('current-user'),
        apiConnect: inject.service('api-resolver'),
        modalTitle: 'Pending Reservation',

        stepster: inject.service('dashboard/stepster'),
        currTab: 0,

        reservation: {},
        resp: {},

        computedDuration: '',
        computeDuration: (function () {
            var sd = this.get('reservation.date_arrival');
            var ed = this.get('reservation.date_departure');
            sd = moment(sd, 'YYYY-MM-DD');
            ed = moment(ed, 'YYYY-MM-DD');

            var days = ed.diff(sd, 'days');

            var computed = '';
            if (days >= 7) {
                var weeks = Math.floor(days / 7);
                var day = days - weeks * 7;
                computed = weeks + ' weeks, ' + day + ' days';
            } else {
                computed = days + ' days';
            }

            this.set('computedDuration', computed);
        }).observes('reservation.date_arrival'),

        setters: (function () {
            _ember['default'].run.schedule('afterRender', this, function () {

                this.set('currTab', 1);
                this.observeCurrTab();

                this.set('stepster.ready', true);
                this.set('stepster.fallback', null);

                this.set('tabSize', $('.resnav>li>a').length);
                $(".resnav a[data-toggle=tab]").on("click", function (e) {
                    e.preventDefault();
                    return false;
                });

                var self = this;
                var resInfoUrl = this.get('apiConnect').getUrl('/reservations/get/' + this.get('model.id'));
                $.ajax({
                    type: 'GET',
                    url: resInfoUrl,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        console.log(data);
                        self.set('reservation', data);
                    },
                    error: function error(xhr, ajaxOptions, thrownError) {
                        console.log(thrownError);
                    }
                });
            });
        }).observes('model'),

        type: (function () {
            console.log(this.get('model'));
            if (this.get('model.status') == 1) {
                return 'confirm';
            } else if (this.get('model.status') == 7) {
                this.set('modalTitle', 'Information Request Response');
                return 'info_req';
            }
        }).property('model'),

        actions: {
            close: function close() {
                $('.modal').modal('hide');
                this.send('refreshModel');
            },
            next: function next() {
                if (this.get('stepster.ready')) {
                    if (!$('.resnav li:last').hasClass('active')) {
                        var curr = $('.steps .tab-pane.active').attr('id').split('-')[1];
                        curr++;
                        this.set('currTab', curr);
                        $('.resnav a[href="#step-' + curr + '"]').tab('show');
                    }
                    //this.set('stepster.ready', false);
                } else {
                        this.get('stepster').fallback();
                    }
            },
            prev: function prev() {
                if (!$('.resnav li:first').hasClass('active')) {
                    var curr = $('.steps .tab-pane.active').attr('id').split('-')[1];
                    curr--;
                    this.set('currTab', curr);
                    $('.resnav a[href="#step-' + curr + '"]').tab('show');
                }
            },
            respondReservation: function respondReservation(action) {

                // Cashing selectors
                var noteStr = $('#reservation-note').val();
                var self = this;
                // Define request Url
                var responseUrl = this.get('apiConnect').getUrl('/reservations/confirmation/' + action);
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                flashMessages.clearMessages();
                // Making request
                $.ajax({
                    type: 'POST',
                    url: responseUrl,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    data: {
                        reservation_id: this.get('model.id'),
                        note: noteStr
                    },
                    async: true,
                    success: function success(data) {
                        if (data.error) {
                            flashMessages.danger(data.error);
                        } else {
                            self.set('resp', data);
                            if (action == 'accept') {
                                self.set('modalTitle', 'Pending Reservation Accepted');
                            } else if (action == 'deny') {
                                self.set('modalTitle', 'Pending Reservation Denied');
                            }
                            self.send('next');
                        }
                    },
                    error: function error(xhr, ajaxOptions, thrownError) {
                        flashMessages.danger(thrownError);
                    }
                });
            },
            replyInfoRequest: function replyInfoRequest() {

                // Cashing selectors
                var noteStr = $('#reservation-note').val();
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                flashMessages.clearMessages();
                // Define request Url
                var replyUrl = this.get('apiConnect').getUrl('/reservations/reply/inforeq');
                var self = this;
                // Making request
                $.ajax({
                    type: 'POST',
                    url: replyUrl,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    data: {
                        reservation_id: this.get('model.id'),
                        note: noteStr
                    },
                    async: true,
                    success: function success(data) {
                        if (data.error) {
                            flashMessages.danger(data.responseJSON.message);
                        } else {
                            self.set('resp', data);
                            self.send('next');
                        }
                    },
                    error: function error(xhr, ajaxOptions, thrownError) {
                        flashMessages.danger(thrownError);
                    }
                });
            }
        },

        observeCurrTab: (function () {
            var c = this.get('currTab');

            this.set('stepster.currentStep', c);
            if (c == 1) {
                $('.res-footer .prev').hide();
            } else {
                if (this.get('tabSize') == c) {
                    $('.res-footer .submit').show();
                    $('.res-footer .prev').hide();
                    $('.res-footer .next').hide();
                } else {
                    $('.res-footer .submit').hide();
                    $('.res-footer .prev').show();
                    $('.res-footer .next').show();
                }
            }
        }).observes('currTab').on('init')
    });
});