define('cn-front/services/dashboard/cg-fields', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Service.extend({
        fields: null,
        csfields: null,
        apiConnect: inject.service('api-resolver'),
        init: function init() {
            var self = this;
            var apiConnect = self.get('apiConnect');
            var url = apiConnect.getUrl('/dashboard/campgrounds/properties');
            var Promise1 = _ember['default'].$.ajax({
                type: "GET",
                url: url,
                data: { token: $.cookie('token') },
                async: false
            });

            var url2 = apiConnect.getUrl('/dashboard/campsites/properties');
            var Promise2 = _ember['default'].$.ajax({
                type: "GET",
                url: url2,
                data: { token: $.cookie('token') },
                async: false
            });

            // wait until "this" slider is rendered before continuing on.
            _ember['default'].run(function () {
                Promise1.then(function (data) {
                    self.set('fields', data);
                });
                Promise2.then(function (data) {
                    self.set('csfields', data);
                });
            });
        }

    });
});