define('cn-front/controllers/confirmation', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        //apiConnect: Ember.inject.service('api-connect'),
        currentUser: _ember['default'].inject.service('current-user'),

        queryParams: ['res_id', 'token'],
        res_id: null,
        token: null,

        successMessage: '',
        errorMessage: '',

        isTokenValid: _ember['default'].computed(function () {

            var response = {};
            var validateTokenUrl = this.get('api').getUrl('/reservations/verification/' + this.get('res_id') + '/' + this.get('token'));
            _ember['default'].$.ajax({
                type: 'GET',
                url: validateTokenUrl,
                async: false,
                success: function success(data) {
                    response = data;
                },
                error: function error(xhr, ajaxOptions, thrownError) {
                    response = { error: thrownError };
                    // TODO: We may want to handle it in some more glamorous way
                }
            });
            if (response.error) {
                this.set('errorMessage', response.error);
                return false;
            } else {
                this.set('successMessage', response.success);
                return true;
            }
        }),

        isLoggedIn: (function () {

            if (this.get('currentUser').user.id === undefined) {
                this.transitionToRoute('/auth/login');
            } else {
                return true;
            }
        }).property('currentUser.user.id'),

        isCGOwner: (function () {

            var response = false;

            var resInfoUrl = this.get('api').getUrl('/reservations/get/' + this.get('res_id'));
            $.ajax({
                type: 'GET',
                url: resInfoUrl,
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                success: function success(data) {
                    response = data.error ? false : true;
                    console.log(data);
                },
                error: function error(xhr, ajaxOptions, thrownError) {
                    console.log(thrownError);
                }
            });

            return response;
        }).property('currentUser.user.id')

    });
});