define('cn-front/components/explore/g-map', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    identMob: '',
    didReceiveAttrs: function detectMob() {
      var _this = this;

      var toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

      return toMatch.some(function (toMatchItem) {
        var matchClass = navigator.userAgent.match(toMatchItem) ? 'mobile' : '';
        return _this.set('identMob', matchClass);
      });
    }
  });
});