define('cn-front/routes/partners', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        title: 'Partners and Inquiry Form | Camp Native',

        actions: {
            send: function send() {
                var args = {
                    url: '/contact',
                    type: 'GET',
                    data: $('#contact-form').serializeJSON()
                };

                this.get('api').sendReq(args).then(function (r) {
                    if (r.success) {
                        $('#contact-form').fadeOut('slow');
                        $('#contact-thanks').removeClass('hidden');
                        $('html,body').animate({
                            scrollTop: 0
                        }, 400);
                    }
                });
            }
        }
    });
});