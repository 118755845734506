define('cn-front/controllers/dashboard/campground/edit/modal/edit-map', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        actions: {
            editMap: function editMap() {
                var formdata = $('form#edit-map').serializeJSON();
                var self = this,
                    model = self.get('model');

                var url = self.get('apiConnect').getUrl('/dashboard/maps/update/campgrounds/' + model.id);

                var flashMessages = _ember['default'].get(self, 'flashMessages');
                $.ajax({
                    type: "PUT",
                    url: url,
                    data: formdata,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        console.log(data);
                        if (data.success) {
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                        } else {
                            flashMessages.danger(_ember['default'].String.htmlSafe(data.error));
                        }
                        self.send('refreshModel');
                    }
                });
            }
        }
    });
});