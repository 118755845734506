define('cn-front/components/dashboard/cancelstep/confirm-res', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Component.extend({
        reservation: inject.service('dashboard/reservation'),
        stepster: inject.service('dashboard/stepster'),

        isPaymentsEnabled: function isPaymentsEnabled() {
            var disabledPaymentOn = ["in_use", "maint", "fcfs", "info_req"];
            return disabledPaymentOn.indexOf(this.get('data.reservation.type')) == -1;
        },

        paymentsEnabled: (function () {
            return this.isPaymentsEnabled();
        }).property('data.reservation.type'),

        onSelect: (function () {
            if (this.get('stepster.currentStep') == this.get('tabId')) {
                //firing every entering on this step
                console.log(this.get('reservation'));
            }
        }).observes('stepster.currentStep').on('init')

    });
});