define('cn-front/components/explore/camp-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    page: 1,
    count_pages: 1,
    camp_list: [],
    didReceiveAttrs: function didReceiveAttrs() {
      var campgrounds = this.get('campgrounds');
      var countModule = campgrounds.length % 7;
      var countPage = campgrounds.length / 7;

      countPage = countModule ? parseInt(countPage) + 1 : countPage;
      this.set('count_pages', countPage);
      // console.log('campgrounds', campgrounds);
    },
    actions: {
      changePage: function changePage(current) {
        this.set('page', current);
        if (current === 1) {
          $('#section-ad-block').css('display', 'flex');
        } else {
          $('#section-ad-block').css('display', 'none');
        }
        $('.camp-card:not([data-index="' + current + '"]), .section-ad-block:not([data-index="' + current + '"])').css('display', 'none');
        $('.camp-card[data-index="' + current + '"], .section-ad-block[data-index="' + current + '"]').css('display', 'flex');
        $('html, body').animate({ scrollTop: $('.camp-list').position().top - 100 }, 600);
      }
    }

  });
});