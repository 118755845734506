define('cn-front/controllers/reservation/cancel', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        reservation: _ember['default'].inject.service('reservation/instance'),
        stepster: _ember['default'].inject.service('reservation/stepster'),
        currentUser: _ember['default'].inject.service('current-user'),
        loading: false,
        loadingText: "Please wait, we're canceling your reservation...",

        defaultInit: (function () {
            var self = this;
            this.get('stepster').reset();
            this.set('stepster.stepsTotal', 2);
            this.set('stepster.finishedSteps', 1);
            this.get('stepster').onSuccessReservation = function () {
                self.transitionToRoute('user.profile');
            };

            if (!this.get('reservation.initiator')) {
                this.set('reservation.initiator', 'camper');
            }
        }).observes('model'),

        stepChange: (function () {
            if (this.get('stepster.currentStep') == 2 && this.get('stepster.finishedSteps') == 1) {
                this.set('loading', true);
                var self = this;
                var url = this.get('api').getUrl("/reservations/cancel");
                var noteStr = $('#cancelation-note').val();

                var ajaxPromise = $.ajax({
                    type: "POST",
                    url: url,
                    data: {
                        reservation_id: this.get('reservation.model.id'),
                        type: this.get('reservation.model.type'),
                        note: noteStr,
                        initiator: this.get('reservation.initiator'),
                        initiator_token: $.cookie('token')
                    },
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    }
                });

                ajaxPromise.then(function (data) {
                    self.set('loading', false);
                    self.set('reservation.response', data);
                    if (data.success) {
                        self.set('stepster.ready', true);
                        self.set('stepster.finishedSteps', 2);
                        self.get('stepster').onStepChange();
                    }
                });
            }
        }).observes('stepster.currentStep'),

        scrollToTop: (function () {
            $('html,body').animate({
                scrollTop: 0
            }, 400);
        }).observes('stepster.currentStep')
    });
});