define('cn-front/components/location-autocomplete', ['exports', 'ember'], function (exports, _ember) {

  var inject = _ember['default'].inject;

  exports['default'] = _ember['default'].Component.extend({
    preselect: inject.service(),
    results: '',
    locationsSeparate: '',
    isSeparating: true,
    campgroundCount: 0,
    locationsAutocompleteUrl: (function () {
      return this.get('api').getUrl('/locations/autocomplete');
    }).property(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      console.log('reinit');
      var self = this;

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        $('.hypersearch-input').attr('autocomplete', 'off');
        _ember['default'].$(document).on('click', '.hypersearch-input', function () {
          // is there was a selection made before, then clear it.
          if ($(this).val() === self.get('preselect').location && $(this).val() !== '') {
            self.get('preselect').setField('location', "");
            self.get('preselect').setField('locationObj', {});
            $(this).val('');
          }
        });

        _ember['default'].$(document).focus('.hypersearch-input', function () {
          var resultsList = $().find(".hypersearch-results");
          if (resultsList.find('li').length > 0) {
            resultsList.slideDown();
          }
        }).on('keyup', '.hypersearch-input', function () {
          if ($(this).val() === '') {
            self.get('preselect').setField('location', "");
            self.get('preselect').setField('locationObj', {});
          }
        });

        _ember['default'].$(document).on('keypress', '.hypersearch-input', function (e) {
          if (e.which === 13 && $('.hypersearch-results').css('display') === 'block') {
            $('.hypersearch-input').blur();
            $('.hypersearch-campground').first().click();
          }
        });

        _ember['default'].$(document).on('mouseup', $.proxy(self.documentMouseUpHandler, self));
      });
    },

    documentMouseUpHandler: function documentMouseUpHandler(e) {
      var self = this;
      var container = $(".hypersearch");
      var actionBtn = $('.explore-btn, #search');

      // if the target of the click isn't the container...
      // ... nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {

        if (self.get('results').length > 0 && self.get('results').indexOf(self.get('preselect').locationObj) === -1 && actionBtn.is(e.target)) {
          self.send('selectResult', self.get('results')[0]);
        }

        $(".hypersearch-results").slideUp();
      }
    },

    validateInput: function validateInput(element) {
      var self = this;

      if (element.val() !== this.get('preselect').location) {
        console.log('validateInput NO MATCH');
        this.get('preselect').setField('location', "");
        this.get('preselect').setField('locationObj', {});
      }

      _ember['default'].run.later(function () {
        if ($.isEmptyObject(self.get('preselect').locationObj)) {
          element.addClass('validate-error');
        } else {
          element.removeClass('validate-error');
        }
      }, 100);
    },

    actions: {
      triggerSearch: function triggerSearch() {
        $('.btn-search').click();
        $(".hypersearch-results").slideUp();
      },
      selectResult: function selectResult(object) {
        this.set('results', '');
        var resultsList = this.$().find(".hypersearch-results");
        var searchInput = this.$().find('.hypersearch-input');

        this.get('preselect').setField('location', object.description);
        this.get('preselect').setField('locationObj', object);
        searchInput.val(object.description);

        resultsList.slideUp();
        this.validateInput(searchInput);
        $('.explore-btn').click();
        // if (this.get('explore')) {
        //     this.get('explore')();
        // }
      },
      handleResults: function handleResults(response) {
        var campgroundCount = 0;
        var locationsSeparate = [];
        var isSeparating = true;
        var locationIndex = false;
        var campgroundIndex = false;

        var locationCounter = 1;
        var campgroundCounter = 1;

        for (var i = 0; i < response.length; i++) {

          var isCamp = response[i].isCampground;

          if (isCamp === undefined) {
            if (locationIndex === false) {
              locationIndex = i;
              response[i].firstLocation = true;
            }
            if (locationCounter <= 4) {
              locationCounter++;
              locationsSeparate.push(response[i]);
            }
          }

          if (isCamp === true) {
            campgroundCount++;
            if (campgroundIndex === false) {
              campgroundIndex = i;
              response[i].firstCampground = true;
            }
            if (campgroundCounter <= 2) {
              campgroundCounter++;
              locationsSeparate.push(response[i]);
            }
          }
        }

        if (locationCounter === 1 || campgroundCounter === 1) {
          isSeparating = false;
        }
        this.set('isSeparating', isSeparating);
        this.set('results', response);
        this.set('locationsSeparate', locationsSeparate);
        this.set('campgroundCount', { check: campgroundCount, count: campgroundCount - 2 });

        var resultsList = this.$().find('.hypersearch-results');
        var searchInput = this.$().find('.hypersearch-input');

        if (searchInput.val() !== '' && resultsList.is(':hidden')) {
          resultsList.slideDown();
        }
      }
    },

    willClearRender: function willClearRender() {
      // remove the custom event
      //this.$(document).off('keypress');
      //this.$(document).off('click');
    }

  });
});