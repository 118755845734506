define('cn-front/components/dashboard/changestep/verify-payment', ['exports', 'ember', 'npm:braintree-web'], function (exports, _ember, _npmBraintreeWeb) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Component.extend({
        reservation: inject.service('dashboard/res-change'),
        stepster: inject.service('dashboard/stepster'),
        refresher: inject.service('dashboard/refresher'),
        currentUser: inject.service('current-user'),
        apiConnect: inject.service('api-resolver'),

        isPaymentsEnabled: function isPaymentsEnabled() {
            var disabledPaymentOn = ["in_use", "maint", "fcfs", "info_req"];
            return disabledPaymentOn.indexOf(this.get('data.reservation.type')) == -1;
        },

        paymentsEnabled: (function () {
            return this.isPaymentsEnabled() && this.get('reservation.mode') != "no_change";
        }).property('reservation.mode'),

        onSelect: (function () {
            if (this.get('stepster.currentStep') == this.get('tabId')) {
                //firing every entering on this step
                var self = this;

                _ember['default'].run.schedule('afterRender', this, function () {
                    if (this.get('reservation.braintree_token').length && this.get('paymentsEnabled')) {

                        //clean up old braintree integrations
                        $('#dropin').html('');
                        $('#braintree-dropin-modal-frame').remove();

                        $('.modal .overlay').css('display', 'block');

                        // braintree integration
                        _npmBraintreeWeb['default'].setup(this.get('reservation.braintree_token'), 'dropin', {
                            container: "dropin",
                            onReady: function onReady() {
                                $('.modal .overlay').css('display', 'none');
                            },
                            paymentMethodNonceReceived: function paymentMethodNonceReceived(event, nonce) {
                                //this fires after user submit form
                                //console.log(nonce)
                                $('#nonce').val(nonce);
                                self.reserv();
                            },
                            onError: function onError(error) {
                                console.log('braintree error: ' + error.message);
                                $('.modal .overlay').css('display', 'none');
                            }
                        });
                    }
                    // "block" next button and fire create reservation instead next slide
                    this.set('stepster.ready', false);
                    this.set('stepster.fallback', (function () {
                        this.createReservation();
                    }).bind(this));
                });
            }
        }).observes('stepster.currentStep').on('init'),

        createReservation: function createReservation() {
            if (this.get('paymentsEnabled')) {
                $('.modal .overlay').css('display', 'block');
                $('#change-reservation').find('input[type="submit"]').click();
            } else {
                this.reserv();
            }
        },

        reserv: function reserv() {
            var self = this;
            var res_info = $('#change-reservation').serializeJSON();

            //console.log(res_info);
            var url = self.get('apiConnect').getUrl('/reservations/change');
            var token = $.cookie('token');
            if (this.get('reservation.remote_token')) {
                res_info.initiator_token = $.cookie('token');
                token = this.get('reservation.remote_token');
                console.log('using remote user token');
            }

            $.ajax({
                type: "POST",
                url: url,
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + token);
                },
                data: res_info,
                async: false,
                success: function success(data) {
                    console.log(data);
                    //set response in reservationobject
                    self.set('reservation.response', data);
                    var modalTitle = 'Reservation Changed';
                    $('.modal .modal-title').html(modalTitle);
                    //remove overlay
                    $('.modal .overlay').css('display', 'none');
                    //clean up next button block
                    self.set('stepster.ready', true);
                    self.set('stepster.fallback', null);
                    //go to next step
                    $('.res-footer .next').click();
                }
            });
        }
    });
});