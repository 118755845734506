define('cn-front/services/dashboard/stepster', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        ready: '',
        fallback: '',
        currentStep: '',
        finishedSteps: 0,
        init: function init() {
            this.reset();
        },
        reset: function reset() {
            this.set('ready', false);
            this.set('finishedSteps', 0);
            this.set('fallback', function () {});
            this.set('currentStep', null);
        }
    });
});