define('cn-front/components/reservation/min-duration', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        duration: null,
        arrival: null,
        arrivalFormated: null,

        didReceiveAttrs: function didReceiveAttrs() {
            var format_arrival_date;

            if (this.get('duration.type') == 'override') {
                format_arrival_date = moment(this.get('arrival')).format('YYYY-MM-DD');
            } else {
                format_arrival_date = moment(this.get('arrival')).format('dddd');
            }
            this.set('arrivalFormated', format_arrival_date);
        }
    });
});