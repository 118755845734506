define('cn-front/services/current-user', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Service.extend({
        apiConnect: inject.service('api-resolver'),
        user: null,
        lastSuccessCheck: false,

        checkUser: (function () {
            var tokenX = $.cookie('token');
            if (tokenX !== '') {

                var url = this.get('apiConnect').getUrl('/check-user');
                var self = this;

                $.ajax({
                    type: "GET",
                    url: url,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        self.set('user', data.user);
                        self.set('lastSuccessCheck', new Date());
                        if ($.cookie('remember') === 'true') {
                            $.cookie('token', data.token, { expires: 30, path: '/' });
                        } else {
                            $.cookie('token', data.token, { expires: 1, path: '/' });
                        }
                        _ember['default'].run.later(function () {
                            self.checkUser();
                        }, 1000 * 60 * 30);
                    },
                    error: function error() {
                        self.logout();
                        if (self.get('lastSuccessCheck')) {
                            self.get('router').transitionTo('auth.login');
                        }
                        self.set('lastSuccessCheck', false);
                    }
                });
            } else {
                this.set('user', {});
            }
        }).on('init'),

        setUser: function setUser(user) {
            this.set('user', user);
        },
        setTemporaryToken: function setTemporaryToken(token) {
            $.cookie('temp_token', token, { path: '/' });
        },
        clearTemporaryToken: function clearTemporaryToken() {
            $.cookie('temp_token', '', { path: '/' });
        },
        login: function login(data) {
            var self = this;
            if ($.cookie('remember') === 'true') {
                $.cookie('token', data.token, { expires: 30, path: '/' });
            } else {
                $.cookie('token', data.token, { expires: 1, path: '/' });
            }

            self.set('user', data.user);
            _ember['default'].run.later(function () {
                self.checkUser();
            }, 1000 * 60 * 30);
        },
        logout: function logout() {
            this.set('user', {});
            $.cookie('token', '', { path: '/' });
            $.cookie('remember', '', { path: '/' });
            this.set('lastSuccessCheck', false);
        },
        hasLevel: (function (level) {
            return parseInt(this.get('user.role')) >= level;
        }).property('user'),
        hasDashboardPermissions: (function () {
            return parseInt(this.get('user.role')) >= 2;
        }).property('user'),
        isCNLevel: (function () {
            //console.log('disabled isCNLevel'); return false;
            return parseInt(this.get('user.role')) >= 5;
        }).property('user'),
        isExecutive: (function () {
            return parseInt(this.get('user.role')) >= 7;
        }).property('user'),
        logged: (function () {
            //don't even ask why...
            if (this.get('user') !== null) {
                if (this.get('user').id === null || typeof this.get('user').id === 'undefined') {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }).property('user')

    });
});