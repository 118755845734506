define('cn-front/app', ['exports', 'ember', 'cn-front/resolver', 'ember-load-initializers', 'cn-front/config/environment'], function (exports, _ember, _cnFrontResolver, _emberLoadInitializers, _cnFrontConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;
  console.log('ENV = ', _cnFrontConfigEnvironment['default'].environment);
  if (_cnFrontConfigEnvironment['default'].environment == 'production') {
    console.log = function () {};
  }

  App = _ember['default'].Application.extend({
    modulePrefix: _cnFrontConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _cnFrontConfigEnvironment['default'].podModulePrefix,
    Resolver: _cnFrontResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _cnFrontConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});