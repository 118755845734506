define('cn-front/components/click-to-view', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        shown: false,
        didInsertElement: function didInsertElement() {
            this.set('shown', false);
        },
        actions: {
            show: function show() {
                this.set('shown', true);
            }
        }
    });
});