define('cn-front/routes/dashboard/campground/edit/campsites', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    /*globals Switchery*/

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        page: 1,
        perPage: 20,

        toTheTop: (function () {
            $('body').animate({
                scrollTop: 0
            }, 200);
        }).observes('page'),

        perPageEvent: (function () {
            var self = this;
            _ember['default'].run.schedule('afterRender', this, function () {
                var perPageSelec = $('#perpage');
                perPageSelec.on('change', function () {
                    self.set('perPage', perPageSelec.val());
                    self.refresh();
                    $('body,html').animate({
                        scrollTop: 0
                    }, 400);
                });
            });
        }).on('activate'),

        deactivate: function deactivate() {
            this.set('perPage', 20);
            this.set('page', 1);
        },

        model: function model() /*params*/{
            _ember['default'].$.ajaxSetup({
                headers: {
                    'Authorization': 'bearer ' + $.cookie('token')
                },
                async: false
            });
            var cg = this.modelFor('dashboard.campground.edit');
            var cs = _ember['default'].$.getJSON(this.get('apiConnect').getUrl('/dashboard/campgrounds/' + cg.general.id + '/campsite-list/?per_page=' + this.get('perPage') + '&page=' + this.get('page')));

            return {
                cs: cs.responseJSON,
                cg: cg
            };
        },

        afterModel: function afterModel() {
            _ember['default'].run.schedule('afterRender', this, function () {
                var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
                elems.forEach(function (html) {
                    new Switchery(html, { size: 'small' });
                });
            });
        },

        actions: {
            pageChanged: function pageChanged(current) {
                this.set('page', current);
                this.refresh();
            },
            deleteCs: function deleteCs(cs) {
                this.send('showModal', 'dashboard.campground.edit.modal.delete-cs', cs);
            },
            renameCs: function renameCs(cs) {
                this.send('showModal', 'dashboard.campground.edit.modal.rename-cs', cs);
            },
            editCsLoop: function editCsLoop(cs, loops) {
                this.send('showModal', 'dashboard.campground.edit.modal.edit-cs-loop', { cs: cs, loops: loops });
            },
            editCsMinStay: function editCsMinStay(cs) {
                this.send('showModal', 'dashboard.campground.edit.modal.edit-cs-min-stay', cs);
            },
            editRate: function editRate(cs, rate, cgrate, type) {
                this.send('showModal', 'dashboard.campground.edit.modal.edit-rate', { cs: cs, rate: rate, cgrate: cgrate, type: type });
            },
            copyCs: function copyCs(cs) {
                this.send('showModal', 'dashboard.campground.edit.modal.copy-campsite', cs);
            },
            createCs: function createCs() {
                var formdata = $('form#addcampsite').serializeJSON();

                var url = this.get('apiConnect').getUrl('/dashboard/campsites/create');
                var self = this;
                var flashMessages = _ember['default'].get(self, 'flashMessages');
                $.ajax({
                    type: "POST",
                    url: url,
                    data: formdata,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        console.log(data);
                        if (data.success) {
                            self.transitionTo('dashboard.campsite.edit.availability', data.cs_id);
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                        } else {
                            flashMessages.danger(_ember['default'].String.htmlSafe(data.error));
                        }
                        self.send('refreshModel');
                    }

                });
            },
            activateCs: function activateCs(cs) {
                console.log(cs);
                var data = {
                    is_active: cs.is_active ? false : true
                };

                var url = this.get('apiConnect').getUrl('/dashboard/campsites/' + cs.id + '/update');
                var self = this;
                var flashMessages = _ember['default'].get(self, 'flashMessages');
                $.ajax({
                    type: "POST",
                    url: url,
                    data: data,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        console.log(data);
                        if (data.success) {
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                        } else {
                            // TODO: rewrite error handler
                            //self.send('refreshModel');
                            if (data.error) {
                                flashMessages.danger(_ember['default'].String.htmlSafe(data.error.errorInfo[2]));
                            }
                        }
                    }

                });
            }
        }
    });
});