define('cn-front/mixins/reset-scroll', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        activate: function activate() {
            this._super.apply(this, arguments); // Call super
            // scroll to top
            $('body,html,#header-navbar').animate({
                scrollTop: 0
            }, 50);
        }
    });
});