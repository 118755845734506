define('cn-front/routes/dashboard', ['exports', 'ember', 'cn-front/mixins/loading-indicator'], function (exports, _ember, _cnFrontMixinsLoadingIndicator) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Route.extend(_cnFrontMixinsLoadingIndicator['default'], {
        currentUser: inject.service('current-user'),

        redirect: (function () {
            if (!this.get('currentUser.logged')) {
                this.transitionTo('auth.login');
            } else if (!this.get('currentUser.hasDashboardPermissions')) {
                this.transitionTo('404', '404');
            }
        }).observes('currentUser.logged'),
        toggleButton: (function () {
            _ember['default'].run.schedule('afterRender', this, function () {
                // allow any button with this class to toggle the sidebar
                $(document).on('click', ".sidebar-wrapper-toggle", function (e) {
                    e.preventDefault();
                    $("body").toggleClass("toggled-sidebar");

                    var event = document.createEvent('Event');
                    event.initEvent('stoggle', true, true);
                    document.dispatchEvent(event);

                    return false;
                });
                // when clicking a sidebar link, hide the side bar if it is "toggled" a.k.a. showing to mobile.
                // only do it for actual links, not data-toggle links.
                $("#sidebar-wrapper a:not([data-toggle='collapse'])").click(function () /*e*/{
                    // only do it if they are currently toggled.
                    $("body.toggled-sidebar").removeClass("toggled-sidebar");
                });
            });
        }).on('activate')
    });
});