define('cn-front/components/dashboard/ava-calendar', ['exports', 'ember', 'cn-front/utils/gtm'], function (exports, _ember, _cnFrontUtilsGtm) {
    var inject = _ember['default'].inject;
    var path = '';
    exports['default'] = _ember['default'].Component.extend({
        apiConnect: inject.service('api-resolver'),
        refresher: inject.service('dashboard/refresher'),
        additionData: {},
        rangeStart: '',
        rangeEnd: '',
        camp: {},
        days: [],
        options: '',
        header: '',
        content: '',
        type: 'front',
        reservations: [],
        page: 0,
        single: false,
        csId: '',
        cs: {},
        sd: '',
        pickedDate: '',
        showPicker: true,
        weekSize: 14,
        rowCounter: 0,
        divId: '',
        calendarSize: (function () {
            if (window.outerWidth < 1000 && window.outerWidth > 500) {
                this.set('weekSize', 7);
            } else if (window.outerWidth <= 500) {
                this.set('weekSize', 3);
            }
        }).on('init'),
        ajaxCalendar: function ajaxCalendar(failcallback) {
            var acUrl = '';
            if (this.get('pickedDate')) {
                var today = moment(this.get('pickedDate'));
                acUrl = path + today.format('YYYY-MM-DD') + '/' + this.page + '/' + this.weekSize + '/' + this.csId;
            } else {
                acUrl = path + this.sd.format('YYYY-MM-DD') + '/' + this.page + '/' + this.weekSize + '/' + this.csId;
            }

            var acPromise = $.get(acUrl, this.get('additionData'));
            var self = this;
            _ember['default'].run(function () {
                acPromise.then(function (data) {
                    self.ajaxCallback(data);
                }, failcallback);
            });
        },
        ajaxCallback: function ajaxCallback(data) {
            var self = this;
            _ember['default'].run(function () {
                //console.log(self);
                self.set('reservations', data);
                self.renderCalendar(self.get('page'));
            });
        },
        setDateRange: function setDateRange() {
            var dr = $.cookie('homefilter') ? JSON.parse($.cookie('homefilter')) : null;

            if (dr != null) {
                if (dr.sdate !== '') {
                    this.set('sd', moment(dr.sdate));
                } else {
                    this.set('sd', moment());
                }
            } else {
                this.set('sd', moment());
            }
            return this.get('sd');
        },
        didInsertElement: (function () {
            //this._super();
            _ember['default'].run.schedule('afterRender', this, this.didRenderElement);
        }).observes('camp'),
        didRenderElement: function didRenderElement() {
            // afterRender from didInsertElement...
            var self = this;
            this.setDateRange();
            var cgId;
            if (this.get('single') && this.get('camp.general.id') > 0) {
                path = this.get('apiConnect').getUrl("/dashboard/campgrounds/" + this.get('camp.general.id') + "/availability/");
                cgId = this.get('camp.general.id');
            } else if (this.get('camp.id') > 0) {
                path = this.get('apiConnect').getUrl("/dashboard/campgrounds/" + this.get('camp.id') + "/availability/");
                cgId = this.get('camp.id');
            } else {
                console.log('ava-calendar', 'no campground ID');
                return false;
            }

            this.set('divId', 'cg' + cgId + this.get('csId'));

            if (this.get('type') == "front") {
                var cgMode = this.get('camp.reservation_mode');
                if (cgMode == 'confirmation_booking') {
                    this.set('restype', 'confirm');
                } else if (cgMode == 'instant_booking') {
                    this.set('restype', 'instant');
                }
            }
            _ember['default'].run.later(function () {
                var datePicker = $('.ava_date_picker');
                datePicker.datepicker({
                    orientation: "bottom left",
                    autoclose: true,
                    startDate: self.get('type') == "dash" ? false : new Date()
                }).on('changeDate', function () /*e*/{
                    if (datePicker.val() !== '') {
                        self.set('pickedDate', datePicker.val());
                        self.set('page', 0);
                        self.ajaxCalendar();
                    }
                });
            }, 700);
            this.ajaxCalendar();
            this.modalInit(this.get('camp'));
        },
        ref: (function () {
            if (this.get('refresher.calendar') && this.sd !== '') {
                this.setDateRange();
                this.ajaxCalendar();
            }
        }).observes('refresher.calendar').on('init'),

        actions: {
            prev: function prev() {
                this.page--;
                this.ajaxCalendar((function () {
                    this.page++;
                }).bind(this));
            },
            next: function next() {
                this.page++;
                this.ajaxCalendar((function () {
                    this.page--;
                }).bind(this));
            },
            today: function today() {
                var originalPage = this.page;
                var originalSd = this.sd;
                this.page = 0;
                //this.sd = moment();
                this.ajaxCalendar((function () {
                    this.page = originalPage;
                    this.sd = originalSd;
                }).bind(this));
            },
            selectMonth: function selectMonth() {
                var originalPage = this.page;
                var originalSd = this.sd;

                this.page = 0;
                var changeMonth = $('#change-month');
                this.sd = moment(changeMonth.val());
                changeMonth.val('default');
                this.ajaxCalendar((function () {
                    this.page = originalPage;
                    this.sd = originalSd;
                }).bind(this));
            }
        },
        renderCalendar: function renderCalendar(week) {
            var _this = this;

            var h = '',
                today = '';
            if (this.get('pickedDate')) {
                today = new Date(this.get('pickedDate'));
            } else {
                today = new Date(this.sd);
            }
            this.set('days', this.DatesInRange(new Date(today.getFullYear(), today.getMonth(), today.getDate() + week * this.get('weekSize')), new Date(today.getFullYear(), today.getMonth(), today.getDate() + this.get('weekSize') + week * this.get('weekSize'))));

            this.set('rangeStart', moment(this.get('days')[0]).format('MMMM DD, YYYY'));
            this.set('rangeEnd', moment(this.get('days')[this.get('weekSize') - 1]).format('MMMM DD, YYYY'));
            h += "";
            //init header
            this.get('days').map((function (d) {
                var dname = d.toString().split(' ')[0];
                var mname = d.toString().split(' ')[1];
                var className = dname == "Sat" || dname == "Sun" ? 'weekend' : '';
                h += '<th class="' + className + '"><i>' + dname + '</i><br>' + mname + ' <br/> ' + d.getDate() + '</th>';
            }).bind(this));

            this.set('header', _ember['default'].String.htmlSafe(h));

            this.set('content', '');

            // create calendar rows
            this.set('rowCounter', 0);
            var match_type = '';
            this.get('reservations').map((function (campsite) {
                if (match_type != campsite.type_match && this.get('reservations').length > 1 && this.get('type') === 'front') {
                    this.addMatchingRow(campsite.type_match);
                    match_type = campsite.type_match;
                }
                this.addRow(campsite);
            }).bind(this));

            $(window).scroll(this.stickyRelocate);
            this.stickyRelocate();
            _ember['default'].run.later(function () {
                _this.linkColWidth();
            }, 700);
        },

        linkColWidth: function linkColWidth() {
            var f = function f() {
                var par = $('.ava-calendar-table tbody tr').first().find('td'),
                    child = $('.ava-calendar-table thead tr').find('th');

                console.log('yo!');
                _ember['default'].run.later(function () {
                    for (var k = 0; k < par.length; k++) {
                        $(child[k]).width($(par[k]).width());
                    }
                }, 500);
            };

            f();
            $(window).resize(f);
            $(document).on('stoggle', f);
        },

        stickyRelocate: function stickyRelocate() {
            var dashboardEl = $('.dashboard-campground-edit-availability');
            var topOffset = 0;
            if (dashboardEl.length) {
                topOffset = dashboardEl.find('.navbar-fixed-top').outerHeight(true);
            } else {
                var jPBanner = $('#pagebanner');
                var header_height = $('#header').outerHeight(true);
                var pagebanner_inner_height = jPBanner.find('.inner').outerHeight(true);
                var campnav_height = $('#camp-nav').outerHeight(true);
                topOffset = header_height + pagebanner_inner_height + campnav_height;
            }

            var window_top = $(window).scrollTop();
            var element_top = $($('.table-wraper')).offset().top - topOffset;
            if (element_top > 0 && window_top > element_top) {
                $('.ava-calendar .next, .ava-calendar .prev, .thead').css({
                    'top': window_top - element_top + 'px',
                    'z-index': 6
                });
            } else {
                $('.ava-calendar .next, .ava-calendar .prev, .thead').css({
                    'top': '',
                    'z-index': ''
                });
            }
        },

        addMatchingRow: function addMatchingRow(matchType) {
            var row = '<tr class="' + matchType + '_header"><td class="campground-campsites" colspan="' + this.get('weekSize') + 1 + '">';
            if (matchType === 'not_match') {
                row += '<h3>Not Matching Dates or Shelter</h3>';
            } else if (matchType === 'partial_match') {
                row += '<h3>Matching Dates & Shelter</h3>';
            } else {
                row += '<h3>Matching All Filters</h3>';
            }
            row += '</td></tr>';
            this.set('content', _ember['default'].String.htmlSafe(this.get('content') + row));
        },

        addRow: function addRow(campsite) {

            var isActiveClass = campsite.is_active ? "" : "closed";
            var isActiveIcon = campsite.is_active ? "" : '<i class="icon-closed black"></i> ';

            var row = '<tr id="' + campsite.id + '" class="' + isActiveClass + '"><td class="ava-site-name gotocs"  data-csid="' + campsite.id + '" >' +
            // (campsite.name.length > 10 ? campsite.name.substring(0, 10) + "..." : campsite.name);
            isActiveIcon + campsite.name;
            row += '<div class="cs-thumbnail" style="background-image: url(';
            row += campsite.photo ? '\'' + campsite.photo + '\'' : '/assets/images/cg/thumbnail/cover-placeholder_1.jpg';
            row += ')"></div>';
            row += '</td>';

            var reservation = campsite.reservations;
            this.days.map((function (d) {
                var dname = d.toString().split(' ')[0];
                var className = dname == "Sat" || dname == "Sun" ? 'weekend' : '';
                var month = ('0' + (d.getMonth() + 1)).slice(-2),
                    day = ('0' + d.getDate()).slice(-2),
                    dayId = d.getFullYear() + '-' + month + '-' + day,
                    formatedId = month + '/' + day + '/' + d.getFullYear();

                row += '<td id="' + dayId + '" class="' + className + '" data-rdaily="' + campsite.rate_daily + '" data-rweekly="' + campsite.rate_weekly + '" data-rmonthly="' + campsite.rate_monthly + '">' + this.getStatus(reservation[dayId], campsite.id, formatedId) + '</td>';
            }).bind(this));

            row += '</tr>';
            // repeat header every 10 rows
            // this.set('rowCounter', this.get('rowCounter') + 1);
            // if (this.get('rowCounter') % 10 === 0) {
            //     row += '<tr class="center-aligne"><th></th>' + this.get('header') + '</tr>';
            // }

            this.set('content', _ember['default'].String.htmlSafe(this.get('content') + row));
        },

        DatesInRange: function DatesInRange(dStart, dEnd) {
            var aDates = [];
            if (dStart <= dEnd) {
                for (var d = dStart; d < dEnd; d.setDate(d.getDate() + 1)) {
                    aDates.push(new Date(d.toString()));
                }
            }
            return aDates;
        },

        getStatus: function getStatus(reservation, campsiteId, dStart) {

            var className = 'daystatus ',
                label;
            var status = reservation.status;

            switch (status) {
                case 'available':
                    className += 'green';
                    label = '<a href="#" data-csid="' + campsiteId + '" data-start="' + dStart + '" data-type="' + this.type + '">' + '<i class="icon-available"></i></a>';
                    break;
                case 'instant':
                case 'confirm':
                    label = '<i class="icon-reserved resinfo" id="' + reservation.reservation_id + '"></i>';
                    break;
                case 'confirm_pending':
                    className += 'red';
                    if (this.type == 'dash') {
                        label = '<i class="icon-reserved pending" id="' + reservation.reservation_id + '"></i>';
                    } else {
                        label = '<i class="icon-reserved"></i>';
                    }

                    break;
                case 'on_site':
                    className += 'red';
                    label = '<i class="icon-reserved resinfo" id="' + reservation.reservation_id + '"></i>';
                    break;
                case 'out-of-season':
                    className += 'black';
                    label = '<i class="icon-out-of-season"></i>';
                    break;
                case 'fcfs':
                    className += 'orange';
                    label = '<i class="icon-fcfs resinfo" id="' + reservation.reservation_id + '"></i>';
                    break;
                case 'in_use':
                    className += 'blue';
                    label = '<i class="icon-reserved resinfo" id="' + reservation.reservation_id + '"></i>';
                    break;
                case 'maint':
                    className += 'grey';
                    label = '<i class="icon-maint resinfo" id="' + reservation.reservation_id + '"></i>';
                    break;
                case 'closed':
                    className += 'black';
                    label = '<i class="icon-closed"></i>';
                    break;
                default:
                    className += 'purple';
                    label = 'Wrong Type';
                    break;

            }
            return '<div class="' + className + '" data-reservation-type="' + status + '">' + label + '</div>';
        },

        modalInit: function modalInit(camp) {
            var self = this;
            $(document).on('click', '#' + this.get('divId') + ' .daystatus a', function (e) {
                e.stopPropagation();
                e.preventDefault();
                var data;
                data = $(this).data();
                var ratesData = $(this).parent().parent().data();

                data.rate_daily = ratesData.rdaily;
                data.rate_weekly = ratesData.rweekly;
                data.rate_monthly = ratesData.rmonthly;

                if (camp.general !== undefined) {
                    data.camp = camp.general;
                } else {
                    data.camp = camp;
                }

                if (self.get('single')) {
                    data.cs = self.get('cs');
                }

                var initiatedFrom = { 'unknown': 'Unknown' };
                if (self.get('single') && data.type == 'dash') {
                    initiatedFrom = { 'admin_campsite_calendar': 'Admin: Campsite availability calendar' };
                } else if (self.get('single') && data.type != 'dash') {
                    initiatedFrom = { 'campsite_calendar': 'Campsite popup availability calendar' };
                } else if (!self.get('single') && data.type == 'dash') {
                    initiatedFrom = { 'admin_campground_calendar': 'Admin: Campground availability calendar' };
                } else if (!self.get('single') && data.type != 'dash') {
                    initiatedFrom = { 'campground_calendar': 'Campground availability calendar' };
                }

                _cnFrontUtilsGtm['default'].trackEvent({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': initiatedFrom,
                            // Where product click was initiated. Options are:
                            //
                            // admin_campground_calendar: 'Admin: Campground availability calendar'
                            // campground_calendar: 'Campground availability calendar'
                            // campsite_list_book_now: 'Campsite list book now button'
                            // admin_campsite_calendar: 'Admin: Campsite availability calendar'
                            // campsite_calendar: 'Campsite popup availability calendar'
                            // campsite_popup_book_now: 'Campsite popup book now button'

                            'products': [{
                                'name': data.camp.name, // Campground name
                                'id': data.csid // campsite ID
                            }]
                        }
                    }
                });

                if (data.type == 'dash') {
                    self.sendAction('showModal', 'dashboard/modal/choose-restype', data);
                } else {
                    data.restype = self.get('restype');
                    self.sendAction('showModal', 'dashboard/modal/reserv-modal', data);
                }
            });
            $(document).on('click', '#' + this.get('divId') + ' .daystatus .pending', function () /*e*/{
                if (self.get('type') == "dash") {
                    var data = {
                        id: $(this).attr('id'),
                        status: 1,
                        type: "dash"
                    };
                    self.sendAction('showModal', 'dashboard/campground/edit/modal/pending-res', data);
                }
            });
            $(document).on('click', '#' + this.get('divId') + ' .daystatus .resinfo', function (e) {
                e.stopPropagation();
                e.preventDefault();
                if (self.get('type') == "dash") {
                    var data = {
                        id: $(this).attr('id'),
                        type: "dash"
                    };

                    self.sendAction('showModal', 'dashboard/modal/resinfo', data);
                }
            });
        },

        willClearRender: function willClearRender() {
            // remove the custom event
            this.$(document).off('click', '#' + this.get('divId') + ' .daystatus a');
            this.$(document).off('click', '#' + this.get('divId') + ' .daystatus .pending');
            this.$(document).off('click', '#' + this.get('divId') + ' .daystatus .resinfo');
            this.$(document).off('stoggle');
            $(window).off('scroll');
        }

    });
});