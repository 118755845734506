define('cn-front/components/campground/section-info-detail', ['exports', 'ember'], function (exports, _ember) {
  var inject = _ember['default'].inject;

  exports['default'] = _ember['default'].Component.extend({
    currentUser: inject.service('current-user'),
    onlineReservationDisabled: (function () {
      return this.get('model.campground.reservation_ccprocessing_id') === 1 || this.get('model.campground.reservation_ccprocessing_id') === 2;
    }).property('model.campground.reservation_ccprocessing_id'),

    onlineReservationEnabled: (function () {
      return this.get('model.campground.reservation_ccprocessing_id') === 3;
    }).property('model.campground.reservation_ccprocessing_id'),

    reservationRequiresConfirmation: (function () {
      return this.get('model.campground.reservation_confirmation_id') === 2;
    }).property('model.campground.reservation_confirmation_id'),

    infoRequestAvailable: (function () {
      var mode = this.get('model.campground.reservation_mode');
      return mode === 'information_request' || mode === 'revoked';
    }).property('model.campground.reservation_mode'),

    didReceiveAttrs: function didReceiveAttrs() {
      _ember['default'].run.schedule("afterRender", this, function () {
        var $body = $('body');
        var $wrapInfo = $('.wrap-info.wrap-info-desc');

        var heightParagraph = $wrapInfo.find('p').height();
        var heightWrapper = $wrapInfo.height();

        if (heightWrapper >= heightParagraph) {
          $wrapInfo.parent().find('.show-more').css('display', 'none');
        }

        $body.on('click', '.show-more', function (e) {
          e.preventDefault();

          var height = 'auto';
          $(this).parent('.block').find('.wrap-info').toggleClass('active').attr('style', '');

          if ($(this).parent('.block').find('.wrap-info').hasClass('active')) {
            $(this).find('span').text('Show less');

            if ($(this).parent('.block').find('.wrap-info').hasClass('wrap-info-desc')) {
              height = $(this).parent('.block').find('.wrap-info > p').height();
            } else {
              height = $(this).parent('.block').find('.wrap-info > ul').height();
            }
          } else {
            $(this).find('span').text('Show more');
          }

          $(this).parent('.block').find('.wrap-info.active').animate({
            height: height
          }, 300);

          console.log('height', height);
        });
        $body.animate({
          scrollTop: 0
        }, 200);
      });
    }
  });
});