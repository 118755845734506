define('cn-front/components/campground/map-section', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        didReceiveAttrs: function didReceiveAttrs() {
            /**
             * Load campground map
             */
            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                if (_ember['default'].testing || this.isDestroyed) {
                    return;
                }

                var mapElement = $('#cg-map');
                if (mapElement.length === 1) {
                    $(mapElement).css('width:100%');

                    var lat = this.get('lat'),
                        lng = this.get('lng');

                    var center = new google.maps.LatLng(lat, lng);
                    var mapOptions = {
                        zoom: 12,
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        center: center,
                        disableDefaultUI: true,
                        zoomControl: true,
                        draggable: true,
                        disableDoubleClickZoom: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        overviewMapControl: false,
                        scrollwheel: false
                    };
                    var map = new google.maps.Map(mapElement[0], mapOptions);
                    var image = '/assets/images/map-icon.png';

                    new google.maps.Marker({
                        position: center,
                        map: map,
                        icon: image
                    });
                }
            });
        }
    });
});