define('cn-front/components/campground/campsite/details-section', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        type: 'campsite',
        label: 'Campsite',

        didReceiveAttrs: function didReceiveAttrs() {
            if (this.get('type') == 'cabin') {
                this.set('label', 'Cabin');
            }
        }
    });
});