define("cn-front/templates/dashboard/modal/reserv-modal", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 4
              },
              "end": {
                "line": 10,
                "column": 4
              }
            },
            "moduleName": "cn-front/templates/dashboard/modal/reserv-modal.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h4");
            var el2 = dom.createTextNode("Reservations are not allowed for this campground at this time. Please try again later.");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "res-footer");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "type", "button");
            dom.setAttribute(el2, "class", "btn btn-default");
            var el3 = dom.createTextNode("OK");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element6);
            return morphs;
          },
          statements: [["element", "action", ["removeModal"], [], ["loc", [null, [8, 58], [8, 82]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 28,
                    "column": 24
                  },
                  "end": {
                    "line": 30,
                    "column": 24
                  }
                },
                "moduleName": "cn-front/templates/dashboard/modal/reserv-modal.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "dashboard/step/verify-user-dash", [], ["tabId", "2"], ["loc", [null, [29, 28], [29, 73]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 24
                  },
                  "end": {
                    "line": 32,
                    "column": 24
                  }
                },
                "moduleName": "cn-front/templates/dashboard/modal/reserv-modal.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "dashboard/step/verify-user", [], ["tabId", "2"], ["loc", [null, [31, 28], [31, 68]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 12
                },
                "end": {
                  "line": 41,
                  "column": 12
                }
              },
              "moduleName": "cn-front/templates/dashboard/modal/reserv-modal.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "nav nav-tabs resnav");
              dom.setAttribute(el1, "role", "tablist");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              dom.setAttribute(el2, "class", "active");
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "href", "#step-1");
              dom.setAttribute(el3, "role", "tab");
              dom.setAttribute(el3, "data-toggle", "tab");
              dom.setAttribute(el3, "aria-controls", "step-1");
              var el4 = dom.createTextNode("1");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "href", "#step-2");
              dom.setAttribute(el3, "role", "tab");
              dom.setAttribute(el3, "data-toggle", "tab");
              dom.setAttribute(el3, "aria-controls", "step-2");
              var el4 = dom.createTextNode("2");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "href", "#step-3");
              dom.setAttribute(el3, "role", "tab");
              dom.setAttribute(el3, "data-toggle", "tab");
              dom.setAttribute(el3, "aria-controls", "step-3");
              var el4 = dom.createTextNode("3");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "href", "#step-4");
              dom.setAttribute(el3, "role", "tab");
              dom.setAttribute(el3, "data-toggle", "tab");
              dom.setAttribute(el3, "aria-controls", "step-4");
              var el4 = dom.createTextNode("4");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment(" Tab panes ");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "tab-content");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "role", "tabpanel");
              dom.setAttribute(el2, "class", "tab-pane active");
              dom.setAttribute(el2, "id", "step-1");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "role", "tabpanel");
              dom.setAttribute(el2, "class", "tab-pane");
              dom.setAttribute(el2, "id", "step-2");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "role", "tabpanel");
              dom.setAttribute(el2, "class", "tab-pane");
              dom.setAttribute(el2, "id", "step-3");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "role", "tabpanel");
              dom.setAttribute(el2, "class", "tab-pane");
              dom.setAttribute(el2, "id", "step-4");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [5]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
              morphs[3] = dom.createMorphAt(dom.childAt(element1, [7]), 1, 1);
              return morphs;
            },
            statements: [["inline", "dashboard/step/verify-camp", [], ["tabId", "1"], ["loc", [null, [25, 24], [25, 64]]], 0, 0], ["block", "if", [["subexpr", "eq", [["get", "reservation.restype", ["loc", [null, [28, 34], [28, 53]]], 0, 0, 0, 0], "on_site"], [], ["loc", [null, [28, 30], [28, 64]]], 0, 0]], [], 0, 1, ["loc", [null, [28, 24], [32, 31]]]], ["inline", "dashboard/step/verify-payment", [], ["tabId", "3"], ["loc", [null, [35, 24], [35, 67]]], 0, 0], ["inline", "dashboard/step/res-confirm", [], ["tabId", "4"], ["loc", [null, [38, 24], [38, 64]]], 0, 0]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 12
                },
                "end": {
                  "line": 60,
                  "column": 12
                }
              },
              "moduleName": "cn-front/templates/dashboard/modal/reserv-modal.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "nav nav-tabs resnav");
              dom.setAttribute(el1, "role", "tablist");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              dom.setAttribute(el2, "class", "active");
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "href", "#step-1");
              dom.setAttribute(el3, "role", "tab");
              dom.setAttribute(el3, "data-toggle", "tab");
              dom.setAttribute(el3, "aria-controls", "step-1");
              var el4 = dom.createTextNode("1");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "href", "#step-2");
              dom.setAttribute(el3, "role", "tab");
              dom.setAttribute(el3, "data-toggle", "tab");
              dom.setAttribute(el3, "aria-controls", "step-2");
              var el4 = dom.createTextNode("2");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "href", "#step-3");
              dom.setAttribute(el3, "role", "tab");
              dom.setAttribute(el3, "data-toggle", "tab");
              dom.setAttribute(el3, "aria-controls", "step-3");
              var el4 = dom.createTextNode("3");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment(" Tab panes ");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "tab-content");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "role", "tabpanel");
              dom.setAttribute(el2, "class", "tab-pane active");
              dom.setAttribute(el2, "id", "step-1");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "role", "tabpanel");
              dom.setAttribute(el2, "class", "tab-pane");
              dom.setAttribute(el2, "id", "step-2");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "role", "tabpanel");
              dom.setAttribute(el2, "class", "tab-pane");
              dom.setAttribute(el2, "id", "step-3");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [5]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
              return morphs;
            },
            statements: [["inline", "dashboard/step/verify-camp", [], ["tabId", "1"], ["loc", [null, [51, 24], [51, 64]]], 0, 0], ["inline", "dashboard/step/verify-payment", [], ["tabId", "2"], ["loc", [null, [54, 24], [54, 67]]], 0, 0], ["inline", "dashboard/step/res-confirm", [], ["tabId", "3"], ["loc", [null, [57, 24], [57, 64]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 4
              },
              "end": {
                "line": 67,
                "column": 4
              }
            },
            "moduleName": "cn-front/templates/dashboard/modal/reserv-modal.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "id", "new-reservation-popup");
            dom.setAttribute(el1, "class", "steps");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment(" Nav tabs ");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "res-footer");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "type", "button");
            dom.setAttribute(el2, "class", "btn btn-default prev");
            var el3 = dom.createTextNode("Prev");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "type", "button");
            dom.setAttribute(el2, "class", "btn btn-primary next");
            var el3 = dom.createTextNode("Next");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "type", "button");
            dom.setAttribute(el2, "class", "btn btn-default");
            var el3 = dom.createTextNode("Close");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [3]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element2, [3]);
            var element5 = dom.childAt(element2, [5]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
            morphs[1] = dom.createElementMorph(element3);
            morphs[2] = dom.createElementMorph(element4);
            morphs[3] = dom.createElementMorph(element5);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "reservation.type", ["loc", [null, [14, 26], [14, 42]]], 0, 0, 0, 0], "front"], [], ["loc", [null, [14, 22], [14, 52]]], 0, 0], ["subexpr", "eq", [["get", "reservation.restype", ["loc", [null, [14, 57], [14, 76]]], 0, 0, 0, 0], "on_site"], [], ["loc", [null, [14, 53], [14, 87]]], 0, 0]], [], ["loc", [null, [14, 18], [14, 88]]], 0, 0]], [], 0, 1, ["loc", [null, [14, 12], [60, 19]]]], ["element", "action", ["prev"], [], ["loc", [null, [63, 63], [63, 80]]], 0, 0], ["element", "action", ["next"], [], ["loc", [null, [64, 63], [64, 80]]], 0, 0], ["element", "action", ["removeModal"], [], ["loc", [null, [65, 58], [65, 82]]], 0, 0]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 69,
              "column": 0
            }
          },
          "moduleName": "cn-front/templates/dashboard/modal/reserv-modal.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "if", [["get", "model.camp.disable_reservations", ["loc", [null, [5, 10], [5, 41]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [5, 4], [67, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 69,
            "column": 13
          }
        },
        "moduleName": "cn-front/templates/dashboard/modal/reserv-modal.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "bs-modal", [], ["title", "New Reservation", "okLabel", "Submit", "closeLabel", "Cancel", "ok", "createReservation", "autoclose", false, "finishClose", "removeModal", "withFooter", false], 0, null, ["loc", [null, [1, 0], [69, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});