define('cn-front/routes/dashboard/campground/edit/ginfo', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        maybeUpdateCampsites: [],
        bindEvents: (function () {
            _ember['default'].run.schedule('afterRender', this, function () {
                $('select[multiple]').multipleSelect();
                $('#campsite_photos_single').change(function () {
                    if ($('#campsite_photos_single').val() == '0') {
                        $('select[name="campsite_photos_id"]').val('').attr('disabled', true);
                    } else {
                        $('select[name="campsite_photos_id"]').attr('disabled', false);
                    }
                });
            });
        }).on('activate'),

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('initialData', JSON.parse(JSON.stringify(model)));
        },

        actions: {
            checkData: function checkData() {

                var rate_daily = $('input[name="rate_daily"]');
                var rate_weekly = $('input[name="rate_weekly"]');
                var rate_monthly = $('input[name="rate_monthly"]');
                var time_checkin = $('select[name="time_checkin"]');
                var time_checkout = $('select[name="time_checkout"]');
                var initialValue;
                var is_promoted = $('input[name="is_promoted"]').prop('checked');

                this.set('maybeUpdateCampsites', []);

                if (is_promoted) {
                    $('input[name="is_promoted"]').val(1);
                } else {
                    $('input[name="is_promoted"]').val(0);
                }

                initialValue = rate_daily.closest('div').attr('data-initial-value');
                if (typeof initialValue == 'undefined') {
                    initialValue = '';
                }
                if (rate_daily.val() !== initialValue) {
                    this.get('maybeUpdateCampsites').push({ key: 'rate_daily', name: 'Rate Daily' });
                }

                initialValue = rate_weekly.closest('div').attr('data-initial-value');
                if (typeof initialValue == 'undefined') {
                    initialValue = '';
                }
                if (rate_weekly.val() !== initialValue) {
                    this.get('maybeUpdateCampsites').push({ key: 'rate_weekly', name: 'Rate Weekly' });
                }

                initialValue = rate_monthly.closest('div').attr('data-initial-value');
                if (typeof initialValue == 'undefined') {
                    initialValue = '';
                }
                if (rate_monthly.val() !== initialValue) {
                    this.get('maybeUpdateCampsites').push({ key: 'rate_monthly', name: 'Rate Monthly' });
                }

                initialValue = time_checkin.closest('div').parent().attr('data-initial-value');
                if (typeof initialValue == 'undefined') {
                    initialValue = '';
                }
                if (time_checkin.val() !== initialValue) {
                    this.get('maybeUpdateCampsites').push({ key: 'time_checkin', name: 'Time Checkin' });
                }

                initialValue = time_checkout.closest('div').parent().attr('data-initial-value');
                if (typeof initialValue == 'undefined') {
                    initialValue = '';
                }
                if (time_checkout.val() !== initialValue) {
                    this.get('maybeUpdateCampsites').push({ key: 'time_checkout', name: 'Time Checkout' });
                }

                if (this.get('maybeUpdateCampsites').length > 0) {
                    var names = this.get('maybeUpdateCampsites').map(function (obj) {
                        return obj.name;
                    }).join(', ');
                    $('.confirm-save .updated-values').html(names);

                    $('.general-save-btn-wrapper > button').slideUp();
                    $('.general-save-btn-wrapper .confirm-save').slideDown();
                } else {
                    this.send('updateData');
                }
            },

            closePopup: function closePopup() {
                $('.general-save-btn-wrapper > button').slideDown();
                $('.general-save-btn-wrapper .confirm-save').slideUp();
            },

            updateData: function updateData(isToUpdateCampsites) {
                var formdata = $('form#campground-edit').serializeJSON();
                var is_promoted = $('input[name="is_promoted"]').prop('checked');

                $('select[multiple]').each(function () {
                    var selectValues = $(this).multipleSelect('getSelects');
                    formdata[$(this).attr('name')] = selectValues.length > 0 ? selectValues : null;
                });

                if (this.get('maybeUpdateCampsites').length > 0) {

                    if (isToUpdateCampsites) {
                        formdata['updateCampsitesWith'] = this.get('maybeUpdateCampsites').map(function (obj) {
                            return obj.key;
                        });
                    }

                    $('.general-save-btn-wrapper > button').slideDown();
                    $('.general-save-btn-wrapper .confirm-save').slideUp();
                }

                if (!is_promoted) {
                    formdata['is_promoted'] = 0;
                }
                console.log(formdata);

                var cg = this.modelFor('dashboard.campground.edit');
                var url = this.get('apiConnect').getUrl('/dashboard/campgrounds/' + cg.general.id + '/update');
                var self = this;
                var flashMessages = _ember['default'].get(self, 'flashMessages');
                $.ajax({
                    type: "POST",
                    url: url,
                    data: formdata,

                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        if (data.success) {
                            self.send('refreshModel');
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                            $('body,html').animate({
                                scrollTop: 0
                            }, 400);
                        } else {
                            $('body,html').animate({
                                scrollTop: 0
                            }, 400);
                            if (data.error) {
                                flashMessages.danger(_ember['default'].String.htmlSafe(data.error.errorInfo[2]));
                                if (data.error.errorInfo[1] == 'per campsite') {
                                    console.log(data.error.errorInfo[1]);
                                    $('#campsite_photos_single').val('0');
                                }
                            }
                        }
                    }

                });
            }
        }
    });
});