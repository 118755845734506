define('cn-front/routes/dashboard/user/edit', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        model: function model(params) {
            _ember['default'].$.ajaxSetup({
                headers: {
                    'Authorization': 'bearer ' + $.cookie('token')
                }
            });
            return _ember['default'].$.getJSON(this.get('apiConnect').getUrl('/dashboard/user/' + params.id + '/edit'));
        }
    });
});