define('cn-front/routes/dashboard/users/index', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        thisController: function thisController() {
            return this.controllerFor('dashboard/users/index');
        },

        queryParams: {
            search: {
                refreshModel: true
            },
            page: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var ctrlData = this.thisController();
            var usersUrl = this.get('apiConnect').getUrl('/dashboard/users');
            var response = {};

            $.ajax({
                type: "GET",
                url: usersUrl,
                data: {
                    'page': params.page,
                    'search': params.search,
                    'orderBy': ctrlData.orderBy,
                    'sort': ctrlData.sort
                },
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                },
                async: false,
                success: function success(data) {
                    response = data;
                    console.log(data);
                }
            });
            _ember['default'].run.schedule('afterRender', function () {
                var slug = ctrlData.orderBy === '' ? 'id' : ctrlData.orderBy;
                var sortHeader = $('.order-source.' + slug);

                sortHeader.removeClass('asc desc');
                $('.order-source.active-sort').removeClass('active-sort asc desc');
                sortHeader.addClass('active-sort');
                sortHeader.addClass(ctrlData.sort.toLowerCase());
            });

            return response;
        },
        actions: {
            applySort: function applySort(slug) {
                var ctrlData = this.thisController();
                var sortHeader = $('.order-source.' + slug);
                sortHeader.removeClass('asc desc');
                var newOrder = 'ASC';

                if (sortHeader.hasClass('active-sort')) {
                    newOrder = ctrlData.get('sort') == 'ASC' ? 'DESC' : 'ASC';
                } else {
                    $('.order-source.active-sort').removeClass('active-sort asc desc');
                    sortHeader.addClass('active-sort');
                    ctrlData.set('orderBy', slug);
                }
                ctrlData.set('sort', newOrder);
                sortHeader.addClass(newOrder.toLowerCase());
                this.refresh();
            }
        }
    });
});