define('cn-front/components/campground/section-navigation', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    campground: null,
    campsite: null,
    isMoreDetails: false,
    didReceiveAttrs: function didReceiveAttrs() {
      var cg = this.get('campground');
      if (!this.get('campsite') && (cg.description_rules || cg.description_camping || cg.description_history || cg.description_boating)) {
        this.set('isMoreDetails', true);
      }

      _ember['default'].run.schedule("afterRender", this, function () {
        // scroll to anchor
        $('li a[href^="#"]').on('click', function (e) {
          e.preventDefault();

          var $target = $(this.hash);
          if (this.hash === '#photo-camp' && $('#photo-camp').length === 0) {
            $target = $('#map-camp');
          }
          $(this).parent('li').addClass('active');
          $(this).parent().siblings('.active').removeClass('active');

          $('html, body').stop().animate({
            'scrollTop': $target.offset().top - 140
          }, 900, 'swing');

          $(this).blur();
        });
      });
    },

    willDestroyElement: function willDestroyElement() {
      $('a[href^="#"]').off('click');
    },

    showAvailabilityButton: (function () {
      if (this.get('campground.ccprocessing')) {
        return true;
      } else if (this.get('campsite') || this.get('campground.type.slug') == 'cabin' || this.get('campground.campsites').length < 1) {
        return false;
      }

      return true;
    }).property('campground'),

    showBookButton: (function () {
      if (this.get('campground.reservation_mode') == 'revoked') {
        return false;
      }

      return true;
    }).property('campground')
  });
});