define('cn-front/routes/dashboard/campsite/edit', ['exports', 'ember'], function (exports, _ember) {
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Route.extend({
    apiConnect: inject.service('api-resolver'),
    model: function model(params) {
      _ember['default'].$.ajaxSetup({
        headers: {
          'Authorization': 'bearer ' + $.cookie('token')
        },
        async: false
      });
      var cs = _ember['default'].$.getJSON(this.get('apiConnect').getUrl('/dashboard/campsites/' + params.id));
      var cg = _ember['default'].$.getJSON(this.get('apiConnect').getUrl('/dashboard/campgrounds/' + cs.responseJSON.campground_id + '/data'));
      var model = {
        campsite: cs.responseJSON,
        campground: cg.responseJSON
      };
      return model;
    },
    actions: {
      refreshModel: function refreshModel() {
        //display message in the console of your browser
        _ember['default'].Logger.log('Route is now refreshing...');
        //refresh the model
        this.refresh();
      }
    },
    beforeModel: function beforeModel(transition) {
      if (transition.targetName === 'dashboard.campsite.edit.index') {
        this.transitionTo('dashboard.campsite.edit.availability');
      }
    },
    renderTemplate: function renderTemplate(controller, model) {
      // render the standard template
      this._super(controller, model);
      // Render the nav into the application template
      this.render('dashboard/campsite/navigation', { // the template to render
        into: 'dashboard', // the template to render into
        outlet: 'top_nav', // the name of the outlet in this (dashboard) template
        controller: 'dashboard/campsite/edit'
      });
    }
  });
});