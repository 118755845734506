define('cn-front/services/api-resolver', ['exports', 'ember', 'cn-front/config/environment'], function (exports, _ember, _cnFrontConfigEnvironment) {
    exports['default'] = _ember['default'].Service.extend({

        host: _cnFrontConfigEnvironment['default'].apiHost,
        url: '',
        init: function init() {
            this.set('url', this.get('host') + '/api/v2');
        },
        getUrl: function getUrl(req) {
            return this.get('url') + req;
        },
        sendReq: function sendReq(args) {
            var out = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

            var self = this;
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                if (!out) {
                    args.url = self.get('url') + args.url;
                }
                _ember['default'].$.ajax(args).done(function (response) {
                    resolve(response);
                }).fail(function (reason) {
                    reject(reason);
                });
            });
        }
    });
});