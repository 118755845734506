define('cn-front/mixins/reset-query-params', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Mixin.create({
        resetController: function resetController(controller, isExiting) {
            if (isExiting) {
                var queryParams = controller.get('queryParams');

                // If query params were defined as hash params they come through in an Object
                if (queryParams.toString().slice(0, 15) === "[object Object]") {
                    queryParams = Object.keys(queryParams[0]);
                }

                // Set each param to null
                queryParams.forEach(function (param) {
                    controller.set(param, null);
                });
            }
        }
    });
});