define('cn-front/components/campground/section-photos', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    photosData: [],
    didReceiveAttrs: function didReceiveAttrs() {
      var campground = this.get('campground');
      var photosArr = [];
      var photosData = photosArr.concat(campground.photos, campground.cs_photos, campground.maps);
      this.set('photosData', photosData);

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var owlT = $('#' + this.get('elementId') + ' .campground-photos-carousel');
        var prevArrow = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 30.25L25.05 29.2L20.65 24.75H30.75V23.25H20.65L25.05 18.8L24 17.75L17.75 24L24 30.25ZM24 42C21.5333 42 19.2083 41.525 17.025 40.575C14.8417 39.625 12.9333 38.3333 11.3 36.7C9.66667 35.0667 8.375 33.1667 7.425 31C6.475 28.8333 6 26.5 6 24C6 21.5 6.475 19.1583 7.425 16.975C8.375 14.7917 9.66667 12.8917 11.3 11.275C12.9333 9.65833 14.8333 8.375 17 7.425C19.1667 6.475 21.5 6 24 6C26.5 6 28.8417 6.475 31.025 7.425C33.2083 8.375 35.1083 9.65833 36.725 11.275C38.3417 12.8917 39.625 14.7917 40.575 16.975C41.525 19.1583 42 21.5 42 24C42 26.4667 41.525 28.7917 40.575 30.975C39.625 33.1583 38.3417 35.0667 36.725 36.7C35.1083 38.3333 33.2083 39.625 31.025 40.575C28.8417 41.525 26.5 42 24 42ZM24 40.45C28.5667 40.45 32.45 38.85 35.65 35.65C38.85 32.45 40.45 28.5667 40.45 24C40.45 19.4333 38.85 15.55 35.65 12.35C32.45 9.15 28.5667 7.55 24 7.55C19.4333 7.55 15.55 9.15 12.35 12.35C9.15 15.55 7.55 19.4333 7.55 24C7.55 28.5667 9.15 32.45 12.35 35.65C15.55 38.85 19.4333 40.45 24 40.45Z" fill="#CACACA"/></g></svg>';
        var nextArrow = '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 30.25L30.25 24L24 17.75L22.95 18.8L27.35 23.25H17.25V24.75H27.35L22.95 29.2L24 30.25ZM24 42C21.5333 42 19.2083 41.525 17.025 40.575C14.8417 39.625 12.9333 38.3333 11.3 36.7C9.66667 35.0667 8.375 33.1667 7.425 31C6.475 28.8333 6 26.5 6 24C6 21.5 6.475 19.1583 7.425 16.975C8.375 14.7917 9.66667 12.8917 11.3 11.275C12.9333 9.65833 14.8333 8.375 17 7.425C19.1667 6.475 21.5 6 24 6C26.5 6 28.8417 6.475 31.025 7.425C33.2083 8.375 35.1083 9.65833 36.725 11.275C38.3417 12.8917 39.625 14.7917 40.575 16.975C41.525 19.1583 42 21.5 42 24C42 26.4667 41.525 28.7917 40.575 30.975C39.625 33.1583 38.3417 35.0667 36.725 36.7C35.1083 38.3333 33.2083 39.625 31.025 40.575C28.8417 41.525 26.5 42 24 42ZM24 40.45C28.5667 40.45 32.45 38.85 35.65 35.65C38.85 32.45 40.45 28.5667 40.45 24C40.45 19.4333 38.85 15.55 35.65 12.35C32.45 9.15 28.5667 7.55 24 7.55C19.4333 7.55 15.55 9.15 12.35 12.35C9.15 15.55 7.55 19.4333 7.55 24C7.55 28.5667 9.15 32.45 12.35 35.65C15.55 38.85 19.4333 40.45 24 40.45Z" fill="#93A444"/></g></svg>';

        owlT.owlCarousel({
          dots: false,
          loop: false,
          autoWidth: false,
          margin: 30,
          nav: true,
          smartSpeed: 800,
          navContainer: '.slide-arrow.campground-photos-slide-arrow',
          navClass: ['slide-prev', 'slide-next'],
          navText: [prevArrow, nextArrow],
          slideBy: 1,
          items: 4,
          responsive: {
            0: {
              items: 1
            },
            577: {
              items: 2
            },
            769: {
              items: 3
            },
            993: {
              items: 4
            }
          }
        });
      });
    }
  });
});