define('cn-front/routes/dashboard/campground/edit/reports', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        thisController: function thisController() {
            return this.controllerFor('dashboard/campground/edit/reports');
        },

        typeCheckboxHandler: function typeCheckboxHandler() {
            var checksArr = ['instant', 'confirm', 'on_site', 'paid', 'in_use', 'maint', 'fcfs', 'info_req'];
            var results = [];
            checksArr.forEach(function (reservationType) {
                if (document.querySelector('#' + reservationType + '_type') && document.querySelector('#' + reservationType + '_type').checked) {
                    if (reservationType == 'paid') {
                        results = results.concat(['instant', 'confirm', 'on_site']);
                    } else {
                        results.push(reservationType);
                    }
                }
            });
            return results;
        },

        statusesCheckboxHandler: function statusesCheckboxHandler() {
            var checks = $('.reservations_statuses :checkbox');
            var results = [];
            checks.map(function () {
                if ($(this)[0].checked) {
                    results.push($(this).attr("data-status"));
                }
            });
            return results;
        },

        columnsHandling: function columnsHandling() {
            var checks = $('.columns_names :checkbox');
            var results = [];
            checks.map(function () {
                if ($(this)[0].checked) {
                    results.push($(this).attr("data-status"));
                }
            });
            return results;
        },

        model: function model() {

            // Get current controller properties as params for model hook
            var params = this.thisController();

            if (params.shouldGetReport) {

                // Access current campground model
                var cpgModel = this.modelFor('dashboard.campground.edit');

                // Response object
                var response = {
                    cpgId: cpgModel.general.id,
                    totalres: false,
                    totalfin: false,
                    reports: false,
                    startDate: params.sdate,
                    endDate: params.edate
                };

                // Reports Url
                var reportsUrl = this.get('apiConnect').getUrl('/dashboard/campgrounds/' + cpgModel.general.id + '/reports');

                // If reservation, get total info
                if (params.report_type == 'reservation') {
                    var totalResUrl = this.get('apiConnect').getUrl('/dashboard/campgrounds/' + cpgModel.general.id + '/reports/totalres');

                    $.ajax({
                        type: "GET",
                        url: totalResUrl,
                        data: {
                            'sdate': params.sdate,
                            'edate': params.edate,
                            'reservation_types': this.typeCheckboxHandler(),
                            'reservation_statuses': this.statusesCheckboxHandler()
                        },
                        beforeSend: function beforeSend(xhr) {
                            xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                        },
                        async: false,
                        success: function success(data) {
                            response['totalres'] = data;
                        }
                    });
                }

                // If financial, get total info
                if (params.report_type == 'financial') {
                    var totalFinUrl = this.get('apiConnect').getUrl('/dashboard/campgrounds/' + cpgModel.general.id + '/reports/totalfinancial');

                    $.ajax({
                        type: "GET",
                        url: totalFinUrl,
                        data: {
                            'sdate': params.sdate,
                            'edate': params.edate,
                            'reservation_types': this.typeCheckboxHandler(),
                            'reservation_statuses': this.statusesCheckboxHandler()
                        },
                        beforeSend: function beforeSend(xhr) {
                            xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                        },
                        async: false,
                        success: function success(data) {
                            response['totalfin'] = data[0];
                        }
                    });
                }

                // If utilization report, util-calendar component is used
                if (params.report_type == 'utilization') {
                    response['reports'] = { 'data': true };
                } else {
                    $.ajax({
                        type: "GET",
                        url: reportsUrl,
                        data: {
                            'report_type': params.report_type,
                            'orderBy': params.orderBy,
                            'sort': params.sort,
                            'sdate': params.sdate,
                            'edate': params.edate,
                            'page': params.page,
                            'reservation_types': this.typeCheckboxHandler(),
                            'reservation_statuses': this.statusesCheckboxHandler(),
                            'columns': this.columnsHandling()
                        },
                        beforeSend: function beforeSend(xhr) {
                            xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                        },
                        async: false,
                        success: function success(data) {
                            if (params.report_type == 'availability' || params.report_type == 'receipt') {
                                response['reports'] = { data: data };
                            } else {
                                response['reports'] = data;
                            }
                        }
                    });
                }

                return response;
            }
        },

        actions: {
            initPrint: function initPrint() {
                window.print();
            },

            exportTo: function exportTo(type) {
                var params = this.thisController();
                params.handleFilters(true);
                var cpgModel = this.modelFor('dashboard.campground.edit');

                var reportUrl = this.get('apiConnect').getUrl('/' + type + '/campground/' + cpgModel.general.id + '/reports');

                reportUrl += '?page=' + params.page + '&orderBy=' + params.orderBy + '&sort=' + params.sort + '&sdate=' + encodeURIComponent(params.sdate) + '&edate=' + encodeURIComponent(params.edate) + '&report_type=' + params.report_type;
                var types = this.typeCheckboxHandler();
                for (var i = 0; i < types.length; i++) {
                    reportUrl += '&reservation_types%5B%5D=' + types[i];
                }

                var statuses = this.statusesCheckboxHandler();
                for (i = 0; i < statuses.length; i++) {
                    reportUrl += '&reservation_statuses%5B%5D=' + statuses[i];
                }

                var columns = this.columnsHandling();
                for (i = 0; i < columns.length; i++) {
                    reportUrl += '&columns%5B%5D=' + columns[i];
                }

                reportUrl += '&token=' + $.cookie('token');

                var windLocation = type == 'pdf' ? '_blank' : '_self';
                window.open(reportUrl, windLocation);
            },

            checkReportType: function checkReportType() {
                var report_type = $('#report-type').val();
                this.thisController().set('reservation_type', report_type === 'reservation');

                if (report_type == 'financial') {
                    $('label[for="report-start-date"]').text('Created:');
                } else {
                    $('label[for="report-start-date"]').text('Arrival:');
                }

                // Force monthly period for utilization
                if (report_type == 'utilization' || report_type == 'camper') {
                    $('#report-period').prop('disabled', true).val('monthly');
                } else {
                    $('#report-period').prop('disabled', false);
                }

                // Show filters only for reservation
                if (report_type == 'reservation' || report_type == 'financial') {
                    $('.filters').removeClass('hide');
                } else {
                    $('.filters').addClass('hide');
                }

                // Custom periods for different report types
                if (report_type == 'receipt' || report_type == 'availability') {
                    $('#report-period').find("option[value='all-time']").attr("disabled", "disabled");
                    $('#report-period').prop("selectedIndex", 0);
                } else {
                    $('#report-period').find("option").removeAttr("disabled");
                    $('#report-period').prop("selectedIndex", 0);
                }
            },

            refreshModel: function refreshModel() {
                this.refresh();
            }
        },

        onInit: (function () {

            _ember['default'].run.schedule('afterRender', this, function () {

                // Initialize datepicker
                $('#report-start-date').datepicker();
                $('#report-start-date').datepicker('setDate', new Date());

                // Check browser type and set body class
                var browserClass;
                if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
                    browserClass = 'chrome-or-opera';
                } else if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
                    browserClass = 'safari';
                } else {
                    browserClass = 'other-browser';
                }
                $('body').addClass(browserClass);

                this.send('checkReportType');
            });
        }).on('activate'),

        onOut: (function () {
            this.thisController().restoreDefaults();
        }).on('deactivate')

    });
});