define('cn-front/initializers/api-resolver', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'api', 'service:api-resolver');
    application.inject('controller', 'api', 'service:api-resolver');
    application.inject('component', 'api', 'service:api-resolver');
  }

  exports['default'] = {
    name: 'api-resolver',
    initialize: initialize
  };
});