define('cn-front/mixins/loading-indicator', ['exports', 'ember'], function (exports, _ember) {

    NProgress.configure({ showSpinner: false });

    exports['default'] = _ember['default'].Mixin.create({
        actions: {
            loading: function loading() {
                NProgress.start();
                this.router.one('didTransition', function () {
                    return NProgress.done();
                });
                return true;
            },
            error: function error() {
                NProgress.done();
                return true;
            }
        }
    });
});
/* globals NProgress */