define('cn-front/helpers/format-currency', ['exports', 'ember'], function (exports, _ember) {
    exports.formatCurrency = formatCurrency;

    function formatCurrency(params /*, hash*/) {
        var value = parseFloat(params[0]),
            dollars = Math.floor(value);
        if (dollars <= 0) {
            dollars = 0;
        }
        var sign = '$';
        if (value < 0) {
            sign = '-' + sign;
        }
        var cents = Math.abs(Math.floor((value - dollars).toFixed(2) * 100));
        if (cents.toString().length === 1) {
            cents = '0' + cents;
        }
        if (params[1] == 'daily_rate') {
            return (cents = '00') ? '' + sign + dollars : '' + sign + dollars + '.' + cents;
        }

        if (params[1]) {
            if (params[1] == 'short') {
                return dollars + '.' + cents;
            } else {
                return '' + sign + dollars;
            }
        } else {
            return '' + sign + dollars + '.' + cents;
        }
    }

    exports['default'] = _ember['default'].Helper.helper(formatCurrency);
});