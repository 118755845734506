define('cn-front/helpers/text-overflow-ellipsis', ['exports', 'ember'], function (exports, _ember) {
  exports.textOverflowEllipsis = textOverflowEllipsis;

  function textOverflowEllipsis(params) {
    var limit = params[1] ? params[1] : 150;
    var text = params[0];

    if (!text) {
      return '';
    }

    text = text.trim();

    if (text.length <= limit) {
      return _ember['default'].String.htmlSafe(text);
    }

    text = text.slice(0, limit);

    var lastSpace = text.lastIndexOf(" ");

    if (lastSpace > 0) {
      text = text.substr(0, lastSpace);
    }
    return _ember['default'].String.htmlSafe(text + "...");
  }

  exports['default'] = _ember['default'].Helper.helper(textOverflowEllipsis);
});