define('cn-front/routes/dashboard/emails-sending', ['exports', 'ember'], function (exports, _ember) {
    /*globals Simditor*/

    exports['default'] = _ember['default'].Route.extend({
        activate: function activate() {
            _ember['default'].run.schedule('afterRender', this, function () {
                Simditor.locale = 'en-US';
                $('.text_editor textarea').attr('name', 'content');

                var senders = $('.senders');
                var behalf_of = $('.behalf_of');

                senders.change(function () {
                    if (senders.val() === 'custom' || senders.val() === 'single_manager') {
                        behalf_of.slideDown("fast");
                    } else {
                        behalf_of.slideUp("fast");
                        $('[name="behalf_of"]').val('');
                    }
                });
            });
        }
    });
});