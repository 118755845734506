define('cn-front/components/reservation/new-reservation/verify-payment', ['exports', 'ember', 'npm:braintree-web', 'cn-front/utils/gtm'], function (exports, _ember, _npmBraintreeWeb, _cnFrontUtilsGtm) {
    exports['default'] = _ember['default'].Component.extend({
        reservation: _ember['default'].inject.service('reservation/instance'),
        stepster: _ember['default'].inject.service('reservation/stepster'),
        refresher: _ember['default'].inject.service(),
        currentUser: _ember['default'].inject.service('current-user'),
        loading: false,
        loadingText: "Please wait, we're creating your reservation...",

        paymentsEnabled: (function () {
            return this.get('reservation').isPaymentsEnabled();
        }).property('reservation.reservation_type'),

        stepInitialize: (function () {
            if (this.get('stepster.finishedSteps') < this.get('step')) {

                if (this.get('reservation').isPaymentsEnabled()) {
                    _cnFrontUtilsGtm['default'].trackEvent({
                        'event': 'checkout',
                        'ecommerce': {
                            'checkout': {
                                'actionField': { 'step': 3, 'option': 'Verify Payment/Add Note' },
                                'products': [{
                                    'name': this.get('reservation.campground.name'),
                                    'id': this.get('reservation.campsite.id')
                                }]
                            }
                        }
                    });
                }

                if (this.get('reservation').isPaymentsEnabled()) {
                    var user = {};
                    if (this.get('reservation.reservation_type') == "on_site") {
                        user = this.get('reservation.remote_user').user;
                    } else {
                        user = this.get('currentUser').user;
                    }
                    _cnFrontUtilsGtm['default'].trackEvent({
                        'event': 'checkout_step_3',
                        'reservation_type': this.get('reservation.reservation_type'),
                        'transactionCG': this.get('reservation.campground.id'), // numeric   CG ID number
                        'transactionUser': user.id, // numeric   User ID number (camper)
                        'transactionUserEmail': user.email, // string    User Email (camper)
                        'transactionUserCity': user.city, // string    User City (camper)
                        'transactionUserState': user.state, // string    User State (camper)
                        'transactionUserZip': user.zip_code // string    User Zip Code (camper)
                    });
                }

                this.set('stepster.finishedSteps', this.get('step'));
            }
        }).observes('stepster.currentStep'),

        didInsertElement: function didInsertElement() {
            var self = this;
            console.log('braintree_token', self.get('reservation.braintree_token'));
            if (self.get('paymentsEnabled') && self.get('reservation.braintree_token').length) {

                //clean up old braintree integrations
                $('#dropin').html('');
                $('#braintree-dropin-modal-frame').remove();

                $('.overlay').css('display', 'block'); // TODO: add non-modal overlay

                // braintree integration
                _npmBraintreeWeb['default'].setup(self.get('reservation.braintree_token'), 'dropin', {
                    container: "dropin",
                    onReady: function onReady() {
                        $('.overlay').css('display', 'none'); // TODO: add non-modal overlay
                        $('.green-header .next-btn').attr('disabled', false);
                    },
                    paymentMethodNonceReceived: function paymentMethodNonceReceived(event, nonce) {
                        //this fires after user submit form
                        $('#nonce').val(nonce);
                        self.reserv();
                    },
                    onError: function onError(error) {
                        console.log('braintree error: ' + error.message);
                        $('.overlay').css('display', 'none'); // TODO: add non-modal overlay
                    }
                });
            }
            // "block" next button and fire create reservation instead next slide
            self.set('stepster.ready', false);
            self.set('stepster.fallback', (function () {
                this.createReservation();
            }).bind(this));

            $('.green-header .next-btn').css('visibility', 'hidden');
        },

        willDestroyElement: function willDestroyElement() {
            $('.green-header .next-btn').css('visibility', 'visible');
        },

        createReservation: function createReservation() {
            if (this.get('reservation.type') == 'front' || this.get('reservation.reservation_type') == "on_site") {
                //innitiate payment process throu braintree
                $('#new-reservation').find('input[type="submit"]').click();
            } else {
                //reserv without payment
                this.reserv();
            }
        },

        reserv: function reserv() {
            var self = this;
            var res_info = $('#new-reservation').serializeJSON();
            var url = self.get('api').getUrl('/reservations/create');
            var token = $.cookie('token');
            if (this.get('reservation.remote_token')) {
                res_info.initiator_token = $.cookie('token');
                token = this.get('reservation.remote_token');
                console.log('using remote user token');
            }
            var ajaxPromise = $.ajax({
                type: "POST",
                url: url,
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', 'bearer ' + token);
                },
                data: res_info,
                async: false
            });

            ajaxPromise.then(function (data) {
                "use strict";
                console.log(data);
                //set response in reservation object
                self.set('reservation.response', data);
                // var type = self.get('reservation.reservation_type');
                // var modalTitle;
                // if (type == 'maint') {
                //     modalTitle = 'New Calendar Item Created';
                //     self.set('reservation.type_desc', 'Maintenance (campsite is temporarily closed)');
                // } else if (type == 'in_use') {
                //     modalTitle = 'New Calendar Item Created';
                //     self.set('reservation.type_desc', 'In Use (campsite has a prior reservation)');
                // } else if (type == 'fcfs') {
                //     modalTitle = 'New Calendar Item Created';
                //     self.set('reservation.type_desc', 'First Come First Served (campsite is open but may not be reserved)');
                // } else if (['confirm', 'instant', 'on_site'].indexOf(type) !== -1) {
                //     modalTitle = 'New Reservation';
                // }

                // $('.modal .modal-title').html(modalTitle); // TODO: modalTitle is not changing because title is in the view template

                //remove overlay
                self.set('loading', false);
                //clean up next button block
                self.set('stepster.ready', true);
                self.set('stepster.fallback', null);
                //go to next step
                $('.green-header .next-btn').click().css('visibility', 'visible');
            });
        },

        actions: {
            createReservation: function createReservation() {
                this.set('loading', true);
                this.createReservation();
            }
        }
    });
});