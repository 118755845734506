define("cn-front/templates/components/camp-owners/section-text-button", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 63,
              "column": 6
            },
            "end": {
              "line": 63,
              "column": 79
            }
          },
          "moduleName": "cn-front/templates/components/camp-owners/section-text-button.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("List Your Property");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 96,
            "column": 0
          }
        },
        "moduleName": "cn-front/templates/components/camp-owners/section-text-button.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "subscribe text-button");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "bg-element-mb bg-element bg-element-left-top");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el3 = dom.createElement("svg");
        dom.setAttribute(el3, "width", "106");
        dom.setAttribute(el3, "height", "65");
        dom.setAttribute(el3, "viewBox", "0 0 106 65");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "xmlns", "http://www.w3.org/2000/svg");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4, "opacity", "0.5");
        dom.setAttribute(el4, "d", "M87.5 39L94.7585 29.0196C95.1374 28.4986 95.9029 28.4666 96.324 28.9541L105 39M75 38.0435L67.9225 31.6968C67.448 31.2713 66.7019 31.3923 66.3862 31.9459L64 36.1304");
        dom.setAttribute(el4, "stroke", "#F8FAF7");
        dom.setAttribute(el4, "stroke-width", "2");
        dom.setAttribute(el4, "stroke-linecap", "round");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4, "d", "M55.9996 -29.5C66.4996 -13.5 26.9996 66 -29.0004 63.5");
        dom.setAttribute(el4, "stroke", "white");
        dom.setAttribute(el4, "stroke-width", "2");
        dom.setAttribute(el4, "stroke-dasharray", "8 8");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("circle");
        dom.setAttribute(el4, "cx", "5");
        dom.setAttribute(el4, "cy", "5");
        dom.setAttribute(el4, "r", "5");
        dom.setAttribute(el4, "transform", "matrix(-1 0 0 1 30.999 34)");
        dom.setAttribute(el4, "fill", "#F8FAF7");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace(null);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "bg-element-mb bg-element bg-element-right-top");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el3 = dom.createElement("svg");
        dom.setAttribute(el3, "width", "53");
        dom.setAttribute(el3, "height", "261");
        dom.setAttribute(el3, "viewBox", "0 0 53 261");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "xmlns", "http://www.w3.org/2000/svg");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4, "opacity", "0.5");
        dom.setAttribute(el4, "d", "M1 30.0476L1.85578 28.174C2.13397 27.565 2.92047 27.3961 3.4242 27.8372L5.94828 30.0476M5.94828 20.5238L9.16635 14.4029C9.52044 13.7294 10.4671 13.6825 10.8862 14.3176L13.7241 18.619M19.1437 14.3333L20.3924 11.9674C20.7078 11.37 21.5124 11.2507 21.9876 11.7308L24.5632 14.3333M21.0287 30.0476L22.7089 24.1055C22.954 23.2388 24.1301 23.1124 24.5537 23.9073L28.3333 31M8.54023 4.09524L5.36017 1.63801C4.90995 1.29012 4.26059 1.38746 3.93216 1.85207L1 6");
        dom.setAttribute(el4, "stroke", "#F8FAF7");
        dom.setAttribute(el4, "stroke-width", "2");
        dom.setAttribute(el4, "stroke-linecap", "round");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4, "d", "M89.1899 70C35.2755 68 31.2758 96.5 36.8969 108.74C43.0091 122.049 103.567 125.477 86.472 149.189C69.3771 172.901 57.7762 176.925 57.7762 208.5C57.7762 230.5 101.533 269.743 113.776 257.5C126.019 245.257 131.276 233.696 131.276 233.696");
        dom.setAttribute(el4, "stroke", "white");
        dom.setAttribute(el4, "stroke-width", "2");
        dom.setAttribute(el4, "stroke-linecap", "round");
        dom.setAttribute(el4, "stroke-dasharray", "8 8");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace(null);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-md");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "block-text");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "bg-element bg-element-left-top");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el5 = dom.createElement("svg");
        dom.setAttribute(el5, "width", "43");
        dom.setAttribute(el5, "height", "47");
        dom.setAttribute(el5, "viewBox", "0 0 43 47");
        dom.setAttribute(el5, "fill", "none");
        dom.setAttribute(el5, "xmlns", "http://www.w3.org/2000/svg");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6, "opacity", "0.5");
        dom.setAttribute(el6, "d", "M1 44.5714L2.56268 41.1502C2.84087 40.5412 3.62737 40.3723 4.13109 40.8134L8.42241 44.5714M8.42241 30.2857L13.6434 20.3553C13.9975 19.6818 14.9441 19.6348 15.3632 20.27L20.0862 27.4286M28.2155 21L30.4068 16.8484C30.7221 16.251 31.5268 16.1316 32.002 16.6117L36.3448 21M31.0431 44.5714L33.9014 34.4627C34.1465 33.596 35.3227 33.4696 35.7462 34.2645L42 46M12.3103 5.64286L7.12741 1.63801C6.67719 1.29012 6.02783 1.38746 5.6994 1.85207L1 8.5");
        dom.setAttribute(el6, "stroke", "#F8FAF7");
        dom.setAttribute(el6, "stroke-width", "2");
        dom.setAttribute(el6, "stroke-linecap", "round");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.setNamespace(null);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "bg-element bg-element-left-bottom");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el5 = dom.createElement("svg");
        dom.setAttribute(el5, "width", "527");
        dom.setAttribute(el5, "height", "177");
        dom.setAttribute(el5, "viewBox", "0 0 527 177");
        dom.setAttribute(el5, "fill", "none");
        dom.setAttribute(el5, "xmlns", "http://www.w3.org/2000/svg");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6, "d", "M7.50066 7.5C54.0007 7.5 64.0007 31.7074 64.0007 46.4217C64.0007 61.1359 -13.9993 63.2379 6.00066 87.0609C26.0007 110.884 80.5007 98.6221 80.5007 142.064C80.5007 185.506 163.707 153.469 217.001 142.064C243.001 136.5 324.501 122.657 388.501 167.5C452.501 212.343 506.501 190.459 526.001 181");
        dom.setAttribute(el6, "stroke", "white");
        dom.setAttribute(el6, "stroke-width", "2");
        dom.setAttribute(el6, "stroke-linecap", "round");
        dom.setAttribute(el6, "stroke-dasharray", "8 8");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("circle");
        dom.setAttribute(el6, "cx", "7");
        dom.setAttribute(el6, "cy", "7");
        dom.setAttribute(el6, "r", "7");
        dom.setAttribute(el6, "fill", "#F2EA5B");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("circle");
        dom.setAttribute(el6, "cx", "77");
        dom.setAttribute(el6, "cy", "124");
        dom.setAttribute(el6, "r", "7");
        dom.setAttribute(el6, "fill", "#F2EA5B");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("circle");
        dom.setAttribute(el6, "cx", "330");
        dom.setAttribute(el6, "cy", "141");
        dom.setAttribute(el6, "r", "7");
        dom.setAttribute(el6, "fill", "#F2EA5B");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6, "opacity", "0.5");
        dom.setAttribute(el6, "d", "M42 156.5L49.2474 146.082C49.6255 145.538 50.4177 145.506 50.8393 146.016L59.5 156.5M29.5 155.5L22.4429 148.884C21.9656 148.437 21.1944 148.56 20.8811 149.135L18.5 153.5M13 129L20.7935 121.665C21.1871 121.294 21.8038 121.304 22.186 121.686L29.5 129");
        dom.setAttribute(el6, "stroke", "#F8FAF7");
        dom.setAttribute(el6, "stroke-width", "2");
        dom.setAttribute(el6, "stroke-linecap", "round");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.setNamespace(null);
        var el4 = dom.createElement("h3");
        var el5 = dom.createTextNode("Let’s get started!");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n        We take care of our Campground partners and their Campers. By clicking the button, you’ll simply fill out a form and a professional support representative will be in touch to conduct a simple and easy onboarding process to help you be set up for success!\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "bg-element bg-element-right-bottom");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el5 = dom.createElement("svg");
        dom.setAttribute(el5, "width", "114");
        dom.setAttribute(el5, "height", "42");
        dom.setAttribute(el5, "viewBox", "0 0 114 42");
        dom.setAttribute(el5, "fill", "none");
        dom.setAttribute(el5, "xmlns", "http://www.w3.org/2000/svg");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6, "opacity", "0.5");
        dom.setAttribute(el6, "d", "M1 41L8.71619 26.6547C9.07247 25.9924 10.0049 25.9456 10.4257 26.569L17.5 37.0494M29 8.40741L32.3663 2.17352C32.6831 1.58683 33.4745 1.46865 33.9489 1.93719L40.5 8.40741M95.5 15.321L102.75 5.02711C103.129 4.48995 103.914 4.4574 104.335 4.96138L113 15.321M83 14.3333L75.9373 7.79376C75.4607 7.35249 74.6965 7.47552 74.3825 8.04407L72 12.358");
        dom.setAttribute(el6, "stroke", "#F8FAF7");
        dom.setAttribute(el6, "stroke-width", "2");
        dom.setAttribute(el6, "stroke-linecap", "round");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        dom.setNamespace(null);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "block-form");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "bg-element-left-top bg-element");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el5 = dom.createElement("svg");
        dom.setAttribute(el5, "width", "257");
        dom.setAttribute(el5, "height", "134");
        dom.setAttribute(el5, "viewBox", "0 0 257 134");
        dom.setAttribute(el5, "fill", "none");
        dom.setAttribute(el5, "xmlns", "http://www.w3.org/2000/svg");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6, "d", "M1 -7.5C10.5 45 72.748 36.248 88.5 52C105 68.5 101.483 83.1398 122.5 103.5C138.5 119 165 130 244 128");
        dom.setAttribute(el6, "stroke", "white");
        dom.setAttribute(el6, "stroke-width", "2");
        dom.setAttribute(el6, "stroke-linecap", "round");
        dom.setAttribute(el6, "stroke-dasharray", "8 8");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6, "d", "M249 123L254.861 127.186C255.419 127.585 255.419 128.415 254.861 128.814L249 133");
        dom.setAttribute(el6, "stroke", "white");
        dom.setAttribute(el6, "stroke-width", "2");
        dom.setAttribute(el6, "stroke-linecap", "round");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.setNamespace(null);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "bg-element-right-top bg-element");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el5 = dom.createElement("svg");
        dom.setAttribute(el5, "width", "114");
        dom.setAttribute(el5, "height", "42");
        dom.setAttribute(el5, "viewBox", "0 0 114 42");
        dom.setAttribute(el5, "fill", "none");
        dom.setAttribute(el5, "xmlns", "http://www.w3.org/2000/svg");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6, "opacity", "0.5");
        dom.setAttribute(el6, "d", "M113 41L105.284 26.6547C104.928 25.9924 103.995 25.9456 103.574 26.569L96.5 37.0494M85 8.40741L81.6337 2.17352C81.3169 1.58683 80.5255 1.46865 80.0511 1.93719L73.5 8.40741M18.5 15.321L11.2495 5.02711C10.8712 4.48995 10.0865 4.4574 9.66491 4.96138L1 15.321M31 14.3333L38.0627 7.79376C38.5393 7.35249 39.3035 7.47552 39.6175 8.04407L42 12.358");
        dom.setAttribute(el6, "stroke", "#F8FAF7");
        dom.setAttribute(el6, "stroke-width", "2");
        dom.setAttribute(el6, "stroke-linecap", "round");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.setNamespace(null);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "bg-element-right-bottom bg-element");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el5 = dom.createElement("svg");
        dom.setAttribute(el5, "width", "48");
        dom.setAttribute(el5, "height", "37");
        dom.setAttribute(el5, "viewBox", "0 0 48 37");
        dom.setAttribute(el5, "fill", "none");
        dom.setAttribute(el5, "xmlns", "http://www.w3.org/2000/svg");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("path");
        dom.setAttribute(el6, "opacity", "0.5");
        dom.setAttribute(el6, "d", "M18.3118 36L11.1496 25.739C10.7715 25.1972 9.98128 25.1645 9.55969 25.6732L1 36M30.6774 35.0141L37.65 28.4993C38.1271 28.0535 38.8965 28.1771 39.2099 28.7499L41.5591 33.0423M47 8.88732L39.2966 1.66149C38.9033 1.29261 38.2885 1.30192 37.9066 1.68254L30.6774 8.88732");
        dom.setAttribute(el6, "stroke", "#F8FAF7");
        dom.setAttribute(el6, "stroke-width", "2");
        dom.setAttribute(el6, "stroke-linecap", "round");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        dom.setNamespace(null);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "bg-element bg-element-mb bg-element-left-bottom");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el5 = dom.createElement("svg");
        dom.setAttribute(el5, "width", "89");
        dom.setAttribute(el5, "height", "58");
        dom.setAttribute(el5, "viewBox", "0 0 89 58");
        dom.setAttribute(el5, "fill", "none");
        dom.setAttribute(el5, "xmlns", "http://www.w3.org/2000/svg");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("g");
        dom.setAttribute(el6, "clip-path", "url(#clip0_878_17319)");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("path");
        dom.setAttribute(el7, "d", "M10.4996 5.99999L5.93469 2.00862C5.82659 1.9141 5.67442 1.90812 5.58588 1.99491L1.49984 5.99992");
        dom.setAttribute(el7, "stroke", "white");
        dom.setAttribute(el7, "stroke-width", "2");
        dom.setAttribute(el7, "stroke-linecap", "round");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("path");
        dom.setAttribute(el7, "d", "M77.1339 134.181C52.1438 118.495 107.519 96.6728 79.0422 66.0034C50.5654 35.3339 11.2569 55.7193 6.43227 8.49324");
        dom.setAttribute(el7, "stroke", "white");
        dom.setAttribute(el7, "stroke-width", "2");
        dom.setAttribute(el7, "stroke-linecap", "round");
        dom.setAttribute(el7, "stroke-dasharray", "8 8");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("defs");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("clipPath");
        dom.setAttribute(el7, "id", "clip0_878_17319");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("rect");
        dom.setAttribute(el8, "width", "89");
        dom.setAttribute(el8, "height", "58");
        dom.setAttribute(el8, "fill", "white");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        dom.setNamespace(null);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "bg-element-mb bg-element bg-element-left-bottom");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el3 = dom.createElement("svg");
        dom.setAttribute(el3, "width", "37");
        dom.setAttribute(el3, "height", "27");
        dom.setAttribute(el3, "viewBox", "0 0 37 27");
        dom.setAttribute(el3, "fill", "none");
        dom.setAttribute(el3, "xmlns", "http://www.w3.org/2000/svg");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("path");
        dom.setAttribute(el4, "opacity", "0.5");
        dom.setAttribute(el4, "d", "M22.828 26L28.1136 18.8911C28.4928 18.3812 29.2451 18.3496 29.6657 18.8259L36 26M13.4194 25.2958L8.31005 20.8142C7.83723 20.3995 7.10349 20.519 6.78673 21.0623L5.13978 23.8873M1 6.6338L6.71661 1.59989C7.10304 1.2596 7.6847 1.26838 8.06069 1.62016L13.4194 6.6338");
        dom.setAttribute(el4, "stroke", "#F8FAF7");
        dom.setAttribute(el4, "stroke-width", "2");
        dom.setAttribute(el4, "stroke-linecap", "round");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 5, 3]), 5, 5);
        return morphs;
      },
      statements: [["block", "link-to", ["camp-owners.form"], ["class", "btn btn-default"], 0, null, ["loc", [null, [63, 6], [63, 91]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});