define('cn-front/helpers/truncate', ['exports', 'ember'], function (exports, _ember) {
  exports.truncate = truncate;

  function truncate(params, hash) {
    var value = params[0];
    var len = hash.limit;
    var out = '';

    if (value !== undefined) {
      out = value.substr(0, len);

      if (value.length > len) {
        out += '...';
      }
    } else {
      out = '';
    }

    return out;
  }

  exports['default'] = _ember['default'].Helper.helper(truncate);

  // http://stackoverflow.com/questions/34189233/ember-2-truncate-text-and-add-ellipses
  // {{truncate-text "Lorem ipsum dolor long text" limit=5}}
});