define('cn-front/helpers/count-image-campground', ['exports', 'ember'], function (exports, _ember) {
  exports.countImageCampground = countImageCampground;

  function countImageCampground(params /*, hash*/) {
    var length = params[0];
    var count = 1;
    if (length > 4) {
      count = 4;
    } else if (length > 3) {
      count = 3;
    } else if (length > 2) {
      count = 2;
    }
    return count;
  }

  exports['default'] = _ember['default'].Helper.helper(countImageCampground);
});