define('cn-front/controllers/dashboard/campground/edit/admin', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        cgdata: inject.service('dashboard/cg-fields'),
        apiConnect: inject.service('api-resolver'),

        properties: {},

        onInit: (function () {
            this.set('properties', this.get('cgdata.fields'));
            //Ember.run.schedule('afterRender', this, function () {
            //})
        }).on('init'),

        actions: {
            updateData: function updateData() {
                var adminTabData = $('form#admin-settings').serializeJSON();
                var cgIsActive = document.querySelector('#admin-settings #is_active'),
                    acctmgrEmailEnabled = document.querySelector('#admin-settings #acctmgr_email_enabled'),
                    gpsOverride = document.querySelector('#admin-settings #gps_override');

                adminTabData.is_active = cgIsActive.checked;
                adminTabData.acctmgr_email_enabled = acctmgrEmailEnabled.checked;
                adminTabData.gps_override = gpsOverride.checked;

                var url = this.get('apiConnect').getUrl('/dashboard/campgrounds/' + this.get('model.general.id') + '/update');
                var self = this;
                var flashMessages = _ember['default'].get(self, 'flashMessages');
                $.ajax({
                    type: "POST",
                    url: url,
                    data: adminTabData,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        if (data.success) {
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                        } else {
                            flashMessages.danger(_ember['default'].String.htmlSafe(data.error.errorInfo[2]));
                        }
                        self.send('refreshModel');
                    }

                });
            }
        }

    });
});