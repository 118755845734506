define('cn-front/controllers/dashboard/campsite/edit', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Controller.extend({
        currentUser: inject.service('current-user'),
        campsites: [],
        next: 0,
        prev: 0,
        current: 0,
        length: 0,
        isPrev: true,
        isNext: true,

        currentRoute: (function () {
            return this.get('router.currentRouteName');
        }).property('router.currentRouteName'),

        onModel: (function () {
            var arr = this.get('model.campground.campsites').map(function (cs) {
                return cs.id;
            });

            var cid = this.get('model.campsite.id');
            var curIndex = arr.indexOf(cid);

            this.set('current', curIndex + 1);
            this.set('length', arr.length);

            if (curIndex == arr.length - 1) {
                this.set('isNext', false);
            } else {
                this.set('isNext', true);
                this.set('next', arr[curIndex + 1]);
            }

            if (curIndex === 0) {
                this.set('isPrev', false);
            } else {
                this.set('isPrev', true);
                this.set('prev', arr[curIndex - 1]);
            }
        }).observes('model')

    });
});