define('cn-front/controllers/dashboard/reports/transactions', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        queryParams: ['search', 'page'],

        reportTitle: (function () {
            return 'Reservation List for ' + (this.get('model.startDate') ? 'Created Date ' + this.get('model.startDate') : 'all times');
        }).property('report_type', 'model'),

        reportTitleSlug: (function () {
            return this.get('reportTitle') ? this.get('reportTitle').replace(/\s+/g, '-').toLowerCase() : 'report';
        }).property('report_type', 'model'),

        actions: {
            pageChanged: function pageChanged(current) {
                this.set('page', current);
            }
        },

        toTheTop: (function () {
            $('body').animate({
                scrollTop: 0
            }, 200);
        }).observes('page')

    });
});