define("cn-front/templates/components/ava-calendar/cs-row", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 12
              },
              "end": {
                "line": 6,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "cs-thumbnail-big");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element5, 'style');
            return morphs;
          },
          statements: [["attribute", "style", ["concat", ["background-image: url('", ["get", "campsite.thumbnail", ["loc", [null, [5, 78], [5, 96]]], 0, 0, 0, 0], "')"], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 12
              },
              "end": {
                "line": 9,
                "column": 12
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "cs-thumbnail-big");
            dom.setAttribute(el1, "style", "background-image: url('/assets/images/cg/thumbnail/cover-placeholder_1.jpg')");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 33
              },
              "end": {
                "line": 10,
                "column": 96
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "campsite.name", ["loc", [null, [10, 79], [10, 96]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 20
              },
              "end": {
                "line": 18,
                "column": 20
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("/day\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "format-currency", [["get", "campsite.rate_daily", ["loc", [null, [17, 42], [17, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 24], [17, 63]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 20
                },
                "end": {
                  "line": 20,
                  "column": 20
                }
              },
              "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("/week\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "format-currency", [["get", "campsite.rate_weekly", ["loc", [null, [19, 42], [19, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 24], [19, 64]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 20,
                    "column": 20
                  },
                  "end": {
                    "line": 22,
                    "column": 20
                  }
                },
                "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("/month\n                    ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "format-currency", [["get", "campsite.rate_monthly", ["loc", [null, [21, 42], [21, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 24], [21, 65]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 20
                },
                "end": {
                  "line": 22,
                  "column": 20
                }
              },
              "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "rate_monthly", ["loc", [null, [20, 30], [20, 42]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [20, 20], [22, 20]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 20
              },
              "end": {
                "line": 22,
                "column": 20
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "rate_weekly", ["loc", [null, [18, 30], [18, 41]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [18, 20], [22, 20]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 20
              },
              "end": {
                "line": 27,
                "column": 20
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element4, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["icon-shelters_", ["get", "campsite.icons.shelters.name", ["loc", [null, [26, 53], [26, 81]]], 0, 0, 0, 0], " ", ["get", "campsite.icons.shelters.value", ["loc", [null, [26, 86], [26, 115]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 20
              },
              "end": {
                "line": 31,
                "column": 20
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element3, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["icon-accesses_", ["get", "accesses", ["loc", [null, [30, 53], [30, 61]]], 0, 0, 0, 0], "_no_circle ", ["get", "accesses", ["loc", [null, [30, 76], [30, 84]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: ["key", "accesses"],
          templates: []
        };
      })();
      var child7 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 20
              },
              "end": {
                "line": 35,
                "column": 20
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["icon-services_", ["get", "services", ["loc", [null, [34, 53], [34, 61]]], 0, 0, 0, 0], "_no_circle"], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: ["key", "services"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 42,
              "column": 0
            }
          },
          "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "cs-item");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "cs-photo-block");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "cs-name");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "cs-description-block");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "desc-header");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "price");
          var el5 = dom.createTextNode("\n\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "property-icons");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "description");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1]);
          var element7 = dom.childAt(element6, [1]);
          var element8 = dom.childAt(element6, [3]);
          var element9 = dom.childAt(element8, [1]);
          var element10 = dom.childAt(element9, [3]);
          var morphs = new Array(8);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createMorphAt(element7, 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element7, [3]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element9, [1]), 1, 1);
          morphs[4] = dom.createMorphAt(element10, 1, 1);
          morphs[5] = dom.createMorphAt(element10, 3, 3);
          morphs[6] = dom.createMorphAt(element10, 5, 5);
          morphs[7] = dom.createMorphAt(dom.childAt(element8, [3]), 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["toCampsite", ["get", "campsite.id", ["loc", [null, [2, 47], [2, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 25], [2, 61]]], 0, 0], ["block", "if", [["get", "campsite.thumbnail", ["loc", [null, [4, 18], [4, 36]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [4, 12], [9, 19]]]], ["block", "link-to", ["campground.campsite", ["get", "campsite.id", ["loc", [null, [10, 66], [10, 77]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [10, 33], [10, 108]]]], ["block", "if", [["get", "campsite.rate_daily", ["loc", [null, [16, 26], [16, 45]]], 0, 0, 0, 0]], [], 3, 4, ["loc", [null, [16, 20], [22, 27]]]], ["block", "if", [["get", "campsite.icons.shelters", ["loc", [null, [25, 26], [25, 49]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [25, 20], [27, 27]]]], ["block", "each-in", [["get", "campsite.icons.accesses", ["loc", [null, [29, 31], [29, 54]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [29, 20], [31, 32]]]], ["block", "each-in", [["get", "campsite.icons.services", ["loc", [null, [33, 31], [33, 54]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [33, 20], [35, 32]]]], ["inline", "text-overflow-ellipsis", [["get", "campsite.description", ["loc", [null, [39, 62], [39, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 37], [39, 84]]], 0, 0]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 8
              },
              "end": {
                "line": 47,
                "column": 8
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "date-block");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 16
                },
                "end": {
                  "line": 51,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "reserv-icon icon-closed");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 16
                  },
                  "end": {
                    "line": 53,
                    "column": 16
                  }
                },
                "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "reserv-icon icon-reserved");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 53,
                    "column": 16
                  },
                  "end": {
                    "line": 55,
                    "column": 16
                  }
                },
                "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element0, 'class');
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["reserv-icon icon-", ["get", "value.status", ["loc", [null, [54, 49], [54, 61]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 16
                },
                "end": {
                  "line": 55,
                  "column": 16
                }
              },
              "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "value.status", ["loc", [null, [51, 34], [51, 46]]], 0, 0, 0, 0], "confirm"], [], ["loc", [null, [51, 30], [51, 57]]], 0, 0], ["subexpr", "eq", [["get", "value.status", ["loc", [null, [51, 62], [51, 74]]], 0, 0, 0, 0], "confirm_pending"], [], ["loc", [null, [51, 58], [51, 93]]], 0, 0], ["subexpr", "eq", [["get", "value.status", ["loc", [null, [51, 98], [51, 110]]], 0, 0, 0, 0], "instant"], [], ["loc", [null, [51, 94], [51, 121]]], 0, 0], ["subexpr", "eq", [["get", "value.status", ["loc", [null, [51, 126], [51, 138]]], 0, 0, 0, 0], "on_site"], [], ["loc", [null, [51, 122], [51, 150]]], 0, 0], ["subexpr", "eq", [["get", "value.status", ["loc", [null, [51, 155], [51, 167]]], 0, 0, 0, 0], "in_use"], [], ["loc", [null, [51, 151], [51, 178]]], 0, 0]], [], ["loc", [null, [51, 26], [51, 179]]], 0, 0]], [], 0, 1, ["loc", [null, [51, 16], [55, 16]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 8
              },
              "end": {
                "line": 57,
                "column": 8
              }
            },
            "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "date-block");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createMorphAt(element1, 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["toReservation", ["get", "key", ["loc", [null, [48, 42], [48, 45]]], 0, 0, 0, 0], ["get", "campsite", ["loc", [null, [48, 46], [48, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [48, 17], [48, 56]]], 0, 0], ["block", "if", [["subexpr", "not", [["get", "onlineReservations", ["loc", [null, [49, 27], [49, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [49, 22], [49, 46]]], 0, 0]], [], 0, 1, ["loc", [null, [49, 16], [55, 23]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 4
            },
            "end": {
              "line": 58,
              "column": 4
            }
          },
          "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "value.status", ["loc", [null, [45, 18], [45, 30]]], 0, 0, 0, 0], "not-valid"], [], ["loc", [null, [45, 14], [45, 43]]], 0, 0]], [], 0, 1, ["loc", [null, [45, 8], [57, 15]]]]],
        locals: ["key", "value"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 60,
            "column": 6
          }
        },
        "moduleName": "cn-front/templates/components/ava-calendar/cs-row.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "cs-dates");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "cgView", ["loc", [null, [1, 6], [1, 12]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [42, 7]]]], ["block", "each-in", [["get", "campsite.reservations", ["loc", [null, [44, 15], [44, 36]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [44, 4], [58, 16]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});