define("cn-front/templates/how-it-works", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 131,
              "column": 72
            },
            "end": {
              "line": 132,
              "column": 34
            }
          },
          "moduleName": "cn-front/templates/how-it-works.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                List Your Property");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 140,
              "column": 83
            },
            "end": {
              "line": 141,
              "column": 34
            }
          },
          "moduleName": "cn-front/templates/how-it-works.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n                List Your Property");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 172,
              "column": 19
            },
            "end": {
              "line": 172,
              "column": 63
            }
          },
          "moduleName": "cn-front/templates/how-it-works.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("List Your Property");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 181,
              "column": 18
            },
            "end": {
              "line": 181,
              "column": 53
            }
          },
          "moduleName": "cn-front/templates/how-it-works.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Contact Us");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 183,
            "column": 10
          }
        },
        "moduleName": "cn-front/templates/how-it-works.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "hero-how-work");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container content");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "hero-content");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        var el5 = dom.createTextNode("FIND YOUR NEXT ADVENTURE");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("Camp Native makes it easy for campers and adventure seekers to find your favorite new outdoor\n                accommodations and discover the perfect campsite or cabin");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "content-how-work");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container content");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("What is Camp Native?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Camp Native is a trusted marketplace for campgrounds, RV parks, cabins, and lodges, as well as outdoor\n            enthusiasts searching for accommodations, to list, discover, and reserve the perfect outdoors experience.\n            Whether car camping for a night, paddling along a shoreline, traveling the country in an RV, or enjoying a\n            cabin in the mountains, Camp Native connects users to their optimum accommodations. For outdoor enthusiasts\n            of any type, Camp Native is your gateway to the outdoors.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("How do reservations work?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Campers visit CampNative.com, find a property, and make a reservation online. Properties set their own prices\n            and we charge the camper a small transaction fee, so properties earn just as much from a Camp Native\n            reservation as they do when a reservation is booked directly. But we make it possible for a property to\n            accept reservations at all times: when the staff is busy (and unable to answer the phone), nights and\n            weekends, and even when the property is closed or out-of-season.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Properties can use our online availability calendar for Instant Booking, or keep their own calendar and use\n            our Confirmation Booking solution. For a Confirmation Booking, the property is notified and has 2 days to\n            accept or deny the reservation request. If the request is accepted, we process the reservation; if it is\n            denied, or the property does not respond in time, the reservation is not processed and the camper is not\n            charged. In either case, we keep the camper informed throughout at every step.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("What about changes and cancellations?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("A camper may change a reservation more than 2 weeks before\n            the scheduled arrival date. A camper may not change a reservation within 2\n            weeks of the scheduled arrival date; during that time, changes can only be made\n            by the property. This protects properties from last-minute changes that\n            may be difficult to accommodate on short notice. For properties using our\n            Confirmation Booking solution, changes can only be made by the property. Any\n            difference in price is refunded to or paid by the camper, minus or including any\n            transaction fees as appropriate.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("A camper may cancel a reservation at any time.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Refunds for changes and cancellations are given in\n            accordance with the cancellation policy of the property, which may include a\n            penalty fee equal to 1 day's stay up to as much as the full cost of the\n            reservation, minus or including any transaction fees as appropriate. No\n            refunds are given for cancellations within 2 weeks of the scheduled arrival\n            date.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("How does the pricing work?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("New Campgrounds invest a small monthly fee to have a customized listing displayed on our website to get exposure and increase reservations for their Campground.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Properties set their own change and cancellation fees, which may include a penalty fee equal to 1 day's stay up to as much as the full cost of the reservation.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("We charge 3% to the property for credit card processing.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("Can I make a reservation from outside the USA?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Camp Native uses AVS credit card verification, which is\n            generally only available for credit cards issued in the USA and Canada.\n            For campers outside North America, we recommend contacting the property\n            directly to make a reservation.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("Is Camp Native good for property owners?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("As a camper, when you think about Camp Native, you probably\n            think about the convenience of finding and booking property reservations online\n            (as we hope you do!). However, recent buzz has led some campers to wonder, Is\n            Camp Native as good for my favorite properties as it is for me? We certainly\n            believe so, and we think that you'll agree.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Our most popular product for properties is Instant Booking,\n            which replaces the traditional pen-and-paper reservation logbook. It's a\n            cloud-based service that enables properties to take reservations 24 hours a\n            day, every day of the year. Properties receive a free Camp Native profile page,\n            enabling them to be found via web searches, to display beautiful photos and property\n            details, to recognize repeat campers, and to manage reservations, with all\n            these features available from a computer or mobile device. Properties don't pay\n            anything for Instant Booking, which includes our software, unlimited upgrades,\n            customer support, marketing support, reservation system, and mobile-friendly\n            profile page.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("For online reservations, the camper pays the reservation\n            cost plus a small transaction fee. This fee is similar to other leading travel\n            services such as Airbnb, Reserve America, Expedia, and Hotels.com. Because\n            Instant Booking is free for properties, every reservation made at Camp Native\n            provides the property with an immediate return for the time they've invested in\n            completing and maintaining their profile page.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("We also offer Confirmation Booking for properties that don't\n            need all the operational benefits of Instant Booking. Confirmation Booking is\n            also free and is designed for properties that want to approve and log\n            reservations in a more traditional manner, such as in a pen-and-paper logbook.\n            Confirmation Booking gives a property access to the Camp Native network of\n            campers as well as online reservation capabilities for their own websites,\n            while also providing the property with a 2 day window to review and respond to\n            reservation requests.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("For any property that is not using Instant Booking or\n            Confirmation Booking, we provide Information Request. Information Request is a\n            free service that makes it easy for campers to notify a property that the\n            camper would like the property to be available for online reservation via Camp\n            Native, and to add the property to the camper's wishlist. Properties that\n            receive an Information Request can respond at their convenience, and can easily\n            upgrade to Instant Booking or Confirmation Booking at any time.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("Does Camp Native help properties improve their \"bottom line\"?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("In a word, Yes.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("In addition to helping properties improve their operational\n            efficiency, we also help them attract more campers. By assisting our property\n            partners to fill campsites that would otherwise be empty, we help them make\n            more money. A large portion of a property's expenses are fixed and don't\n            increase as a property serves more guests, so more campers means more revenue.\n            For example, most properties pay the same amount for mortgage and staffing\n            whether the property is half-empty or fully booked. By increasing the number of\n            reservations and spreading the overhead costs over a larger number of paying\n            customers, properties increase their profit. By making it possible for them to\n            dynamically price their campsites, we help them make more revenue from\n            reservations. By providing them with tools to pre-sell products like firewood,\n            we also help increase revenue. By providing information about surrounding\n            activities, we help them provide a better camper experience, which leads to\n            more reservations.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Think about an airplane that takes off half-full. That\n            airplane still has the same equipment and personnel costs as a full plane. Every\n            empty seat represents a lost revenue opportunity for that airline, just as\n            every empty campsite represents a lost revenue opportunity for a property. Filling\n            more campsites and pricing them appropriately generates more revenue. We help a\n            property to take more reservations and increase profits on existing\n            reservations.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("How does a property participate?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("All of our services are FREE for properties. Properties can\n            continue using their existing processes while also participating with Camp\n            Native, so no changes are needed to the ways in which a property does business.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("We've compiled a database of most of the short-stay\n            campgrounds, RV parks, cabins, and lodges in the USA. We're constantly\n            expanding and updating that list, so that we can enable every property to offer\n            online reservations to their customers. If you're a property owner and we\n            haven't already talked, we want to hear from you, so please ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(".");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("We help property owners to update their Camp Native listing\n            with full details on the property and campsites, services, activities, photos,\n            and other information that will attract customers to the property.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("Why isn't my property listed on Camp Native?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Although we've tried to find information for every\n            campground, RV park, cabin, and lodge in the USA, we've probably missed a few.\n            If you own a property and are unable to find it on Camp Native, please ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" and we'll be very happy\n            to add it.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("How are payments distributed?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Campers make full payment for a reservation at the time of\n            the reservation, and that money is distributed the same day to the property,\n            minus credit card processing fees.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("We use Braintree, a division of PayPal, for all payment\n            processing. Properties must complete a simple online application and provide\n            banking information so that money for reservations may be legally distributed\n            to the property. All activities and payments on Camp Native are secure, and all\n            banking details are stored only by PayPal, ensuring that there is no security\n            risk to properties or campers.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("Is Camp Native truly free for properties?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("The only fee that a property pays is for credit card\n            processing, the same as they would pay when accepting a reservation by phone.\n            For example, if a property takes a $50.00 reservation by phone and pays their\n            bank 3% for credit card processing, the property will keep $48.50 after credit\n            card fees are deducted. If a property accepts a reservation through Camp Native,\n            the property will receive the same amount.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("What about cancellations and refunds?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Payment for reservations is distributed to properties on the\n            same day that the reservation is made, and no money is held in escrow for\n            future cancellations and refunds. If a reservation is later changed or cancelled,\n            we charge the property's credit card, minus processing fees, to secure the\n            money needed to refund to the camper.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("How can I change the details and photos for my property?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Updating the information for your property is quite easy.\n            Please ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" and we'll help\n            you get started.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("The best photos are sized in a 4x3 ratio, which is the\n            standard for most digital cameras and camera phones. Photos that are 640x480 or\n            larger will work, but higher resolutions are preferred, such as 1920x1440. The\n            best photos are \"landscape\" (wide/horizontal), not \"portrait\" (tall/vertical).");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("How can I contact you with questions not answered here?");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Please ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" and we'll get right back to you.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [55]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [61]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [79]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [85]), 1, 1);
        return morphs;
      },
      statements: [["block", "link-to", ["camp-owners"], [], 0, null, ["loc", [null, [131, 72], [132, 46]]]], ["block", "link-to", ["camp-owners"], [], 1, null, ["loc", [null, [140, 83], [141, 46]]]], ["block", "link-to", ["camp-owners"], [], 2, null, ["loc", [null, [172, 19], [172, 75]]]], ["block", "link-to", ["contact-us"], [], 3, null, ["loc", [null, [181, 18], [181, 65]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});