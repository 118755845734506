define('cn-front/controllers/dashboard/reports/onboards', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        queryParams: ['page'],
        page: 1,
        orderBy: '',
        sort: 'ASC',
        statuses: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        types: ['none', 1, 2, 3, 4, 5, 6, 7],
        acct_mgr: false,
        acctMgrVal: 'all',
        state: '',
        processing: '',
        application: '',
        confirmation: '',
        pageType: '',

        reportTitle: (function () {
            return 'Active Campgrounds';
        }).property('report_type', 'model'),

        reportTitleSlug: (function () {
            return this.get('reportTitle') ? this.get('reportTitle').replace(/\s+/g, '-').toLowerCase() : 'report';
        }).property('report_type', 'model'),

        restoreDefaults: function restoreDefaults() {
            this.set('page', 1);
            this.set('orderBy', '');
            this.set('acctMgrVal', 'all');
            this.set('types', ['none', 1, 2, 3, 4, 5, 6, 7]);
            this.set('statuses', [1, 2, 3, 4, 5, 6, 7, 8, 9]);
            this.set('sort', 'ASC');
            this.set('state', '');
            this.set('processing', '');
            this.set('application', '');
            this.set('confirmation', '');
            this.set('pageType', '');
            $('#state').prop('selectedIndex', 0);
            $('#processing').prop('selectedIndex', 0);
            $('#application').prop('selectedIndex', 0);
            $('#confirmation').prop('selectedIndex', 0);
            $('#page_type').prop('selectedIndex', 0);
            $('#report-acctmgr').prop('selectedIndex', 0);
            $('#affiliate').prop('selectedIndex', 0);
            $('#owner_group').prop('selectedIndex', 0);
        },

        handleFilters: function handleFilters() {
            var state = $('#state').val();
            var processing = $('#processing').val();
            var application = $('#application').val();
            var confirmation = $('#confirmation').val();
            var pageType = $('#page_type').val();
            var affiliate = $('#affiliate').val();
            var owner_group = $('#owner_group').val();

            this.set('state', state);
            this.set('processing', processing);
            this.set('application', application);
            this.set('confirmation', confirmation);
            this.set('pageType', pageType);
            this.set('affiliate', affiliate);
            this.set('owner_group', owner_group);
            this.set('acctMgrVal', $('#report-acctmgr').val());
            this.typeCheckboxHandler();
            this.statusesCheckboxHandler();
            this.set('page', 1);
        },

        actions: {
            pageChanged: function pageChanged(current) {
                this.set('page', current);
            },
            getReports: function getReports() {
                this.handleFilters();
                this.send('refreshModel');
            },
            resetReports: function resetReports() {
                this.restoreDefaults();
                this.resetTypesCheckboxes();
                this.resetStatusesCheckboxes();
                this.send('refreshModel');
            },
            exportTo: function exportTo(type) {
                this.handleFilters();
                var reportUrl = this.get('apiConnect').getUrl('/' + type + '/reports/active');
                var acctmgrEmail = $('#report-acctmgr').val();
                reportUrl += '?search=' + this.search + '&page=' + this.page + '&acct_mgr=' + acctmgrEmail + '&orderBy=' + this.orderBy + '&sort=' + this.sort + '&state=' + this.state + '&processing=' + this.processing + '&application=' + this.application + '&confirmation=' + this.confirmation + '&affiliate=' + this.affiliate + '&owner_group=' + this.owner_group + '&page_type=' + this.pageType + '&cgReservation=' + this.cgReservation;
                for (var i = 0; i < this.statuses.length; i++) {
                    reportUrl += '&statuses%5B%5D=' + this.statuses[i];
                }
                for (i = 0; i < this.types.length; i++) {
                    reportUrl += '&types%5B%5D=' + this.types[i];
                }
                reportUrl += '&token=' + $.cookie('token');

                var windLocation = type == 'pdf' ? '_blank' : '_self';
                window.open(reportUrl, windLocation);
            }
        },

        toTheTop: (function () {
            $('body').animate({
                scrollTop: 0
            }, 200);
        }).observes('page'),

        typeCheckboxHandler: function typeCheckboxHandler() {
            var checks = $('.reservations_types :checkbox');
            var results = [];
            checks.map(function () {
                if ($(this)[0].checked) {
                    results.push($(this).attr("data-type"));
                }
            });
            this.set('types', results);
        },

        resetTypesCheckboxes: function resetTypesCheckboxes() {
            this.get('types').forEach(function (type) {
                var typeCheckbox = document.querySelector('.reservations_types [data-type="' + type + '"]');
                if (!typeCheckbox.checked) {
                    typeCheckbox.checked = true;
                }
            });
        },

        statusesCheckboxHandler: function statusesCheckboxHandler() {
            var checks = $('.reservations_statuses :checkbox');
            var results = [];
            checks.map(function () {
                if ($(this)[0].checked) {
                    results.push($(this).attr("data-status"));
                }
            });
            this.set('statuses', results);
        },

        resetStatusesCheckboxes: function resetStatusesCheckboxes() {
            this.get('statuses').forEach(function (status) {
                var statusCheckbox = document.querySelector('.reservations_statuses [data-status="' + status + '"]');
                if (!statusCheckbox.checked) {
                    statusCheckbox.checked = true;
                }
            });
        }
    });
});