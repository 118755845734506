define('cn-front/routes/auth/forgot-password', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;

    exports['default'] = _ember['default'].Route.extend({
        apiConnect: inject.service('api-resolver'),
        currentUser: inject.service('current-user'),

        actions: {
            resetPassword: function resetPassword() {
                var flashMessages = _ember['default'].get(this, 'flashMessages');
                flashMessages.clearMessages();

                $.fn.serializeObject = function () {
                    var o = {};
                    var a = this.serializeArray();
                    $.each(a, function () {
                        if (o[this.name]) {
                            if (!o[this.name].push) {
                                o[this.name] = [o[this.name]];
                            }
                            o[this.name].push(this.value || '');
                        } else {
                            o[this.name] = this.value || '';
                        }
                    });
                    return o;
                };

                var url = this.get('apiConnect').getUrl('/password/email');

                $.ajax({
                    type: "post",
                    url: url,
                    data: $('#forgot-password-form').serializeObject(),
                    success: function success(data) {
                        flashMessages.success(data.success);
                    },
                    error: function error(data) {
                        flashMessages.danger(data.responseJSON.error);
                    }
                });
            }
        }
    });
});