define('cn-front/controllers/dashboard/campground/edit/modal/unassign-user', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Controller.extend({
        apiConnect: inject.service('api-resolver'),
        actions: {
            unAssignUser: function unAssignUser() {
                var formdata = {
                    campground_id: this.get('model.campground_id'),
                    user_id: this.get('model.id')
                };

                var url = this.get('apiConnect').getUrl('/dashboard/campgrounds/assign/remove');
                var self = this;
                var flashMessages = _ember['default'].get(self, 'flashMessages');
                $.ajax({
                    type: "POST",
                    url: url,
                    data: formdata,
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', 'bearer ' + $.cookie('token'));
                    },
                    async: false,
                    success: function success(data) {
                        console.log(data);
                        if (data.success) {
                            flashMessages.success(_ember['default'].String.htmlSafe(data.success));
                        } else {
                            flashMessages.danger(_ember['default'].String.htmlSafe(data.error));
                        }
                        self.send('refreshModel');
                    }

                });
            }
        }
    });
});