define('cn-front/routes/404', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        activate: function activate() {
            _ember['default'].$('body').addClass('not-found');
        },
        deactivate: function deactivate() {
            _ember['default'].$('body').removeClass('not-found');
        },
        actions: {
            back: function back() {
                history.back();
            }
        }
    });
});