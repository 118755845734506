define('cn-front/components/campground/activities-section', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        actions: {
            showAll: function showAll(className) {
                var lists = $('.all-activities');
                var list = lists.find('.' + className);

                if (list.hasClass('expanded')) {
                    list.find('.show-all').text('Show more');
                    list.find('.list-activities').animate({
                        height: 190
                    }, 400, function () {
                        list.removeClass('expanded');
                    });
                } else {
                    list.addClass('expanded');

                    list.find('.show-all').text('Hide');
                    list.find('.list-activities').animate({
                        height: list.find('.list-activities').get(0).scrollHeight
                    }, 400, function () {
                        $(this).height('auto');
                    });
                }
            }
        }
    });
});