define('cn-front/utils/gtm', ['exports'], function (exports) {
    var gtmUtil = {

        defaultEvent: {
            event: 'trackEvent'
        },

        defaultPageView: {
            event: 'vpv',
            virtualPagePath: ''
        },

        createPayload: function createPayload(type, o) {
            var data = o || {};
            var defaultPayload = this['default' + type];
            var payload = data;
            Object.keys(defaultPayload).forEach(function (key) {
                payload[key] = data.hasOwnProperty(key) ? data[key] : defaultPayload[key];
            });
            return payload;
        },

        trackEvent: function trackEvent(o) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(this.createPayload('Event', o));
        },

        trackPageView: function trackPageView(path) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(this.createPayload('PageView', { virtualPagePath: path }));
        }
    };

    exports['default'] = gtmUtil;
});